import React, { useState } from "react";
import FormGroup from "@liveramp/motif/core/FormGroup";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import Radio from "@liveramp/motif/core/Radio";

export default function SizeDemo() {
  let [radio, setRadio] = useState("medium");
  let handleChange = event => setRadio(event.target.value);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Radio
            checked={radio === "medium"}
            onChange={handleChange}
            value="medium"
          />
        }
        label="Medium"
      />
      <FormControlLabel
        control={
          <Radio
            checked={radio === "size"}
            onChange={handleChange}
            size="small"
            value="size"
          />
        }
        size="small"
        label="Small"
      />
    </FormGroup>
  );
}
