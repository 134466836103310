import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M11.637 3.483a.505.505 0 01.724 0l3.356 3.356a.505.505 0 01-.357.867h-2.336v7.016c.571.2 1.064.566 1.424 1.039l4.123-2.381-1.168-2.023a.51.51 0 01.568-.752l4.584 1.229a.505.505 0 01.362.627l-1.228 4.584c-.111.44-.7.522-.93.124l-1.168-2.022-4.525 2.612a3.06 3.06 0 01-6.117-.149l.004.155-4.54-2.621-1.167 2.023a.51.51 0 01-.935-.116l-1.228-4.584a.505.505 0 01.362-.627l4.584-1.228a.505.505 0 01.573.742l-1.168 2.023 4.134 2.388a3.067 3.067 0 011.415-1.039v-7.02H8.649a.51.51 0 01-.367-.867l3.356-3.356z"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "Distribute"
);
