import React from "react";
import clsx from "clsx";
import useFormControl from "@material-ui/core/FormControl/useFormControl";
import useControlled from "@material-ui/core/utils/useControlled";
import Card from "../../core-components/Card";
import { makeStyles } from "../../utils";
import { CardStyle } from "../../utils/styleKeys";

// this component was shamelessly stolen from MUIs PrivateSwitchBase
export default React.forwardRef(function SwitchBase(props, ref) {
  const {
    autoFocus,
    checked: checkedProp,
    checkedIcon,
    children,
    classes,
    className,
    defaultChecked,
    disabled: disabledInitialState,
    icon,
    id,
    inputProps,
    inputRef,
    name,
    onBlur,
    onChange,
    onFocus,
    readOnly,
    required,
    selectable = false,
    size = "small",
    tabIndex,
    value,
    ...etc
  } = props;
  const defaultClasses = useStyles({ size });
  useCustomStyles();
  const [checked, setCheckedState] = useControlled({
    controlled: checkedProp,
    default: Boolean(defaultChecked),
    name: "LogoCard",
    state: "checked"
  });

  let disabled = disabledInitialState;

  const muiFormControl = useFormControl();

  const handleFocus = event => {
    if (onFocus) onFocus(event);
    if (muiFormControl?.onFocus) muiFormControl.onFocus(event);
  };

  const handleBlur = event => {
    if (onBlur) onBlur(event);
    if (muiFormControl?.onBlur) muiFormControl.onBlur(event);
  };

  const handleInputChange = event => {
    const newChecked = event.target.checked;
    setCheckedState(newChecked);

    if (onChange) onChange(event);
  };

  if (muiFormControl && typeof disabled === "undefined") {
    disabled = muiFormControl.disabled;
  }

  const ComposedCard = (
    <Card
      data-testid="LogoCard"
      className={clsx(
        "LogoCard",
        {
          "LogoCard-small": size === "small",
          "LogoCard-large": size === "large",
          "LogoCard--selectable": selectable,
          "Mui-checked": checked,
          "LogoCard--checked": checked,
          "Mui-disabled": disabled,
          "LogoCard--disabled": disabled
        },
        className
      )}
      classes={defaultClasses}
      disabled={disabled}
      ref={ref}
      role={selectable ? "checkbox" : null}
      elevation={selectable && checked ? 0 : 1}
      onFocus={selectable ? handleFocus : null}
      onBlur={selectable ? handleBlur : null}
      {...etc}
    >
      {selectable && (
        <input
          autoFocus={autoFocus}
          checked={checkedProp}
          defaultChecked={defaultChecked}
          className="LogoCard__input"
          disabled={disabled}
          id={id}
          name={name}
          onChange={handleInputChange}
          readOnly={readOnly}
          ref={inputRef}
          required={required}
          tabIndex={tabIndex}
          type="checkbox"
          value={value}
          {...inputProps}
        />
      )}
      {/* ensures all child elements are also disabled if the logocard is disabled */}
      {React.Children.map(children, (child, i) =>
        React.cloneElement(child, {
          key: `LogoCard-child-${i}`,
          disabled
        })
      )}
    </Card>
  );

  return selectable ? <label htmlFor={id}>{ComposedCard}</label> : ComposedCard;
});

const useStyles = makeStyles(theme => ({
  ...CardStyle,
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "192px",
    width: props => (props.size === "small" ? "192px" : "304px"),
    border: "2px solid transparent",

    "& .LogoCardActions .LogoCardActions-onHover": {
      display: "none"
    },

    "&:hover:not(.Mui-disabled) .LogoCardActions:not([data-hover='false'])": {
      "& .LogoCardActions-onHover": {
        display: "block"
      },

      "& .LogoCardActions-default": {
        display: "none"
      }
    }
  }
}));

const useCustomStyles = makeStyles(theme => ({
  "@global": {
    ".LogoCard--selectable": {
      ".Mui-focusVisible &:not(.Mui-disabled), &:not(.Mui-disabled):hover": {
        boxShadow: theme.shadows[24]
      }
    },
    ".LogoCard--checked": {
      borderColor: theme.palette.info.main,
      "&.Mui-disabled": {
        borderColor: theme.palette.disabled.main
      }
    },
    ".LogoCard--disabled": {
      "& .LogoCardHeader": {
        "& .MuiCardHeader-content": {
          color: theme.palette.text.disabled
        },
        "& .MuiCardHeader-action": {
          filter: "grayscale(100%)",
          opacity: "20%"
        }
      },
      "& .LogoCardMedia": {
        filter: "grayscale(100%)",
        opacity: "20%"
      }
    },
    ".LogoCard__input": {
      cursor: "inherit",
      opacity: 0,
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
      zIndex: 1,
      position: "fixed"
    }
  }
}));
