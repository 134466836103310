import React from "react";
import Select from "@liveramp/motif/core/Select";
import css from "../../../resources/Page.module.css";

export default function SimpleDemo() {
  let list = [
    { label: "one" },
    { label: "two" },
    { label: "three" },
    { label: "four" }
  ];

  return (
    <div className={css.grid}>
      <Select
        id="single-outlined"
        options={list}
        getOptionLabel={option => option.label}
      />
      <Select
        id="single-underlined"
        variant="underlined"
        options={list}
        getOptionLabel={option => option.label}
      />
    </div>
  );
}
