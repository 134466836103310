import React from "react";

let NavOut = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M22.502 1.448l-.45 7.208a.7.7 0 1 0 1.397.088l.55-8.792-8.793.55a.7.7 0 1 0 .088 1.397l7.208-.45z" />
      <path d="M13.245 11.695l10-10a.7.7 0 1 0-.99-.99l-10 10a.7.7 0 1 0 .99.99z" />
      <path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12a.7.7 0 0 0-1.4 0c0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6C1.4 6.146 6.146 1.4 12 1.4A.7.7 0 0 0 12 0z" />
    </g>
  </svg>
);

export default NavOut;
