import TableCell from "@material-ui/core/TableCell";
import withStyles from "../../styles/withStyles";
import { TableCellStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...TableCellStyle,
  root: {
    ...theme.typography.body3,
    backgroundColor: theme.palette.common.white,
    padding: `0 16px`,
    height: "52px",
    "&.MuiTableCell-head, &.MuiTableCell-footer": {
      height: "56px"
    },

    border: "none",
    ".MuiTableBody-root &": {
      borderBottom: "none",
      borderTop: `thin solid ${theme.palette.secondary.light}`
    },
    ".MuiTableBody-root .MuiTableRow-root:first-child &": {
      borderTop: `none`
    },
    ".MuiTableBody-root .MuiTableRow-root:last-child &": {
      borderBottom: `thin solid ${theme.palette.secondary.light}`
    },
    ".MuiTableBody-root .MuiTableRow-root:hover &.MuiTableCell-root": {
      // prevents any underlying background color from showing through on hover
      background: `linear-gradient(${theme.palette.info.hover}, ${theme.palette.info.hover}), linear-gradient(white, white)`,
      borderTop: `thin solid ${theme.palette.info.main}`,
      borderBottom: `thin solid ${theme.palette.info.main}`
    },
    ".MuiTable-root:not(.MuiTable-stickyHeader) .MuiTableBody-root .MuiTableRow-root:hover + .MuiTableRow-root &.MuiTableCell-root":
      {
        borderTop: `thin solid ${theme.palette.info.main}`
      },

    ".MuiTableBody-root .MuiTableRow-root & .MuiCheckbox-root": {
      visibility: "hidden"
    },
    ".MuiTableBody-root .MuiTableRow-root & .MuiCheckbox-root.Mui-checked,\
    .MuiTableBody-root .MuiTableRow-root:hover & .MuiCheckbox-root": {
      visibility: "visible"
    }
  },
  head: {
    ...theme.typography.subtitle3,
    backgroundColor: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.secondary.light}`,
    borderTop: "none",
    top: 0,
    position: "sticky"
  },
  footer: {
    backgroundColor: "white",
    borderBottom: "none",
    borderTop: `2px solid ${theme.palette.secondary.light}`,
    bottom: 0,
    position: "sticky"
  },
  stickyHeader: {
    backgroundColor: theme.palette.common.white
  },
  sizeSmall: {
    height: "36px",
    "&.MuiTableCell-head, &.MuiTableCell-footer": {
      height: "40px"
    }
  }
});

export default withStyles(styles, { name: "MuiTableCell" })(TableCell);
