import React from "react";

let FlagAU = props => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0H2C.89543 0 0 .89543 0 2v14c0 1.1046.89543 2 2 2h20c1.1046 0 2-.8954 2-2V2c0-1.10457-.8954-2-2-2z"
      fill="#0A17A7"
    />
    <path
      d="M5.74198 0c.16569 0 .3.134315.3.3L6.041 3.398 10.108.260666c.1301-.100381.3167-.07747.4186.051393l.3082.389719c.0881.111388.0845.265914-.0001.372512l-.0491.04889c-.0009.00074-.0019.00147-.0028.00219L7.158 3.922l3.4565.00011c.1656 0 .3.13432.3.3v.64138c0 .16569-.1344.3-.3.3L7.101 5.163l3.7926 2.90672c.0008.00057.0015.00114.0023.00172.1318.10498.1532.29372.0499.42332l-.3028.38016c-.1019.12788-.2874.15065-.4171.05121L6.041 5.72l.00098 2.98c0 .16569-.13431.3-.3.3h-.54889c-.16568 0-.3-.13431-.3-.3L4.893 5.67.806463 8.82494c-.130064.10038-.316675.07747-.418594-.0514l-.3082307-.38971c-.1027807-.12996-.08075312-.31863.0491997-.42141.000932-.00073.001867-.00146.002807-.00219L3.755 5.163.3 5.16349c-.165685 0-.3-.13431-.3-.3v-.64138c0-.16568.134315-.3.3-.3L3.695 3.922.308036 1.32623c-.000747-.00057-.00149-.00114-.002231-.00172L.254723 1.2737C.170432 1.16681.167372 1.01227.255856.901189L.558688.521024C.66055.393149.846044.370378.975812.469817L4.893 3.471 4.89309.3c0-.165685.13432-.3.3-.3h.54889z"
      fill="#E02525"
      stroke="#fff"
      strokeWidth=".5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.951 13.4776l-.2367.8081.2367.8081-.8081-.2367-.8082.2367.2367-.8081-.2367-.8081.8082.2367.8081-.2367zm-12.80814-2.049l.50382 1.0208 1.12657.1637-.81519.7946.19244 1.122L5.14286 14l-1.00763.5297.19244-1.122-.81519-.7946 1.12657-.1637.50381-1.0208zM19.2612 9.31023l-.1183.40406.1183.40411-.404-.1184-.4041.1184.1183-.40411-.1183-.40406.4041.11835.404-.11835zm-4.7388-2.11835L14.2857 8l.2367.80813-.8081-.2367-.8081.2367L13.1429 8l-.2367-.80812.8081.23669.8081-.23669zm6.8572-1.14286l-.2367.80813.2367.80812-.8082-.2367-.8081.2367L20 6.85715l-.2367-.80813.8081.2367.8082-.2367zM17.951 2.62045l-.2367.80813.2367.80812L17.1429 4l-.8082.2367.2367-.80812-.2367-.80813.8082.2367.8081-.2367z"
      fill="#fff"
    />
  </svg>
);

export default FlagAU;
