import React from "react";
import { Link } from "react-router-dom";

import { generateDemos } from "../../resources/Demo";
import { ReactComponent as Logo } from "../../resources/logo.svg";
import CodeChunk from "../../resources/CodeChunk";
import ColorDemo from "./demos/Color";
import IconShuffleDemo from "./demos/IconShuffle";
import Button from "@liveramp/motif/core/Button";
import { Dropper, Puzzle, SuperUniverse } from "@liveramp/motif/icons";
import css from "./index.module.css";

export default function Motif() {
  let demos = generateDemos("getting-started/motif", ["GettingStarted"]);

  return (
    <div className={css.app}>
      <div className={css.logo}>
        <Logo />
        <h1>
          <span className={css.spacingHack}>Mo</span>tif
        </h1>
        <h4>/mō'tēf/</h4>
        <h4>
          A recurring subject, theme, idea, etc., especially in a literary,
          artistic, or musical work.
        </h4>
      </div>
      <div className={css.cards}>
        <div className={css.card}>
          <h3>
            <Puzzle />
            Usage
          </h3>
          <p>
            Motif provides an application-level wrapper and pre-styled
            components to inject and match the LiveRamp branding and style
            guide.
          </p>
          <div className={css.codeChunk}>
            <CodeChunk>{demos.GettingStarted}</CodeChunk>
          </div>
          <div>
            <Button
              component={Link}
              to="/getting-started/installation"
              variant="secondary"
              className={css.button}
            >
              Get Started
            </Button>
          </div>
        </div>
        <div className={css.card}>
          <h3>
            <Dropper />
            Colors
          </h3>
          <p>
            Convey meaning through color. Out of the box you get access to all
            the colors in the LiveRamp design specifications.
          </p>
          <ColorDemo />
          <div>
            <Button
              component={Link}
              to="/style/colors"
              variant="secondary"
              className={css.button}
            >
              Colors
            </Button>
          </div>
        </div>
        <div className={css.card}>
          <h3>
            <SuperUniverse />
            Icons
          </h3>
          <p>
            LiveRamp icons are delightful, beautifully crafted symbols for
            common actions and items. They are designed to be simple, modern,
            and friendly.
          </p>
          <IconShuffleDemo />
          <div>
            <Button
              component={Link}
              to="icons"
              variant="secondary"
              className={css.button}
            >
              Icons
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
