import React, { Component } from "react";
import clsx from "clsx";
import Collapse from "@liveramp/motif/core/Collapse";
import IconButton from "@liveramp/motif/core/IconButton";
import Tooltip from "@liveramp/motif/core/Tooltip";

import CodeChunk from "./CodeChunk";

import { Code, Github, Palette } from "@liveramp/motif/icons";
import css from "./Demo.module.css";

let DEMO_DIR = "https://github.com/LiveRamp/motif/tree/master/docs/src";

export default class Demo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsx: false,
      css: false
    };
  }

  toggleCode = code => () => this.setState({ [code]: !this.state[code] });

  render({ demo, children, dark, leftAligned } = this.props) {
    return (
      <div className={css.wrapper}>
        <div className={css.header}>
          <Tooltip title="Javascript">
            <IconButton onClick={this.toggleCode("jsx")} disabled={!demo.jsx}>
              <Code />
            </IconButton>
          </Tooltip>
          <Tooltip title="CSS">
            <IconButton onClick={this.toggleCode("css")} disabled={!demo.css}>
              <Palette />
            </IconButton>
          </Tooltip>
          <Tooltip title="Github">
            <IconButton href={`${DEMO_DIR}/${demo.str}.jsx`}>
              <Github />
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <Collapse
            in={this.state.jsx}
            timeout="auto"
            unmountOnExit
            className={css.collapse}
          >
            <CodeChunk>{demo.jsx}</CodeChunk>
          </Collapse>
          <Collapse
            in={this.state.css}
            timeout="auto"
            unmountOnExit
            className={css.collapse}
          >
            <CodeChunk css>{demo.css}</CodeChunk>
          </Collapse>
          <div
            className={clsx(css.body, {
              [css.dark]: dark,
              [css.bodyLeft]: leftAligned
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export function generateDemos(component, demoNames) {
  let demos = {};
  let path = `${component}/demos`;

  demoNames.forEach(demoName => {
    let jsx, css;
    try {
      jsx = require(`!!raw-loader!../${path}/${demoName}.jsx`);
    } catch {
      jsx = "";
    }

    try {
      css = require(`!!raw-loader!../${path}/${demoName}.module.css`);
    } catch {
      css = "";
    }

    let demoObj = {
      [demoName]: {
        // str: `${component}/${demoName}`,
        jsx,
        css
      }
    };

    Object.assign(demos, demoObj);
  });

  return demos;
}
