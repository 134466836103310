import React from "react";
import MuiChip from "@material-ui/core/Chip";
import withStyles from "../../styles/withStyles";
import blue from "../../styles/colors/blue";
import { ChipStyle } from "../../utils/styleKeys";

function Chip({ children, ...props }) {
  return <MuiChip label={children} {...props} />;
}

let styles = theme => ({
  ...ChipStyle,
  root: {
    ...theme.typography.subtitle2,
    borderRadius: "4px",
    padding: "0 16px",
    gap: "4px",
    height: "32px",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary
  },
  label: {
    padding: "0"
  },
  sizeSmall: {
    height: "24px"
  },
  deleteIcon: {
    margin: 0
  },

  colorPrimary: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.common.white
  },
  deletableColorPrimary: {
    "&:focus": {
      backgroundColor: blue[400]
    }
  },
  deleteIconColorPrimary: {
    fill: theme.palette.info.light,
    "&:hover, &:active": {
      fill: blue[100]
    }
  },

  colorSecondary: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.text.primary
  }
});

export default withStyles(styles, { name: "MuiChip" })(Chip);
