import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import SimpleInline from "./demos/SimpleInline";
import Custom from "./demos/Custom";
import QueuedTransitions from "./demos/QueuedTransitions";
import ForcedTransitions from "./demos/ForcedTransitions";

let demoNames = ["Simple", "Custom", "QueuedTransitions", "ForcedTransitions", "SimpleInline"];

export default function Alert() {
  let demos = generateDemos("core-component-demo/alert", demoNames);

  return (
    <>
    <Page components="Alert">
      <Title>Alerts</Title>
      <Subtitle>
        Alerts provide brief messages about app processes through a message
      </Subtitle>
      <Supporting>
        Alerts inform users of a process that an app has performed or will
        perform. You can use them to display notifications.
      </Supporting>
      <Usage>
        <ul>
          <li>Only one alert should be displayed at a time.</li>
          <li>
            They should only appear temporarily at the top of the screen. They
            should not require user input to disappear.
          </li>
          <li>
            If possible, it should contain a short single line of text directly
            related to the operation performed.
          </li>
          <li>They may contain a text button that performs an action.</li>
          <li>
            Alerts can disrupt a user's workflow, so they should only be used
            for time-sensitive messages.
          </li>
        </ul>
      </Usage>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Custom Alerts</SectionTitle>
      <p>Colored alerts help define the context and urgency of a message.</p>
      <ul>
        <li>
          <b>Default</b> alerts should be used for informational purposes.
        </li>
        <li>
          <b>Success</b> alerts should be reserved for success messages.
        </li>
        <li>
          <b>Warning</b> alerts should be reserved for informational purposes
          that can affect the user's expectation of their actions.
        </li>
        <li>
          <b>Danger</b> alerts should be used for high severity actions or
          errors.
        </li>
      </ul>
      <p>
        N.B. the alerts in the following snippet have been manually aligned for
        demonstration purposes. Do not adjust the position of the alerts in
        production applications.
      </p>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>

      <SectionTitle>Transitions</SectionTitle>
      <p>
        When a second alert is triggered while the first is displayed, the first
        should start the contraction motion downwards before the second one
        animates upwards.
      </p>

      <SectionTitle subtitle>Queued</SectionTitle>
      <p>
        Alerts in this demo are placed in a queue; the next alert will render
        only after the first has been cleared or has timed out. This demo's
        timeout has been set to 2 seconds.
      </p>
      <Demo demo={demos.QueuedTransitions}>
        <QueuedTransitions />
      </Demo>

      <SectionTitle subtitle>Forced</SectionTitle>
      <p>
        Alerts in this demo are immediately forced to close to render the next
        alert.
      </p>
      <Demo demo={demos.ForcedTransitions}>
        <ForcedTransitions />
      </Demo>
      <SectionTitle>Alert Inline</SectionTitle>
      <p>
        An inline version of the main alert component that you can use for in-context help that stays in-line with specific affordances. 
      </p>
      <Demo demo={demos.SimpleInline}>
        <SimpleInline />
      </Demo>
    </Page>
    </>
  );
}
