import React from "react";
import ErrorBoundary from "../../core-components/ErrorBoundary";
import GlobalNavigation from "./GlobalNavigation";
import makeStyles from "../../styles/makeStyles";

export default function BoundaryWrappedGlobalNavigation({
  errorCallback = () => {},
  ...props
}) {
  useStyles(props);
  const errorDisplay = <nav className="GN-error"></nav>;

  return (
    <ErrorBoundary errorCallback={errorCallback} errorDisplay={errorDisplay}>
      <GlobalNavigation {...props} />
    </ErrorBoundary>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".GN-error": {
      display: "flex",
      flexDirection: "row",
      fontFamily: theme.typography.fontFamilyHeader,
      backgroundColor: ({ customisation }) =>
        customisation?.backgroundColor
          ? customisation.backgroundColor
          : theme.palette.grey[900],
      borderRight: 0,
      transition: theme.transitions.common.enteringScreen,
      minHeight: "100vh",
      minWidth: "50px"
    }
  }
}));
