import React from "react";
import Table from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import { CustomizationWarning } from "../index";
import Demo, { generateDemos } from "../../resources/Demo";
import CodeChunk from "../../resources/CodeChunk";
import Simple from "./demos/Simple";
import MultiFilter from "./demos/MultiFilter";
import VirtualizedList from "./demos/VirtualizedList";

let demoNames = ["Simple", "MultiFilter", "OptionTypes", "VirtualizedList"];

export default function Filter() {
  let demos = generateDemos("composed-component-demo/filter", demoNames);

  return (
    <Page components={["Filter"]}>
      <Title>Filter</Title>
      <Subtitle>
        An advanced multi-select option customized for low-space pages
      </Subtitle>
      <Supporting>
        This <code>Select</code>-like component variation shows a summary of
        selected options in its <code>Button</code> label. The search field and
        checkboxes are contained within a <code>Popper</code> component that
        sits elevated from the content beneath.
      </Supporting>
      <Usage>
        <ul>
          <li>Typically used on pages containing tables to apply filtering</li>
          <li>
            If you want to display a number of files associated with each
            option, give each option an <code>optCount</code> for the number to
            display in-line with its title.
          </li>
        </ul>
      </Usage>

      <CustomizationWarning />

      <h4>Props</h4>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Prop</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Default Value</TableCell>
            <TableCell>Values</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <code>listPlacement</code>
            </TableCell>
            <TableCell>String</TableCell>
            <TableCell>
              <code>"bottom-start"</code>
            </TableCell>
            <TableCell>"bottom-start", "top-start"</TableCell>
            <TableCell>
              The filter list will open from the bottom of the button by
              default. <br /> If a button is rendered near the bottom of a page
              and should open <br /> from the top, use{" "}
              <code>listPlacement="top-start"</code>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>loading</code>
            </TableCell>
            <TableCell>Boolean</TableCell>
            <TableCell>
              <code>false</code>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Displays a loading indicator in the search bar
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>onOpen</code>
            </TableCell>
            <TableCell>Function</TableCell>
            <TableCell>
              <code>undefined</code>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              The onOpen function will be called with a reference to the menu
              DOM node
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>onClose</code>
            </TableCell>
            <TableCell>Function</TableCell>
            <TableCell>
              <code>undefined</code>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              The onClose function will be called with a reference to the menu
              DOM node
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>MultiFilter</SectionTitle>
      <p>
        The multifilter can be used anywhere to condense multiple selectable
        options into a single dropdown. It can consist of single options, or an
        object with an array of options. The context of these options can be
        disparate.
      </p>
      <p>
        In a Rich Data Table, it should be used as a filter for data that is not
        necessarily being shown in the table; this can include data that isn't
        being rendered or data that requires another API call to retrieve.
      </p>
      <p>
        Because of the Apply button inside of the MultiFilter, there is no
        uncontrolled version of this component.
      </p>
      <Demo demo={demos.MultiFilter}>
        <MultiFilter />
      </Demo>
      <p>
        The multifilter expects an array of options that can be in the following
        shapes e.g.
      </p>
      <CodeChunk jsx>{demos.OptionTypes}</CodeChunk>
      <p>Please note the following requirements/notes:</p>
      <ul>
        <li>All top level object options must have a unique id</li>
        <li>
          All suboptions must have a unique id within their group of options
        </li>
        <li>
          Any predefined values passed to the component must contain the same
          structure as the options
        </li>
        <li>
          Suboptions must be defined in the option object under{" "}
          <code>options</code>
        </li>
        <li>
          The label used for the option is determined in the following order:
          <ul>
            <li>The option string's value</li>
            <li>
              The option object's <code>getOptionLabel</code>
            </li>
            <li>
              The multifilter's <code>getOptionLabel</code> prop
            </li>
          </ul>
        </li>
      </ul>

      <SectionTitle>Virtualized List</SectionTitle>
      <Usage>
        <ul>
          <li>
            Typically used to increase performance of rendering large lists
          </li>
          <li>
            ⚠️ Text length of virtualized list items can't be determined ahead
            of time and as a result the default width of the list container
            might not be sufficient, causing unsightly text overlap. Consider
            using <code>virtualizedWidth</code> to set an explicit width on list
            items based on the longest item in the list.
          </li>
          <li>
            ✅ To determine what the <code>virtualizedWidth</code> should be,
            render the list un-virtualized first to let the UI render naturally,
            then use the dev tools inspector to get the computed width.
          </li>
        </ul>

        <h4>Props</h4>
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell>Prop</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Default Value</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Required?</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>
                <code>virtualized</code>
              </TableCell>
              <TableCell>Boolean</TableCell>
              <TableCell>
                <code>false</code>
              </TableCell>
              <TableCell>Use a virtualized list</TableCell>
              <TableCell>Optional</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <code>virtualizedWidth</code>
              </TableCell>
              <TableCell>String | undefined</TableCell>
              <TableCell>
                <code>undefined</code>
              </TableCell>
              <TableCell>Sets virtualized list items width in px</TableCell>
              <TableCell>
                Optional
                <br />
                (Recommended when <code>virtualized</code> is set, ignored
                otherwise)
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Usage>
      <Demo demo={demos.VirtualizedList}>
        <VirtualizedList />
      </Demo>
    </Page>
  );
}
