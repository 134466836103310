let purple = {
  50: "#f3e8fd",
  100: "#e1c4e7",
  200: "#d7b2dc",
  300: "#cea0d1",
  400: "#c58ec7",
  500: "#bc7cbc",
  600: "#b36ab1",
  700: "#a958a6",
  800: "#a0469b",
  900: "#973490"
};

export default purple;
