/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiToggleButton",
  description: "",
  displayName: "ToggleButton",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: true,
      description: "The content of the button."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the button will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableFocusRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the  keyboard focus ripple will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the ripple effect will be disabled."
    },
    onChange: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    onClick: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    selected: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the button will be rendered in an active state."
    },
    size: {
      type: {
        name: "enum",
        value: [
          { value: "'small'", computed: false },
          { value: "'medium'", computed: false },
          { value: "'large'", computed: false }
        ]
      },
      required: false,
      description: "@ignore",
      defaultValue: { value: "'medium'", computed: false }
    },
    value: {
      type: { name: "any" },
      required: true,
      description:
        "The value to associate with the button when selected in a\r\nToggleButtonGroup."
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    selected: {
      description:
        "Pseudo-class applied to the root element if `selected={true}`."
    },
    label: { description: "Styles applied to the `label` wrapper element." },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"`.'
    },
    sizeLarge: {
      description: 'Styles applied to the root element if `size="large"`.'
    }
  }
};
export default generatedDocs;
