import React from "react";
import Motif from "@liveramp/motif/core/Motif";

import { BrowserRouter as Router } from "react-router-dom";
import { ScrollToTop } from "./resources/utils";
import Navigation from "./nav/Navigation";

export default function App() {
  return (
    <Motif>
      <Router>
        <ScrollToTop>
          <Navigation />
        </ScrollToTop>
      </Router>
    </Motif>
  );
}
