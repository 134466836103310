import React from "react";
import css from "./CSSModules.module.css";

export default function CSSModulesDemo() {
  return (
    <div className={css.root}>
      <p className={css.blue}>
        This is blue since it is inside the blue class.
      </p>
      <p className={css.green}>
        This is green since it is inside the green class
      </p>
      <p className={css.teal}>
        and this is teal since it is inside the teal class
      </p>
    </div>
  );
}
