import React, { useState } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Collapse from "@liveramp/motif/core/Collapse";
import Rdt from "./Rdt";
import CodeChunk from "../../resources/CodeChunk";

const columnCode = require(`!!raw-loader!./RdtColumnDefinitions.js`);
const rdtCode = require(`!!raw-loader!./Rdt.js`);
const sidepanelCode = require(`!!raw-loader!./RdtSidePanel.js`);
const toolbarCode = require(`!!raw-loader!./RdtToolbarActions.js`);

export default function Sandbox() {
  const [collapseOpen, setCollapseOpen] = useState(null);

  const handleCollapsibles = newCollapseOpen => {
    setCollapseOpen(null);
    if (!collapseOpen) {
      setCollapseOpen(newCollapseOpen);
    } else if (collapseOpen !== newCollapseOpen) {
      // timeout so that the previous collapsible segment has time to visibly close
      setTimeout(() => {
        setCollapseOpen(newCollapseOpen);
      }, 1000);
    }
  };

  return (
    <>
      <ButtonFormGroup>
        <Button
          onClick={() => handleCollapsibles("columns")}
          variant="outlined"
        >
          RDT Column Definitions
        </Button>
        <Button onClick={() => handleCollapsibles("rdt")} variant="outlined">
          RDT Sample Code
        </Button>
        <Button
          onClick={() => handleCollapsibles("toolbar")}
          variant="outlined"
        >
          RDT Toolbar Actions Code
        </Button>
        <Button
          onClick={() => handleCollapsibles("sidepanel")}
          variant="outlined"
        >
          RDT Side Panel Code
        </Button>
      </ButtonFormGroup>
      <Collapse in={collapseOpen === "columns"} timeout={750} unmountOnExit>
        <br />
        <CodeChunk>{columnCode}</CodeChunk>
        <br />
      </Collapse>
      <Collapse in={collapseOpen === "rdt"} timeout={750} unmountOnExit>
        <br />
        <CodeChunk>{rdtCode}</CodeChunk>
        <br />
      </Collapse>
      <Collapse in={collapseOpen === "toolbar"} timeout={750} unmountOnExit>
        <br />
        <CodeChunk>{toolbarCode}</CodeChunk>
        <br />
      </Collapse>
      <Collapse in={collapseOpen === "sidepanel"} timeout={750} unmountOnExit>
        <br />
        <CodeChunk>{sidepanelCode}</CodeChunk>
        <br />
      </Collapse>
      <Rdt />
      <br />
      <p>
        N.B. Filters should be used to fetch and filter{" "}
        <strong>data that is not in the table</strong>.
      </p>
      <p>
        The Spotify API does not accept any additional parameters so for the
        purposes of this demo, it is using data in the table. Please be aware
        changing the filters in the table will not be reflected in the
        [multi]filter component.
      </p>
    </>
  );
}
