/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "TreeView",
  description: "",
  displayName: "TreeView",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    defaultCollapseIcon: {
      type: { name: "node" },
      required: false,
      description: "The default icon used to collapse the node."
    },
    defaultEndIcon: {
      type: { name: "node" },
      required: false,
      description:
        "The default icon displayed next to a end node. This is applied to all\r\ntree nodes and can be overridden by the TreeItem `icon` prop."
    },
    defaultExpanded: {
      type: { name: "arrayOf", value: { name: "string" } },
      required: false,
      description: "Expanded node ids. (Uncontrolled)",
      defaultValue: { value: "[]", computed: false }
    },
    defaultExpandIcon: {
      type: { name: "node" },
      required: false,
      description: "The default icon used to expand the node."
    },
    defaultParentIcon: {
      type: { name: "node" },
      required: false,
      description:
        "The default icon displayed next to a parent node. This is applied to all\r\nparent nodes and can be overridden by the TreeItem `icon` prop."
    },
    defaultSelected: {
      type: {
        name: "union",
        value: [
          { name: "arrayOf", value: { name: "string" } },
          { name: "string" }
        ]
      },
      required: false,
      description:
        "Selected node ids. (Uncontrolled)\r\nWhen `multiSelect` is true this takes an array of strings; when false (default) a string.",
      defaultValue: { value: "[]", computed: false }
    },
    disableSelection: {
      type: { name: "bool" },
      required: false,
      description: "If `true` selection is disabled.",
      defaultValue: { value: "false", computed: false }
    },
    expanded: {
      type: { name: "arrayOf", value: { name: "string" } },
      required: false,
      description: "Expanded node ids. (Controlled)"
    },
    multiSelect: {
      type: { name: "bool" },
      required: false,
      description: "If true `ctrl` and `shift` will trigger multiselect.",
      defaultValue: { value: "false", computed: false }
    },
    onNodeSelect: {
      type: { name: "func" },
      required: false,
      description:
        "Callback fired when tree items are selected/unselected.\r\n\r\n@param {object} event The event source of the callback\r\n@param {(array|string)} value of the selected nodes. When `multiSelect` is true\r\nthis is an array of strings; when false (default) a string."
    },
    onNodeToggle: {
      type: { name: "func" },
      required: false,
      description:
        "Callback fired when tree items are expanded/collapsed.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {array} nodeIds The ids of the expanded nodes."
    },
    selected: {
      type: {
        name: "union",
        value: [
          { name: "arrayOf", value: { name: "string" } },
          { name: "string" }
        ]
      },
      required: false,
      description:
        "Selected node ids. (Controlled)\r\nWhen `multiSelect` is true this takes an array of strings; when false (default) a string."
    }
  },
  styles: { root: { description: "Styles applied to the root element." } }
};
export default generatedDocs;
