let reporting1 = {
  50: "#dcecc9",
  100: "#b3ddcc",
  200: "#8acdce",
  300: "#62bed2",
  400: "#46aace",
  500: "#3d91be",
  600: "#3577ae",
  700: "#2d5e9e",
  800: "#24448e",
  900: "#1c2b7f"
};

export default reporting1;
