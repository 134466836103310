// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Image category

let icons = [
  "LiverampLogo",
  "SegmentRobot",
  "Wallpaper",
  "ConnectIllustration"
];

export default icons;
