import React from "react";
import MuiTreeItem from "@material-ui/lab/TreeItem";
import { makeStyles, mergeClasses } from "../../utils";
import { TreeItemStyle } from "../../utils/styleKeys";

const TreeItem = ({ classes, size, ...props }) => {
  const defaultClasses = useStyles({ size });
  return (
    <MuiTreeItem classes={mergeClasses(defaultClasses, classes)} {...props} />
  );
};

const variations = {
  small: {
    labelGap: "8px",
    contentGap: 0,
    marginLeft: "16px"
  },
  medium: {
    labelGap: "8px",
    contentGap: 0,
    marginLeft: "32px"
  },
  large: {
    labelGap: "16px",
    contentGap: "8px",
    marginLeft: "32px"
  }
};

const useStyles = makeStyles(
  theme => ({
    ...TreeItemStyle,
    root: {
      "& svg": {
        fontSize: theme.typography.h2.fontSize,
        fill: theme.palette.secondary.main
      }
    },
    content: {
      height: "32px",
      gap: ({ size }) => variations[size].contentGap
    },
    group: {
      marginLeft: "8px",
      "& .MuiCollapse-wrapper": {
        marginLeft: ({ size }) => variations[size].marginLeft
      }
    },
    label: {
      fontSize: theme.typography.body2.fontSize,
      fontFamily: theme.typography.body2.fontFamily,
      color: theme.palette.text.primary,
      height: "32px",
      display: "flex",
      alignItems: "center",
      gap: ({ size }) => variations[size].labelGap,
      paddingLeft: "8px"
    }
  }),
  { name: "MuiTreeItem" }
);

export default TreeItem;
