import React from "react";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import Button from "@liveramp/motif/core/Button";
import reporting1 from "@liveramp/motif/colors/reporting1";

export default function HookDemo() {
  const classes = useStyles();
  return <Button className={classes.root}>Hook</Button>;
}

const useStyles = makeStyles({
  root: {
    background: `linear-gradient(45deg, ${reporting1[200]} 30%,  ${reporting1[500]} 90%)`
  }
});
