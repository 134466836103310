import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path
        d="M6.5 19.5c1.1 0 2 .9 2 2s-.9 2-2 2-1.99-.9-1.99-2 .89-2 1.99-2zm10 0c1.1 0 2 .9 2 2s-.9 2-2 2-1.99-.9-1.99-2 .89-2 1.99-2zm-15-16h1.64c.338 0 .66.174.84.458l.06.112.67 1.43h7.47a6.27 6.27 0 005.606 6.185L16.8 13.47c-.314.572-.9.966-1.579 1.023l-.171.007H7.6l-1.1 2h11c.55 0 1 .45 1 1 0 .51-.388.935-.884.993l-.116.007h-11c-1.47 0-2.415-1.523-1.817-2.835l.067-.135 1.35-2.44L2.5 5.5h-1c-.55 0-1-.45-1-1 0-.51.388-.935.884-.993L1.5 3.5h1.64H1.5zm16.95-3a4.95 4.95 0 110 9.9 4.95 4.95 0 010-9.9zm2.475 4.125h-4.95a.825.825 0 00-.103 1.644l.103.006h4.95a.825.825 0 000-1.65z"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  "UnrequestSegment"
);
