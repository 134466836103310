import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <>
    <path d="M11 7.75a.57.57 0 00-.55 0 .56.56 0 00-.26.48v4.29a.57.57 0 00.46.48h.09a.57.57 0 00.47-.56V9.32l2.85 2-3.76 2.57-.07.11a.57.57 0 00.41.97.58.58 0 00.33-.1l4.43-3.12.07-.06a.54.54 0 00.16-.45.56.56 0 00-.24-.42L11 7.75z" />
    <path d="M22.32 5.28a1 1 0 00-1-.91H2.59a1 1 0 00-.91 1v11.25a1 1 0 001 .91h18.73a1 1 0 00.91-1V5.28zM2.82 16.4V5.51h18.36v10.88l-18.36.01zM16 18.63H8a.48.48 0 00-.3.49c0 .28.17.5.37.5h8a.48.48 0 00.3-.49c-.03-.27-.2-.5-.37-.5z" />
  </>,
  "AdvancedTV"
);
