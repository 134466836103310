import React from "react";
import Chip from "@liveramp/motif/core/Chip";
import { AddCircle, Edit, Delete, Help, Warning } from "@liveramp/motif/icons";
import democss from "./Styling.module.css";
import css from "../../../resources/Page.module.css";
import withStyles from "@liveramp/motif/styles/withStyles";

function IconsDemo({ classes }) {
  return (
    <div>
      <Chip className={democss.chip}>Color</Chip>
      <div className={css.grid}>
        <AddCircle color="primary" />
        <Edit htmlColor="#9e9e9e" />
        <Help className={democss.blueIcon} />
        <Warning classes={{ root: classes.colorsRoot }} />
        <Delete style={{ color: "#f13a42" }} />
      </div>
      <br />
      <Chip className={democss.chip}>Size</Chip>
      <div className={css.grid}>
        <AddCircle fontSize="large" />
        <Edit className={democss.large} />
        <Help classes={{ root: classes.fontSizeRoot }} />
        <Warning classes={{ root: classes.whSizeRoot }} />
        <Delete style={{ fontSize: "2rem" }} />
      </div>
    </div>
  );
}

let styles = theme => ({
  colorsRoot: {
    fill: theme.palette.warning.main
  },
  fontSizeRoot: {
    fontSize: "2rem"
  },
  whSizeRoot: {
    height: "32px",
    width: "32px"
  }
});

export default withStyles(styles)(IconsDemo);
