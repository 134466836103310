import React from "react";
import {
  GlobalActionButton,
  Action
} from "@liveramp/motif/core/GlobalActionButton";
import Add from "@liveramp/motif/icons/Add";

export default function SingleActionDemo() {
  const handleItemClick = () => {
    alert("you clicked an action :)");
  };

  return (
    <GlobalActionButton icon={<Add />}>
      <Action onClick={handleItemClick}>New Audience</Action>
    </GlobalActionButton>
  );
}
