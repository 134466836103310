import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      d="M10.27 4.99L2.737 17.998A2 2 0 004.468 21h15.063a2 2 0 001.731-3.002L13.731 4.99a2 2 0 00-3.462 0zM12 6l7.53 13H4.47M11 11v2.732a1 1 0 102 0V11a1 1 0 00-2 0zm0 5v2h2v-2"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "WarningOutline"
);
