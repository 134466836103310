import React from "react";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, mergeClasses } from "../../utils";
import { ListItemStyle } from "../../utils/styleKeys";

export default function ListItem({ classes, ...props }) {
  let defaultClasses = useStyles();

  return (
    <MuiListItem classes={mergeClasses(defaultClasses, classes)} {...props} />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...ListItemStyle,
    root: {
      "&:hover": {
        backgroundColor: theme.palette.secondary.hover
      },
      "&:active": {
        backgroundColor: theme.palette.secondary.active
      },
      "&.Mui-focusVisible, &.Mui-selected": {
        backgroundColor: theme.palette.secondary.selected,
        "&:hover": {
          backgroundColor: theme.palette.secondary.hover
        }
      },
      "& .MuiTouchRipple-root span": {
        backgroundColor: theme.palette.secondary.active,
        opacity: 0.4
      }
    },
    disabled: {
      "&:hover": {
        backgroundColor: "transparent"
      }
    }
  }),
  { name: "MuiListItem" }
);
