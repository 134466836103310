import React, { useContext } from "react";
import { GlobalNavigationContext } from "./GlobalNavigation";
import LiveRampLogo from "../../icons/LiverampLogo";
import withStyles from "../../styles/withStyles";

function L1L2Header() {
  const { customisation } = useContext(GlobalNavigationContext);

  return (
    <div
      className="GNL1L2Header-root"
      onClick={() =>
        customisation?.link
          ? (window.location.href = customisation.link)
          : (window.location.href = "https://connect.liveramp.com/login")
      }
    >
      <div className="GNL1L2Header-logo">
        {customisation?.logo ? (
          customisation.logo
        ) : (
          <LiveRampLogo className="GNL1L2Header-liverampLogo" />
        )}
      </div>
      <div className="GNL1L2Header-label">
        {customisation?.label ? customisation.label : "LiveRamp"}
      </div>
    </div>
  );
}

const styles = theme => ({
  "@global": {
    ".GNL1L2Header-root": {
      color: theme.palette.common.white,
      fontSize: "24px",
      fontWeight: "300",
      height: "40px",
      minHeight: "40px",
      display: "grid",
      gridTemplateColumns: "50px 1fr",
      alignItems: "center",
      overflowX: "hidden"
    },
    ".GNL1L2Header-logo": {
      cursor: "pointer",
      overflowX: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    ".GNL1L2Header-liverampLogo": {
      height: "24px",
      width: "24px",
      "& path": {
        fill: "white"
      }
    },
    ".GNL1L2Header-label": {
      overflow: "hidden",
      whiteSpace: "nowrap"
    }
  }
});

export default withStyles(styles)(L1L2Header);
