import React from "react";
import MuiRadio from "@material-ui/core/Radio";
import withStyles from "../../styles/withStyles";
import { RadioStyle } from "../../utils/styleKeys";

function Radio(props) {
  return <MuiRadio color="default" {...props} />;
}

let styles = theme => ({
  ...RadioStyle,
  root: {
    color: theme.palette.grey[500],
    transition: theme.transitions.common.short,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover
    }
  },
  checked: {
    color: theme.palette.primary.main
  }
});

export default withStyles(styles, { name: "MuiRadio" })(Radio);
