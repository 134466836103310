import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <>
    <path
      d="M20.001 14.024 20 17c0 1.657-3.134 3-7 3-3.772 0-6.847-1.278-6.994-2.88L6 17v-1h4.75l.153-.005c.152-.01.3-.036.441-.074.537.052 1.092.079 1.656.079 3.033 0 5.643-.747 7.001-1.976Zm0-5L20 11.99V12c0 1.581-2.887 2.933-6.711 2.998L13 15l-.373-.005c.202-.303.332-.658.365-1.04l.008-.193V11c3.033 0 5.643-.747 7.001-1.976ZM7 2c1.725 0 3.125 1.387 3.125 3.095v1.238h.625c.688 0 1.25.557 1.25 1.238v6.19c0 .682-.563 1.239-1.25 1.239h-7.5C2.562 15 2 14.443 2 13.762v-6.19c0-.682.563-1.239 1.25-1.239h.625V5.095C3.875 3.387 5.275 2 7 2Zm0 7.429c-.688 0-1.25.557-1.25 1.238 0 .68.563 1.238 1.25 1.238.688 0 1.25-.557 1.25-1.238 0-.681-.563-1.238-1.25-1.238ZM13 4c3.772 0 6.847 1.278 6.994 2.88L20 7c0 1.62-3.033 3-7 3V7.571l-.005-.153-.018-.167a2.25 2.25 0 0 0-1.615-1.833l-.16-.04-.077-.013v-.27l-.005-.204a4.063 4.063 0 0 0-.112-.768C11.64 4.043 12.308 4 13 4Zm-6-.824a1.93 1.93 0 0 0-1.938 1.92v1.237h3.875V5.095A1.93 1.93 0 0 0 7 3.176Z"
      fillRule="nonzero"
    />
  </>,
  "PrivacyDataSet"
);
