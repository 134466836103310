import React from "react";
import TextField from "@liveramp/motif/core/TextField";
import css from "./Custom.module.css";
import withStyles from "@liveramp/motif/styles/withStyles";
import makeStyles from "@liveramp/motif/styles/makeStyles";

function CustomDemo({ classes: withStylesClasses }) {
  let outlinedMakeStyles = useOutlinedStyles();
  let underlinedMakeStyles = useUnderlinedStyles();

  return (
    <div className={css.grid}>
      <TextField
        id="outlined-css-modules"
        label="CSS Modules"
        className={css.outlined}
      />
      <TextField
        id="outlined-withStyles"
        label="withStyles"
        InputProps={{ classes: { root: withStylesClasses.outlined } }}
        InputLabelProps={{
          classes: { root: withStylesClasses.outlinedLabel }
        }}
      />
      <TextField
        id="outlined-makeStyles"
        label="makeStyles"
        InputProps={{ classes: { root: outlinedMakeStyles.input } }}
        InputLabelProps={{ classes: { root: outlinedMakeStyles.inputLabel } }}
      />
      <TextField
        id="underlined-css-modules"
        label="CSS Modules"
        className={css.underlined}
        variant="underlined"
      />
      <TextField
        id="underlined-withStyles"
        label="withStyles"
        InputProps={{ classes: { root: withStylesClasses.underlined } }}
        InputLabelProps={{
          classes: { root: withStylesClasses.underlinedLabel }
        }}
        variant="underlined"
      />
      <TextField
        id="underlined-makeStyles"
        label="makeStyles"
        InputProps={{ classes: { root: underlinedMakeStyles.input } }}
        InputLabelProps={{ classes: { root: underlinedMakeStyles.inputLabel } }}
        variant="underlined"
      />
    </div>
  );
}

let withStylesStyles = theme => ({
  outlined: {
    "&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.info.main
    }
  },
  outlinedLabel: {
    "&.Mui-focused": {
      color: theme.palette.info.main
    }
  },
  underlined: {
    "&:hover:not(.Mui-disabled):before, &:after": {
      borderColor: theme.palette.info.main
    }
  },
  underlinedLabel: {
    "&.Mui-focused": {
      color: theme.palette.info.main
    }
  }
});

let useOutlinedStyles = makeStyles(theme => ({
  input: {
    "&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.info.main
    }
  },
  inputLabel: {
    "&.Mui-focused": {
      color: theme.palette.info.main
    }
  }
}));
let useUnderlinedStyles = makeStyles(theme => ({
  input: {
    "&:hover:not(.Mui-disabled):before, &:after": {
      borderColor: theme.palette.info.main
    }
  },
  inputLabel: {
    "&.Mui-focused": {
      color: theme.palette.info.main
    }
  }
}));

export default withStyles(withStylesStyles)(CustomDemo);
