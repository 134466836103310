import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { useDropzone } from "react-dropzone";
import Card from "../../core-components/Card";
import CardContent from "../../core-components/CardContent";
import { Dropzone, FileList, FileStatus } from "./components";
import { useFileList, useUploadFile } from "./hooks";
import withUploadContextProvider from "./hooks/useFileList/withUploadContextProvider";
import { bytesToSize, makeStyles } from "../../utils";

const Uploader = ({
  id = "motif-uploader",
  multiple = false,
  maxFiles = null,
  successComponent = null,
  validator = null,
  customErrorMessage,
  className,
  url,
  onFilesAdded,
  disabled
}) => {
  const hasPropsError =
    !disabled && ((!url && !onFilesAdded) || (url && onFilesAdded));
  if (hasPropsError) {
    throw new Error(
      "You must pass exactly ONE of either URL or `onFilesAdded` properties."
    );
  }

  const [{ files, count, mostRecentFile: currentFile }, actionsFactory] =
    useFileList();

  const uploadFile = useUploadFile(url, onFilesAdded);

  const addFilesHandler = acceptedFiles => {
    acceptedFiles.forEach(rawFile => {
      uploadFile(rawFile);
    });
  };

  const onFilesRejectedHandler = rejectedFiles => {
    rejectedFiles.forEach(rejection => {
      const motifId = rejection.id ?? uuidv4();
      const actions = actionsFactory(motifId);
      const file = {
        motifId,
        name: rejection.file.name,
        data: rejection.file,
        progress: 100,
        size: bytesToSize(rejection.file.size || 0),
        removeFile: actions.removeFile
      };
      actions.addFile(file);
      actions.fileRejected(rejection.errors[0].message || "File Rejected");
    });
  };

  const hasSingleFile = !multiple && currentFile;
  const isDisabled =
    disabled || hasSingleFile || (multiple && count === maxFiles);
  const isError = hasSingleFile && currentFile.isError;
  const isSuccess = hasSingleFile && currentFile.isSuccess;
  const isRejected = hasSingleFile && currentFile.isRejected;

  const classes = useStyles({
    isDisabled,
    isError,
    isSuccess,
    isRejected,
    count,
    multiple
  });

  useEffect(() => {
    if (!currentFile) return;
    if (hasSingleFile && currentFile.isSuccess) {
      const actions = actionsFactory(currentFile.motifId);
      const resetStateTimeout = setTimeout(actions.removeFile, 10000);
      return () => clearTimeout(resetStateTimeout);
    }
  }, [hasSingleFile, currentFile]);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    disabled: isDisabled,
    onDropAccepted: addFilesHandler,
    onDropRejected: onFilesRejectedHandler,
    multiple,
    maxFiles: !multiple && 1,
    validator: validator
  });

  return (
    <div
      data-testid="Uploader"
      className={clsx("Uploader", "Uploader-root", className)}
      id={id}
      style={{ width: count && multiple ? "100%" : "auto" }}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <Card
          variant="outlined"
          className={clsx("dz-message", classes.card, {
            "dz-drag-hover": isDragActive
          })}
        >
          <CardContent className={classes.cardContent}>
            {hasSingleFile ? (
              <FileStatus
                file={currentFile}
                successComponent={successComponent}
                errorMessage={customErrorMessage}
                open={open}
              />
            ) : (
              <Dropzone
                inputProps={getInputProps()}
                disabled={isDisabled}
                multiple={multiple}
              />
            )}
          </CardContent>
        </Card>
      </div>
      {multiple && count > 0 && (
        <FileList
          files={Object.values(files)}
          errorMessage={customErrorMessage}
          successComponent={successComponent}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  "@global": {
    ".Uploader": {
      display: "flex",
      gap: "16px"
    }
  },

  card: {
    display: "flex",
    height: "192px",
    width: "192px",
    color: ({ isDisabled }) =>
      isDisabled ? theme.palette.common.secondary : theme.palette.common.black,
    borderWidth: "2px",
    borderStyle: ({ isSuccess, isError }) =>
      isSuccess || isError ? "solid" : "dashed",
    borderColor: ({ isDisabled, isError, isSuccess }) => {
      if (isError) return theme.palette.error.main;
      if (isSuccess) return theme.palette.primary.main;
      if (isDisabled) return theme.palette.disabled.main;
      return theme.palette.secondary.main;
    },
    transition: theme.transitions.common.enteringScreen,

    "&.dz-drag-hover": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.grey[50],
      color: theme.palette.secondary.main,
      transition: theme.transitions.common.leavingScreen,

      "& .uploader-button": {
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,

        "& .MuiSvgIcon-root": {
          fill: theme.palette.secondary.main
        }
      }
    }
  },
  cardContent: {
    display: "flex",
    width: "100%",
    padding: ({ isError }) => (isError ? "12px" : "16px")
  }
}));

Uploader.defaultProps = {
  multiple: false,
  disabled: false
};

Uploader.propTypes = {
  id: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  successComponent: PropTypes.func,
  customErrorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onFilesAdded: PropTypes.func,
  url: PropTypes.string,
  className: PropTypes.string
};

export default withUploadContextProvider(Uploader);
