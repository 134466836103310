let green = {
  50: "#eef7ed",
  100: "#d5ecd3",
  200: "#b9e0b5",
  300: "#9dd397",
  400: "#88c981",
  500: "#73c06b",
  600: "#6bba63",
  700: "#60b258",
  800: "#56aa4e",
  900: "#439c3c"
};

export default green;
