import Installation from "../getting-started/installation";
import Usage from "../getting-started/usage";
import SetupOverrides from "../style/setup-overrides";
import Basics from "../style/basics";
import Theme from "../style/theme";
import Icons from "../style/icons";
import Colors from "../style/colors";
import Typography from "../core-component-demo/typography"; // duplicate of the typography demo
import ZIndex from "../style/z-index";

import StickyTop from "../layouts/sticky-top";

import AlertDemo from "../core-component-demo/alert";
import BreadcrumbDemo from "../core-component-demo/breadcrumb"
import ButtonDemo from "../core-component-demo/button";
import ButtonMenuDemo from "../core-component-demo/button-menu";
import CardDemo from "../core-component-demo/card";
import CheckboxDemo from "../core-component-demo/checkbox";
import ChipDemo from "../core-component-demo/chip";
import DataGridDemo from "../core-component-demo/data-grid";
import DialogDemo from "../core-component-demo/dialog";
import DrawerDemo from "../core-component-demo/drawer";
import HeaderDemo from "../core-component-demo/header";
import IconButtonDemo from "../core-component-demo/icon-button";
import LinkDemo from "../core-component-demo/link";
import ListDemo from "../core-component-demo/list";
import LoaderDemo from "../core-component-demo/loader";
import MenuDemo from "../core-component-demo/menu";
import RadioDemo from "../core-component-demo/radio";
import SelectDemo from "../core-component-demo/select";
import StatusDemo from "../core-component-demo/status";
import StepperDemo from "../core-component-demo/stepper";
import SwitchDemo from "../core-component-demo/switch";
import TableDemo from "../core-component-demo/table";
import TabsDemo from "../core-component-demo/tabs";
import TextFieldDemo from "../core-component-demo/text-field";
import ToggleButtonDemo from "../core-component-demo/toggle-button";
import TooltipDemo from "../core-component-demo/tooltip";
import TypographyDemo from "../core-component-demo/typography";
import TreeViewDemo from "../core-component-demo/tree-view";

import FilterDemo from "../composed-component-demo/filter";
import FooterDemo from "../composed-component-demo/footer";
import FullScreenDialogDemo from "../composed-component-demo/full-screen-dialog";
import GabDemo from "../composed-component-demo/global-action-button";
import GlobalNavigation from "../composed-component-demo/global-navigation";
import LogoCardDemo from "../composed-component-demo/logo-card";
import NotificationContentDemo from "../composed-component-demo/notification-content";
import SidePanelDemo from "../composed-component-demo/side-panel";
import Uploader from "../composed-component-demo/uploader";
import VerticalNavDemo from "../composed-component-demo/vertical-nav";
import TruncateDemo from "../composed-component-demo/truncate";

import RdtLayoutDemo from "../composed-component-demo/rich-data-table/layout";
import RdtColumnsDemo from "../composed-component-demo/rich-data-table/columns";
import RdtRowsDemo from "../composed-component-demo/rich-data-table/rows";
// import RdtSortingDemo from "../composed-component-demo/rich-data-table/sorting";
import RdtFilteringDemo from "../composed-component-demo/rich-data-table/filtering";
import RdtPaginationDemo from "../composed-component-demo/rich-data-table/pagination";
import RdtOtherFunctionalityDemo from "../composed-component-demo/rich-data-table/other-functionality";
import RdtDataGridSlotsDemo from "../composed-component-demo/rich-data-table/data-grid-slots";
// import RdtStylingDemo from "../composed-component-demo/rich-data-table/styling";

import DialogSandbox from "../sandbox/Dialog";
import DrawerSandbox from "../sandbox/Drawer";
import FilterSandbox from "../sandbox/Filter";
import GlobalNavSandbox from "../sandbox/GlobalNav";
import SelectSandbox from "../sandbox/Select";
import RdtSandbox from "../sandbox/RichDataTable";

export let startingRoutes = [
  { name: "Installation", link: "installation", component: Installation },
  { name: "Usage", link: "usage", component: Usage }
];

export let styleRoutes = [
  { name: "Setup Style Overrides", link: "setup-overrides", component: SetupOverrides },
  { name: "Style Basics", link: "basics", component: Basics },
  { name: "Theme", link: "theme", component: Theme },
  { name: "Icons", link: "icons", component: Icons },
  { name: "Colors", link: "colors", component: Colors },
  { name: "Typography", link: "typography", component: Typography },
  { name: "Z-Index", link: "z-index", component: ZIndex },
];

export let layoutsRoutes = [
  { name: "Sticky Top", link: "sticky-top", component: StickyTop }
]

export let coreDemoRoutes = [
  { name: "Alert", link: "alert", component: AlertDemo },
  { name: "Breadcrumb", link: "breadcrumb", component: BreadcrumbDemo },
  { name: "Button", link: "button", component: ButtonDemo },
  { name: "Button Menu", link: "button-menu", component: ButtonMenuDemo },
  { name: "Card", link: "card", component: CardDemo },
  { name: "Checkbox", link: "checkbox", component: CheckboxDemo },
  { name: "Chip", link: "chip", component: ChipDemo },
  { name: "Dialog", link: "dialog", component: DialogDemo, sandbox: DialogSandbox },
  { name: "DataGrid", link: "data-grid", component: DataGridDemo },
  { name: "Drawer", link: "drawer", component: DrawerDemo, sandbox: DrawerSandbox },
  { name: "Header", link: "header", component: HeaderDemo },
  { name: "Icon Button", link: "icon-button", component: IconButtonDemo },
  { name: "Link", link: "link", component: LinkDemo },
  { name: "List", link: "list", component: ListDemo },
  { name: "Loaders", link: "loader", component: LoaderDemo },
  { name: "Menu", link: "menu", component: MenuDemo },
  { name: "Radio", link: "radio", component: RadioDemo },
  { name: "Select", link: "select", component: SelectDemo, sandbox: SelectSandbox },
  { name: "Status", link: "status", component: StatusDemo },
  { name: "Stepper", link: "stepper", component: StepperDemo },
  { name: "Switch", link: "switch", component: SwitchDemo },
  { name: "Table", link: "table", component: TableDemo },
  { name: "Tabs", link: "tabs", component: TabsDemo },
  { name: "Text Field", link: "text-field", component: TextFieldDemo },
  { name: "Toggle Button", link: "toggle-button", component: ToggleButtonDemo },
  { name: "Tooltip", link: "tooltip", component: TooltipDemo },
  { name: "Typography", link: "typography", component: TypographyDemo },
  { name: "Tree View", link: "tree-view", component: TreeViewDemo }
];

export let composedDemoRoutes = [
  { name: "Filter", link: "filter", component: FilterDemo, sandbox: FilterSandbox },
  { name: "Footer", link: "footer", component: FooterDemo },
  { name: "Full Screen Dialog", link: "full-screen-dialog", component: FullScreenDialogDemo },
  { name: "Global Action Button", link: "global-action-button", component: GabDemo },
  { name: "Global Navigation", link: "global-navigation", component: GlobalNavigation, sandbox: GlobalNavSandbox },
  { name: "Logo Card", link: "logo-card", component: LogoCardDemo },
  { name: "Notification Content", link: "notification-content", component: NotificationContentDemo },
  { name: "Side Panel", link: "side-panel", component: SidePanelDemo },
  { name: "Truncate", link: "truncate", component: TruncateDemo },
  { name: "Uploader", link: "uploader", component: Uploader },
  { name: "Vertical Nav", link: "vertical-nav", component: VerticalNavDemo },
];

export let rdtDemoRoutes = [
  { name: "Layout", link: "layout", component: RdtLayoutDemo },
  { name: "Columns", link: "columns", component: RdtColumnsDemo },
  { name: "Rows", link: "rows", component: RdtRowsDemo },
  // { name: "Sorting", link: "sorting", component: RdtSortingDemo },
  { name: "Filtering", link: "filtering", component: RdtFilteringDemo },
  { name: "Pagination", link: "pagination", component: RdtPaginationDemo },
  { name: "Other Functionality", link: "other-functionality", component: RdtOtherFunctionalityDemo },
  { name: "DataGrid Slots", link: "data-grid-slots", component: RdtDataGridSlotsDemo },
  // { name: "Styling", link: "styling", component: RdtStylingDemo },
  { name: "Sandbox", link: "sandbox", component: RdtSandbox }
]
