// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Account category

let icons = [
  "Facebook",
  "Github",
  "LinkedIn",
  "Microsoft",
  "Pinterest",
  "Snapchat",
  "Twitter",
  "TikTok"
];

export default icons;
