import React from "react";
import { Subtitle, Supporting } from "../../resources/Page";
import Inspector from "../../resources/Inspector";
import theme from "@liveramp/motif/theme";

export default function Theme() {
  // Material and Motif component names don't match up for various components
  // e.g. MuiSnackbar and Alert, and we want our theme obj in the docs to
  // represent the motif names, so we are overriding and replacing them
  delete theme.zIndex.mobileStepper;
  delete theme.zIndex.snackbar;
  delete theme.zIndex.modal;

  return (
    <div>
      <h1>Theme</h1>
      <Subtitle>
        The core object which powers LiveRamp's application theming and styling
      </Subtitle>
      <Supporting>
        A Javascript object has been made available under{" "}
        <code>@liveramp/motif/theme</code> which should include all the
        variables you may require from LiveRamp's design guide. The{" "}
        <code>theme.palette.common</code> object should surface the main colors
        in an easily accessible way.
      </Supporting>

      <p>The shape of the theme object is as follows:</p>
      <Inspector
        data={theme}
        expandLevel={1}
        expandPaths={["$.palette", "$.palette.common"]}
        sortObjectKeys={true}
      />
      <p>
        The theme is a combination of Material UI's base object and Motif
        overrides. The source for each can be found here:
      </p>
      <ul>
        <li>
          <a href="https://github.com/LiveRamp/motif/blob/master/lib/src/styles/theme.js">
            Motif theme
          </a>
        </li>
        <li>
          <a href="https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js">
            Material UI's theme
          </a>
        </li>
      </ul>
    </div>
  );
}
