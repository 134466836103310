import React, { useState } from "react";
import Checkbox from "@liveramp/motif/core/Checkbox";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import {
  Check,
  Clear,
  Favorite,
  FavoriteOutline,
  Star,
  StarOutline,
  Lightbulb,
  LightbulbOutline
} from "@liveramp/motif/icons";
import css from "./Custom.module.css";
import withStyles from "@liveramp/motif/styles/withStyles";
import makeStyles from "@liveramp/motif/styles/makeStyles";

function CustomDemo({ classes: withStylesClasses }) {
  let [icon, setIcon] = useState(false);
  let [heart, setHeart] = useState(true);
  let [withStyles, setWithStyles] = useState(true);
  let [makeStyles, setMakeStyles] = useState(true);
  let makeStylesClasses = useStyles();

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={icon}
            onChange={() => setIcon(!icon)}
            icon={<Clear />}
            checkedIcon={<Check />}
            value="icon"
          />
        }
        label="Icon"
      />
      <FormControlLabel
        control={
          <Checkbox
            className={css.heart}
            checked={heart}
            onChange={() => setHeart(!heart)}
            icon={<FavoriteOutline />}
            checkedIcon={<Favorite />}
            value="heart"
          />
        }
        label="CSS Modules"
      />
      <FormControlLabel
        control={
          <Checkbox
            classes={withStylesClasses}
            checked={withStyles}
            onChange={() => setWithStyles(!withStyles)}
            icon={<StarOutline />}
            checkedIcon={<Star />}
            value="star"
          />
        }
        label="JSS withStyles"
      />
      <FormControlLabel
        control={
          <Checkbox
            classes={makeStylesClasses}
            checked={makeStyles}
            onChange={() => setMakeStyles(!makeStyles)}
            icon={<LightbulbOutline />}
            checkedIcon={<Lightbulb />}
            value="lightbulb"
          />
        }
        label="JSS makeStyles"
      />
    </>
  );
}

let styles = theme => ({
  root: {
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.hover
    }
  },
  checked: {
    color: theme.palette.warning.main,
    "&:hover": {
      backgroundColor: theme.palette.warning.hover
    }
  }
});

let useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.hover
    }
  },
  checked: {
    color: theme.palette.info.main,
    "&:hover": {
      backgroundColor: theme.palette.info.hover
    }
  }
}));

export default withStyles(styles)(CustomDemo);
