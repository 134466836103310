import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M22.2324 4.15649C22.2869 4.15649 22.3311 4.20152 22.3311 4.25705C22.3311 4.30862 22.293 4.35112 22.2439 4.35694L22.2324 4.35761H19.7928C19.7383 4.35761 19.6941 4.31259 19.6941 4.25706C19.6941 4.20549 19.7322 4.16298 19.7813 4.15717L19.7928 4.15649H22.2324Z"
      fill="#73C06B"
    />
    <path
      d="M22.5661 3.75427C22.6206 3.75427 22.6648 3.79929 22.6648 3.85483C22.6648 3.9064 22.6267 3.9489 22.5776 3.95472L22.5661 3.95539H20.1265C20.072 3.95539 20.0278 3.91037 20.0278 3.85483C20.0278 3.80326 20.0659 3.76076 20.115 3.75495L20.1265 3.75427H22.5661Z"
      fill="#73C06B"
    />
    <path
      d="M22.967 3.3855C23.0215 3.3855 23.0657 3.43052 23.0657 3.48606C23.0657 3.53763 23.0276 3.58013 22.9785 3.58594L22.967 3.58662H20.5274C20.4729 3.58662 20.4287 3.5416 20.4287 3.48606C20.4287 3.43449 20.4668 3.39199 20.5159 3.38617L20.5274 3.3855H22.967Z"
      fill="#73C06B"
    />
    <path
      d="M22.7334 17.2288C22.7876 17.2288 22.8315 17.2738 22.8315 17.3293C22.8315 17.3809 22.7936 17.4234 22.7448 17.4292L22.7334 17.4299H19.7585C19.7043 17.4299 19.6604 17.3849 19.6604 17.3293C19.6604 17.2778 19.6983 17.2353 19.747 17.2294L19.7585 17.2288H22.7334Z"
      fill="#73C06B"
    />
    <path
      d="M22.6999 17.6313C22.7542 17.6313 22.7983 17.6764 22.7983 17.7319C22.7983 17.7835 22.7603 17.826 22.7113 17.8318L22.6999 17.8325H19.7591C19.7047 17.8325 19.6606 17.7874 19.6606 17.7319C19.6606 17.6803 19.6987 17.6378 19.7476 17.632L19.7591 17.6313H22.6999Z"
      fill="#73C06B"
    />
    <path
      d="M22.4666 18.0669C22.5211 18.0669 22.5652 18.1119 22.5652 18.1675C22.5652 18.219 22.5271 18.2615 22.4781 18.2673L22.4666 18.268H19.7597C19.7053 18.268 19.6611 18.223 19.6611 18.1675C19.6611 18.1159 19.6992 18.0734 19.7482 18.0676L19.7597 18.0669H22.4666Z"
      fill="#73C06B"
    />
    <path
      d="M21.8666 18.905C21.9204 18.905 21.9641 18.9501 21.9641 19.0056C21.9641 19.0572 21.9265 19.0997 21.878 19.1055L21.8666 19.1061H19.7584C19.7045 19.1061 19.6609 19.0611 19.6609 19.0056C19.6609 18.954 19.6985 18.9115 19.747 18.9057L19.7584 18.905H21.8666V18.905Z"
      fill="#73C06B"
    />
    <path
      d="M21.533 19.3074C21.5869 19.3074 21.6305 19.3524 21.6305 19.4079C21.6305 19.4595 21.5929 19.502 21.5444 19.5078L21.533 19.5085H19.7587C19.7048 19.5085 19.6611 19.4635 19.6611 19.4079C19.6611 19.3564 19.6988 19.3139 19.7473 19.3081L19.7587 19.3074H21.533Z"
      fill="#73C06B"
    />
    <path
      d="M21.1987 19.7432C21.2526 19.7432 21.2963 19.7882 21.2963 19.8437C21.2963 19.8953 21.2586 19.9378 21.21 19.9436L21.1987 19.9443H19.7582C19.7043 19.9443 19.6606 19.8993 19.6606 19.8437C19.6606 19.7922 19.6983 19.7497 19.7469 19.7438L19.7582 19.7432H21.1987Z"
      fill="#73C06B"
    />
    <path
      d="M22.0998 18.4695C22.1536 18.4695 22.1973 18.5145 22.1973 18.57C22.1973 18.6216 22.1596 18.6641 22.1112 18.6699L22.0998 18.6706H19.7579C19.704 18.6706 19.6604 18.6256 19.6604 18.57C19.6604 18.5185 19.698 18.476 19.7465 18.4702L19.7579 18.4695H22.0998Z"
      fill="#73C06B"
    />
    <path
      d="M18.8599 2.21228C18.9113 2.21228 18.9536 2.25047 18.9594 2.29967L18.96 2.31121V5.16362C18.96 5.21825 18.9152 5.26255 18.8599 5.26255C18.8086 5.26255 18.7662 5.22436 18.7604 5.17516L18.7598 5.16362V2.31121C18.7598 2.25657 18.8046 2.21228 18.8599 2.21228V2.21228Z"
      fill="#73C06B"
    />
    <path
      d="M18.3924 1.91064C18.4437 1.91064 18.4861 1.94874 18.4918 1.99782L18.4925 2.00933V5.56614C18.4925 5.62064 18.4477 5.66483 18.3924 5.66483C18.341 5.66483 18.2987 5.62673 18.2929 5.57765L18.2922 5.56614V2.00933C18.2922 1.95483 18.3371 1.91064 18.3924 1.91064V1.91064Z"
      fill="#73C06B"
    />
    <path
      d="M17.9581 1.64246C18.0094 1.64246 18.0517 1.68049 18.0575 1.72948L18.0582 1.74097V6.03555C18.0582 6.08996 18.0134 6.13406 17.9581 6.13406C17.9067 6.13406 17.8644 6.09603 17.8586 6.04704L17.8579 6.03555V1.74097C17.8579 1.68656 17.9027 1.64246 17.9581 1.64246V1.64246Z"
      fill="#73C06B"
    />
    <path
      d="M17.5242 1.37427C17.5756 1.37427 17.6179 1.41225 17.6237 1.46119L17.6244 1.47266V6.4714C17.6244 6.52574 17.5795 6.5698 17.5242 6.5698C17.4729 6.5698 17.4305 6.53182 17.4247 6.48288L17.4241 6.47141V1.47266C17.4241 1.41832 17.4689 1.37427 17.5242 1.37427Z"
      fill="#73C06B"
    />
    <path
      d="M16.6231 0.972046C16.6744 0.972046 16.7168 1.01017 16.7226 1.05928L16.7232 1.07079V7.44296C16.7232 7.4975 16.6784 7.54171 16.6231 7.54171C16.5717 7.54171 16.5294 7.50359 16.5236 7.45448L16.5229 7.44296V1.07079C16.5229 1.01626 16.5678 0.972046 16.6231 0.972046V0.972046Z"
      fill="#73C06B"
    />
    <path
      d="M17.0569 1.17322C17.1083 1.17322 17.1506 1.21117 17.1564 1.26006L17.1571 1.27152V6.97436C17.1571 7.02865 17.1122 7.07267 17.0569 7.07267C17.0056 7.07267 16.9633 7.03472 16.9575 6.98583L16.9568 6.97436V1.27152C16.9568 1.21723 17.0016 1.17322 17.0569 1.17322V1.17322Z"
      fill="#73C06B"
    />
    <path
      d="M16.1556 0.804443C16.2069 0.804443 16.2492 0.842529 16.255 0.891597L16.2557 0.903102V7.84525C16.2557 7.89973 16.2109 7.9439 16.1556 7.9439C16.1042 7.9439 16.0619 7.90582 16.0561 7.85675L16.0554 7.84524V0.903099C16.0554 0.848612 16.1003 0.804443 16.1556 0.804443V0.804443Z"
      fill="#73C06B"
    />
    <path
      d="M15.722 0.636841C15.7733 0.636841 15.8156 0.674894 15.8214 0.723916L15.8221 0.735412V8.31501C15.8221 8.36946 15.7773 8.41359 15.722 8.41359C15.6706 8.41359 15.6283 8.37553 15.6225 8.32651L15.6218 8.31501V0.735412C15.6218 0.680971 15.6667 0.636841 15.722 0.636841V0.636841Z"
      fill="#73C06B"
    />
    <path
      d="M15.2881 0.502808C15.3395 0.502808 15.3818 0.540644 15.3876 0.589389L15.3883 0.600819V2.34895C15.3883 2.40308 15.3434 2.44696 15.2881 2.44696C15.2368 2.44696 15.1945 2.40912 15.1887 2.36038L15.188 2.34895V0.600818C15.188 0.546689 15.2328 0.502808 15.2881 0.502808V0.502808Z"
      fill="#73C06B"
    />
    <path
      d="M9.41166 6.06739C9.44947 6.06739 9.4987 6.07647 9.54965 6.10468C9.66887 6.17068 9.71867 6.30448 9.67259 6.49552L9.66633 6.51969C9.64439 6.59443 9.63962 6.67309 9.65226 6.74915C9.65987 6.79288 9.67301 6.83549 9.69114 6.87549C9.74839 7.00002 9.87821 7.07247 10.0451 7.0788L10.0692 7.07925H11.5514C11.6058 7.07925 11.6498 7.12287 11.6498 7.17667C11.6498 7.22663 11.6118 7.2678 11.5629 7.27342L11.5514 7.27408H10.0692C9.81906 7.27408 9.6069 7.16269 9.51174 6.9557C9.48664 6.90032 9.46868 6.84205 9.45813 6.78151C9.44255 6.68774 9.44599 6.59195 9.46782 6.50077L9.47687 6.4668C9.51028 6.34588 9.49343 6.29678 9.45355 6.27471C9.44034 6.2674 9.42605 6.26379 9.41719 6.26263L9.41166 6.26221C9.3573 6.26221 9.31323 6.2186 9.31323 6.1648C9.31323 6.111 9.3573 6.06738 9.41166 6.06738L9.41166 6.06739Z"
      fill="#616161"
    />
    <path
      d="M12.2522 0.0670646C12.3064 0.0670646 12.3504 0.111495 12.3504 0.166304C12.3504 0.217197 12.3125 0.259145 12.2636 0.264875L12.2522 0.265543L12.2025 0.265535L11.9586 0.268577C11.8453 0.271463 11.7322 0.282402 11.6191 0.301517C11.5239 0.316345 11.4295 0.334821 11.3356 0.357026C11.1038 0.411021 10.8787 0.491055 10.6643 0.595693C10.4535 0.698241 10.2582 0.830607 10.0854 0.987796C9.77332 1.28044 9.54933 1.65616 9.43921 2.07166C9.42517 2.1246 9.37132 2.15602 9.31892 2.14184C9.26653 2.12766 9.23543 2.07325 9.24946 2.02031C9.36917 1.56867 9.61263 1.16034 9.95291 0.841253C10.1407 0.670441 10.3515 0.527525 10.5791 0.416868C10.8065 0.305855 11.0455 0.220895 11.2912 0.16365C11.3896 0.140357 11.489 0.120908 11.588 0.105508C11.6888 0.088469 11.7905 0.0774159 11.8932 0.0723855L11.955 0.0700901L12.2013 0.0670166H12.2522L12.2522 0.0670646Z"
      fill="#616161"
    />
    <path
      d="M9.25449 1.98373C9.2685 1.93109 9.32207 1.89989 9.37414 1.91406C9.42621 1.92823 9.45706 1.9824 9.44304 2.03504C9.33215 2.45171 9.30935 2.88736 9.37653 3.31611L9.37969 3.34499L9.38211 3.37299C9.38982 3.47151 9.39018 3.57595 9.37817 3.67552C9.36873 3.75379 9.35208 3.82382 9.32635 3.88401L9.31477 3.90919L9.30953 3.91849L8.77522 4.75748L8.77276 4.76194L8.7661 4.77573L8.75807 4.79506C8.7454 4.82812 8.73887 4.86042 8.74079 4.88648C8.74322 4.9195 8.75816 4.94129 8.80704 4.96001L8.82122 4.96505L9.2197 5.12398C9.25534 5.13819 9.27947 5.17217 9.28139 5.21087L9.28273 5.25987L9.28329 5.32421L9.28291 5.401L9.28144 5.49057L9.27878 5.59328C9.27838 5.60655 9.27534 5.61955 9.26989 5.63154L9.26221 5.64608L9.25903 5.65365C9.25161 5.67293 9.25038 5.69063 9.25617 5.70368C9.26216 5.71716 9.27649 5.73143 9.30529 5.74612C9.35342 5.77069 9.37275 5.83006 9.34845 5.87873C9.32416 5.92741 9.26544 5.94695 9.2173 5.92238C9.15022 5.88815 9.10332 5.84143 9.07806 5.7846C9.04753 5.71589 9.05238 5.64618 9.07704 5.58206L9.08421 5.56503L9.08681 5.45644L9.08764 5.39979L9.08803 5.32548L9.08773 5.28347L8.75538 5.15081C8.63248 5.11066 8.56264 5.02705 8.54806 4.92023L8.54606 4.90115C8.54166 4.84145 8.55374 4.78183 8.57598 4.72374C8.58495 4.70033 8.59406 4.68096 8.60273 4.66503L8.60914 4.65371L9.14172 3.81725L9.14773 3.8042C9.16236 3.76944 9.17347 3.72643 9.18092 3.67691L9.18434 3.65161C9.19454 3.56708 9.19422 3.47502 9.18746 3.38856L9.18498 3.36013L9.1833 3.3444C9.1122 2.89082 9.13646 2.4272 9.25449 1.98375L9.25449 1.98373Z"
      fill="#616161"
    />
    <path
      d="M12.2467 0.0676676C13.2708 0.0455397 14.3694 0.589401 14.9132 1.4126C15.0793 1.66455 15.2976 2.0953 15.3832 2.35071C15.4004 2.40225 15.3726 2.45803 15.3211 2.47529C15.2696 2.49256 15.2138 2.46478 15.1965 2.41324C15.1167 2.17509 14.906 1.7594 14.7488 1.52098C14.2434 0.755791 13.2091 0.243776 12.251 0.264442C12.1966 0.265616 12.1516 0.222514 12.1504 0.168173C12.1493 0.113829 12.1924 0.0688244 12.2467 0.0676495L12.2467 0.0676676Z"
      fill="#616161"
    />
    <path
      d="M14.8632 2.71509H12.5085C12.237 2.71509 12.0168 2.93513 12.0168 3.20656V7.82138C12.0168 8.09281 12.237 8.31285 12.5085 8.31285H14.8632C15.1347 8.31285 15.3548 8.09281 15.3548 7.82138V3.20656C15.3548 2.93513 15.1347 2.71509 14.8632 2.71509V2.71509ZM12.5085 2.91168H14.8632C15.0261 2.91168 15.1582 3.0437 15.1582 3.20656V7.82138C15.1582 7.98424 15.0261 8.11626 14.8632 8.11626H12.5085C12.3456 8.11626 12.2135 7.98424 12.2135 7.82138V3.20656C12.2135 3.0437 12.3456 2.91168 12.5085 2.91168V2.91168Z"
      fill="#616161"
    />
    <path
      d="M15.2227 3.45251C15.2772 3.45251 15.3213 3.49754 15.3213 3.55307C15.3213 3.60464 15.2833 3.64714 15.2342 3.65296L15.2227 3.65364H12.6494C12.5949 3.65364 12.5508 3.60861 12.5508 3.55308C12.5508 3.50151 12.5889 3.45901 12.6379 3.45319L12.6494 3.45251H15.2227V3.45251Z"
      fill="#616161"
    />
    <path
      d="M15.2564 7.10596C15.3107 7.10596 15.3547 7.15098 15.3547 7.20652C15.3547 7.25809 15.3167 7.30059 15.2678 7.3064L15.2564 7.30708H12.6491C12.5948 7.30708 12.5508 7.26206 12.5508 7.20652C12.5508 7.15495 12.5887 7.11245 12.6376 7.10663L12.6491 7.10596H15.2564V7.10596Z"
      fill="#616161"
    />
    <path
      d="M13.9208 7.57544C13.9752 7.57544 14.0193 7.62046 14.0193 7.676C14.0193 7.72757 13.9813 7.77007 13.9323 7.77588L13.9208 7.77656H13.4169C13.3625 7.77656 13.3184 7.73154 13.3184 7.676C13.3184 7.62443 13.3564 7.58193 13.4054 7.57612L13.4169 7.57544H13.9208Z"
      fill="#616161"
    />
    <path
      d="M6.20878 10.0891C6.26014 10.0891 6.30246 10.1272 6.30825 10.1763L6.30893 10.1878V11.901C6.30893 11.9555 6.26409 11.9997 6.20878 11.9997C6.15743 11.9997 6.11511 11.9616 6.10932 11.9125L6.10864 11.901V10.1878C6.10864 10.1333 6.15348 10.0891 6.20878 10.0891Z"
      fill="#616161"
    />
    <path
      d="M5.44096 10.5583C5.49232 10.5583 5.53464 10.5961 5.54043 10.6448L5.5411 10.6563V11.9018C5.5411 11.9558 5.49627 11.9997 5.44096 11.9997C5.38961 11.9997 5.34728 11.9619 5.34149 11.9132L5.34082 11.9018V10.6563C5.34082 10.6022 5.38565 10.5583 5.44096 10.5583Z"
      fill="#616161"
    />
    <path
      d="M6.94316 9.41919C6.99451 9.41919 7.03684 9.45743 7.04263 9.50669L7.0433 9.51824V11.9011C7.0433 11.9558 6.99847 12.0002 6.94316 12.0002C6.8918 12.0002 6.84948 11.9619 6.84369 11.9127L6.84302 11.9011V9.51825C6.84302 9.46354 6.88785 9.41919 6.94316 9.41919V9.41919Z"
      fill="#616161"
    />
    <path
      d="M7.69394 6.03345C8.02975 6.03345 8.32478 6.2569 8.4164 6.5807C8.50806 6.90449 8.37409 7.24991 8.0883 7.42668C8.0424 7.45507 7.98223 7.44079 7.95391 7.39479C7.92559 7.34879 7.93984 7.28848 7.98574 7.2601C8.1972 7.12932 8.29632 6.87372 8.2285 6.63415C8.16069 6.39457 7.94241 6.22922 7.69393 6.22922C7.44544 6.22922 7.22716 6.39456 7.15935 6.63415C7.09153 6.87374 7.19065 7.12932 7.40211 7.2601C7.44801 7.28848 7.46226 7.34879 7.43394 7.39479C7.40561 7.44079 7.34545 7.45507 7.29955 7.42668C7.01376 7.24994 6.87979 6.90451 6.97145 6.5807C7.0631 6.25688 7.3581 6.03345 7.69391 6.03345H7.69394Z"
      fill="#616161"
    />
    <path
      d="M7.67778 6.7041C7.72913 6.7041 7.77146 6.74202 7.77725 6.79087L7.77792 6.80232V11.9019C7.77792 11.9562 7.73309 12.0001 7.67778 12.0001C7.62642 12.0001 7.5841 11.9622 7.57831 11.9134L7.57764 11.9019V6.80232C7.57764 6.74808 7.62247 6.7041 7.67778 6.7041V6.7041Z"
      fill="#616161"
    />
    <path
      d="M23.4445 10.9272H20.8843C20.6142 10.9272 20.3953 11.1475 20.3953 11.4191V15.2287C20.3953 15.5003 20.6142 15.7206 20.8843 15.7206H23.4445C23.7146 15.7206 23.9335 15.5003 23.9335 15.2287V11.4191C23.9335 11.1475 23.7146 10.9272 23.4445 10.9272ZM20.8843 11.124H23.4445C23.6066 11.124 23.7379 11.2561 23.7379 11.4191V15.2287C23.7379 15.3917 23.6066 15.5238 23.4445 15.5238H20.8843C20.7222 15.5238 20.5909 15.3917 20.5909 15.2287V11.4191C20.5909 11.2561 20.7222 11.124 20.8843 11.124Z"
      fill="#616161"
    />
    <path
      d="M22.4655 15.0837C22.5203 15.0837 22.5648 15.1288 22.5648 15.1843C22.5648 15.2359 22.5264 15.2784 22.477 15.2842L22.4655 15.2849H21.863C21.8081 15.2849 21.7637 15.2398 21.7637 15.1843C21.7637 15.1327 21.802 15.0902 21.8514 15.0844L21.863 15.0837H22.4655Z"
      fill="#616161"
    />
    <path
      d="M23.8344 14.6482C23.8888 14.6482 23.933 14.6932 23.933 14.7488C23.933 14.8003 23.8949 14.8428 23.8459 14.8486L23.8344 14.8493H20.9272C20.8727 14.8493 20.8286 14.8043 20.8286 14.7488C20.8286 14.6972 20.8667 14.6547 20.9157 14.6489L20.9272 14.6482H23.8344Z"
      fill="#616161"
    />
    <path
      d="M21.378 0.0291139L19.2553 2.16086C19.2369 2.17937 19.2266 2.20446 19.2266 2.23063V4.69461C19.2266 4.7491 19.2706 4.79327 19.3248 4.79327H21.7784C21.8045 4.79327 21.8294 4.78288 21.8479 4.76438L23.9709 2.63263L23.9784 2.62429C24.0289 2.56144 23.9852 2.46419 23.9015 2.46419L21.5456 2.46409L21.5458 0.0988697C21.5458 0.0109631 21.44 -0.0330624 21.3781 0.0290987L21.378 0.0291139ZM21.3492 0.33701V2.56281L21.3498 2.57432C21.3555 2.62339 21.397 2.66148 21.4474 2.66148H23.6642L21.7377 4.59591H19.423V2.27148L21.3492 0.337051L21.3492 0.33701Z"
      fill="#616161"
    />
    <path
      d="M21.3624 2.50943C21.4006 2.47089 21.4627 2.47081 21.501 2.50923C21.5363 2.54469 21.5391 2.60057 21.5093 2.63927L21.5012 2.64856L14.0533 10.1613C14.0151 10.1998 13.9531 10.1999 13.9148 10.1615C13.8794 10.126 13.8766 10.0701 13.9064 10.0314L13.9146 10.0221L21.3624 2.50943V2.50943Z"
      fill="#616161"
    />
    <path
      d="M17.9916 9.88851C18.4899 9.88851 18.9114 10.2892 18.926 10.7721L18.9264 10.798V11.5331C18.9264 11.5875 18.8821 11.6316 18.8275 11.6316C18.7768 11.6316 18.735 11.5936 18.7293 11.5446L18.7286 11.5331V10.798C18.7286 10.4135 18.3906 10.0854 17.9916 10.0854C17.5999 10.0854 17.2685 10.3991 17.2554 10.7749L17.255 10.798V11.533C17.255 11.5874 17.2107 11.6315 17.156 11.6315C17.1053 11.6315 17.0635 11.5935 17.0578 11.5445L17.0571 11.533V10.798C17.0571 10.3021 17.4838 9.88843 17.9916 9.88843L17.9916 9.88851Z"
      fill="#616161"
    />
    <path
      d="M19.1274 11.4302C19.1741 11.4296 19.2191 11.4478 19.2525 11.4807C19.2821 11.51 19.3003 11.5488 19.3041 11.5903L19.3049 11.606L19.3049 13.4676C19.3045 13.5144 19.2856 13.5591 19.2524 13.592C19.2233 13.6207 19.1854 13.6382 19.1457 13.6419L19.1287 13.6426H17.2253C17.1712 13.6426 17.1274 13.5985 17.1274 13.5441C17.1274 13.4937 17.1652 13.4521 17.2139 13.4464L17.2253 13.4457L19.109 13.4457V11.627H16.9191L16.9192 14.5152C16.9192 14.6217 16.9954 14.6818 17.1376 14.6868L17.1574 14.6871H19.4271C19.6599 14.6871 19.85 14.8715 19.8606 15.1029L19.8611 15.1234L19.8367 18.6184L19.8275 19.8465C19.8271 19.9008 19.7829 19.9446 19.7288 19.9442C19.6786 19.9438 19.6375 19.9055 19.6322 19.8565L19.6317 19.845L19.6652 15.2091L19.6653 15.1235C19.6653 14.9965 19.567 14.8926 19.4428 14.8845L19.4271 14.884H17.1574C16.9131 14.884 16.7345 14.7594 16.7239 14.5365L16.7234 14.5153V11.6057C16.7236 11.5587 16.7425 11.5137 16.7758 11.4808C16.8049 11.452 16.8431 11.4345 16.8826 11.4309L16.8996 11.4303H19.1274L19.1274 11.4302Z"
      fill="#616161"
    />
    <path
      d="M18.0091 11.866C18.1916 11.866 18.3432 12.009 18.3567 12.2016C18.3563 12.2863 18.325 12.3668 18.2705 12.4285L18.2616 12.4378L18.3901 13.0193C18.4029 13.0772 18.363 13.1324 18.3067 13.139L18.2952 13.1397L17.7221 13.1396C17.6721 13.1397 17.631 13.1016 17.6254 13.0525L17.6248 13.041C17.6248 13.0332 17.6256 13.0256 17.6273 13.0183L17.6308 13.0063L17.7562 12.4377L17.7474 12.4282C17.6977 12.3713 17.6674 12.2978 17.6624 12.2163L17.6617 12.1938C17.675 12.009 17.8266 11.866 18.0091 11.866L18.0091 11.866ZM18.0091 12.0635C17.9285 12.0635 17.8616 12.1266 17.856 12.2004C17.8562 12.243 17.8748 12.2831 17.9063 12.3105L17.9187 12.3201C17.9483 12.3406 17.9642 12.3758 17.9607 12.4114L17.9588 12.4233L17.8444 12.9421H18.1737L18.0591 12.4233C18.0512 12.3877 18.0634 12.3509 18.09 12.3273L18.0994 12.32C18.1385 12.2931 18.162 12.2484 18.1625 12.2082C18.1566 12.1266 18.0897 12.0635 18.0091 12.0635L18.0091 12.0635Z"
      fill="#73C06B"
    />
    <path
      d="M12.0462 9.31437C12.0853 9.27509 12.1487 9.27509 12.1878 9.31437C12.2269 9.35364 12.2269 9.4173 12.1878 9.45658C12.1487 9.49585 12.0853 9.49585 12.0462 9.45658C12.0071 9.41731 12.0071 9.35364 12.0462 9.31437Z"
      fill="#616161"
    />
    <path
      d="M11.91 9.28523C11.9642 9.28087 12.0117 9.32132 12.0161 9.37558C12.0205 9.42985 11.98 9.47738 11.9258 9.48174C10.7519 9.57625 9.81635 10.5024 9.70961 11.6757C9.70467 11.7299 9.65674 11.7699 9.60255 11.765C9.54835 11.76 9.50842 11.7121 9.51335 11.6579C9.62879 10.389 10.6405 9.38747 11.91 9.28523L11.91 9.28523Z"
      fill="#616161"
    />
    <path
      d="M9.54251 11.6942C9.58162 11.655 9.64502 11.655 9.68413 11.6942C9.72324 11.7335 9.72324 11.7972 9.68413 11.8365C9.64503 11.8757 9.58162 11.8757 9.54251 11.8365C9.50341 11.7972 9.50341 11.7335 9.54251 11.6942Z"
      fill="#616161"
    />
    <path
      d="M9.54251 11.996C9.58162 11.9567 9.64502 11.9567 9.68413 11.996C9.72324 12.0353 9.72324 12.0989 9.68413 12.1382C9.64503 12.1775 9.58162 12.1775 9.54251 12.1382C9.50341 12.0989 9.50341 12.0353 9.54251 11.996Z"
      fill="#616161"
    />
    <path
      d="M9.62996 12.1683C9.68341 12.1604 9.73313 12.1976 9.74102 12.2514C9.90985 13.403 10.8682 14.2704 12.0227 14.3166C12.0767 14.3187 12.1187 14.3646 12.1166 14.419C12.1144 14.4734 12.0689 14.5157 12.015 14.5135C10.7665 14.4637 9.73003 13.5255 9.54745 12.2802C9.53956 12.2264 9.57648 12.1763 9.62993 12.1683L9.62996 12.1683Z"
      fill="#616161"
    />
    <path
      d="M12.0462 14.3422C12.0853 14.3029 12.1487 14.3029 12.1878 14.3422C12.2269 14.3815 12.2269 14.4451 12.1878 14.4844C12.1487 14.5237 12.0853 14.5237 12.0462 14.4844C12.0071 14.4451 12.0071 14.3815 12.0462 14.3422Z"
      fill="#616161"
    />
    <path
      d="M9.04325 11.7991C9.09723 11.7991 9.14099 11.8431 9.141 11.8975C9.14114 13.5143 10.417 14.8384 12.0219 14.8872C12.0758 14.8888 12.1182 14.9342 12.1166 14.9885C12.115 15.0429 12.0699 15.0856 12.016 15.0839C10.3055 15.0319 8.94568 13.6207 8.94556 11.8975C8.94556 11.8432 8.9893 11.7991 9.04328 11.7991L9.04325 11.7991Z"
      fill="#616161"
    />
    <path
      d="M15.1903 11.7991C15.2442 11.7991 15.2879 11.8432 15.2879 11.8976C15.2878 13.5959 13.9683 14.9965 12.2863 15.0838C12.2324 15.0866 12.1865 15.0449 12.1837 14.9905C12.181 14.9362 12.2224 14.8899 12.2763 14.8871C13.8544 14.8052 15.0924 13.4911 15.0925 11.8976C15.0926 11.8432 15.1363 11.7991 15.1902 11.7991L15.1903 11.7991Z"
      fill="#616161"
    />
    <path
      d="M12.6258 8.11157C12.68 8.11157 12.7239 8.15565 12.7239 8.21003C12.7239 8.26052 12.686 8.30213 12.6373 8.30782L12.6258 8.30848H12.117C10.6707 8.30835 9.36667 9.18315 8.81311 10.5249C8.25956 11.8667 8.56547 13.4113 9.58819 14.4383C10.6109 15.4652 12.149 15.7724 13.4852 15.2166C14.8214 14.6607 15.6926 13.3513 15.6924 11.8989C15.6924 11.8445 15.7363 11.8005 15.7905 11.8005C15.8446 11.8004 15.8885 11.8445 15.8885 11.8989C15.8887 13.4309 14.9697 14.8122 13.5603 15.3985C12.1508 15.9849 10.5283 15.6608 9.4496 14.5775C8.37079 13.4942 8.04811 11.865 8.63203 10.4497C9.21004 9.04859 10.5637 8.13018 12.0709 8.11193L12.1171 8.11165H12.6259L12.6258 8.11157Z"
      fill="#616161"
    />
    <path
      d="M12.0865 9.95537C12.1406 9.95455 12.1851 9.99789 12.1859 10.0522C12.1868 10.1065 12.1436 10.1512 12.0895 10.152C11.1172 10.167 10.3382 10.9655 10.3431 11.942C10.3482 12.9186 11.1353 13.709 12.1077 13.714C13.0802 13.7189 13.8753 12.9367 13.8902 11.9603C13.8911 11.906 13.9356 11.8626 13.9897 11.8635C14.0437 11.8643 14.0869 11.909 14.0861 11.9633C14.0695 13.0475 13.1866 13.9162 12.1067 13.9106C11.0268 13.905 10.1528 13.0274 10.1472 11.943C10.1418 10.8587 11.0067 9.97201 12.0865 9.95532L12.0865 9.95537Z"
      fill="#616161"
    />
    <path
      d="M12.5496 9.41919C12.4951 9.41919 12.4509 9.46353 12.4509 9.51825V11.4989C12.4509 11.5536 12.4951 11.5979 12.5496 11.5979H14.522C14.5765 11.5979 14.6206 11.5536 14.6206 11.4989C14.6206 10.3504 13.6935 9.41938 12.5496 9.4192V9.41919ZM12.6482 11.3998V9.61989C13.6047 9.67032 14.3707 10.4395 14.4206 11.3998H12.6482Z"
      fill="#616161"
    />
    <path
      d="M12.117 14.3464C12.1683 14.3464 12.2107 14.3844 12.2165 14.4333L12.2171 14.4447V17.466C12.2171 17.5203 12.1723 17.5643 12.117 17.5643C12.0656 17.5643 12.0233 17.5264 12.0175 17.4775L12.0168 17.466V14.4447C12.0168 14.3904 12.0617 14.3464 12.117 14.3464Z"
      fill="#616161"
    />
    <path
      d="M9.01249 21.3181C9.06385 21.3181 9.10617 21.3559 9.11196 21.4046L9.11264 21.416V23.3319C9.11264 23.386 9.0678 23.4298 9.0125 23.4298C8.96114 23.4298 8.91882 23.392 8.91303 23.3433L8.91235 23.3319V21.416C8.91235 21.362 8.95719 21.3181 9.01249 21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M9.41288 21.3181C9.46424 21.3181 9.50656 21.3559 9.51235 21.4046L9.51303 21.416V23.4325C9.51303 23.4866 9.46819 23.5304 9.41289 23.5304C9.36153 23.5304 9.31921 23.4926 9.31342 23.4439L9.31274 23.4325V21.416C9.31274 21.362 9.35758 21.3181 9.41288 21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M9.84721 21.3181C9.89857 21.3181 9.94089 21.3559 9.94668 21.4046L9.94735 21.416V23.5666C9.94735 23.6207 9.90252 23.6645 9.84721 23.6645C9.79586 23.6645 9.75353 23.6267 9.74774 23.578L9.74707 23.5666V21.416C9.74707 21.3619 9.7919 21.3181 9.84721 21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M10.2474 21.3181C10.2987 21.3181 10.341 21.3559 10.3468 21.4046L10.3475 21.416V23.6336C10.3475 23.6877 10.3027 23.7315 10.2474 23.7315C10.196 23.7315 10.1537 23.6937 10.1479 23.645L10.1472 23.6336V21.416C10.1472 21.3619 10.1921 21.3181 10.2474 21.3181V21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M8.5789 21.3181C8.63026 21.3181 8.67258 21.3559 8.67837 21.4046L8.67904 21.4161V23.1978C8.67904 23.2519 8.63421 23.2957 8.5789 23.2957C8.52755 23.2957 8.48522 23.2579 8.47943 23.2092L8.47876 23.1978V21.4161C8.47876 21.362 8.52359 21.3181 8.5789 21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M8.17802 21.3181C8.22938 21.3181 8.2717 21.3559 8.27749 21.4046L8.27816 21.4161V23.0973C8.27816 23.1514 8.23333 23.1952 8.17802 23.1952C8.12667 23.1952 8.08434 23.1574 8.07855 23.1087L8.07788 23.0973V21.4161C8.07788 21.362 8.12271 21.3181 8.17802 21.3181V21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M7.74418 21.3181C7.79554 21.3181 7.83786 21.3564 7.84365 21.4057L7.84433 21.4173V22.962C7.84433 23.0167 7.79949 23.0611 7.74419 23.0611C7.69283 23.0611 7.65051 23.0229 7.64472 22.9735L7.64404 22.962V21.4173C7.64404 21.3625 7.68888 21.3181 7.74418 21.3181H7.74418Z"
      fill="#73C06B"
    />
    <path
      d="M6.90971 19.4075C6.96107 19.4075 7.00339 19.4456 7.00918 19.4948L7.00985 19.5063V22.56C7.00985 22.6146 6.96502 22.6589 6.90971 22.6589C6.85836 22.6589 6.81603 22.6207 6.81024 22.5716L6.80957 22.56V19.5063C6.80957 19.4517 6.8544 19.4075 6.90971 19.4075Z"
      fill="#73C06B"
    />
    <path
      d="M7.31035 21.3181C7.3617 21.3181 7.40402 21.356 7.40981 21.4047L7.41049 21.4161V22.7285C7.41049 22.7826 7.36565 22.8265 7.31035 22.8265C7.25899 22.8265 7.21667 22.7887 7.21088 22.7399L7.21021 22.7285V21.4161C7.21021 21.362 7.25504 21.3181 7.31035 21.3181Z"
      fill="#73C06B"
    />
    <path
      d="M6.47587 19.0725C6.52723 19.0725 6.56955 19.1107 6.57534 19.1598L6.57602 19.1713V22.3256C6.57602 22.3802 6.53118 22.4245 6.47588 22.4245C6.42452 22.4245 6.3822 22.3863 6.37641 22.3372L6.37573 22.3257V19.1713C6.37573 19.1168 6.42057 19.0725 6.47587 19.0725V19.0725Z"
      fill="#73C06B"
    />
    <path
      d="M6.07499 18.7039C6.12635 18.7039 6.16867 18.742 6.17446 18.7911L6.17514 18.8026V22.0911C6.17514 22.1457 6.1303 22.1899 6.075 22.1899C6.02364 22.1899 5.98132 22.1518 5.97553 22.1027L5.97485 22.0911V18.8026C5.97485 18.7481 6.01969 18.7039 6.07499 18.7039V18.7039Z"
      fill="#73C06B"
    />
    <path
      d="M9.4794 14.4759C9.51774 14.4374 9.5799 14.4374 9.61824 14.4759C9.65364 14.5114 9.65636 14.5674 9.62641 14.606L9.61824 14.6153L5.70886 18.5411C5.67052 18.5796 5.60836 18.5796 5.57002 18.5411C5.53462 18.5055 5.5319 18.4496 5.56185 18.4109L5.57002 18.4016L9.4794 14.4759Z"
      fill="#616161"
    />
    <path
      d="M14.4654 18.7044C14.5154 18.7095 14.5527 18.7516 14.5535 18.801L14.553 18.8126L14.0587 23.7277C14.0395 23.879 13.915 23.9931 13.7668 23.9997L13.7489 24H10.7793C10.7252 24 10.6814 23.9557 10.6814 23.9012C10.6814 23.8506 10.7192 23.8088 10.7679 23.8031L10.7793 23.8025L13.7503 23.8025C13.8039 23.8033 13.8501 23.7662 13.8621 23.7168L13.8642 23.7052L14.3582 18.7926C14.3632 18.7423 14.405 18.7046 14.454 18.7039L14.4654 18.7044H14.4654Z"
      fill="#616161"
    />
    <path
      d="M14.2398 16.4913C14.4042 16.4886 14.5408 16.6155 14.5527 16.7796L14.5535 16.7973V17.2979C14.5535 17.3522 14.5096 17.3962 14.4554 17.3962C14.4051 17.3962 14.3637 17.3583 14.358 17.3093L14.3574 17.2979L14.3574 16.7991C14.3564 16.7409 14.3117 16.6937 14.2539 16.6885L14.2414 16.688L10.0239 16.688C9.96589 16.6871 9.91742 16.7303 9.91034 16.7854L9.90947 16.7973V17.2979C9.90947 17.3522 9.86557 17.3962 9.81142 17.3962C9.76114 17.3962 9.7197 17.3583 9.71404 17.3093L9.71338 17.2979V16.7956C9.71626 16.6306 9.84721 16.4979 10.0081 16.4915L10.0255 16.4913L14.2398 16.4913L14.2398 16.4913Z"
      fill="#616161"
    />
    <path
      d="M14.8865 17.2288C15.0468 17.2288 15.1778 17.3544 15.1869 17.5127L15.1874 17.5305V18.7384C15.1874 18.8255 15.1208 18.8969 15.0359 18.9041L15.0216 18.9047H12.1498C12.0955 18.9047 12.0514 18.8606 12.0514 18.8061C12.0514 18.7556 12.0894 18.7139 12.1383 18.7082L12.1498 18.7075L14.9907 18.7075L14.9907 17.5304C14.9907 17.4766 14.9501 17.4323 14.8979 17.4266L14.8865 17.426H12.1147C12.0604 17.426 12.0164 17.3818 12.0164 17.3274C12.0164 17.2768 12.0543 17.2351 12.1032 17.2294L12.1147 17.2288H14.8865V17.2288Z"
      fill="#616161"
    />
    <path
      d="M10.1832 11.7991C10.2372 11.7991 10.2811 11.8441 10.2811 11.8996C10.2811 11.9512 10.2433 11.9937 10.1946 11.9995L10.1832 12.0002H4.37058C4.31653 12.0002 4.27271 11.9552 4.27271 11.8996C4.27271 11.8481 4.31049 11.8056 4.35917 11.7997L4.37059 11.7991H10.1832Z"
      fill="#616161"
    />
    <path
      d="M11.9882 17.2288C12.0489 17.2286 12.1072 17.2527 12.1502 17.2957C12.1889 17.3344 12.2124 17.3855 12.2167 17.4397L12.2174 17.4579V20.2144C12.2174 20.2686 12.1736 20.3125 12.1195 20.3125C12.0693 20.3125 12.0279 20.2746 12.0223 20.2258L12.0216 20.2144V17.4579C12.0216 17.4492 12.0182 17.4408 12.012 17.4346C12.0073 17.43 12.0015 17.4269 11.9951 17.4256L11.9885 17.425H7.40558C7.38972 17.425 7.37649 17.4363 7.37343 17.4513L7.37276 17.4579V20.2144C7.37276 20.2686 7.32894 20.3125 7.27488 20.3125C7.22469 20.3125 7.18332 20.2747 7.17766 20.2259L7.177 20.2144V17.4579C7.177 17.3366 7.27099 17.2374 7.38993 17.2293L7.40558 17.2288H11.9882L11.9882 17.2288Z"
      fill="#616161"
    />
    <path
      d="M12.5191 20.6482H6.90762C6.85333 20.6482 6.80933 20.6931 6.80933 20.7485V20.8828C6.80933 21.052 6.87533 21.2142 6.99277 21.3337C7.1102 21.4532 7.26942 21.5202 7.43529 21.5197H11.9931C12.3376 21.5186 12.6164 21.2342 12.6175 20.8831V20.7485C12.6175 20.6931 12.5734 20.6482 12.5192 20.6482L12.5191 20.6482ZM7.00585 20.8488H12.4208V20.8828C12.4201 21.1233 12.2288 21.3184 11.9927 21.3192H7.435C7.3211 21.3194 7.21202 21.2736 7.13157 21.1917L7.11595 21.175C7.04528 21.095 7.00585 20.991 7.00585 20.8828V20.8488L7.00585 20.8488Z"
      fill="#616161"
    />
    <path
      d="M23.5334 8.84319C23.5725 8.80584 23.6347 8.807 23.6723 8.84579C23.7071 8.88159 23.7087 8.93694 23.678 8.97462L23.6697 8.98365L22.3307 10.2635C22.2916 10.3009 22.2294 10.2997 22.1918 10.2609C22.157 10.2251 22.1554 10.1698 22.1861 10.1321L22.1944 10.123L23.5334 8.84319V8.84319Z"
      fill="#73C06B"
    />
    <path
      d="M21.0308 8.87681C21.0696 8.83877 21.1315 8.83989 21.169 8.87932C21.2036 8.91571 21.2054 8.97202 21.1749 9.01039L21.1666 9.01958L19.7599 10.3966C19.7211 10.4347 19.6591 10.4335 19.6217 10.3941C19.587 10.3577 19.5853 10.3014 19.6158 10.2631L19.6241 10.2539L21.0308 8.87681V8.87681Z"
      fill="#73C06B"
    />
    <path
      d="M23.7662 9.21302C23.8042 9.17478 23.8661 9.17439 23.9046 9.21214C23.9401 9.24699 23.9432 9.30217 23.9136 9.34053L23.9055 9.34974L23.2 10.0602C23.162 10.0984 23.1 10.0988 23.0616 10.061C23.0261 10.0262 23.023 9.97102 23.0526 9.93266L23.0607 9.92344L23.7662 9.21302V9.21302Z"
      fill="#73C06B"
    />
    <path
      d="M21.3289 9.21302C21.3669 9.17478 21.4289 9.17439 21.4673 9.21214C21.5028 9.24699 21.5059 9.30217 21.4763 9.34053L21.4682 9.34974L20.7627 10.0602C20.7248 10.0984 20.6628 10.0988 20.6243 10.061C20.5888 10.0262 20.5858 9.97102 20.6154 9.93266L20.6234 9.92344L21.3289 9.21302V9.21302Z"
      fill="#73C06B"
    />
    <path
      d="M20.8735 8.78223H19.9858C19.714 8.78246 19.4939 9.00296 19.4937 9.27504V10.0161C19.4935 10.1599 19.5505 10.298 19.6521 10.3998C19.7536 10.5015 19.8914 10.5587 20.0351 10.5587H20.5284C20.8029 10.5587 21.0661 10.4495 21.2601 10.2552C21.4542 10.0608 21.5632 9.79721 21.5632 9.52235V9.47297C21.5632 9.09148 21.2544 8.78225 20.8736 8.78225L20.8735 8.78223ZM19.9858 8.97795H20.8735C21.1465 8.97795 21.3678 9.19958 21.3678 9.47298V9.52236C21.3678 9.74532 21.2794 9.95915 21.1219 10.1168C20.9645 10.2745 20.751 10.363 20.5284 10.363H20.0351C19.9433 10.363 19.8552 10.3265 19.7903 10.2614C19.7254 10.1964 19.689 10.1082 19.6891 10.0162V9.27513C19.6892 9.11111 19.822 8.97809 19.9859 8.97796L19.9858 8.97795Z"
      fill="#616161"
    />
    <path
      d="M23.4415 8.78228H22.5528C22.1723 8.7825 21.864 9.09169 21.864 9.473V9.52238C21.864 10.0946 22.3267 10.5586 22.8976 10.5588H23.3902C23.5338 10.5594 23.6721 10.5025 23.7741 10.4007C23.8762 10.2989 23.9336 10.1605 23.9336 10.0161V9.27512C23.9333 9.00279 23.7131 8.78223 23.4415 8.78223L23.4415 8.78228ZM22.5528 8.978H23.4415C23.6053 8.978 23.7381 9.11105 23.7383 9.27526V10.0162C23.7383 10.1085 23.7016 10.197 23.6364 10.2621C23.5711 10.3272 23.4827 10.3635 23.3906 10.3631H22.8976C22.4345 10.363 22.0593 9.98662 22.0593 9.52245V9.47307C22.0593 9.19978 22.2802 8.97819 22.5528 8.97804L22.5528 8.978Z"
      fill="#616161"
    />
    <path
      d="M21.9667 9.18408C22.0204 9.18408 22.0639 9.2291 22.0639 9.28464C22.0639 9.33621 22.0264 9.37871 21.978 9.38453L21.9667 9.3852H21.4269C21.3731 9.3852 21.3296 9.34018 21.3296 9.28464C21.3296 9.23307 21.3671 9.19057 21.4155 9.18476L21.4268 9.18408H21.9667V9.18408Z"
      fill="#616161"
    />
    <path
      d="M19.5632 9.18408C19.6172 9.18408 19.661 9.22828 19.661 9.2828C19.661 9.33343 19.6232 9.37516 19.5746 9.38086L19.5632 9.38153H16.375C16.092 9.38153 15.9751 9.48927 15.9353 9.78027L15.93 9.82418C15.9292 9.83172 15.9284 9.83937 15.9277 9.84713L15.9239 9.89505C15.9234 9.90327 15.9229 9.9116 15.9224 9.92004L15.92 9.97211L15.9185 10.027L15.9178 10.0848L15.9177 11.8675C15.9177 11.922 15.8739 11.9662 15.8199 11.9662C15.7698 11.9662 15.7285 11.9281 15.7228 11.879L15.7222 11.8675L15.7223 10.0771L15.7231 10.0059C15.7233 9.99456 15.7236 9.98339 15.7239 9.97244L15.7262 9.90922C15.7344 9.73691 15.7567 9.62046 15.8072 9.50885C15.8995 9.30483 16.0765 9.19205 16.3458 9.18451L16.375 9.18411H19.5632V9.18408Z"
      fill="#616161"
    />
    <path
      d="M9.27979 18.3006C9.27979 18.2251 9.35961 18.1786 9.42395 18.2123L9.43348 18.218L10.5376 18.9567C10.5926 18.9935 10.5961 19.0719 10.5479 19.1139L10.5376 19.1218L9.43348 19.8605C9.38805 19.8909 9.32668 19.8785 9.2964 19.8329C9.26828 19.7906 9.27686 19.7345 9.3146 19.7023L9.32382 19.6954L10.3044 19.0392L9.47742 18.4858L9.47749 19.4547C9.47749 19.5056 9.43933 19.5475 9.39017 19.5533L9.37864 19.5539C9.32795 19.5539 9.28617 19.5156 9.28046 19.4663L9.27979 19.4547V18.3005L9.27979 18.3006Z"
      fill="#73C06B"
    />
    <path
      d="M12.1188 0.0670522C12.173 0.0670522 12.2169 0.111221 12.2169 0.165707C12.2169 0.220193 12.173 0.264363 12.1188 0.264363C7.91861 0.264363 4.10997 2.52583 2.05256 6.11813C2.02551 6.16535 1.96552 6.18158 1.91856 6.15438C1.87159 6.12719 1.85545 6.06687 1.8825 6.01966C3.97507 2.36681 7.84791 0.0670166 12.1192 0.0670166L12.1188 0.0670522Z"
      fill="#616161"
    />
    <path
      d="M5.64359 18.3689C5.69759 18.3689 5.74137 18.4129 5.74137 18.4672C5.74137 18.5177 5.70362 18.5592 5.65499 18.5649L5.64359 18.5656L5.52868 18.5656C4.42875 18.5832 3.43455 18.9441 2.69109 19.5412C2.03834 20.0654 1.64618 20.7272 1.6313 21.2931L1.63086 21.3263L1.6308 21.6917L2.09728 21.6918C2.14742 21.6918 2.18875 21.7297 2.19439 21.7786L2.19505 21.7901C2.19505 21.8405 2.15731 21.8821 2.10868 21.8878L2.09727 21.8885H1.53308C1.48293 21.8885 1.44161 21.8505 1.43596 21.8016L1.4353 21.7901V21.3263C1.4353 20.6866 1.86069 19.9564 2.56901 19.3874C3.33179 18.7748 4.34394 18.4013 5.46354 18.3703L5.52704 18.3689H5.6435L5.64359 18.3689Z"
      fill="#616161"
    />
    <path
      d="M5.64316 21.7207C5.69734 21.7207 5.74127 21.7657 5.74127 21.8213C5.74127 21.8728 5.7034 21.9153 5.6546 21.9211L5.64316 21.9218H3.33591C3.28172 21.9218 3.23779 21.8768 3.23779 21.8213C3.23779 21.7697 3.27567 21.7272 3.32446 21.7214L3.33591 21.7207H5.64316Z"
      fill="#616161"
    />
    <path
      d="M5.64371 20.0112C5.69766 20.0112 5.74139 20.0563 5.74139 20.1118C5.74139 20.1634 5.70368 20.2059 5.6551 20.2117L5.64371 20.2123H2.90164C2.84769 20.2123 2.80396 20.1673 2.80396 20.1118C2.80396 20.0602 2.84166 20.0177 2.89024 20.0119L2.90163 20.0112H5.64371V20.0112Z"
      fill="#73C06B"
    />
    <path
      d="M2.73703 20.95C2.33146 20.95 2.00269 21.2801 2.00269 21.6874C2.00269 22.0946 2.33147 22.4248 2.73703 22.4248C3.1426 22.4248 3.47137 22.0946 3.47137 21.6874C3.47137 21.2801 3.14259 20.95 2.73703 20.95ZM2.73703 21.1482C3.03358 21.1482 3.27397 21.3896 3.27397 21.6874C3.27397 21.9852 3.03357 22.2266 2.73703 22.2266C2.44048 22.2266 2.20009 21.9852 2.20009 21.6874C2.20009 21.3896 2.44049 21.1482 2.73703 21.1482Z"
      fill="#616161"
    />
    <path
      d="M22.9975 12.2984C23.0349 12.259 23.0967 12.2578 23.1356 12.2957C23.1715 12.3307 23.1753 12.3867 23.1462 12.4261L23.1383 12.4356L21.9901 13.6455C21.9551 13.6824 21.8988 13.6857 21.8599 13.6552L21.8506 13.6469L21.3916 13.1815C21.3535 13.1429 21.3535 13.0802 21.3917 13.0416C21.4269 13.0059 21.4823 13.0032 21.5206 13.0334L21.5298 13.0416L21.9183 13.4356L22.9975 12.2984L22.9975 12.2984Z"
      fill="#73C06B"
    />
    <path
      d="M3.72948 15.5954C3.77875 15.5731 3.83676 15.595 3.85905 15.6445C4.31034 16.6462 4.93833 17.5578 5.71276 18.3349C5.75101 18.3733 5.75103 18.4356 5.7128 18.474C5.67458 18.5124 5.61259 18.5124 5.57433 18.4741C4.78323 17.6801 4.14165 16.7489 3.6806 15.7255C3.65831 15.676 3.68018 15.6178 3.72945 15.5954L3.72948 15.5954Z"
      fill="#616161"
    />
    <path
      d="M3.55501 15.1172C3.34301 15.1172 3.17114 15.2898 3.17114 15.5027C3.17114 15.7156 3.34301 15.8881 3.55501 15.8881C3.76702 15.8881 3.93888 15.7156 3.93888 15.5027C3.93888 15.2898 3.76702 15.1172 3.55501 15.1172V15.1172ZM3.55501 15.314C3.65879 15.314 3.74292 15.3985 3.74292 15.5027C3.74292 15.6069 3.65879 15.6914 3.55501 15.6914C3.45124 15.6914 3.36711 15.6069 3.36711 15.5027C3.36711 15.3985 3.45124 15.314 3.55501 15.314Z"
      fill="#616161"
    />
    <path
      d="M1.38533 15.1172C1.17333 15.1172 1.00146 15.2898 1.00146 15.5027C1.00146 15.7156 1.17333 15.8881 1.38533 15.8881C1.59734 15.8881 1.7692 15.7156 1.7692 15.5027C1.7692 15.2898 1.59734 15.1172 1.38533 15.1172V15.1172ZM1.38533 15.314C1.48911 15.314 1.57324 15.3985 1.57324 15.5027C1.57324 15.6069 1.48911 15.6914 1.38533 15.6914C1.28156 15.6914 1.19743 15.6069 1.19743 15.5027C1.19743 15.3985 1.28156 15.314 1.38533 15.314Z"
      fill="#616161"
    />
    <path
      d="M4.37441 11.7989C4.42848 11.7986 4.47253 11.8427 4.4728 11.8973C4.47307 11.952 4.42946 11.9965 4.37539 11.9967C4.16271 11.9978 3.98502 12.1579 3.95773 12.3694L3.95556 12.39L3.74552 14.7925C3.7414 14.8396 3.70502 14.8766 3.65955 14.882L3.64801 14.8827H0.739429C0.692619 14.8827 0.652963 14.8493 0.643609 14.8041L0.641915 14.7925L0.43423 12.4311C0.429481 12.3771 0.468465 12.3301 0.520426 12.3241L0.531748 12.3234H3.38824C3.44231 12.3234 3.48615 12.3677 3.48615 12.4223C3.48615 12.4731 3.44835 12.5149 3.39966 12.5206L3.38824 12.5213L0.638729 12.5212L0.828948 14.6848H3.5583L3.76044 12.3731C3.7861 12.0575 4.04074 11.8124 4.35154 11.7994L4.37438 11.7988L4.37441 11.7989Z"
      fill="#616161"
    />
    <path
      d="M1.63554 12.3352C1.6869 12.3352 1.72922 12.3732 1.73501 12.4222L1.73568 12.4337V14.7842C1.73568 14.8386 1.69085 14.8827 1.63554 14.8827C1.58419 14.8827 1.54186 14.8447 1.53607 14.7957L1.5354 14.7842V12.4337C1.5354 12.3793 1.58023 12.3352 1.63554 12.3352Z"
      fill="#616161"
    />
    <path
      d="M2.7371 12.3352C2.78846 12.3352 2.83078 12.3732 2.83657 12.4222L2.83725 12.4337V14.7842C2.83725 14.8386 2.79241 14.8827 2.73711 14.8827C2.68575 14.8827 2.64343 14.8447 2.63764 14.7957L2.63696 14.7842V12.4337C2.63696 12.3793 2.6818 12.3352 2.7371 12.3352Z"
      fill="#616161"
    />
    <path
      d="M3.7409 13.542C3.79498 13.542 3.83882 13.587 3.83882 13.6426C3.83882 13.6941 3.80102 13.7366 3.75232 13.7424L3.7409 13.7431H0.6321C0.578021 13.7431 0.53418 13.6981 0.53418 13.6426C0.53418 13.591 0.571981 13.5485 0.620682 13.5427L0.632101 13.542H3.7409Z"
      fill="#616161"
    />
    <path
      d="M13.8924 4.66294C13.918 4.57679 14.0327 4.56795 14.0728 4.64376L14.0778 4.65462L14.3651 5.38107L14.6888 5.38112C14.7392 5.38112 14.7807 5.41946 14.7864 5.46886L14.7871 5.48044C14.7871 5.53138 14.7491 5.57336 14.7003 5.5791L14.6888 5.57976H14.2986C14.2624 5.57976 14.2295 5.55973 14.2124 5.52822L14.2073 5.51731L13.9997 4.99242L13.6122 6.29794C13.5866 6.38409 13.4719 6.39293 13.4318 6.31712L13.4268 6.30626L13.1394 5.57975L12.8158 5.57979C12.7654 5.57979 12.7239 5.54145 12.7182 5.49206L12.7175 5.48047C12.7175 5.42954 12.7555 5.38755 12.8043 5.38182L12.8158 5.38115H13.206C13.2422 5.38115 13.2751 5.40119 13.2922 5.43269L13.2973 5.44361L13.5048 5.96841L13.8924 4.663L13.8924 4.66294Z"
      fill="#73C06B"
    />
    <path
      d="M18.5882 5.35818C18.6265 5.31986 18.6883 5.32009 18.7263 5.35868C19.6975 6.34548 20.4371 7.5393 20.8905 8.85235C20.9082 8.90371 20.8813 8.95984 20.8304 8.97773C20.7795 8.99562 20.7238 8.96849 20.7061 8.91713C20.2622 7.63185 19.5384 6.46334 18.5877 5.49745C18.5497 5.45886 18.5499 5.39652 18.5882 5.3582L18.5882 5.35818Z"
      fill="#616161"
    />
    <path
      d="M20.3934 20.0739C20.4314 20.0351 20.4936 20.0345 20.5322 20.0726C20.5709 20.1106 20.5715 20.1729 20.5335 20.2117C18.772 22.0099 16.4823 23.196 13.9995 23.5962C13.946 23.6048 13.8956 23.5684 13.887 23.5147C13.8784 23.4611 13.9148 23.4107 13.9683 23.402C16.4098 23.0084 18.6614 21.8421 20.3937 20.0739L20.3934 20.0739Z"
      fill="#616161"
    />
    <path
      d="M21.7057 5.14595C21.75 5.11514 21.8108 5.1263 21.8414 5.17087C22.5192 6.15701 23.0432 7.24153 23.3949 8.3869C23.4108 8.43867 23.382 8.4936 23.3305 8.50959C23.279 8.52558 23.2244 8.49658 23.2085 8.4448C22.8626 7.31853 22.3474 6.25208 21.6809 5.2824C21.6503 5.23783 21.6613 5.17672 21.7057 5.14591V5.14595Z"
      fill="#616161"
    />
    <path
      d="M3.80738 6.33533C3.86162 6.33533 3.90558 6.38035 3.90558 6.43589C3.90558 6.48746 3.86767 6.52996 3.81883 6.53577L3.80737 6.53645H1.26666C1.21242 6.53645 1.16846 6.49143 1.16846 6.43589C1.16846 6.38432 1.20637 6.34182 1.25521 6.336L1.26666 6.33533H3.80738Z"
      fill="#73C06B"
    />
    <path
      d="M2.57156 6.771C2.62613 6.771 2.67037 6.81602 2.67037 6.87156C2.67037 6.92313 2.63223 6.96563 2.58309 6.97144L2.57157 6.97212H1.06682C1.01225 6.97212 0.968018 6.92709 0.968018 6.87156C0.968018 6.81999 1.00616 6.77749 1.05529 6.77167L1.06682 6.771H2.57156Z"
      fill="#73C06B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00377 7.17297C2.05857 7.17297 2.103 7.218 2.103 7.27353C2.103 7.3251 2.06469 7.3676 2.01534 7.37342L2.00376 7.37409H0.867057C0.81225 7.37409 0.767822 7.32907 0.767822 7.27354C0.767822 7.22196 0.806131 7.17946 0.855489 7.17365L0.867062 7.17297H2.00377Z"
      fill="#73C06B"
    />
    <path
      d="M1.4388 8.01099C1.49218 8.01099 1.53545 8.05601 1.53545 8.11155C1.53545 8.16312 1.49814 8.20562 1.45007 8.21143L1.4388 8.21211H0.563934C0.510556 8.21211 0.467285 8.16709 0.467285 8.11155C0.467285 8.05998 0.504596 8.01748 0.552666 8.01166L0.563937 8.01099H1.4388Z"
      fill="#73C06B"
    />
    <path
      d="M1.43621 8.41357C1.4911 8.41357 1.53561 8.4586 1.53561 8.51413C1.53561 8.5657 1.49723 8.6082 1.4478 8.61402L1.43621 8.61469H0.39994C0.345042 8.61469 0.300537 8.56967 0.300537 8.51414C0.300537 8.46257 0.33891 8.42007 0.388348 8.41425L0.39994 8.41357H1.43621Z"
      fill="#73C06B"
    />
    <path
      d="M1.70352 8.84912C1.75814 8.84912 1.80242 8.89414 1.80242 8.94968C1.80242 9.00125 1.76424 9.04375 1.71506 9.04956L1.70352 9.05024H0.299094C0.244474 9.05024 0.200195 9.00522 0.200195 8.94968C0.200195 8.89811 0.238374 8.85561 0.287562 8.8498L0.299096 8.84912H1.70352V8.84912Z"
      fill="#73C06B"
    />
    <path
      d="M2.07111 9.25146C2.12552 9.25146 2.16963 9.29649 2.16963 9.35202C2.16963 9.40359 2.1316 9.44609 2.0826 9.45191L2.07111 9.45259H0.198618C0.144207 9.45259 0.100098 9.40756 0.100098 9.35203C0.100098 9.30046 0.13813 9.25796 0.187128 9.25214L0.198618 9.25146H2.07111Z"
      fill="#73C06B"
    />
    <path
      d="M2.5722 9.68701C2.62646 9.68701 2.67045 9.73203 2.67045 9.78757C2.67045 9.83914 2.63252 9.88164 2.58366 9.88746L2.5722 9.88813H0.131692C0.0774341 9.88813 0.0334473 9.84311 0.0334473 9.78757C0.0334473 9.736 0.0713729 9.6935 0.120232 9.68769L0.131689 9.68701H2.5722V9.68701Z"
      fill="#73C06B"
    />
    <path
      d="M4.10799 10.0891C4.16204 10.0891 4.20586 10.1341 4.20586 10.1897C4.20586 10.2412 4.16808 10.2837 4.1194 10.2896L4.10799 10.2902H0.0978665C0.0438158 10.2902 0 10.2452 0 10.1897C0 10.1381 0.0377804 10.0956 0.0864545 10.0898L0.0978677 10.0891H4.10799Z"
      fill="#73C06B"
    />
    <path
      d="M1.67249 7.60901C1.72589 7.60901 1.76917 7.65403 1.76917 7.70957C1.76917 7.76114 1.73185 7.80364 1.68376 7.80945L1.67249 7.81013H0.730962C0.677565 7.81013 0.634277 7.76511 0.634277 7.70957C0.634277 7.658 0.671601 7.6155 0.719688 7.60969L0.730963 7.60901H1.67249V7.60901Z"
      fill="#73C06B"
    />
    <path
      d="M23.3018 8.34656C23.3556 8.34656 23.3993 8.39158 23.3993 8.44712C23.3993 8.49869 23.3616 8.54119 23.3131 8.547L23.3018 8.54768H21.3604C21.3066 8.54768 21.2629 8.50266 21.2629 8.44712C21.2629 8.39555 21.3006 8.35305 21.3491 8.34723L21.3604 8.34656H23.3018Z"
      fill="#616161"
    />
    <path
      d="M1.23031 16.1974C1.28098 16.177 1.33865 16.2014 1.35913 16.252C1.78034 17.2932 2.34967 18.2683 3.04958 19.1471C3.08359 19.1899 3.0765 19.252 3.03373 19.286C2.99096 19.32 2.92871 19.3129 2.8947 19.2702C2.18298 18.3765 1.60403 17.3849 1.17569 16.3261C1.15522 16.2755 1.17969 16.2179 1.23036 16.1974L1.23031 16.1974Z"
      fill="#616161"
    />
    <path
      d="M1.79377 16.366C1.84301 16.3442 1.90053 16.3667 1.92225 16.4163C2.29676 17.2725 2.77659 18.0777 3.35037 18.8133C3.38364 18.8559 3.37631 18.9177 3.33401 18.9512C3.2917 18.9847 3.23044 18.9773 3.19717 18.9347C2.61326 18.1862 2.12502 17.3668 1.7439 16.4955C1.72219 16.4459 1.74451 16.3879 1.79375 16.366L1.79377 16.366Z"
      fill="#616161"
    />
    <path
      d="M19.7628 20.1046C19.8022 20.0676 19.8641 20.0696 19.9011 20.1091C19.938 20.1486 19.936 20.2106 19.8966 20.2476C18.3559 21.695 16.4413 22.6808 14.3698 23.093C14.3168 23.1035 14.2653 23.0691 14.2548 23.016C14.2443 22.9629 14.2787 22.9113 14.3317 22.9007C16.3673 22.4956 18.2488 21.527 19.7628 20.1047L19.7628 20.1046Z"
      fill="#616161"
    />
    <path
      d="M21.0349 4.98118C21.0783 4.94807 21.1404 4.95645 21.1735 4.99989C21.9587 6.02989 22.5629 7.18581 22.9605 8.41825C22.9773 8.47023 22.9487 8.52595 22.8967 8.54271C22.8447 8.55948 22.789 8.53093 22.7722 8.47894C22.3815 7.26784 21.7877 6.13192 21.0161 5.11976C20.983 5.07632 20.9914 5.01426 21.0348 4.98116L21.0349 4.98118Z"
      fill="#616161"
    />
    <path
      d="M9.3242 0.841071C9.37668 0.827664 9.43004 0.859582 9.44338 0.912361C9.45671 0.965139 9.42497 1.01879 9.37248 1.0322C6.5221 1.76028 4.07169 3.58818 2.55164 6.11993C2.52366 6.16655 2.46339 6.18153 2.41703 6.15339C2.37066 6.12525 2.35577 6.06464 2.38375 6.01803C3.9302 3.44187 6.42357 1.58191 9.32399 0.841061L9.3242 0.841071Z"
      fill="#616161"
    />
    <path
      d="M3.24048 16.1902C3.29433 16.1902 3.33798 16.2352 3.33798 16.2907C3.33798 16.3423 3.30034 16.3848 3.25185 16.3906L3.24048 16.3913H1.29917C1.24531 16.3913 1.20166 16.3463 1.20166 16.2907C1.20166 16.2392 1.2393 16.1967 1.2878 16.1909L1.29917 16.1902H3.24048Z"
      fill="#616161"
    />
    <path
      d="M18.0248 17.5975L17.9946 17.5969C17.7932 17.5892 17.6016 17.5055 17.4585 17.3618C17.3083 17.2109 17.224 17.0063 17.2241 16.793C17.2241 16.4676 17.4193 16.1742 17.7187 16.0497C18.0181 15.9253 18.3627 15.9942 18.5918 16.2243C18.8209 16.4545 18.8893 16.8005 18.7652 17.1011C18.6447 17.3931 18.3654 17.5864 18.0525 17.597L18.0248 17.5975L18.0248 17.5975ZM18.4544 16.3623C18.2809 16.1879 18.0199 16.1357 17.7931 16.23C17.5663 16.3243 17.4184 16.5465 17.4184 16.7931C17.4183 16.9546 17.4822 17.1096 17.596 17.2239C17.703 17.3315 17.846 17.3946 17.9966 17.4017L18.0249 17.4023L18.0494 17.4019C18.2769 17.3928 18.4804 17.2564 18.5759 17.0489L18.5858 17.0264C18.6798 16.7987 18.6279 16.5366 18.4544 16.3623L18.4544 16.3623Z"
      fill="#616161"
    />
    <path
      d="M18.0266 15.9888C18.4712 15.9983 18.8265 16.3557 18.8265 16.7932C18.8265 17.2308 18.4712 17.5881 18.0266 17.5976C17.9719 17.5988 17.9265 17.5561 17.9253 17.5022C17.9241 17.4483 17.9675 17.4037 18.0223 17.4025C18.3591 17.3953 18.6282 17.1246 18.6282 16.7932C18.6282 16.4618 18.3591 16.1911 18.0223 16.1839C17.9675 16.1827 17.9241 16.1381 17.9253 16.0842C17.9265 16.0303 17.9719 15.9876 18.0266 15.9888V15.9888Z"
      fill="#616161"
    />
    <path
      d="M18.0252 16.3909C18.0765 16.3909 18.1189 16.4286 18.1247 16.4771L18.1253 16.4885V17.0977C18.1253 17.1516 18.0805 17.1953 18.0252 17.1953C17.9738 17.1953 17.9315 17.1576 17.9257 17.1091L17.925 17.0977V16.4885C17.925 16.4346 17.9699 16.3909 18.0252 16.3909Z"
      fill="#616161"
    />
    <path
      d="M18.3281 16.6926C18.3818 16.6926 18.4254 16.7376 18.4254 16.7932C18.4254 16.8448 18.3878 16.8873 18.3395 16.8931L18.3281 16.8937H17.7215C17.6678 16.8937 17.6243 16.8487 17.6243 16.7932C17.6243 16.7416 17.6618 16.6991 17.7101 16.6933L17.7215 16.6926H18.3281Z"
      fill="#616161"
    />
    <path
      d="M18.0252 14.7483C18.0765 14.7483 18.1189 14.7866 18.1247 14.836L18.1253 14.8476V16.0232C18.1253 16.0781 18.0805 16.1226 18.0252 16.1226C17.9738 16.1226 17.9315 16.0842 17.9257 16.0348L17.925 16.0232V14.8476C17.925 14.7928 17.9699 14.7483 18.0252 14.7483Z"
      fill="#616161"
    />
    <path
      d="M4.28937 6.33533C2.7377 6.33533 1.46875 7.21126 1.46875 8.31295C1.46875 9.41465 2.7377 10.2906 4.28937 10.2906C5.84103 10.2906 7.10998 9.41465 7.10998 8.31295C7.10998 7.21126 5.84103 6.33533 4.28937 6.33533V6.33533ZM4.28937 6.53175C5.74497 6.53175 6.91396 7.33869 6.91396 8.31295C6.91396 9.28722 5.74497 10.0942 4.28937 10.0942C2.83376 10.0942 1.66477 9.28722 1.66477 8.31295C1.66477 7.33869 2.83376 6.53175 4.28937 6.53175Z"
      fill="#616161"
    />
    <path
      d="M4.20571 6.9386C3.44988 6.9386 2.83716 7.55388 2.83716 8.31287C2.83716 9.07185 3.44988 9.68714 4.20571 9.68714C4.96153 9.68714 5.57425 9.07185 5.57425 8.31287C5.57425 7.55388 4.96153 6.9386 4.20571 6.9386V6.9386ZM4.20571 7.13547C4.85329 7.13547 5.37823 7.66263 5.37823 8.3129C5.37823 8.96317 4.85327 9.49033 4.20571 9.49033C3.55812 9.49033 3.03318 8.96317 3.03318 8.3129C3.03318 7.66263 3.55814 7.13547 4.20571 7.13547V7.13547Z"
      fill="#616161"
    />
    <path
      d="M4.1891 7.60901C3.79274 7.60901 3.47144 7.93166 3.47144 8.32967C3.47144 8.72769 3.79274 9.05034 4.1891 9.05034C4.58546 9.05034 4.90676 8.72769 4.90676 8.32967C4.90676 7.93166 4.58546 7.60901 4.1891 7.60901V7.60901ZM4.1891 7.80742C4.47633 7.80742 4.70919 8.04124 4.70919 8.32968C4.70919 8.61812 4.47634 8.85194 4.1891 8.85194C3.90186 8.85194 3.66901 8.61812 3.66901 8.32968C3.66901 8.04124 3.90186 7.80742 4.1891 7.80742Z"
      fill="#616161"
    />
    <path
      d="M5.64116 18.3689C5.69251 18.3689 5.73484 18.407 5.74063 18.4561L5.7413 18.4676V21.8232C5.7413 21.8777 5.69646 21.922 5.64116 21.922C5.5898 21.922 5.54748 21.8838 5.54169 21.8347L5.54102 21.8232V18.4676C5.54102 18.4131 5.58585 18.3689 5.64116 18.3689V18.3689Z"
      fill="#616161"
    />
  </React.Fragment>,
  "ConnectIllustration"
);
