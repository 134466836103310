import React from "react";
import clsx from "clsx";
import DialogContent from "../../core-components/DialogContent";
import { makeStyles, mergeClasses } from "../../utils";

export default function FullScreenDialogContent({
  classes,
  className,
  ...props
}) {
  let defaultClasses = useStyles();
  return (
    <DialogContent
      className={clsx("FullScreenDialogContent-root", className)}
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  );
}

let useStyles = makeStyles(theme => ({
  // overrides Dialog fullScreens hardcoded column setting, to be removed in 2.0
  root: {
    ".MuiDialog-paperFullScreen > &": {
      padding: "12px 24px 24px 24px"
    }
  }
}));
