import React, { Fragment } from "react";
import CodeChunk from "../resources/CodeChunk";
import PropsTable from "./PropsTable";
import ClassesTable from "./ClassesTable";
import css from "../resources/Page.module.css";

export default function Page({ component, docs }) {
  let split = component.split("/");
  let parent = split[0];
  let child = split.length > 1 ? split[1] : "";
  let importStr = [];
  if (child !== "") {
    importStr.push(
      `// named export`,
      `import { ${child} } from "@liveramp/motif/core/${parent}";`,
      ``,
      `// default export`
    );
  }
  importStr.push(
    `import ${
      child !== "" ? child : parent
    } from "@liveramp/motif/core/${component}";`
  );
  importStr = importStr.join(`\n`);

  return (
    <div>
      <h1>{component} API</h1>
      <CodeChunk>{importStr}</CodeChunk>
      <h3>Props</h3>
      <p>Prop names followed by an asterisk are required props.</p>
      <PropsTable props={docs.props} />

      {docs.muiName && (
        <Fragment>
          <h3>
            <a id="css" href="#css" className={css.anchor}>
              Classes
            </a>
            Classes:<code className={css.codeHeading}>{docs.muiName}</code>
          </h3>
          <p>
            You can override all the styles injected by Material-UI and Motif
            using either the <code>classes</code> prop object or the
            corresponding global class name.
          </p>
          <ClassesTable name={docs.muiName} styles={docs.styles} />
        </Fragment>
      )}
    </div>
  );
}
