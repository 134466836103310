import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path d="M5.322 17.14a1.555 1.555 0 100-3.11 1.555 1.555 0 000 3.11zm0-1a.555.555 0 110-1.11.555.555 0 010 1.11zm.208-3.62l-.634-8.3A2.415 2.415 0 002.503 2l-.006 1A1.414 1.414 0 013.9 4.298l.668 8.76.035.462h4.826v-1h-3.9zm10.514-7.258c-.056.763-.119 1.552-.187 2.326l.996.088a154.415 154.415 0 00.24-3.074l.018-.274.034-.532H6.092v1h9.985l-.033.466z" />
    <path d="M12.007 4.296v4.362h1V4.296zm-4.106 0v8.724h1V4.296z" />
    <path d="M12.507 8.158H4.745v1h7.762zm8.48 3.835v7.185c0 .93-1.901 1.809-4.503 1.809-2.602 0-4.504-.88-4.504-1.81v-7.184h-1v7.185c0 1.692 2.414 2.809 5.504 2.809 3.089 0 5.503-1.117 5.503-2.81v-7.184h-1z" />
    <path d="M12.967 14.384c.94.365 2.148.547 3.517.547 3.035 0 5.503-1.23 5.503-2.938 0-1.692-2.414-2.809-5.503-2.809-3.09 0-5.504 1.117-5.504 2.81h1c0-.93 1.902-1.81 4.504-1.81 2.602 0 4.503.88 4.503 1.81 0 .965-1.95 1.937-4.503 1.937-1.254 0-2.344-.164-3.155-.48l-.362.933zm-.001 3.335a9.828 9.828 0 003.514.676c3.039 0 5.507-1.23 5.507-2.938h-1c0 .966-1.95 1.938-4.503 1.938a8.824 8.824 0 01-3.154-.607l-.364.93z" />
  </React.Fragment>,
  "DataMarketplace"
);
