import React, { createContext } from "react";
import { useFileListReducer } from "./";

const UploadContext = createContext();

export const UploadContextProvider = ({ children }) => {
  const [state, actions] = useFileListReducer();
  return (
    <UploadContext.Provider value={[state, actions]}>
      {children}
    </UploadContext.Provider>
  );
};

export default UploadContext;
