// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Devices category

let icons = [
  "Android",
  "Apple",
  "Household",
  "Laptop",
  "Mobile",
  "Phone",
  "TV"
];

export default icons;
