import CardContent from "@material-ui/core/CardContent";
import withStyles from "../../styles/withStyles";
import { CardContentStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...CardContentStyle,
  root: {
    "&:last-child": {
      paddingBottom: "16px",
      fontFamily: theme.typography.fontFamily
    }
  }
});

export default withStyles(styles, { name: "MuiCardContent" })(CardContent);
