import React from "react";
import MuiStepper from "@material-ui/core/Stepper";
import StepConnector from "../StepConnector";

export default function Stepper({ orientation, ...props }) {
  return (
    <MuiStepper
      connector={<StepConnector />}
      orientation={orientation}
      alternativeLabel={orientation !== "vertical"}
      {...props}
    />
  );
}
