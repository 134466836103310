import React from "react";
import Divider from "@liveramp/motif/core/Divider";
import Chip from "@liveramp/motif/core/Chip";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import theme from "@liveramp/motif/theme";
import css from "./Demos.module.css";

let lorem =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.";

export default function FontsDemo() {
  const classes = useStyles();
  return (
    <div className={css.root}>
      <Chip className={css.chip}>
        Check out the code snippets to see how these typography styles are
        generated
      </Chip>
      <h1>h1 Heading</h1>
      <Divider />
      <h2>h2 Heading</h2>
      <h2 className={classes.h2Branding}>h2 Branding</h2>
      <Divider />
      <h3>h3 Heading</h3>
      <h3 className={classes.h3Branding}>h3 Branding</h3>
      <Divider />
      <h4>h4 Heading</h4>
      <h4 className={classes.h4Branding}>h4 Branding</h4>
      <Divider />
      <h5>h5 Heading</h5>
      <Divider />
      <h6>h6 Heading</h6>
      <Divider />
      <span className={classes.subtitle1}>Subtitle 1</span>
      <span className={classes.subtitle2}>Subtitle 2</span>
      <span className={classes.subtitle3}>Subtitle 3</span>
      <Divider />
      <p>Body 1. {lorem}</p>
      <p className={classes.body2}>Body 2. {lorem}</p>
      <p className={classes.body3}>Body 3. {lorem}</p>
      <Divider />
      <span className={classes.caption}>Caption</span>
    </div>
  );
}

const useStyles = makeStyles({
  h2Branding: theme.typography.h2Branding,
  h3Branding: theme.typography.h3Branding,
  h4Branding: theme.typography.h4Branding,
  subtitle1: theme.typography.subtitle1,
  subtitle2: theme.typography.subtitle2,
  subtitle3: theme.typography.subtitle3,
  body1: theme.typography.body1,
  body2: theme.typography.body2,
  body3: theme.typography.body3,
  caption: theme.typography.caption
});
