import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM8.667 8.486v7.98c0 .61.735.979 1.3.648l6.878-3.99c.524-.301.524-.994 0-1.303L9.968 7.84c-.566-.332-1.301.038-1.301.647z"
      fill="#73C06B"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "StatusActive"
);
