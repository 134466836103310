import Tabs from "@material-ui/core/Tabs";
import withStyles from "../../styles/withStyles";
import { TabsStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...TabsStyle,
  root: {
    boxShadow: `0 4px 2px -1px ${theme.palette.secondary.light}`,
    position: "relative"
  },
  indicator: {
    backgroundColor: theme.palette.info.main,
    height: "3px"
  },
  scrollButtons: {
    width: 24,
    height: 24,
    alignSelf: "center",
    borderRadius: 12,
    "&:hover": {
      backgroundColor: "rgba(0, 0 , 0, 0.1)"
    },
    "&:active": {
      backgroundColor: "rgba(0, 0 , 0, 0.2)"
    }
  }
});

export default withStyles(styles, { name: "MuiTabs" })(Tabs);

import MotifTabs from "./Tabs";
import Tab from "./Tab";

export { MotifTabs as Tabs, Tab };
