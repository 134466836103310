import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path d="M11.869 19.944c5.701 0 10.375-3.523 10.375-7.964 0-4.441-4.674-7.964-10.375-7.964-5.702 0-10.375 3.523-10.375 7.964 0 4.441 4.673 7.964 10.375 7.964zm0-1.033c-5.213 0-9.387-3.146-9.387-6.93 0-3.785 4.174-6.932 9.387-6.932 5.212 0 9.387 3.147 9.387 6.931 0 3.785-4.175 6.93-9.387 6.93z" />
    <path d="M11.869 17.541c2.938 0 5.32-2.49 5.32-5.56 0-3.072-2.382-5.562-5.32-5.562s-5.32 2.49-5.32 5.561c0 3.072 2.382 5.561 5.32 5.561zm0-1.033c-2.392 0-4.332-2.027-4.332-4.528 0-2.5 1.94-4.528 4.332-4.528S16.2 9.479 16.2 11.98c0 2.501-1.939 4.528-4.331 4.528z" />
    <path d="M13.512 11.02v-.722a1.72 1.72 0 00-.473-1.223 1.578 1.578 0 00-1.183-.494 1.562 1.562 0 00-1.158.494 1.729 1.729 0 00-.472 1.237v.707c0 .458.156.796.457 1.101l-.697.066c-.557.134-.903.586-.909 1.109v1.328c0 .285.221.516.494.516a.506.506 0 00.494-.516V13.3c0-.043.034-.086.08-.098l1.194-.108a.508.508 0 00.45-.514v-.6a.524.524 0 00-.205-.42c-.28-.21-.37-.343-.37-.542v-.72a.68.68 0 01.183-.494.612.612 0 01.459-.191.63.63 0 01.485.191.67.67 0 01.183.48v.734c0 .198-.092.332-.37.543a.524.524 0 00-.205.418v.6c0 .268.195.49.45.515l1.263.12c-.023 0 .01.043.01.093v1.315c0 .285.222.516.495.516a.506.506 0 00.494-.516V13.3c-.007-.53-.352-.981-.84-1.103l-.769-.076c.302-.306.46-.644.46-1.103z" />
  </React.Fragment>,
  "IdentityInfrastructure"
);
