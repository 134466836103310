/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiLink",
  description: "",
  displayName: "Link",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: true,
      description: "The content of the link."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'initial'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
          { value: "'textPrimary'", computed: false },
          { value: "'textSecondary'", computed: false },
          { value: "'error'", computed: false }
        ]
      },
      required: false,
      description: "The color of the link.",
      defaultValue: { value: "'primary'", computed: false }
    },
    component: {
      type: { name: "custom", raw: "elementTypeAcceptingRef" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'a'", computed: false }
    },
    onBlur: { type: { name: "func" }, required: false, description: "@ignore" },
    onFocus: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    TypographyClasses: {
      type: { name: "object" },
      required: false,
      description:
        "`classes` prop applied to the [`Typography`](/api/typography/) element."
    },
    underline: {
      type: {
        name: "enum",
        value: [
          { value: "'none'", computed: false },
          { value: "'hover'", computed: false },
          { value: "'always'", computed: false }
        ]
      },
      required: false,
      description: "Controls when the link should have an underline.",
      defaultValue: { value: "'hover'", computed: false }
    },
    variant: {
      type: { name: "string" },
      required: false,
      description: "Applies the theme typography styles.",
      defaultValue: { value: "'inherit'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    underlineNone: {
      description: 'Styles applied to the root element if `underline="none"`.'
    },
    underlineHover: {
      description: 'Styles applied to the root element if `underline="hover"`.'
    },
    underlineAlways: {
      description: 'Styles applied to the root element if `underline="always"`.'
    },
    button: {
      description: 'Styles applied to the root element if `component="button"`.'
    },
    focusVisible: {
      description:
        "Pseudo-class applied to the root element if the link is keyboard focused."
    }
  }
};
export default generatedDocs;
