import React from "react";
import { Search, Settings } from "@liveramp/motif/icons";
import TextField from "@liveramp/motif/core/TextField";
import IconButton from "@liveramp/motif/core/IconButton";
import css from "./TableFilters.module.css";

export default function TableFilters() {
  return (
    <div className={css.filters}>
      <div className={css.search}>
        <TextField
          id="outlined-end-icon-adornment"
          defaultValue="Nexus"
          InputProps={{
            startAdornment: <Search />
          }}
        />
      </div>
      <IconButton>
        <Settings />
      </IconButton>
    </div>
  );
}
