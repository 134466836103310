/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiStepConnector",
  description: "",
  displayName: "StepConnector",
  methods: [],
  props: {
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    horizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.'
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.'
    },
    alternativeLabel: {
      description:
        "Styles applied to the root element if `alternativeLabel={true}`."
    },
    active: {
      description:
        "Pseudo-class applied to the root element if `active={true}`."
    },
    completed: {
      description:
        "Pseudo-class applied to the root element if `completed={true}`."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    line: { description: "Styles applied to the line element." },
    lineHorizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.'
    },
    lineVertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.'
    }
  }
};
export default generatedDocs;
