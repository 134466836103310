import React, { useState } from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepLabel from "@liveramp/motif/core/StepLabel";
import css from "../../../resources/Page.module.css";

export default function WarningDemo() {
  const [activeStep] = useState(2);
  const steps = getSteps();

  return (
    <Stepper
      activeStep={activeStep}
      className={css.fullWidth}
      orientation="vertical"
    >
      {steps.map(({ label, labelProps = {} }) => (
        <Step key={label}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function getSteps() {
  return [
    {
      label: "Transferring",
      labelProps: {
        optional: `
          Your file is being scanned, queued, and transferred to LiveRamp.
          Completed on 12-31-22 at 13:45
        `
      }
    },
    {
      label: "Processing",
      labelProps: {
        optional: `
          Your file is processed, checked against product limits, and matched.
          Completed on 12-31-22 at 13:45
        `,
        variant: "warning"
      }
    },
    {
      label: "Exceeds Product Limits",
      labelProps: {
        variant: "warning",
        optional: `The file contains 500,000,001 rows out of the 500,000,000 allowable.
        Exceeding product limits can result in slowdown and fees.
        Completed on 2021-11-28 at 19:02`
      }
    },
  ];
}
