import React from "react";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, mergeClasses } from "../../utils";
import { DialogActionsStyle } from "../../utils/styleKeys";

export default function DialogActions({ classes, ...props }) {
  let defaultClasses = useStyles();

  return (
    <MuiDialogActions
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...DialogActionsStyle,
    root: {
      padding: 0
    }
  }),
  { name: "MuiDialogActions" }
);
