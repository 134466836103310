import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Drawer from "../../core-components/Drawer";
import { makeStyles, mergeClasses } from "../../utils";
import { NAV_DRAWER_OPEN_WIDTH } from "../GlobalNavigation/L1L2";

export default function Footer({
  classes,
  className,
  globalNavDisplayLevel,
  ...props
}) {
  const [translateX, setTranslateX] = useState("0%");
  const defaultClasses = useStyles({ translateX });

  useEffect(() => {
    setTranslateX(
      globalNavDisplayLevel > 1 ? NAV_DRAWER_OPEN_WIDTH + "px" : "0%"
    );
  }, [globalNavDisplayLevel]);

  return (
    <Drawer
      data-testid="Footer"
      className={clsx("Footer", className)}
      classes={mergeClasses(defaultClasses, classes)}
      variant={"persistent"}
      anchor={"bottom"}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".Footer": {
      "& .MuiDrawer-paper": {
        boxShadow: `0 -5px 5px -5px ${theme.palette.secondary.main}`,
        transition: theme.transitions.common.enteringScreen,
        transform: props => `translateX(${props.translateX})`,
        display: "grid",
        paddingTop: 0,
        height: "56px"
      }
    }
  }
}));
