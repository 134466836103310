import React, { useState } from "react";
import clsx from "clsx";
import Collapse from "../../core-components/Collapse";
import Checkbox from "../../core-components/Checkbox";
import FormControlLabel from "../../core-components/FormControlLabel";
import ListItem from "../../core-components/ListItem";
import VirtualizedList from "../../core-components/VirtualizedFixedSizeList";
import { handleGetOptionLabel } from "./utils";

import makeStyles from "../../styles/makeStyles";
import ArrowDropDownIcon from "../../icons/ArrowDropDown";

const ITEM_HEIGHT = 52;
const MAX_ITEMS_SHOWING = 4;
const OVERSCAN_COUNT = 5;

export default function MultiFilterOptionGroup({
  getOptionLabel: getOptionLabelProp,
  id,
  onCheckboxClick: handleCheckboxClick,
  optionGroupLabel,
  options,
  searching = false
}) {
  const [open, setOpen] = useState(false);
  useStyles();

  const selectedOptionsCount = options.reduce((prev, curr) => {
    if (curr.optionProps["aria-selected"]) prev++;
    return prev;
  }, 0);

  return (
    <div data-testid="MultiFilterOptionGroup" key={id}>
      <ListItem
        key={id}
        className="MultiFilterOptionGroup__ListItem MultiFilter-optionGroup"
        onClick={() => setOpen(!open)}
      >
        <Checkbox
          checked={selectedOptionsCount > 0}
          indeterminate={
            selectedOptionsCount > 0 && selectedOptionsCount < options.length
          }
          onChange={e => {
            // See if there are any currently selected options in the group
            // to determine whether to select or deselect all options
            let selectAll = true;
            for (let i = 0; i < options.length; i++) {
              if (options[i].optionProps["aria-selected"]) {
                selectAll = false;
                break;
              }
            }
            handleCheckboxClick(e, options, selectAll);

            // Keep the current collapsible state
            setOpen(open);
          }}
        />
        <span className="MultiFilterOptionGroup__ListItem-label MultiFilter-optionGroupLabel">
          {optionGroupLabel}
        </span>
        <span className="MultiFilterOptionGroup__ListItem-count MultiFilter-optionGroupCount">{`(${selectedOptionsCount}/${options.length})`}</span>
        <ArrowDropDownIcon
          className={clsx("MultiFilterOptionGroup__ListItem-caret", {
            ["MultiFilterOptionGroup__ListItem-caret--isOpen"]: open
          })}
        />
      </ListItem>
      <Collapse
        className="MultiFilterOptionGroup__Collapse MultiFilter-collapse"
        in={searching || open}
      >
        {options.length > 0 && (
          <VirtualizedList
            className="MultiFilterOptionGroup__VirtualizedList MultiFilter-optionGroupList"
            height={
              options.length < MAX_ITEMS_SHOWING
                ? ITEM_HEIGHT * options.length
                : ITEM_HEIGHT * MAX_ITEMS_SHOWING
            }
            itemSize={ITEM_HEIGHT}
            itemCount={options.length}
            overscanCount={OVERSCAN_COUNT}
          >
            {({ index, style }) => {
              let opt = options[index];

              return (
                <ListItem key={opt.optionProps.id} style={style}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={opt.optionProps["aria-selected"]}
                        {...opt.optionProps}
                      />
                    }
                    label={handleGetOptionLabel(opt.option, getOptionLabelProp)}
                  />
                </ListItem>
              );
            }}
          </VirtualizedList>
        )}
      </Collapse>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".MultiFilterOptionGroup": {
      // .MultiFilterOptionGroup__ListItem
      "&__ListItem": {
        display: "flex",
        padding: "5px 6px",
        gap: "8px",
        cursor: "pointer",

        // .MultiFilterOptionGroup__ListItem-label
        "&-label": {
          flex: 1
        },

        // .MultiFilterOptionGroup__ListItem-count
        "&-count": {
          paddingLeft: "16px"
        },

        // .MultiFilterOptionGroup__ListItem-caret
        "&-caret": {
          transition: theme.transitions.common.enteringScreen,

          // .MultiFilterOptionGroup__ListItem-caret--isOpen
          "&&--isOpen": {
            transform: "rotate(180deg)"
          }
        }
      },

      // .MultiFilterOptionGroup__Collapse
      "&__Collapse": {
        paddingRight: "9px"
      },

      // .MultiFilterOptionGroup__VirtualizedList
      "&__VirtualizedList": {
        cursor: "pointer",

        "& .MuiListItem-root": {
          paddingLeft: "52px"
        }
      }
    }
  }
}));
