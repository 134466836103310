import React from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Tooltip from "@liveramp/motif/core/Tooltip";

export default function SimpleDemo() {
  return (
    <ButtonFormGroup>
      <Tooltip title="This is a tooltip">
        <Button variant="secondary">Hover/Focus on me!</Button>
      </Tooltip>
    </ButtonFormGroup>
  );
}
