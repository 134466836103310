import React from "react";
import Checkbox from "@liveramp/motif/core/Checkbox";
import FormControl from "@liveramp/motif/core/FormControl";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import FormGroup from "@liveramp/motif/core/FormGroup";

export default function LabelPlacementDemo() {
  return (
    <FormControl component="fieldset">
      <FormGroup aria-label="position" name="position" row>
        <FormControlLabel
          value="start"
          control={<Checkbox />}
          label="Start"
          labelPlacement="start"
        />
        <FormControlLabel
          value="top"
          control={<Checkbox />}
          label="Top"
          labelPlacement="top"
        />
        <FormControlLabel
          value="bottom"
          control={<Checkbox />}
          label="Bottom"
          labelPlacement="bottom"
        />
        <FormControlLabel
          value="end"
          control={<Checkbox />}
          label="End"
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  );
}
