import React from "react";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import CloseButton from "@liveramp/motif/core/CloseButton";
import css from "./Simple.module.css";

export default function CloseButtonDemo() {
  return (
    <ButtonFormGroup>
      <CloseButton />
      <span className={css.coloredBg}>
        <CloseButton light />
      </span>
    </ButtonFormGroup>
  );
}
