// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Alert category

let icons = [
  "Error",
  "ErrorOutline",
  "Help",
  "HelpOutline",
  "Hourglass",
  "HourglassOutline",
  "Info",
  "InfoOutline",
  "Mail",
  "MailOutline",
  "NotAuthorized",
  "Notifications",
  "NotificationsOutline",
  "Privacy",
  "PrivacyOutline",
  "QuestionMark",
  "SMS",
  "Warning",
  "WarningOutline",
  "WarningCircle",
  "WarningCircleOutline"
];

export default icons;
