import React from "react";
import makeStyles from "../../styles/makeStyles";
import clsx from "clsx";

export default function ButtonFormGroup({
  start = false,
  end = false,
  vertical = false,
  fullWidth = false,
  className = "",
  ...props
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.grid,
        {
          [classes.start]: start,
          [classes.end]: end,
          [classes.vertical]: vertical,
          [classes.fullWidth]: fullWidth
        },
        className
      )}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: "grid",
    gridAutoFlow: "column",
    gap: "16px",
    justifyContent: "center",
    alignItems: "center"
  },
  start: {
    justifyContent: "flex-start"
  },
  end: {
    justifyContent: "flex-end"
  },
  vertical: {
    gridAutoFlow: "row"
  },
  fullWidth: {
    width: "100%"
  }
}));
