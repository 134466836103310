import React from 'react'
import {
  Page,
  Title,
  Subtitle,
  NoticePanel,
  SectionTitle,
} from '../../../resources/DemoPage'
import Table from '@liveramp/motif/core/Table'
import TableHeader from '@liveramp/motif/core/TableHeader'
import TableBody from '@liveramp/motif/core/TableBody'
import TableRow from '@liveramp/motif/core/TableRow'
import TableCell from '@liveramp/motif/core/TableCell'
import { RdtNotices } from '../index'
import Demo, { generateDemos } from '../../../resources/Demo'
import SlotsDGToolbarDemo from './demos/SlotsDGToolbar'
import SlotsDGSettingsMenuDemo from './demos/SlotsDGSettingsMenu'
import SlotsDGFooterDemo from './demos/SlotsDGFooter'
import SlotsDGOverlaysDemo from './demos/SlotsDGOverlays'
import SlotsDGFooterCustomSelectAllDemo from './demos/SlotsDGFooterCustomSelectAll'

let demoNames = [
  'SlotsDGToolbar',
  'SlotsDGSettingsMenu',
  'SlotsDGFooter',
  'SlotsDGOverlays',
  'SlotsDGFooterCustomSelectAll',
]

export default function RichDataTable() {
  let demos = generateDemos(
    'composed-component-demo/rich-data-table/data-grid-slots',
    demoNames,
  )

  return (
    <Page>
      <Title>Rich Data Table - DataGrid Slots</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <NoticePanel variant="warning">
        <p>
          The following components have been modified and customized by AX. If
          you need more control over them, you can create your own and use the{' '}
          <code>components</code> prop to override these. Be aware that
          overriding means custom functionality provided by AX will need to be
          re-implemented or they will not exist.
        </p>
        <p>
          Please chat to us at{' '}
          <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">#dev-ax</a>{' '}
          if you need help.
        </p>
      </NoticePanel>
      <p>
        The RDT has a notion of 'slots' which are components that typically
        accompany a table. The following slots are provided by MUI and can be
        overridden by using the <code>components</code> prop.
      </p>
      <p>
        Material UI provides container components and props to allow custom
        components to fit seamlessly within the MUI ecosystem. The DataGrid
        relies on these properties to handle the slots correctly; omitting them
        may result in unexpected rendering issues.
      </p>
      <p>
        Please be aware that because these slots are rendered as part of the
        whole datagrid component, state inside the slots will not persist across
        re-renders. You will need to store state at a higher component level and
        pass it down.
      </p>
      <p>
        Examples of overrides can be found in Motif's codebase under{' '}
        <a href="https://github.com/LiveRamp/motif/tree/master/lib/src/composed-components/RichDataTableSlots">
          RichDataTableSlots
        </a>
        .
      </p>

      <SectionTitle subsection>Toolbar</SectionTitle>
      <NoticePanel>
        <p>
          Custom toolbars should be wrapped in <code>GridToolbarContainer</code>{' '}
          and will need to import and handle the SettingsMenu manually.
        </p>
      </NoticePanel>
      <Demo demo={demos.SlotsDGToolbar}>
        <SlotsDGToolbarDemo />
      </Demo>

      <SectionTitle>Settings Menu</SectionTitle>
      <p>
        The SettingsMenu is technically part of the toolbar component, but is
        complex component enough in and of itself to warrant a dedicated demo.
        Please keep in mind, the settings menu is primarily reserved for generic
        table functionality and should be agnostic to the data in it.
      </p>
      <ul>
        <li>
          Table density settings are shown by default. It can be disabled by
          using the <code>disableDensity</code> prop.
        </li>
        <li>
          Column visibility is enabled by default for all columns. It can be
          customized by passing in an array of columns or be set to false to
          prevent all columns from being hidden. If the number of hideable
          columns is equal to the number of columns available, the first column
          will automatically be disabled (at least one column MUST be shown at
          all times).
        </li>
        <li>
          Extra controls can be passed in using the{' '}
          <code>additionalControls</code> prop. It expects a function that can
          accept an <code>apiRef</code>.
        </li>
      </ul>
      <Demo demo={demos.SlotsDGSettingsMenu}>
        <SlotsDGSettingsMenuDemo />
      </Demo>

      <SectionTitle subsection>Footer</SectionTitle>
      <p>
        The footer by default is disabled. To enable it, pass in
        <code>pagination</code> or a <code>footerActions</code> function that
        returns a React node.
      </p>
      <Demo demo={demos.SlotsDGFooter}>
        <SlotsDGFooterDemo />
      </Demo>

      <SectionTitle>Overlays</SectionTitle>
      <p>There are various overlays that can be applied:</p>
      <h4>Props</h4>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Prop</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Default Value</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <code>noRowsErrorText</code>
            </TableCell>
            <TableCell>String</TableCell>
            <TableCell>
              <code>"There is no data to display"</code>
            </TableCell>
            <TableCell>This is for customize no rows error text</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>noSearchResultsErrorText</code>
            </TableCell>
            <TableCell>String</TableCell>
            <TableCell>
              <code>"There is no results for this search"</code>
            </TableCell>
            <TableCell>This is for customize no results for search</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <ul>
        <li>NoRowsOverlay</li>
        <li>NoResultsOverlay</li>
        <li>LoadingOverlay</li>
      </ul>
      <Demo demo={demos.SlotsDGOverlays}>
        <SlotsDGOverlaysDemo />
      </Demo>

      <SectionTitle>Custom Select All</SectionTitle>
      <p>
        When footer is enabled you can pass in <code>selectAllHandler</code>,{' '}
        <code>selectAllCount</code> and <code>deselectAllHandler</code> to
        footerProps. selectAllHandler and selectAllCount are required in order
        to activate this functionality.
      </p>
      <ul>
        <li>
          <code>selectAllHandler</code> is called when the 'Select all rows in
          this table' is clicked.
        </li>
        <li>
          <code>deselectAllHandler</code> is called when all rows in the table
          are no longer selected but only when selectAllHandler has already been
          called.
        </li>
        <li>
          <code>selectAllCount</code> takes a number and displays it in the
          'Select all rows in this table' string and as a custom number of
          selected rows when <code>selectAllCount</code> is called.
        </li>
      </ul>
      <Demo demo={demos.SlotsDGFooterCustomSelectAll}>
        <SlotsDGFooterCustomSelectAllDemo />
      </Demo>
    </Page>
  )
}
