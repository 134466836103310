import React from "react";
import ButtonFormGroup from "../ButtonFormGroup";

/**
 * @deprecated This component should be removed prior to Motif 2.0
 */
export default function ButtonGroup(props) {
  console.warn(
    `Motif 2.0
DEPRECATION WARNING: ButtonGroup is being renamed in Motif 2.0 to ButtonFormGroup.`
  );

  return <ButtonFormGroup {...props} />;
}
