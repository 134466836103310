import React from "react";

let FlagCN = props => (
  <svg
    width="24"
    height="18"
    viewBox="0 0 24 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 0H2C.89543 0 0 .89543 0 2v14c0 1.1046.89543 2 2 2h20c1.1046 0 2-.8954 2-2V2c0-1.10457-.8954-2-2-2z"
      fill="#F1361D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2508 8.70521l.0099.84201.4627.70358-.84204.0099-.70361.4627-.00992-.84204-.46263-.70361.84202-.00992.70358-.46262zM5.71429 2.28572l.78595 2.3468 2.47481.02228-1.98906 1.47269.74356 2.36057-2.01526-1.43663-2.01527 1.43663.74357-2.36057L2.45352 4.6548l2.47481-.02228.78596-2.3468zM10.7731 6.4924l.7547.37343.8369-.09277-.3734.75474.0928.83695-.7548-.37343-.8369.09277.3734-.75474-.0927-.83695zM12 3.58169l-.0195.84184.4378.71933-.8418-.01947-.7194.43778.0195-.84184L10.4388 4l.8419.01947L12 3.58169zM9.34221 1.20512l.62257.567.83012.14152-.567.62257-.1415.8301-.62261-.567-.8301-.14152.567-.62257.14152-.8301z"
      fill="#FFDC42"
    />
  </svg>
);

export default FlagCN;
