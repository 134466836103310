import React from "react";
import MuiStep from "@material-ui/core/Step";
import { makeStyles, mergeClasses } from "../../utils";
import { StepStyle } from "../../utils/styleKeys";

export default function Step({ classes, ...props }) {
  const defaultClasses = useStyles();

  return <MuiStep classes={mergeClasses(defaultClasses, classes)} {...props} />;
}

const useStyles = makeStyles(
  theme => ({
    ...StepStyle,
    horizontal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }),
  { name: "MuiStep" }
);
