import {
  ActivateMeasure,
  Audience,
  Clear as Database,
  Clear as Items,
  Clear as Security,
  Clear as Users,
  CreateAudience,
  DataMarketplace,
  Distribute,
  Folder,
  IdentityInfrastructure,
  Insights,
  Lightbulb,
  Lookalike,
  SafeHaven,
  Segment,
  Settings,
  Star,
  SuperUniverse,
  Taxonomy,
  View,
  DerivedView,
  GNAccess,
  Upload,
  Store,
  // BuyData,
  GNManage,
  GNActivate,
  AddCircle,
  GNAnalyze,
  User
} from "@liveramp/motif/icons";

export default function navigation(
  match,
  {
    businessObjectValue,
    setBusinessObjectValue,
    businessObjects,
    loadingBusinessObjects
  }
) {
  const { path, url } = match;
  const countries = ["US", "CN", "AU", "EU", "JP"];
  const advancedTvRegex = new RegExp(`${url}/tv.*`);

  return [
    [
      {
        label: "My Subscriptions",
        icon: ActivateMeasure,
        level: 1,
        activeOn: [
          `${url}/connect`,
          advancedTvRegex,
          "data-plus-math",
          `${url}/no-l3-element`
        ],
        route: path,
        children: [
          {
            label: "Connect",
            level: 2,
            route: `${url}/connect`,
            children: [
              {
                label: "Welcome to Connect",
                level: 3,
                icon: Star,
                onNavigate: () => {
                  alert("Navigating via onNavigate hook");
                },
                route: `${url}/connect/welcome`
              },
              {
                label: "My Dashboard",
                level: 3,
                icon: Star,
                route: `${url}/connect/dashboard`
              },
              {
                label: "Activation",
                level: 3,
                collapsed: true,
                children: [
                  {
                    label: "Upload Data",
                    icon: Items,
                    route: `${url}/connect/upload`
                  },
                  {
                    label: "My Files",
                    icon: Folder,
                    route: `${url}/connect/files`
                  }
                ]
              },
              {
                label: "Audiences",
                level: 3,
                children: [
                  {
                    label: "My Audiences",
                    icon: Segment,
                    route: `${url}/connect/audiences`,
                    businessObjects: {
                      loading: loadingBusinessObjects,
                      getOptionLabel: option => option?.name,
                      onChange: (e, option) => {
                        console.log(`Clicked ${option.name}`);
                        setBusinessObjectValue(option);
                      },
                      options: businessObjects,
                      value: businessObjectValue
                    },
                    count: businessObjects.length
                  },
                  {
                    label: "Segment Builder",
                    icon: CreateAudience,
                    route: `${url}/connect/segment-builder`
                  },
                  {
                    label: "My Segments",
                    icon: Segment,
                    route: `${url}/connect/my-segments`,
                    count: 100
                  }
                ]
              },
              {
                label: "Destinations",
                level: 3,
                children: [
                  {
                    label: "All Destination",
                    icon: Distribute,
                    route: `${url}/connect/destinations`
                  },
                  {
                    label: "Active Destination",
                    icon: Distribute,
                    route: `${url}/connect/destinations/active`
                  }
                ]
              },
              {
                label: "Measurement",
                level: 3,
                children: [
                  {
                    label: "Summary",
                    icon: Insights,
                    route: `${url}/connect/measurement-summary`
                  },
                  {
                    label: "Impressions",
                    icon: Insights,
                    route: `${url}/connect/impressions`
                  },
                  {
                    label: "Lift",
                    icon: Insights,
                    route: `${url}/connect/lift`
                  },
                  {
                    label: "My Reports",
                    icon: Insights,
                    route: `${url}/connect/reports`
                  }
                ]
              }
            ]
          },
          {
            label: "Advanced TV",
            level: 2,
            route: advancedTvRegex,
            children: [
              {
                label: "Welcome to TV",
                level: 3,
                icon: Star,
                route: `${url}/tv-welcome`
              },
              {
                label: "My Dashboard",
                level: 3,
                icon: Star,
                route: `${url}/tv-dashboard`
              },
              {
                label: "Activation",
                level: 3,
                children: [
                  {
                    label: "Upload Data",
                    icon: Items,
                    route: `${url}/tv-upload`
                  },
                  {
                    label: "My Files",
                    icon: Folder,
                    route: `${url}/tv-files`,
                    count: 20
                  },
                  {
                    label: "My DataSets",
                    icon: Database,
                    route: `${url}/tv-datasets`,
                    count: 10
                  },
                  {
                    label: "My Taxonomies",
                    icon: Taxonomy,
                    route: `${url}/tv-taxonomies`,
                    count: 6
                  },
                  {
                    label: "Profiling Configuration",
                    icon: View,
                    route: `${url}/tv-profiling`
                  }
                ]
              },
              {
                label: "Audiences",
                level: 3,
                children: [
                  {
                    label: "Segment Builder",
                    icon: CreateAudience,
                    route: `${url}/tv-segment-builder`
                  },
                  {
                    label: "My Segments",
                    icon: Segment,
                    route: `${url}/tv-segments`,
                    count: 100
                  },
                  {
                    label: "Lookalike Models",
                    icon: Users,
                    route: `${url}/tv-lookalike-models`,
                    count: 5
                  }
                ]
              },
              {
                label: "Universes",
                level: 3,
                collapsed: true,
                children: [
                  {
                    label: "Universe-Integration",
                    icon: SuperUniverse,
                    route: `${url}/tv-universe-integrations`
                  },
                  {
                    label: "My Universe",
                    icon: SuperUniverse,
                    route: `${url}/tv-my-universe`,
                    count: 12
                  }
                ]
              }
            ]
          },
          {
            label: "Data + Math",
            level: 2,
            route: `${url}/data-plus-math`,
            children: [
              {
                label: "Welcome to D+M",
                level: 3,
                icon: Star,
                route: `${url}/data-plus-math/welcome`
              },
              {
                label: "My Dashboard",
                level: 3,
                icon: Star,
                route: `${url}/data-plus-math/dashboard`
              },
              {
                label: "Audiences",
                level: 3,
                icon: Audience,
                route: `${url}/data-plus-math/audiences`,
                count: 300
              },
              {
                label: "Analytics",
                level: 3,
                children: [
                  {
                    label: "Summary",
                    icon: Insights,
                    route: `${url}/data-plus-math/analytics`
                  },
                  {
                    label: "Impressions",
                    icon: Insights,
                    route: `${url}/data-plus-math/impressions`
                  },
                  {
                    label: "Reach",
                    icon: Insights,
                    route: `${url}/data-plus-math/reach`
                  },
                  {
                    label: "Lift",
                    icon: Insights,
                    route: `${url}/data-plus-math/lift`
                  },
                  {
                    label: "Creative",
                    icon: Insights,
                    route: `${url}/data-plus-math/creative`
                  },
                  {
                    label: "All Cuts",
                    icon: Insights,
                    route: `${url}/data-plus-math/all-cuts`
                  },
                  {
                    label: "Cumulative",
                    icon: Insights,
                    route: `${url}/data-plus-math/cumulative`
                  },
                  {
                    label: "Only Both",
                    icon: Insights,
                    route: `${url}/data-plus-math/only-both`
                  },
                  {
                    label: "Cuts Compare",
                    icon: Insights,
                    route: `${url}/data-plus-math/cuts-compare`
                  },
                  {
                    label: "Property Impact",
                    icon: Insights,
                    route: `${url}/data-plus-math/property-impact`
                  },
                  {
                    label: "By Platform",
                    icon: Insights,
                    route: `${url}/data-plus-math/by-platform`
                  },
                  {
                    label: "Audience Response",
                    icon: Insights,
                    route: `${url}/data-plus-math/audience-response`
                  },
                  {
                    label: "Attribution",
                    icon: Insights,
                    route: `${url}/data-plus-math/attribution`
                  },
                  {
                    label: "Summary x2",
                    icon: Insights,
                    route: `${url}/data-plus-math/summary`
                  }
                ]
              }
            ]
          },
          {
            label: "Element with no L3",
            level: 2,
            route: `${url}/no-l3-element`
          }
        ]
      },
      {
        label: "Safe Haven",
        level: 1,
        icon: SafeHaven,
        countries,
        currentCountry: countries[0],
        onCountryClick: country => alert(`You have clicked ${country}`),
        route: `${url}/safe-haven`,
        children: [
          {
            label: "Welcome to LSH",
            level: 3,
            icon: Star,
            route: `${url}/safe-haven/welcome`
          },
          {
            label: "My Dashboard",
            level: 3,
            icon: Star,
            route: `${url}/safe-haven/dashboard`
          },
          {
            label: "Activation",
            level: 3,
            children: [
              {
                label: "Upload Data",
                icon: Items,
                route: `${url}/safe-haven/upload`
              },
              {
                label: "My Files",
                icon: Folder,
                route: `${url}/safe-haven/files`,
                count: 20
              },
              {
                label: "My DataSets",
                icon: Folder,
                route: `${url}/safe-haven/datasets`,
                count: 10
              },
              {
                label: "My Taxonomies",
                icon: Folder,
                route: `${url}/safe-haven/taxonomies`,
                count: 6
              },
              {
                label: "Profiling Configuration",
                icon: Folder,
                route: `${url}/safe-haven/profiling`,
                count: 20
              }
            ]
          },
          {
            label: "Audiences",
            level: 3,
            children: [
              {
                label: "Segment Builder",
                icon: CreateAudience,
                route: `${url}/safe-haven/create-audience`
              },
              {
                label: "My Segments",
                icon: Segment,
                route: `${url}/safe-haven/my-segments`,
                count: 100
              },
              {
                label: "My Lookalike Models",
                icon: Lookalike,
                route: `${url}/safe-haven/lookalike-models`,
                count: 5
              },
              {
                label: "Permission Tracking",
                icon: Security,
                route: `${url}/safe-haven/permission-tracking`
              }
            ]
          },
          {
            label: "Destinations",
            level: 3,
            children: [
              {
                label: "All Destination",
                icon: Distribute,
                route: `${url}/safe-haven/destinations`
              },
              {
                label: "Active Destination",
                icon: Distribute,
                route: `${url}/safe-haven/destinations/active`
              }
            ]
          },
          {
            label: "Measurement",
            level: 3,
            children: [
              {
                label: "Summary",
                icon: Insights,
                route: `${url}/safe-haven/measurement`
              },
              {
                label: "Impressions",
                icon: Insights,
                route: `${url}/safe-haven/impressions`
              },
              {
                label: "Lift",
                icon: Insights,
                route: `${url}/safe-haven/lift`
              },
              {
                label: "My Reports",
                icon: Insights,
                route: `${url}/safe-haven/reports`
              }
            ]
          }
        ]
      },
      {
        label: "Data Marketplace",
        level: 1,
        icon: DataMarketplace,
        route: `${url}/data-marketplace`
      },
      {
        label: "Identity Infrastructure",
        level: 1,
        icon: IdentityInfrastructure,
        route: `${url}/identity-infrastructure`
      }
    ],
    // Second array in the navigation prop is optional, for Admin
    // actions that appear at the bottom of the black bar
    [
      {
        label: "Ideas",
        icon: Lightbulb,
        route: `${url}/ideas`
      },
      {
        label: "Account Settings",
        icon: Settings,
        route: `${url}/account-settings`
      }
    ]
  ];
}

export const navigationAlt = (altNavHasBusinessObjects = true) => {
  const welcome = {
    level: 3,
    label: "Welcome to LiveRamp",
    icon: Star,
    route: "/"
  };

  const dashboard = {
    level: 3,
    label: "Dashboard",
    icon: DerivedView,
    route: "/"
  };

  const data = [
    [
      {
        label: "Access",
        icon: GNAccess,
        level: 1,
        children: [
          welcome,
          dashboard,
          {
            label: "Data In",
            level: 3,
            collapsible: true,
            children: [
              {
                level: 4,
                label: "File Upload",
                icon: Upload,
                route: "/"
              },
              {
                label: "Files",
                level: 4,
                icon: Folder,
                route: "/"
              }
            ]
          },
          {
            label: "Data Marketplace",
            level: 3,
            collapsible: true,
            children: [
              {
                label: "Storefront",
                level: 3,
                icon: Store,
                route: "/"
              },
              {
                label: "Buy Data",
                level: 3,
                // icon: BuyData,
                route: "/"
              }
            ]
          }
        ]
      },
      {
        label: "Manage",
        icon: GNManage,
        level: 1,
        children: [
          welcome,
          dashboard,
          {
            label: "Data Catalog",
            level: 3,
            collapsible: true,
            children: [
              {
                label: "Audiences",
                level: 4,
                route: "/",
                icon: Audience,
                businessObjects: {
                  loading: false,
                  getOptionLabel: option => option?.name,
                  onChange: () => {},
                  options: altNavHasBusinessObjects
                    ? [
                        { name: "Audience 1" },
                        { name: "Audience 2" },
                        { name: "Audience 3" },
                        { name: "Audience 4" },
                        { name: "Audience 5" },
                        { name: "Audience 6" },
                        { name: "Audience 7" },
                        { name: "Audience 8" },
                        { name: "Audience 9" },
                        { name: "Audience 10" }
                      ]
                    : [],
                  value: ""
                },
                count: altNavHasBusinessObjects ? 10 : 0
              },
              {
                label: "Segments",
                level: 4,
                icon: Segment,
                route: "/"
              },
              {
                label: "Lookalike Models",
                level: 4,
                icon: Lookalike,
                route: "/"
              }
            ]
          }
        ]
      },
      {
        label: "Activate",
        icon: GNActivate,
        level: 1,
        children: [
          welcome,
          dashboard,
          {
            label: "Data Out",
            level: 3,
            collapsible: true,
            children: [
              {
                label: "Destination Accounts",
                level: 4,
                route: "/",
                icon: Distribute,
                businessObjects: {
                  loading: false,
                  getOptionLabel: option => option?.name,
                  onChange: () => {},
                  options: altNavHasBusinessObjects
                    ? [
                        { name: "Destination Account 1" },
                        { name: "Destination Account 2" },
                        { name: "Destination Account 3" },
                        { name: "Destination Account 4" },
                        { name: "Destination Account 5" },
                        { name: "Destination Account 6" },
                        { name: "Destination Account 7" },
                        { name: "Destination Account 8" },
                        { name: "Destination Account 9" },
                        { name: "Destination Account 10" }
                      ]
                    : [],
                  value: ""
                },
                count: altNavHasBusinessObjects ? 10 : 0
              },
              {
                label: "New Destination Account",
                level: 4,
                icon: AddCircle,
                route: "/",
                count: null
              }
            ]
          }
        ]
      },
      {
        label: "Analyze",
        icon: GNAnalyze,
        level: 1,
        children: [
          welcome,
          dashboard,
          {
            label: "Measure & Report",
            level: 3,
            collapsible: true,
            children: [
              {
                label: "Usage Report",
                level: 4,
                icon: Insights,
                route: "/"
              }
            ]
          }
        ]
      }
    ],
    [
      {
        label: "Admin",
        icon: Settings,
        level: 1,
        children: [
          {
            label: "Org Management",
            level: 3,
            collapsible: true,
            children: [
              {
                label: "Users",
                level: 4,
                icon: User,
                route: "/"
              },
              {
                label: "Company Settings",
                level: 4,
                icon: Settings,
                route: "/"
              }
            ]
          }
        ]
      }
    ]
  ];

  return data;
};
