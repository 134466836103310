import React from "react";
import {
  Page,
  Title,
  Supporting,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import Simple from "./demos/Simple";
import FilesAddedCallback from "./demos/FilesAddedCallback";
import Disabled from "./demos/Disabled";
import ErrorStatus from "./demos/ErrorStatus";
import Multiple from "./demos/Multiple";
import MultipleError from "./demos/MultipleError";
import FileValidation from "./demos/FileValidation";
import CodeChunk from "../../resources/CodeChunk";
import { InfoOutline } from "@liveramp/motif/icons";

const demoNames = [
  "Simple",
  "FilesAddedCallback",
  "Disabled",
  "ErrorStatus",
  "Multiple",
  "MultipleError",
  "FileValidation"
];

export default function Uploader() {
  const demos = generateDemos("composed-component-demo/uploader", demoNames);

  return (
    <Page components={["Uploader"]}>
      <Title>Uploader</Title>
      <Supporting>
        For upload functionality, Motif utilizes React-dropzone which is a
        simple React hook to create a HTML5-compliant drag and drop zone for
        files.
      </Supporting>
      <Usage>
        <ul>
          <li>
            React-dropzone and Motif do not handle the server-side portion of
            your file upload. You have to implement the code to receive and
            store the file yourself.
          </li>
          <li>
            <a
              href="https://react-dropzone.js.org"
              target="_blank"
              rel="noreferrer"
            >
              React-dropzone documentation
            </a>
          </li>
        </ul>
      </Usage>
      <p>
        This component comes with a default upload handler that sends dropped
        files to a given url via a POST request. To use this upload handler, set
        the <code>url</code> prop accordingly. Below is a demo of a successful
        single upload.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
      <SectionTitle subtitle>Multiple Uploads</SectionTitle>
      <p>
        <>
          To enable uploading multiple files at once and display all file
          statuses, set the <code>multiple</code> prop to true.
          <InfoOutline /> Files are each sent in their own requests, as opposed
          to being sent all at once. This enables us to show individual progress
          and success/failure states, per document.
        </>
      </p>
      <Demo demo={demos.Multiple}>
        <Multiple />
      </Demo>
      <SectionTitle>States</SectionTitle>
      <SectionTitle subtitle>Disabled</SectionTitle>
      <p>
        You can disable the uploader and stop file acceptance by setting the{" "}
        <code>disabled</code> prop to true.
      </p>
      <Demo demo={demos.Disabled}>
        <Disabled />
      </Demo>
      <SectionTitle>Error States</SectionTitle>
      <SectionTitle subtitle>Single Error</SectionTitle>
      <p>
        A default error message is provided, if you wish to override it with a
        custom error message, set the <code>customErrorMessage</code> prop. It
        accepts either a string -- for static error messages, or a function --
        for dynamic error messages. If provided, the function is calld with
        <code>name</code>,<code>errorDetail</code>, and <code>file</code>.
      </p>
      <CodeChunk>
        `customErrorMessage(fileName, errorDetailFromServer, file)`
      </CodeChunk>
      <p>Internally the function is called like this:</p>
      <CodeChunk>
        {`customErrorMessage = (typeof customError === 'function') 
            ? customError(name, errorDetail, file) 
            : customError;`}
      </CodeChunk>
      <Demo demo={demos.ErrorStatus}>
        <ErrorStatus />
      </Demo>
      <SectionTitle subtitle>Multiple Errors</SectionTitle>
      <p>
        If you would like to display different error messages in the multi file
        status view, use can use the same prop <code>customErrorMessage</code>
        from before, and expect the same arguments. It will be called once per
        file.
      </p>
      <Demo demo={demos.MultipleError}>
        <MultipleError />
      </Demo>
      <SectionTitle subtitle>Client-side Validation</SectionTitle>
      <p>
        File validations may be run before posting files to the server. To
        validate files you can pass a function to the <code>validator</code>
        prop. You can return an object containing a <code>message</code>{" "}
        property from the function which will be used as the error message.
      </p>
      <Demo demo={demos.FileValidation}>
        <FileValidation />
      </Demo>
      <SectionTitle subtitle>Manual Uploads</SectionTitle>
      <p>
        As previously stated, the Uploader comes with a default upload handler
        that will handle POSTing your file(s) to a provided URL. If you would
        like to handle the upload manually you can pass a function to the
        <code>onFilesAdded</code> prop. This means you will have to take care of
        the file progress and statuses yourself. To facilitate managing file
        state we have provided an included hook `useFileList` that exposes both
        state and the actions to manipulate it.
      </p>
      <CodeChunk>
        {`const [ fileState, fileActionsFactory ] = useFileList();`}
      </CodeChunk>
      State is in the following shape:
      <CodeChunk>
        {`type UploadState = {
            files: {
              [key: string]: File
            },
            count: number,
            mostRecentFile?: File // used for easy access to for single-file uploads.
          }`}
      </CodeChunk>
      The actions factory is a function that takes one argument, the file's
      <code>motifId</code>. In order to provide a consistent way to interact
      with state for both the built in and custom upload handlers, all files are
      given an internal id, stored on the `motifId` property. It is generated
      when the files are added. This is the property that needs to be passed to
      the actionsFactory. It is important to note that this is NOT the same as
      an ID that the file may be assigned by the server as part of the upload
      process. This id is for Motif's use only.
      <CodeChunk>
        {`const useMySuperCoolUploader = (file) => {
              const { motifId } = file;
              const actions = actionsFactory(motifId)
              // go forth and do your uploads now. 
            }`}
      </CodeChunk>
      When called, the factory function returns an object with the following
      shape:
      <CodeChunk>
        {`type UploadActions {
              addFile: (id: string, file: File): void
              recordFileProgress: (id: string, percentage: number): void,
              fileUploadCancelled: (id: string): void,
              fileUploadCompleted: (id: string): void,
              fileUploadError: (id: string, errorMessage: string): void
              removeFile: (id:string): void
            }`}
      </CodeChunk>
      <Demo demo={demos.FilesAddedCallback}>
        <FilesAddedCallback />
      </Demo>
    </Page>
  );
}
