import React from "react";
import MuiDrawer from "@material-ui/core/Drawer";
import withStyles from "../../styles/withStyles";
import { DrawerStyle } from "../../utils/styleKeys";

function Drawer({ hideBackdrop = true, variant = "persistent", ...props }) {
  return (
    <MuiDrawer
      BackdropProps={{ invisible: hideBackdrop }}
      variant={variant}
      {...props}
    />
  );
}

let styles = theme => ({
  ...DrawerStyle,
  paperAnchorDockedBottom: {
    borderTop: `1px solid ${theme.palette.secondary.light}`
  }
});

export default withStyles(styles, { name: "MuiDrawer" })(Drawer);
