import React, { Component, Fragment } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Alert from "@liveramp/motif/core/Alert";

export default class ForcedTransitionsDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      message: "",
      variant: null
    };
  }

  handleOpen = (message, variant) => () => {
    if (this.state.open) {
      this.handleClose();
      setTimeout(() => this.setState({ open: true, message, variant }), 250);
    } else {
      this.setState({ open: true, message, variant });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ open: false });
  };

  render({ open, variant, message } = this.state) {
    return (
      <Fragment>
        <ButtonFormGroup>
          <Button
            variant="secondary"
            onClick={this.handleOpen("Message One - Forced")}
          >
            Message One
          </Button>
          <Button
            variant="secondary"
            onClick={this.handleOpen("Message Two - Forced", "success")}
          >
            Message Two
          </Button>
        </ButtonFormGroup>

        <Alert open={open} onClose={this.handleClose} variant={variant}>
          {message}
        </Alert>
      </Fragment>
    );
  }
}
