import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import makeStyles from "../../styles/makeStyles";

export default function SidePanelContent({ className, ...props }) {
  useStyles();

  return (
    <div
      className={clsx("SidePanelContent SidePanelContent-root", className)}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".SidePanelContent": {
      display: "grid",
      gap: "32px",
      padding: "32px",
      backgroundColor: "white",
      overflow: "auto"
    }
  }
}));

SidePanelContent.propTypes = {
  children: PropTypes.node
};
