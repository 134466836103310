/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiDivider",
  description: "",
  displayName: "Divider",
  methods: [],
  props: {
    absolute: {
      type: { name: "bool" },
      required: false,
      description: "Absolutely position the element.",
      defaultValue: { value: "false", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "@ignore"
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'hr'", computed: false }
    },
    flexItem: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, a vertical divider will have the correct height when used in flex container.\r\n(By default, a vertical divider will have a calculated height of `0px` if it is the child of a flex container.)",
      defaultValue: { value: "false", computed: false }
    },
    light: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the divider will have a lighter color.",
      defaultValue: { value: "false", computed: false }
    },
    orientation: {
      type: {
        name: "enum",
        value: [
          { value: "'horizontal'", computed: false },
          { value: "'vertical'", computed: false }
        ]
      },
      required: false,
      description: "The divider orientation.",
      defaultValue: { value: "'horizontal'", computed: false }
    },
    role: {
      type: { name: "string" },
      required: false,
      description: "@ignore",
      defaultValue: {
        value: "Component !== 'hr' ? 'separator' : undefined",
        computed: false
      }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'fullWidth'", computed: false },
          { value: "'inset'", computed: false },
          { value: "'middle'", computed: false }
        ]
      },
      required: false,
      description: "The variant to use.",
      defaultValue: { value: "'fullWidth'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    absolute: {
      description: "Styles applied to the root element if `absolute={true}`."
    },
    inset: {
      description: 'Styles applied to the root element if `variant="inset"`.'
    },
    light: {
      description: "Styles applied to the root element if `light={true}`."
    },
    middle: {
      description: 'Styles applied to the root element if `variant="middle"`.'
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.'
    },
    flexItem: {
      description: "Styles applied to the root element if `flexItem={true}`."
    }
  }
};
export default generatedDocs;
