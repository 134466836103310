let reporting2 = {
  50: "#f9cdac",
  100: "#f3aca2",
  200: "#ee8b97",
  300: "#e96a8d",
  400: "#db5087",
  500: "#b8428c",
  600: "#973490",
  700: "#742796",
  800: "#5e1f88",
  900: "#4d1a70"
};

export default reporting2;
