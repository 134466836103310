import React from "react";
import Select from "@liveramp/motif/core/Select";
import css from "../../../resources/Page.module.css";

export default function MultiDemo() {
  let list = [
    { label: "one" },
    { label: "two" },
    { label: "three" },
    { label: "this-is-a-really-long-option-name" }
  ];

  return (
    <div className={css.grid}>
      <Select
        id="multi-outlined"
        options={list}
        getOptionLabel={option => option.label}
        defaultValue={[list[0], list[1]]}
        multiple
      />
      <Select
        id="multi-underlined"
        variant="underlined"
        options={list}
        getOptionLabel={option => option.label}
        defaultValue={[list[0], list[1]]}
        multiple
      />
    </div>
  );
}
