import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5 1.5 17.799 1.5 12 6.201 1.5 12 1.5zM11.993 5A6.997 6.997 0 005 12c0 3.864 3.129 7 6.993 7A7.004 7.004 0 0019 12c0-3.864-3.136-7-7.007-7zM12 6.4c3.094 0 5.6 2.506 5.6 5.6s-2.506 5.6-5.6 5.6A5.598 5.598 0 016.4 12c0-3.094 2.506-5.6 5.6-5.6zm-.154 2.1h-.042a.502.502 0 00-.504.504v3.304c0 .245.126.476.343.602l2.905 1.743c.238.14.546.07.686-.168a.497.497 0 00-.175-.693l-2.709-1.61V9.004a.502.502 0 00-.504-.504z"
      fill="#00A9E0"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "StatusScheduled"
);
