// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Toggle category

let icons = [
  "CheckboxChecked",
  "CheckboxUnchecked",
  "Favorite",
  "FavoriteOutline",
  "IndeterminateCheckBox",
  "RadioButtonChecked",
  "RadioButtonUnchecked",
  "Star",
  "StarHalf",
  "StarOutline",
  "ViewCenterSplit",
  "ViewLeftFocus",
  "ViewRightFocus"
];

export default icons;
