import React, { useState } from "react";
import Button from "@liveramp/motif/core/Button";
import TextField from "@liveramp/motif/core/TextField";
import Filter from "@liveramp/motif/core/Filter";
import ListItem from "@liveramp/motif/core/ListItem";
import CheckIcon from "@liveramp/motif/icons/Check";

let options = [];
for (let i = 1; i <= 20; i++) {
  options.push({
    title: `Option ${i}`,
    optCount: Math.floor(Math.random() * 100) + 1
  });
}
let lorem = (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
);

export default function Sandbox() {
  let [filterValue, setFilterValue] = useState([options[0], options[1]]);

  return (
    <div style={{ padding: "50px" }}>
      <TextField placeholder="TextField" />
      <Filter
        id="filter-demo"
        label="Default"
        options={options}
        value={filterValue}
        getOptionLabel={option => option.title}
        onChange={(event, value) => setFilterValue(value)}
      />
      <Filter
        id="renderOption-filter-demo"
        label="renderOption"
        options={options}
        value={filterValue}
        getOptionLabel={option => option.title}
        renderOption={(option, props) => (
          <ListItem
            style={{ display: "flex", justifyContent: "space-between" }}
            {...props}
          >
            <span>{option.title}</span>
            {props["aria-selected"] && <CheckIcon />}
          </ListItem>
        )}
        onChange={(event, value) => setFilterValue(value)}
      />
      <Button variant="secondary">Button</Button>
      <br />
      <code>
        <pre>{JSON.stringify(filterValue)}</pre>
      </code>
      {lorem}
      {lorem}
      {lorem}
      {lorem}
    </div>
  );
}
