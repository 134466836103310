import React, { useState } from "react";
import Switch from "@liveramp/motif/core/Switch";
import FormControl from "@liveramp/motif/core/FormControl";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import FormGroup from "@liveramp/motif/core/FormGroup";
import FormHelperText from "@liveramp/motif/core/FormHelperText";
import FormLabel from "@liveramp/motif/core/FormLabel";
import css from "../../../resources/Page.module.css";

export default function FormGroupDemo() {
  let [ipa, setIpa] = useState(true);
  let [pilsner, setPilsner] = useState(false);
  let [lager, setLager] = useState(false);
  let [stout, setStout] = useState(true);

  let error = [ipa, pilsner, lager, stout].filter(Boolean).length < 3;

  return (
    <div className={css.grid}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Pick Beer(s)</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={ipa} onChange={() => setIpa(!ipa)} value="ipa" />
            }
            label="IPA"
          />
          <FormControlLabel
            control={
              <Switch
                checked={pilsner}
                onChange={() => setPilsner(!pilsner)}
                value="pilsner"
              />
            }
            label="Pilsner"
          />
          <FormControlLabel
            control={
              <Switch
                checked={lager}
                onChange={() => setLager(!lager)}
                value="lager"
              />
            }
            label="Lager"
          />
          <FormControlLabel
            control={
              <Switch
                checked={stout}
                onChange={() => setStout(!stout)}
                value="stout"
              />
            }
            label="Stout"
          />
        </FormGroup>
        <FormHelperText>This is your helper text</FormHelperText>
      </FormControl>
      <FormControl component="fieldset" required error={error}>
        <FormLabel component="legend">Pick Beer(s)</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch checked={ipa} onChange={() => setIpa(!ipa)} value="ipa" />
            }
            label="IPA"
          />
          <FormControlLabel
            control={
              <Switch
                checked={pilsner}
                onChange={() => setPilsner(!pilsner)}
                value="pilsner"
              />
            }
            label="Pilsner"
          />
          <FormControlLabel
            control={
              <Switch
                checked={lager}
                onChange={() => setLager(!lager)}
                value="lager"
              />
            }
            label="Lager"
          />
          <FormControlLabel
            control={
              <Switch
                checked={stout}
                onChange={() => setStout(!stout)}
                value="stout"
              />
            }
            label="Stout"
          />
        </FormGroup>
        <FormHelperText>Pick at least 3</FormHelperText>
      </FormControl>
    </div>
  );
}
