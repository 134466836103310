import React, { Component, Fragment } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Alert from "@liveramp/motif/core/Alert";

export default class QueuedTransitionsDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      queue: []
    };
  }

  componentDidUpdate() {
    let { open, queue } = this.state;
    // this function ensures that when there are more messages queued, there is
    // a slight delay which allows the alert to animate closing before opening
    // the next one
    if (!open && queue.length > 1) {
      queue.shift();
      setTimeout(() => this.setState({ open: true, queue }), 250);
    }
  }

  addMsg = (message, variant) => () => {
    let queue = this.state.queue;
    queue.push({ message, variant, key: new Date().getTime() });
    this.setState({ open: true, queue });
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ open: false });
  };

  render({ open, queue } = this.state) {
    let text = null;
    let variant = null;

    if (queue.length > 0) {
      text = queue[0].message;
      variant = queue[0].variant;
    }

    return (
      <Fragment>
        <ButtonFormGroup>
          <Button
            variant="secondary"
            onClick={this.addMsg("Message One - Queued")}
          >
            Message One
          </Button>
          <Button
            variant="secondary"
            onClick={this.addMsg("Message Two - Queued", "success")}
          >
            Message Two
          </Button>
        </ButtonFormGroup>

        <Alert
          open={open}
          onClose={this.handleClose}
          variant={variant}
          autoHideDuration={2000}
        >
          {text}
        </Alert>
      </Fragment>
    );
  }
}
