import React, { useState } from "react";
import Paper from "@liveramp/motif/core/Paper";
import ListSubheader from "@liveramp/motif/core/ListSubheader";
import List from "@liveramp/motif/core/List";
import ListItem from "@liveramp/motif/core/ListItem";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";
import ListItemText from "@liveramp/motif/core/ListItemText";
import Collapse from "@liveramp/motif/core/Collapse";

import {
  MoveToInbox as InboxIcon,
  Drafts as DraftsIcon,
  Send as SendIcon,
  ExpandLess,
  ExpandMore,
  Star,
  StarBorder
} from "@liveramp/motif/icons";

export default function NestedDemo() {
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Paper style={{ width: "300px" }}>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Nested List Items
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <SendIcon />
          </ListItemIcon>
          <ListItemText primary="Sent mail" />
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <DraftsIcon />
          </ListItemIcon>
          <ListItemText primary="Drafts" />
        </ListItem>
        <ListItem button onClick={handleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Inbox" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button style={{ paddingLeft: "72px" }}>
              <ListItemIcon>
                <Star />
              </ListItemIcon>
              <ListItemText primary="Starred" />
            </ListItem>
            <ListItem button style={{ paddingLeft: "72px" }}>
              <ListItemIcon>
                <StarBorder />
              </ListItemIcon>
              <ListItemText primary="Unstarred" />
            </ListItem>
          </List>
        </Collapse>
      </List>
    </Paper>
  );
}
