import React from "react";
import MuiDivider from "@material-ui/core/Divider";
import light from "../../styles/colors/light";
import withStyles from "../../styles/withStyles";
import { DividerStyle } from "../../utils/styleKeys";

function Divider(props) {
  return <MuiDivider {...props} />;
}

let styles = theme => ({
  ...DividerStyle,
  light: {
    backgroundColor: light.divider
  }
});

export default withStyles(styles, { name: "MuiDivider" })(Divider);
