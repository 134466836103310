import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import CodeChunk from "../../resources/CodeChunk";

import AdornmentsOutlined from "./demos/AdornmentsOutlined";
import AdornmentsUnderlined from "./demos/AdornmentsUnderlined";
import AdvancedCustom from "./demos/AdvancedCustom";
import Custom from "./demos/Custom";
import Datepicker from "./demos/Datepicker";
import Form from "./demos/Form";
import Outlined from "./demos/Outlined";
import OutlinedStates from "./demos/OutlinedStates";
import Underlined from "./demos/Underlined";
import UnderlinedStates from "./demos/UnderlinedStates";

let demoNames = [
  "AdornmentsOutlined",
  "AdornmentsUnderlined",
  "AdvancedCustom",
  "ComponentizedForm",
  "Custom",
  "Datepicker",
  "Form",
  "HtmlForm",
  "Outlined",
  "OutlinedStates",
  "TextFieldForm",
  "Underlined",
  "UnderlinedStates"
];

export default function TextField() {
  let demos = generateDemos("core-component-demo/text-field", demoNames);

  return (
    <Page
      components={[
        "FormControl",
        "FormHelperText",
        "InputBase",
        "InputLabel",
        "OutlinedInput",
        "TextField",
        "UnderlinedInput"
      ]}
    >
      <Title>Text Fields</Title>
      <Subtitle>Text fields let users enter and edit text</Subtitle>
      <Supporting>
        Text fields allow users to enter text into a UI. They typically appear
        in forms and dialogs.
      </Supporting>
      <Usage>
        <ul>
          <li>Do not mix text field variants on a form.</li>
          <li>
            <code>disabled</code> text fields should be used when the value is
            to be omitted in the form. These fields should be left blank.
          </li>
          <li>
            <code>readOnly</code> text fields should be used when the value is
            expected to be submitted in the form but should not be editable by
            the user. These fields should have a <code>defaultValue</code> set.
          </li>
          <li>
            Material Design documentation -{" "}
            <a href="https://material.io/components/text-fields">
              https://material.io/components/text-fields
            </a>
          </li>
        </ul>
      </Usage>

      <SectionTitle>Outlined</SectionTitle>
      <p>
        The <code>TextField</code> wrapper component is a complete form control
        including a label, input and help text.
      </p>
      <SectionTitle subtitle>Usage</SectionTitle>
      <p>
        Use the default outlined text field for pages that are primarily used as
        a form:
      </p>
      <ul>
        <li>Login pages</li>
        <li>User account pages</li>
        <li>Advanced search pages</li>
      </ul>
      <Demo demo={demos.Outlined}>
        <Outlined />
      </Demo>
      <Demo demo={demos.OutlinedStates}>
        <OutlinedStates />
      </Demo>

      <SectionTitle>Underlined</SectionTitle>
      <p>
        <code>TextField</code> also supports an underlined variant to reduce
        page clutter.
      </p>
      <SectionTitle subtitle>Usage</SectionTitle>
      <p>
        Use the underlined text field for pages that require a text input but
        are not dedicated page forms:
      </p>
      <ul>
        <li>Global application search fields</li>
        <li>Search/Select inputs above a table</li>
      </ul>
      <Demo demo={demos.Underlined}>
        <Underlined />
      </Demo>
      <Demo demo={demos.UnderlinedStates}>
        <UnderlinedStates />
      </Demo>

      <SectionTitle>Adornments</SectionTitle>
      <p>
        <code>OutlinedInput</code> and <code>UnderlinedInput</code> both accept
        an <code>InputAdornment</code> prop. These can be used to add a prefix,
        suffix, or an action to an input; e.g. an icon to show/hide a password.
      </p>

      <SectionTitle subtitle>Outlined</SectionTitle>
      <Demo demo={demos.AdornmentsOutlined}>
        <AdornmentsOutlined />
      </Demo>

      <SectionTitle subtitle>Underlined</SectionTitle>
      <Demo demo={demos.AdornmentsUnderlined}>
        <AdornmentsUnderlined />
      </Demo>

      <SectionTitle>Datepicker</SectionTitle>
      <p>
        Text field can create a native datepicker with <code>type="date"</code>.
      </p>
      <NoticePanel variant="warning">
        <p>
          Native input controls support by browsers
          <a href="https://caniuse.com/input-datetime">isn't perfect</a>. In the
          future, Motif will feature a fully styled{" "}
          <a href="https://next.material-ui.com/components/date-picker/">
            advanced datepicker
          </a>
          .
        </p>
      </NoticePanel>
      <Demo demo={demos.Datepicker}>
        <Datepicker />
      </Demo>

      <SectionTitle>Custom Text Fields</SectionTitle>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>

      <SectionTitle>Advanced Customization</SectionTitle>
      <p>
        The text field is a complex component consisting of many smaller
        components which can be leveraged to customize the default look and feel
        into unique solutions.
      </p>
      <p>
        Some native HTML input properties are missing from the TextField
        component; this is on purpose. The component takes care of the most used
        properties. You can use <code>inputProps</code> (and{" "}
        <code>InputProps</code>, <code>InputLabelProps</code>
        properties) if you want to avoid some boilerplate.
      </p>
      <NoticePanel variant="warning">
        <p>
          Avoid heavy customization to the textfield component to prevent
          introducing bad UX practices and patterns. Please verify and consult
          Theresa or Veronica before attempting to build your own textfield
          component.
        </p>
      </NoticePanel>
      <p>
        <code>FormControl</code>
        <code>InputBase</code>
        <code>InputLabel</code>
        input
        <code>FormHelperText</code>
      </p>
      <p>
        The textfield has four customizable components which are handled by
        passing in overriding props
      </p>
      <ul>
        <li>
          <code>inputProps</code> (Attributes applied to the input element.)
        </li>
        <li>
          <code>InputProps</code> (Props applied to the Input element. It will
          be a OutlinedInput or UnderlinedInput component depending on the
          variant prop value)
        </li>
        <li>
          <code>InputLabelProps</code>
        </li>
        <li>
          <code>FormHelperTextProps</code>
        </li>
      </ul>
      <Demo demo={demos.Custom}>
        <AdvancedCustom />
      </Demo>

      <SectionTitle>Forms</SectionTitle>
      <Demo demo={demos.Form}>
        <Form />
      </Demo>

      <SectionTitle>Accessibility</SectionTitle>
      <p>
        In order for the text field to be accessible, the input should be linked
        to the label and the helper text. The underlying DOM nodes should have
        this structure.
      </p>
      <CodeChunk>{demos.HtmlForm}</CodeChunk>
      <ul>
        <li>
          If you are using the TextField component, you just have to provide a
          unique id.
        </li>
      </ul>
      <CodeChunk>{demos.TextFieldForm}</CodeChunk>
      <ul>
        <li>If you are composing the component:</li>
      </ul>
      <CodeChunk>{demos.ComponentizedForm}</CodeChunk>
    </Page>
  );
}
