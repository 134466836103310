/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiFormControlLabel",
  description:
    "Drop in replacement of the `Radio`, `Switch` and `Checkbox` component.\r\nUse this component if you want to display an extra label.",
  displayName: "FormControlLabel",
  methods: [],
  props: {
    checked: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the component appears selected."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    control: {
      type: { name: "element" },
      required: true,
      description:
        "A control element. For instance, it can be be a `Radio`, a `Switch` or a `Checkbox`."
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the control will be disabled."
    },
    inputRef: {
      type: { name: "custom", raw: "refType" },
      required: false,
      description: "Pass a ref to the `input` element."
    },
    label: {
      type: { name: "node" },
      required: false,
      description: "The text to be used in an enclosing label element."
    },
    labelPlacement: {
      type: {
        name: "enum",
        value: [
          { value: "'bottom'", computed: false },
          { value: "'end'", computed: false },
          { value: "'start'", computed: false },
          { value: "'top'", computed: false }
        ]
      },
      required: false,
      description: "The position of the label.",
      defaultValue: { value: "'end'", computed: false }
    },
    name: { type: { name: "string" }, required: false, description: "@ignore" },
    onChange: {
      type: { name: "func" },
      required: false,
      description:
        "Callback fired when the state is changed.\r\n\r\n@param {object} event The event source of the callback.\r\nYou can pull out the new checked state by accessing `event.target.checked` (boolean)."
    },
    value: {
      type: { name: "any" },
      required: false,
      description: "The value of the component."
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    labelPlacementStart: {
      description:
        'Styles applied to the root element if `labelPlacement="start"`.'
    },
    labelPlacementTop: {
      description:
        'Styles applied to the root element if `labelPlacement="top"`.'
    },
    labelPlacementBottom: {
      description:
        'Styles applied to the root element if `labelPlacement="bottom"`.'
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    label: {
      description: "Styles applied to the label's Typography component."
    }
  }
};
export default generatedDocs;
