import React, { useState, useEffect } from "react";
import GlobalNavigation from "@liveramp/motif/core/GlobalNavigation";
import { SuperUniverse, GNLogo } from "@liveramp/motif/icons";
import navigation, { navigationAlt } from "./navigation";

let customisation = {
  logo: <SuperUniverse />,
  label: "Super Universe",
  link: "https://www.google.com",
  theme: "#00d9ff",
  backgroundColor: "#05003a"
};

export default function GlobalNavDemo({
  match,
  applyCustomStyling,
  setGlobalNavDisplayLevel,
  isAltNav,
  altNavHasBusinessObjects
}) {
  let [films, setFilms] = useState([]);
  let [loadingFilms, setLoadingFilms] = useState(true);
  let [businessObjectValue, setBusinessObjectValue] = useState({
    name: "Pazu"
  });
  let [businessObjects, setBusinessObjects] = useState([]);
  let [loadingBusinessObjects, setLoadingBusinessObjects] = useState(true);
  let [account, setAccount] = useState({
    title: "Castle in the Sky",
    url: "https://ghibliapi.herokuapp.com/films/2baf70d1-42bb-4437-b551-e5fed5a87abe"
  });

  const onOpen = () => {
    console.log("open");
  };
  const onClose = () => {
    console.log("close");
  };

  useEffect(() => {
    (async () => {
      fetch("https://ghibliapi.herokuapp.com/films")
        .then(response => response.json())
        .then(films => {
          return new Promise(resolve =>
            setTimeout(() => {
              setFilms(films);
              setAccount(films[0]);
              setLoadingFilms(false);
            }, 100)
          );
        });
    })();
  }, []);

  useEffect(() => {
    (async () => {
      fetch("https://ghibliapi.herokuapp.com/people")
        .then(response => response.json())
        .then(people => {
          return new Promise(resolve =>
            setTimeout(() => {
              const peopleObjects = people.map((person, index) => {
                // odd people will use the onNavigate hook
                let routingProps = {
                  route: `sandbox/connect/${person.name}`, // route url currently hardcoded for the demo
                  onNavigate: () => alert("Navigating via onNavigate hook")
                };

                // even people will use window.location to navigate
                if (index % 2 === 0) {
                  routingProps.route = person.url;
                  routingProps.onNavigate = null;
                }

                return {
                  id: person.id,
                  name: person.name,
                  gender: person.gender,
                  age: person.age,
                  films: person.films,
                  species: person.species,
                  ...routingProps
                };
              });

              // simulating a large list
              setBusinessObjects([
                ...peopleObjects,
                ...peopleObjects.map(appendIndex(1)),
                ...peopleObjects.map(appendIndex(2)),
                ...peopleObjects.map(appendIndex(3)),
                ...peopleObjects.map(appendIndex(4)),
                ...peopleObjects.map(appendIndex(5)),
                ...peopleObjects.map(appendIndex(6)),
                ...peopleObjects.map(appendIndex(7)),
                ...peopleObjects.map(appendIndex(8)),
                ...peopleObjects.map(appendIndex(9)),
                ...peopleObjects.map(appendIndex(10))
              ]);
              setBusinessObjectValue(peopleObjects[0]);
              setLoadingBusinessObjects(false);
            }, 1000)
          );
        });
    })();
  }, []);

  return (
    <GlobalNavigation
      openOnLoad
      navigation={
        isAltNav
          ? navigationAlt(altNavHasBusinessObjects)
          : navigation(match, {
              businessObjectValue,
              setBusinessObjectValue,
              businessObjects,
              loadingBusinessObjects
            })
      }
      accountSwitcher={{
        value: account,
        options: films,
        loading: loadingFilms,
        getOptionLabel: option => option.title,
        getOptionLink: option => option.url,
        onChange: (e, option) => {
          console.log(`Changed account to: ${option.title}`);
          setAccount(option);
        }
      }}
      customisation={
        applyCustomStyling
          ? customisation
          : isAltNav
          ? { logo: <GNLogo /> }
          : null
      }
      setGlobalNavDisplayLevel={setGlobalNavDisplayLevel}
      onOpen={onOpen}
      onClose={onClose}
      thisisatestprop="true"
      isAltNav={isAltNav}
    />
  );
}

let appendIndex = index => obj => {
  let newObj = { ...obj };
  newObj.id = `${newObj.id} - ${index}`;
  newObj.name = `${newObj.name} - ${index}`;
  return newObj;
};
