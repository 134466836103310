import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <path
    d="M10.95 2.292a8.95 8.95 0 017.223 14.236l3.426 3.426a1.37 1.37 0 11-1.938 1.937l-3.425-3.426A8.95 8.95 0 1110.95 2.292zm0 2.74a6.21 6.21 0 100 12.42 6.21 6.21 0 000-12.42zm1.08 3.094a.5.5 0 01.5.5v7.333c0 .084-.02.163-.057.232a5.641 5.641 0 01-2.354.233.5.5 0 01-.316-.465V8.626a.5.5 0 01.5-.5h1.727zM8.394 9.792a.5.5 0 01.5.5v5.667c0 .061-.011.12-.031.174l-.033-.01a5.258 5.258 0 01-2.664-2.135v-3.696a.5.5 0 01.5-.5h1.728zm7.163 1.667a.5.5 0 01.49.397c-.14 1.669-1.15 3.106-2.602 3.913a.468.468 0 01-.006-.076v-3.734a.5.5 0 01.5-.5h1.618z"
    fillRule="nonzero"
  />,

  "SqlLab"
);
