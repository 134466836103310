import React from "react";
import {
  Page,
  Title,
  NoticePanel,
  Subtitle,
  SectionTitle
} from "../../resources/DemoPage";
import { CustomizationWarning } from "../index";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import LongTitleNames from "./demos/LongTitleNames";
import Large from "./demos/Large";
import FormGroup from "./demos/FormGroup";
import FormGroupLarge from "./demos/FormGroupLarge";
import ActionsWithMenu from "./demos/ActionsWithMenu";

let demoNames = [
  "Simple",
  "LongTitleNames",
  "Large",
  "FormGroup",
  "FormGroupLarge",
  "ActionsWithMenu"
];

export default function LogoCard() {
  let demos = generateDemos("composed-component-demo/logo-card", demoNames);
  return (
    <Page
      components={[
        "LogoCard",
        "LogoCardActions",
        "LogoCardContent",
        "LogoCardGroup",
        "LogoCardHeader",
        "LogoCardMedia"
      ]}
    >
      <Title>Logo Card</Title>
      <Subtitle>
        Collection of card components that incorporate all the required styling
        to replace the legacy tiles.
      </Subtitle>
      <CustomizationWarning />

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
      <p>
        If titles in LogoCards are too long to fully display, they will render
        inside a tooltip on hover. It's also highly recommended to add a tooltip
        to the status icon prop that is passed in.
      </p>
      <Demo demo={demos.LongTitleNames}>
        <LongTitleNames />
      </Demo>

      <SectionTitle>Large Cards</SectionTitle>
      <p>
        Large cards can have expandable and collapsible areas. They can also
        optionally render a custom div on hover, such as CTA buttons. To display
        the actions area permanently without hover interactions, pass in{" "}
        <code>{`renderOnHover={false}`}</code>.
      </p>
      <p>
        If the Logo Card has a nested collapsible component and the parent
        container is using <code>display: grid</code> or{" "}
        <code>display: flex</code>, wrap each card in another HTML element to
        prevent all the cards from resizing when collapsing and expanding.
      </p>
      <Demo demo={demos.Large}>
        <Large />
      </Demo>
      <Demo demo={demos.ActionsWithMenu}>
        <ActionsWithMenu />
      </Demo>

      <SectionTitle>Checkbox Cards</SectionTitle>
      <p>
        When LogoCards are wrapped in a LogoCardGroup, they become selectable
        and function as checkboxes.
      </p>
      <NoticePanel>
        The LogoCardGroup heavily relies on unique ids for each card option.
        They are semantically required for accessibility and usability purposes;
        please do not omit these.
      </NoticePanel>

      <SectionTitle>Small Selectable Card Form Group</SectionTitle>
      <Demo demo={demos.FormGroup}>
        <FormGroup />
      </Demo>

      <SectionTitle subtitle>Large Selectable Card Form Group</SectionTitle>
      <Demo demo={demos.FormGroupLarge}>
        <FormGroupLarge />
      </Demo>
    </Page>
  );
}
