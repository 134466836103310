import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import CodeChunk from "../../resources/CodeChunk";

import BasicHeader from "./demos/BasicHeader";
import StickyFilterBar from "./demos/StickyFilterBar";

let demoNames = [
  "BasicHeader",
  "StickyFilterBar",
  "sticky"
];

export default function StickyTop() {
  let demos = generateDemos("layouts/sticky-top", demoNames);

  return (
    <Page >
      <Title>Sticky Top</Title>
      <Subtitle>
        A Sticky Top section follows the user as they scroll down the page.
      </Subtitle>
      <Usage>
        <ul>
          <li>Try and reserve this for only important pieces of UI.</li>
          <li>
            Avoid excessively large sticky components that take up too much screen real estate.
          </li>
        </ul>
      </Usage>

      <SectionTitle>How to Create</SectionTitle>
      <p>Use CSS to apply sticky properties</p>
      <CodeChunk css>{demos.sticky}</CodeChunk>


      <SectionTitle>Basic Header</SectionTitle>
      <p>Here is a line of text that sticky to the top of the page.</p>
      <Demo demo={demos.BasicHeader}>
        <BasicHeader />
      </Demo>

      <SectionTitle>Complex Section</SectionTitle>
      <p>Anything within a sticky div will stick to the top. This can be used for form controls, buttons, and more.</p>
      <Demo demo={demos.StickyFilterBar}>
        <StickyFilterBar />
      </Demo>

    </Page>
  );
}
