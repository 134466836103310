import React from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepLabel from "@liveramp/motif/core/StepLabel";
import StepContent from "@liveramp/motif/core/StepContent";

export default function Steps() {
  const steps = getSteps();
  return (
    <Stepper orientation="vertical">
      {steps.map((label, i) => (
        <Step key={i}>
          <StepLabel>{label}</StepLabel>
          <StepContent>Optional Description</StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

function getSteps() {
  return [
    "Label One",
    "Label Two (Long Label)",
    "Label Three",
    "Label Four",
    "Label Five",
    "Label Six"
  ];
}
