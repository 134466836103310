import React, { useState } from "react";
import Radio from "@liveramp/motif/core/Radio";

export default function RadioButtonDemo() {
  let [radio, setRadio] = useState("a");
  let handleChange = event => setRadio(event.target.value);

  return (
    <>
      <Radio checked={radio === "a"} onChange={handleChange} value="a" />
      <Radio checked={radio === "b"} onChange={handleChange} value="b" />
    </>
  );
}
