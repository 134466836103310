import React from "react";
import MuiListItemText from "@material-ui/core/ListItemText";
import { makeStyles, mergeClasses } from "../../utils";
import { ListItemTextStyle } from "../../utils/styleKeys";

export default function ListItemText({ classes, ...props }) {
  let defaultClasses = useStyles();

  return (
    <MuiListItemText
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...ListItemTextStyle,
    root: {
      color: theme.palette.common.black
    }
  }),
  { name: "MuiListItemText" }
);
