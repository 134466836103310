import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { withStyles } from "../../utils";
import { BreadcrumbsStyle } from "../../utils/styleKeys";

const styles = theme => ({
  ...BreadcrumbsStyle,
  root: {
    margin: "16px 0 24px"
  },
  li: {
    "& *": {
      ...theme.typography.body3,
      display: "flex",
      alignItems: "center",
      "&, &:visited": {
        color: theme.palette.text.primary
      }
    },

    "&:not(:last-child) *": {
      transition: theme.transitions.common.enteringScreen,
      "&:hover": {
        color: theme.palette.text.primary,
        textDecoration: "underline"
      }
    }
  }
});

export default withStyles(styles, { name: "MuiBreadcrumbs" })(Breadcrumbs);
