import React, { Fragment } from "react";
import Drawer from "@liveramp/motif/core/Drawer";
import Paper from "@liveramp/motif/core/Paper";
import Tooltip from "@liveramp/motif/core/Tooltip";
import IconButton from "@liveramp/motif/core/IconButton";
import Delete from "@liveramp/motif/icons/Delete";
import Download from "@liveramp/motif/icons/Download";
import Save from "@liveramp/motif/icons/Save";
import withStyles from "@liveramp/motif/styles/withStyles";
import css from "./Permanent.module.css";

function Permanent({ classes }) {
  return (
    <Fragment>
      <Paper className={css.page}>
        <Drawer
          classes={{
            root: classes.root,
            paperAnchorBottom: classes.paper
          }}
          className={css.drawer}
          variant="permanent"
          anchor="bottom"
        >
          <div className={css.content}>
            <Tooltip title="Save">
              <IconButton>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton>
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Download">
              <IconButton>
                <Download />
              </IconButton>
            </Tooltip>
          </div>
        </Drawer>
      </Paper>
    </Fragment>
  );
}

let styles = theme => ({
  root: { width: "100%" },
  paper: {
    "&.MuiDrawer-paperAnchorBottom": {
      position: "relative"
    }
  }
});

export default withStyles(styles)(Permanent);
