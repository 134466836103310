/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiBreadcrumbs",
  description: "",
  displayName: "Breadcrumbs",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The breadcrumb children."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'nav'", computed: false }
    },
    expandText: {
      type: { name: "string" },
      required: false,
      description:
        "Override the default label for the expand button.\r\n\r\nFor localization purposes, you can use the provided [translations](/guides/localization/).",
      defaultValue: { value: "'Show path'", computed: false }
    },
    itemsAfterCollapse: {
      type: { name: "number" },
      required: false,
      description:
        "If max items is exceeded, the number of items to show after the ellipsis.",
      defaultValue: { value: "1", computed: false }
    },
    itemsBeforeCollapse: {
      type: { name: "number" },
      required: false,
      description:
        "If max items is exceeded, the number of items to show before the ellipsis.",
      defaultValue: { value: "1", computed: false }
    },
    maxItems: {
      type: { name: "number" },
      required: false,
      description:
        "Specifies the maximum number of breadcrumbs to display. When there are more\r\nthan the maximum number, only the first `itemsBeforeCollapse` and last `itemsAfterCollapse`\r\nwill be shown, with an ellipsis in between.",
      defaultValue: { value: "8", computed: false }
    },
    separator: {
      type: { name: "node" },
      required: false,
      description: "Custom separator node.",
      defaultValue: { value: "'/'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    ol: { description: "Styles applied to the ol element." },
    li: { description: "Styles applied to the li element." },
    separator: { description: "Styles applied to the separator element." }
  }
};
export default generatedDocs;
