import React from "react";

const Motif = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M12.06 1.397A.71.71 0 0112 1.4c-.724 0-1.43.073-2.113.21-.683.14-1.362.35-2.029.633a.71.71 0 01-.056.02A10.603 10.603 0 001.4 12c0 1.449.29 2.83.817 4.087a.71.71 0 01.026.055c.212.5.459.977.736 1.427a10.656 10.656 0 003.17 3.271 10.656 10.656 0 004.25 1.64 10.683 10.683 0 003.715-.09 10.649 10.649 0 003.454-1.369 10.656 10.656 0 003.272-3.17 10.656 10.656 0 001.64-4.25c.079-.522.12-1.057.12-1.601 0-.02 0-.04.003-.06a10.568 10.568 0 00-.846-4.082 10.603 10.603 0 00-9.697-6.46zm11.804 12.416a11.93 11.93 0 01-1.856 4.81 11.93 11.93 0 01-3.704 3.59 11.926 11.926 0 01-3.911 1.548c-1.408.288-2.83.314-4.206.103a11.93 11.93 0 01-4.81-1.856 11.93 11.93 0 01-3.59-3.704A11.944 11.944 0 010 12C0 6.192 4.126 1.348 9.607.239c5.479-1.121 11.17 1.726 13.44 7.072a11.944 11.944 0 01.817 6.502z" />
      <path d="M15.6 6c-1.392 0-2.728.618-3.6 1.595C11.128 6.618 9.792 6 8.4 6 5.936 6 4 7.846 4 10.196c0 2.884 2.72 5.234 6.84 8.805L12 20l1.16-1.007C17.28 15.43 20 13.08 20 10.196 20 7.846 18.064 6 15.6 6zm-3.52 11.864l-.08.076-.08-.076C8.112 14.575 5.6 12.4 5.6 10.196c0-1.526 1.2-2.67 2.8-2.67 1.232 0 2.432.755 2.856 1.8h1.496c.416-1.045 1.616-1.8 2.848-1.8 1.6 0 2.8 1.144 2.8 2.67 0 2.205-2.512 4.38-6.32 7.668z" />
    </g>
  </svg>
);

export default Motif;
