import React, { useContext } from "react";
import clsx from "clsx";
import Paper from "../../core-components/Paper";
import Divider from "../../core-components/Divider";
import List from "../../core-components/List";
import AccountSwitcher from "./AccountSwitcher";
import L3Item from "./L3Item";
import L3Collapse from "./L3Collapse";
import L3CountryPicker from "./L3CountryPicker";
import { GlobalNavigationContext } from "./GlobalNavigation";
import withStyles from "../../styles/withStyles";

function L3() {
  const { displayLevel, currentL3, isAltNav } = useContext(
    GlobalNavigationContext
  );

  if (!currentL3) return null;

  let titleHolder = currentL3;
  while (titleHolder?.level > 2) {
    titleHolder = titleHolder.parent;
  }

  return (
    <Paper
      component="nav"
      className={clsx("GNL3", {
        ["GNL3--show"]: displayLevel === 3
      })}
    >
      {currentL3.countries ? (
        <L3CountryPicker
          {...currentL3}
          label={isAltNav ? "LiveRamp" : currentL3.label}
        />
      ) : (
        <div
          className={clsx("GNL3-header", {
            ["GNL3-header--isAltNav"]: isAltNav
          })}
        >
          {isAltNav ? "LiveRamp" : titleHolder.label}
        </div>
      )}
      <Divider />
      <AccountSwitcher />
      <Divider />
      <List className="GNL3-list">
        {currentL3.children?.map(l3Item =>
          l3Item?.children ? (
            <L3Collapse
              key={l3Item.label}
              label={l3Item.label}
              collapsed={l3Item?.collapsed}
            >
              {l3Item.children.map(l3SubItem => (
                <L3Item key={l3SubItem.label} {...l3SubItem} />
              ))}
            </L3Collapse>
          ) : (
            <div key={l3Item.label}>
              <L3Item {...l3Item} />
              {l3Item.label === "Dashboard" && (
                <Divider className="GNL3-dashboard-divider" />
              )}
            </div>
          )
        )}
      </List>
    </Paper>
  );
}

const styles = theme => ({
  "@global": {
    ".GNL3": {
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      position: "fixed",
      left: "50px",
      transition: theme.transitions.common.enteringScreen,
      height: "100vh",
      width: "0",

      // .GNL3--show
      "&--show": {
        width: "208px"
      },

      // .GNL3-header
      "&-header": {
        minHeight: "40px",
        display: "flex",
        paddingLeft: "10px",
        fontSize: "16px",
        alignItems: "center",
        fontWeight: "600",
        overflowX: "hidden",
        whiteSpace: "nowrap",

        // .GNL3-header--isAltNav
        "&--isAltNav": {
          ...theme.typography.h4Branding,
          fontWeight: "300"
        }
      },

      // .GNL3-list
      "&-list": {
        overflow: "auto"
      },

      // .GNL3-dashboard-divider
      "&-dashboard-divider": {
        marginTop: "8px"
      }
    }
  }
});

export default withStyles(styles)(L3);
