import React from "react";
import { Page, Title, Subtitle, SectionTitle } from "../../resources/DemoPage";
import { CustomizationWarning } from "../index";
import Demo, { generateDemos } from "../../resources/Demo";

import SimpleDemo from "./demos/Simple";
import SurfaceDemo from "./demos/Surface";
import MessageStatesDemo from "./demos/MessageStates";

let demoNames = ["Simple", "Surface", "MessageStates"];

export default function NotificationDemo() {
  let demos = generateDemos("notification-content", demoNames);

  return (
    <Page components={["NotificationContent"]}>
      <Title>Notification Content</Title>
      <Subtitle>
        A business-oriented component that displays a list of notifications on
        any surface
      </Subtitle>
      <CustomizationWarning />

      <Demo demo={demos.Simple}>
        <SimpleDemo />
      </Demo>

      <p>
        Use this component to display notifications on any surface. Pass in a
        height prop to make the notifications scrollable.
      </p>
      <Demo demo={demos.Surface}>
        <SurfaceDemo />
      </Demo>
      <SectionTitle>Message States</SectionTitle>
      <p>
        Set the loading or error prop to true to display a corresponding state.
        The no notifications message will display when no notifications are
        passed to the component.
      </p>
      <Demo demo={demos.MessageStates}>
        <MessageStatesDemo />
      </Demo>
    </Page>
  );
}
