let darkBlue = {
  50: "#e0eaf3",
  100: "#b3c9e0",
  200: "#80a6cb",
  300: "#4d82b6",
  400: "#2667a7",
  500: "#004c97",
  600: "#00458f",
  700: "#003c84",
  800: "#00337a",
  900: "#002469"
};

export default darkBlue;
