import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import makeStyles from "../../styles/makeStyles";

export default function SidePanelImageSection({
  className,
  children,
  childrenProps = {},
  image,
  imageProps = {},
  ...props
}) {
  useStyles();

  let imageClassName = imageProps.className;
  delete imageProps.className;
  let childrenClassName = childrenProps.className;
  delete childrenProps.className;

  return (
    <div
      className={clsx(
        "SidePanelImageSection",
        "SidePanelImageSection-root",
        className
      )}
      {...props}
    >
      {image ? (
        <img
          className={clsx(
            "SidePanelImageSection__image",
            "SidePanelImageSection-image",
            imageClassName
          )}
          src={image}
          onError={e => {
            e.currentTarget.src =
              "https://storage.googleapis.com/motif-docs-images/side-panel/broken-image.png";
          }}
          alt="Side Panel Image"
          {...imageProps}
        />
      ) : (
        <div className="SidePanelImageSection__no-image SidePanelImageSection-noImage" />
      )}
      <div
        className={clsx(
          "SidePanelImageSection__children",
          "SidePanelImageSection-children",
          childrenClassName
        )}
        {...childrenProps}
      >
        {children}
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".SidePanelImageSection": {
      display: "grid",
      gridTemplateColumns: "1fr 2fr",
      gridGap: "16px",

      // .SidePanelImageSection__image
      "&__image": {
        display: "block",
        maxWidth: "200px",
        maxHeight: "144px"
      },

      // .SidePanelImageSection__no-image
      "&__no-image": {
        backgroundColor: theme.palette.grey[100],
        height: "100%",
        width: "144px",
        borderRadius: "4px"
      },

      // .SidePanelImageSection__children
      "&__children": {
        display: "flex",
        flexDirection: "column",
        gap: "1px"
      }
    }
  }
}));

SidePanelImageSection.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string
};
