let blue = {
  50: "#e0f5fb",
  100: "#b3e5f6",
  200: "#80d4f0",
  300: "#4dc3e9",
  400: "#26b6e5",
  500: "#00a9e0",
  600: "#00a2dc",
  700: "#0098d8",
  800: "#008fd3",
  900: "#007ecb"
};

export default blue;
