import React from "react";
import Button from "../../../core-components/Button";

const StatusButton = ({ onClick, text }) => {
  return (
    <Button size="small" variant="text" color="secondary" onClick={onClick}>
      {text}
    </Button>
  );
};

const MultipleStatusButton = ({ file, successComponent }) => {
  const { isError, isSuccess, isComplete, retryUpload, cancelUpload } = file;

  if (isError) return <StatusButton onClick={retryUpload} text={"Try Again"} />;

  if (isComplete && isSuccess)
    return successComponent && successComponent(file);

  if (!isComplete)
    return <StatusButton onClick={cancelUpload} text={"Cancel"} />;

  return null;
};

export default MultipleStatusButton;
