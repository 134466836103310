import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import IconButton from "@liveramp/motif/core/IconButton";
import MenuList from "@liveramp/motif/core/MenuList";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MoreHorizontal from "@liveramp/motif/icons/MoreHorizontal";
import { rows } from "../../demos/data";

const caffeineOrder = ["High", "Medium", "Low"];

const RowMenu = cell => {
  const handleIncreaseClick = e => {
    const updatedRow = Object.assign({}, cell.row);
    updatedRow.caffeine =
      caffeineOrder[caffeineOrder.indexOf(cell.row.caffeine) - 1];

    cell.api.updateRows([updatedRow]);
  };

  const handleDecreaseClick = e => {
    const updatedRow = Object.assign({}, cell.row);
    updatedRow.caffeine =
      caffeineOrder[caffeineOrder.indexOf(cell.row.caffeine) + 1];

    cell.api.updateRows([updatedRow]);
  };

  return (
    <ButtonMenu
      component={
        <IconButton>
          <MoreHorizontal />
        </IconButton>
      }
    >
      <MenuList>
        <MenuItem
          onClick={handleIncreaseClick}
          disabled={cell.row.caffeine === "High"}
        >
          Increase Caffeine
        </MenuItem>
        <MenuItem
          onClick={handleDecreaseClick}
          disabled={cell.row.caffeine === "Low"}
        >
          Decrease Caffeine
        </MenuItem>
      </MenuList>
    </ButtonMenu>
  );
};

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  {
    field: "caffeine",
    headerName: "Caffeine",
    width: 120,
    sortComparator: (value1, value2) =>
      caffeineOrder.indexOf(value1) - caffeineOrder.indexOf(value2)
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    width: 120,
    valueFormatter: params => `$${params.value.toFixed(2)}`
  },
  { field: "desc", headerName: "Description", width: 475 },
  {
    field: "__rowMenu__",
    cellClassName: "MuiDataGrid-cellRowMenu",
    headerName: " ",
    hideSortIcons: true,
    width: 75,
    renderCell: RowMenu
  }
];

export default function RowMenusDemo() {
  return <RichDataTable columns={columns} rows={rows} />;
}
