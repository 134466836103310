import React from "react";

const EditDataStoreTile = props => (
  <svg width={82} height={82} viewBox="0 0 85 85" {...props}>
    <path
      d="M77.027 43.878H55.08v22.81l10.974 14.563 9.916-13.158H61.68a1.053 1.053 0 110-2.105h15.347v-22.11zM66.894 83.633a1.053 1.053 0 01-1.681 0l-12.02-15.95a1.05 1.05 0 01-.219-.653V38.973c0-4.482 5.628-7.657 13.08-7.657 7.45 0 13.079 3.175 13.079 7.657v28.009a1.032 1.032 0 01-.219.7l-12.02 15.951zm10.133-44.66c0-2.995-4.53-5.551-10.974-5.551-6.443 0-10.974 2.556-10.974 5.551v2.8h21.948v-2.8zM7.187 2.138l.064-.1a3.672 3.672 0 013.042-1.54h63.394a3.682 3.682 0 013.062 1.54l.063.1 4.903 8.773a11.1 11.1 0 011.791 6.324c0 6.203-4.998 11.234-11.166 11.234-4.473 0-8.331-2.646-10.113-6.466-1.783 3.82-5.64 6.466-10.114 6.466-4.473 0-8.33-2.646-10.113-6.466-1.782 3.82-5.64 6.466-10.113 6.466-4.474 0-8.331-2.646-10.114-6.466-1.782 3.82-5.64 6.466-10.113 6.466-1.257 0-2.466-.21-3.594-.595v41.918c0 .636.51 1.149 1.134 1.149h37.72a1.053 1.053 0 110 2.105H9.2a3.247 3.247 0 01-3.24-3.254V26.898a11.217 11.217 0 01-5.466-9.636 11.124 11.124 0 011.791-6.35l4.903-8.774zM4.107 11.97a1.088 1.088 0 01-.044.071 9.043 9.043 0 00-1.463 5.194c0 5.043 4.058 9.128 9.061 9.128s9.06-4.085 9.06-9.128a1.053 1.053 0 012.106 0c0 5.043 4.058 9.128 9.06 9.128 5.003 0 9.061-4.085 9.061-9.128a1.053 1.053 0 012.106 0c0 5.043 4.058 9.128 9.06 9.128 5.003 0 9.061-4.085 9.061-9.128a1.053 1.053 0 012.105 0c0 5.043 4.058 9.128 9.061 9.128s9.06-4.085 9.061-9.156a9.022 9.022 0 00-1.177-4.698h-66.65a1.053 1.053 0 010-2.105h65.445l-4.012-7.182a1.587 1.587 0 00-1.3-.619H10.273c-.5-.01-.974.22-1.28.62L4.111 11.96a1.059 1.059 0 01-.005.01z"
      fillRule="nonzero"
    />
  </svg>
);

export default EditDataStoreTile;
