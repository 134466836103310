import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path d="M16.07 4.684v6.934c0 .391-.349.801-1.04 1.138a4.258 4.258 0 01-.203.093l.355.837c.085-.036.167-.074.247-.113.971-.474 1.55-1.153 1.55-1.955V4.684h-.909zM7.411 11.123v-6.44h-1v6.44z" />
    <path d="M8.31 6.965c.904.351 2.068.526 3.385.526 2.917 0 5.284-1.18 5.284-2.807C16.98 3.07 14.663 2 11.695 2S6.411 3.071 6.411 4.684h.91c0-.92 1.849-1.775 4.374-1.775 2.526 0 4.375.856 4.375 1.775 0 .953-1.896 1.898-4.375 1.898-1.213 0-2.269-.159-3.055-.465l-.33.848zM8.31 10.184a9.459 9.459 0 003.382.65c2.92 0 5.287-1.18 5.287-2.807h-.909c0 .953-1.896 1.898-4.375 1.898a8.546 8.546 0 01-3.054-.588l-.331.847zM6.904 18.43l-4.55 1.933.355.837 5.357-2.276-2.545-6.647-3.145 1.143.311.854 2.304-.838zM16.187 15.936l3.098-2.477c.201-.203.418-.295.645-.295.227 0 .444.092.602.255l.654-.63a1.744 1.744 0 00-2.512 0l-3.055 2.437.568.71z" />
    <path d="M12.696 19.56c1.034.514 2.227.4 3.113-.274l5.087-4.094-.57-.708-5.077 4.086a2.018 2.018 0 01-2.113.195l-3.804-2.333-.202-.124-2.199.962.365.832 1.764-.771 3.636 2.229z" />
    <path d="M5.812 14.263l3.344-1.486c.296-.137.612-.207.932-.209.067 0 .153.015.254.044.123.036.258.09.427.17l.233.112 4.154 2.816c.392.27.466.535.25.854-.224.33-.719.391-1.1.129l-3.24-1.995-.476.775 3.22 1.98c.757.524 1.826.39 2.348-.378.52-.765.292-1.577-.49-2.116l-4.213-2.85a7.7 7.7 0 00-.3-.15 3.859 3.859 0 00-.557-.22 1.82 1.82 0 00-.512-.08 3.159 3.159 0 00-1.305.29l-3.338 1.483.37.83zM4.563 17.895l-1.607-4.21-.849.324 1.607 4.21z" />
  </React.Fragment>,
  "SafeHaven"
);
