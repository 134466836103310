let orange = {
  50: "#fef1e5",
  100: "#feddbf",
  200: "#fdc694",
  300: "#fcaf69",
  400: "#fb9e48",
  500: "#fa8d28",
  600: "#f98524",
  700: "#f97a1e",
  800: "#f87018",
  900: "#f65d0f"
};

export default orange;
