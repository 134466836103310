import React from "react";

const WorkflowSupport = props => (
  <svg width={82} height={82} viewBox="0 0 82 82" {...props}>
    <g fillRule="nonzero">
      <path d="M41 82c22.644 0 41-18.356 41-41S63.644 0 41 0 0 18.356 0 41s18.356 41 41 41zm0-2.158C19.548 79.842 2.158 62.452 2.158 41 2.158 19.548 19.548 2.158 41 2.158c21.452 0 38.842 17.39 38.842 38.842 0 21.452-17.39 38.842-38.842 38.842z" />
      <path d="M22.776 45.836a19.109 19.109 0 01-.622-4.832 18.845 18.845 0 115.526 13.329 1.077 1.077 0 00-1.522 1.524A20.999 20.999 0 1020 41.004c0 1.815.233 3.621.692 5.377a1.077 1.077 0 002.084-.545z" />
      <path d="M68.19 12.31L54.31 26.19a1.06 1.06 0 001.5 1.5l13.88-13.88a1.06 1.06 0 00-1.5-1.5zM13.81 69.69l13.88-13.88a1.06 1.06 0 00-1.5-1.5L12.31 68.19a1.06 1.06 0 001.5 1.5zM12.31 13.81l13.88 13.88a1.06 1.06 0 001.5-1.5L13.81 12.31a1.06 1.06 0 00-1.5 1.5zM54.31 55.81l13.88 13.88a1.06 1.06 0 001.5-1.5L55.81 54.31a1.06 1.06 0 00-1.5 1.5z" />
    </g>
  </svg>
);

export default WorkflowSupport;
