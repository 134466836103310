import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM8.667 8.083V9.8c0 .287.116.564.327.77l1.895 1.847-1.9 1.852a1.09 1.09 0 00-.322.77v1.711c0 .596.5 1.083 1.11 1.083h4.445c.611 0 1.111-.487 1.111-1.083v-1.712c0-.287-.116-.563-.322-.763l-1.9-1.858 1.895-1.842c.21-.206.327-.482.327-.77V8.084c0-.596-.5-1.083-1.11-1.083H9.777c-.611 0-1.111.487-1.111 1.083z"
      fill="#26B6E5"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "StatusInProgress"
);
