import React from "react";
import Truncate from "@liveramp/motif/core/Truncate";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function SingleLine() {
  const classes = useStyles();
  const children = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla ac purus a diam dapibus sollicitudin.";
  return (
      <div className={classes.container}>
        <Truncate variant="h3Branding">{children}</Truncate>
      </div>
  );
}

let useStyles = makeStyles({
  container: {
    display: "grid",
    maxWidth: "800px"
  }
});
