// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Table category
let icons = [
  "PrivacyDataSet",
  "PrivacyDerivedView",
  "Table",
  "TableView",
  "TableViewPrivacy",
  "TableEmptyState",
  "TableNullState",
  "TableSensitiveColumn"
];

export default icons;
