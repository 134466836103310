import MenuItem from "@material-ui/core/MenuItem";
import withStyles from "../../styles/withStyles";
import { MenuItemStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...MenuItemStyle,
  root: {
    "&:active, &.Mui-active, &.Mui-selected": {
      backgroundColor: theme.palette.secondary.active
    },
    "&:hover, &.Mui-focus, &.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.hover
    }
  }
});

export default withStyles(styles, { name: "MuiMenuItem" })(MenuItem);
