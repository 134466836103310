import React, { useState } from "react";
import Select from "@liveramp/motif/core/Select";
import { films } from "./data";
import css from "./States.module.css";

export default function MultiStatesDemo() {
  let [controlled, setControlled] = useState([films[62]]);

  return (
    <div
      className={css.grid}
      style={{
        gridTemplateColumns: "repeat(auto-fit, 250px)"
      }}
    >
      <Select
        id="multi-controlled-input"
        label="value"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This input is controlled"
        value={controlled}
        onChange={(event, value) => setControlled(value)}
        multiple
      />
      <Select
        id="multi-helper-input"
        label="label, helperText"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This is your helper text"
        multiple
      />
      <Select
        id="multi-default-value-input"
        label="defaultValue"
        options={films}
        getOptionLabel={film => film.title}
        defaultValue={[films[30]]}
        helperText="This input is uncontrolled and has a default value"
        multiple
      />
      <Select
        id="multi-placeholder-input"
        label="placeholder"
        options={films}
        getOptionLabel={film => film.title}
        placeholder="Hello World"
        helperText="This input has a placeholder"
        multiple
      />
      <Select
        id="multi-required-input"
        label="required"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This input is marked as required"
        required
        multiple
      />
      <Select
        id="multi-error-input"
        label="error"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This input has an error"
        error
        multiple
      />
      <Select
        id="multi-disabled-input"
        label="disabled"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This input is disabled"
        disabled
        multiple
      />
      {/* TODO: this does not work */}
      {/* <Select
        id="multi-readonly-input"
        options={films}
        getOptionLabel={film => film.title}
        defaultValue={[films[49].title]}
        helperText="This input is read-only"
        readOnly
        multiple
      /> */}
      <Select
        id="multi-fullwidth-input"
        label="fullWidth"
        options={films}
        getOptionLabel={film => film.title}
        helperText="This input takes up the full width of its container"
        fullWidth
        multiple
      />
    </div>
  );
}
