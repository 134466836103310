import React, { Fragment, useState } from "react";
import Checkbox from "@liveramp/motif/core/Checkbox";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";

export default function CheckboxTypeDemo() {
  let [checkedA, setCheckedA] = useState(true);
  let [checkedE, setCheckedE] = useState(true);

  return (
    <Fragment>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedA}
            onChange={() => setCheckedA(!checkedA)}
            value="checkedA"
            inputProps={{
              "aria-label": "primary checkbox"
            }}
          />
        }
        label="Default"
      />
      <FormControlLabel
        control={
          <Checkbox
            inputProps={{
              "aria-label": "uncontrolled-checkbox"
            }}
          />
        }
        label="Uncontrolled"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled
            value="checkedC"
            inputProps={{
              "aria-label": "disabled checkbox"
            }}
          />
        }
        label="Disabled Unchecked"
      />
      <FormControlLabel
        control={
          <Checkbox
            disabled
            checked
            value="checkedD"
            inputProps={{
              "aria-label": "disabled checked checkbox"
            }}
          />
        }
        label="Disabled Checked"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkedE}
            onChange={() => setCheckedE(!checkedE)}
            value="checkedE"
            indeterminate
            inputProps={{
              "aria-label": "indeterminate checkbox"
            }}
          />
        }
        label="Indeterminate"
      />
    </Fragment>
  );
}
