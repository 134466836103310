import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import Placement from "./demos/Placement";
import Controlled from "./demos/Controlled";
import Interactive from "./demos/Interactive";
import ShowingHiding from "./demos/ShowingHiding";
import Html from "./demos/Html";

let demoNames = [
  "Simple",
  "Placement",
  "Controlled",
  "Interactive",
  "ShowingHiding",
  "Html"
];

export default function Tooltip() {
  let demos = generateDemos("core-component-demo/tooltip", demoNames);
  return (
    <Page components="Tooltip">
      <Title>Tooltip</Title>
      <Subtitle>
        Tooltips display informative text when users hover over, focus on, or
        tap an element
      </Subtitle>
      <Supporting>
        When activated, Tooltips display a text label identifying an element,
        such as a description of its function.
      </Supporting>
      <Usage>
        <ul>
          <li>
            Tooltips that get pushed out of the page view will automatically use
            the opposite placement.
          </li>
        </ul>
      </Usage>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
      <SectionTitle>Placement</SectionTitle>
      <p>
        Placement is defaulted to <code>top</code>. Utilize the positions show
        in this demo to place the tooltip on any side of its child. For edge
        cases when you need to position a Tooltip on the edge of an object or
        near the side of a screen, see the full positioning demo with all
        positions available{" "}
        <a
          href="https://codesandbox.io/s/tooltips-positioning-full-demo-53jn3"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <Demo demo={demos.Placement}>
        <Placement />
      </Demo>
      <SectionTitle>Controlled</SectionTitle>
      <p>
        You can use the <code>open</code>, <code>onOpen</code> and{" "}
        <code>onClose</code> properties to control the behavior of the tooltip.
      </p>
      <Demo demo={demos.Controlled}>
        <Controlled />
      </Demo>
      <SectionTitle>HTML Content</SectionTitle>
      <p>
        Html can be used in the tooltip content area by passing elements through
        the <code>title</code> prop.
      </p>
      <SectionTitle>Interactive</SectionTitle>
      <Demo demo={demos.Interactive}>
        <Interactive />
      </Demo>
      <SectionTitle>Showing and Hiding</SectionTitle>
      <Demo demo={demos.ShowingHiding}>
        <ShowingHiding />
      </Demo>
      <Demo demo={demos.Html}>
        <Html />
      </Demo>
    </Page>
  );
}
