import React from "react";
import MuiFormControlLabel from "@material-ui/core/FormControlLabel";
import makeStyles from "../../styles/makeStyles";
import { FormControlLabelStyle } from "../../utils/styleKeys";

export default function FormControlLabel({ size = "medium", ...props }) {
  let classes = useStyles({ size });

  return <MuiFormControlLabel classes={classes} {...props} />;
}

let useStyles = makeStyles(
  theme => ({
    ...FormControlLabelStyle,
    root: {
      gap: "8px"
    },
    label: {
      fontSize: props =>
        props.size === "small"
          ? theme.typography.body2.fontSize
          : theme.typography.body1.fontSize
    }
  }),
  { name: "MuiFormControlLabel" }
);
