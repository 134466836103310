import React, { useState } from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import SidePanel from "@liveramp/motif/core/SidePanel";
import SidePanelHeader from "@liveramp/motif/core/SidePanelHeader";
import SidePanelContent from "@liveramp/motif/core/SidePanelContent";
import SidePanelImageSection from "@liveramp/motif/core/SidePanelImageSection";
import SidePanelFieldValueRow from "@liveramp/motif/core/SidePanelFieldValueRow";
import Typography from "@liveramp/motif/core/Typography";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import { columns, rows } from "../../demos/data";

export default function SidePanelDemo() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(false);
  const classes = useStyles();

  const handleRowClick = (params, event) => {
    if (open && data && data.params.row.id === params.row.id) {
      setOpen(false);
    } else {
      setOpen(true);
      setData({ params, event });
    }
  };

  const renderSidePanelContent = params => {
    if (params) {
      return (
        <>
          <SidePanelHeader onClose={() => setOpen(false)}>
            {params.row.name}
          </SidePanelHeader>
          <SidePanelContent className={classes.sections}>
            <SidePanelImageSection image="https://storage.googleapis.com/motif-docs-images/side-panel/lr-logo.png">
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Caffeine
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    {params.row.caffeine}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
              <SidePanelFieldValueRow>
                <div>
                  <Typography
                    variant="body3"
                    color="secondary"
                    paragraph={false}
                  >
                    Price
                  </Typography>
                  <Typography variant="body2" paragraph={false}>
                    ${params.row.price.toFixed(2)}
                  </Typography>
                </div>
              </SidePanelFieldValueRow>
            </SidePanelImageSection>
            {params.row.desc}
          </SidePanelContent>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <RichDataTable
        columns={columns}
        rows={rows}
        onRowClick={handleRowClick}
        showPointerOnRows
        showActiveRow={open}
      />
      <SidePanel open={open} onClose={() => setOpen(false)} width="500px">
        {renderSidePanelContent(data?.params)}
      </SidePanel>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  fvPairSection: {
    display: "grid",
    gap: "16px",
    gridAutoFlow: "column"
  },
  hidden: {
    display: "none"
  },
  chip: {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.text.primary
  },
  chipIcon: {
    fill: theme.palette.primary[500]
  },
  sections: {
    width: "500px"
  }
}));
