import React from "react";

const WorkflowCreateSegment = props => (
  <svg width={82} height={82} viewBox="0 0 82 82" {...props}>
    <g fillRule="nonzero">
      <path d="M69.79 46.483c-.197 11.971-7.161 22.907-18.21 28.596-.537.277-.737.916-.447 1.428.29.512.96.703 1.498.426C64.383 70.882 71.79 59.25 72 46.516c.01-.582-.478-1.061-1.088-1.07-.61-.01-1.113.455-1.122 1.037zM5.87 60.692A33.553 33.553 0 012.176 45.67c-.054-18.404 15.003-33.42 33.749-33.652A1.078 1.078 0 0037 10.935a1.08 1.08 0 00-1.102-1.055C15.958 10.127-.057 26.1 0 45.68a35.665 35.665 0 003.925 15.972c.27.527.924.74 1.46.475a1.06 1.06 0 00.484-1.435zM44.083 38.53c-.598 0-1.083-.48-1.083-1.07V1.07C43 .48 43.485 0 44.083 0c10.056 0 19.7 3.947 26.811 10.972C78.005 17.997 82 27.525 82 37.46c0 .59-.485 1.07-1.083 1.07H44.083zm1.084-2.14h34.65c-.275-8.98-4.006-17.534-10.455-23.905C62.914 6.115 54.255 2.43 45.167 2.157V36.39zM8.924 81.077a3.148 3.148 0 01-.921-2.357v-8.65c0-2.599 2.03-4.797 4.643-5.394l.115-.02 8.268-.971V60.86c-2.5-1.95-3.925-4.988-3.799-8.138v-4.337c0-5.987 4.287-10.843 9.77-10.843s9.77 4.856 9.77 10.843v4.295a9.895 9.895 0 01-3.8 8.18v2.825l8.27.971.114.02c2.614.597 4.643 2.795 4.643 5.394v8.65a3.148 3.148 0 01-.92 2.357 3.156 3.156 0 01-2.361.92H11.284a3.156 3.156 0 01-2.36-.92zm4.152-14.274c-1.656.4-2.902 1.766-2.902 3.267v8.674l-.002.056a.98.98 0 001.032 1.03l.055-.001h31.482l.055.001a.983.983 0 001.032-1.03l-.002-.056V70.07c0-1.5-1.246-2.866-2.902-3.267l-9.166-1.077a1.085 1.085 0 01-.959-1.077v-4.337c0-.356.175-.69.469-.892a7.749 7.749 0 003.33-6.698v-4.337c0-4.846-3.38-8.674-7.598-8.674-4.219 0-7.599 3.828-7.599 8.674v4.38a7.712 7.712 0 003.33 6.654c.294.203.47.536.47.893v4.337c0 .55-.412 1.013-.96 1.077l-9.165 1.077z" />
    </g>
  </svg>
);

export default WorkflowCreateSegment;
