import React from "react";

let NavIn = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M12.252 11.748l-.45 7.208a.7.7 0 1 0 1.397.088l.55-8.792-8.793.55a.7.7 0 1 0 .088 1.397l7.208-.45z" />
      <path d="M2.995 21.995l10-10a.7.7 0 1 0-.99-.99l-10 10a.7.7 0 1 0 .99.99z" />
      <path d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12a.7.7 0 1 0 1.4 0C1.4 6.146 6.146 1.4 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6a.7.7 0 1 0 0 1.4z" />
    </g>
  </svg>
);

export default NavIn;
