import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import OptionalLabel from "./demos/OptionalLabel";
import OptionalStep from "./demos/OptionalStep";
import Error from "./demos/Error";
import Warning from "./demos/Warning"
import Canceled from "./demos/Canceled"
import NonLinear from "./demos/NonLinear";
import Vertical from "./demos/Vertical";
import VerticalNonLinear from "./demos/VerticalNonLinear";
import { NoticePanel } from "resources/Page";

let demoNames = [
  "Simple",
  "OptionalLabel",
  "OptionalStep",
  "Error",
  "Warning",
  "Canceled",
  "NonLinear",
  "Vertical",
  "VerticalNonLinear"
];

export default function Stepper() {
  let demos = generateDemos("core-component-demo/stepper", demoNames);
  return (
    <Page
      components={[
        "Stepper",
        "Step",
        "StepButton",
        "StepContent",
        "StepConnector",
        "StepIcon",
        "StepLabel"
      ]}
    >
      <Title>Stepper</Title>
      <Subtitle>
        Steppers convey progress through numbered steps. It provides a
        wizard-like workflow.
      </Subtitle>
      <Supporting>
        Steppers display progress through a sequence of logical and numbered
        steps. They may also be used for navigation. Steppers may display a
        transient feedback message after a step is saved.
      </Supporting>
      <SectionTitle>Horizontal</SectionTitle>
      <p>
        The Stepper can be controlled by passing the current step index
        (zero-based) as the <code>activeStep</code> property. Actions can be
        attached to Motif affordances such as Buttons to step forward and
        backward.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
      <SectionTitle>Optional Label Text</SectionTitle>
      <p>
        The <code>optional</code> property on the <code>Step</code> refers to
        the optional text that is shown below the step text, not the ability to
        skip a step. 
      </p>
      <NoticePanel variant="warning">
        Keep optional label text concise and clear. If a long description is necessary,
        consider a different affordance to communicate additional information.
      </NoticePanel>
      <Demo demo={demos.OptionalLabel}>
        <OptionalLabel />
      </Demo>
      <SectionTitle>Optional Step</SectionTitle>
      <p>
        Note that it's up to you to manage when an optional step is skipped.
        Once you've determined this for a particular step you must set
        <code>{"completed={false}"}</code> to signify that even though the
        active step index has gone beyond the optional step, it's not actually
        complete.
      </p>
      <Demo demo={demos.OptionalStep}>
        <OptionalStep />
      </Demo>
      <SectionTitle>Error Step</SectionTitle>
      <p>
        A step can be marked as errored by using <code>variant="error"</code> prop.
      </p>
      <Demo demo={demos.Error}>
        <Error />
      </Demo>
      <SectionTitle>Non-Linear Stepper</SectionTitle>
      <p>
        Non-linear steppers allow users to enter a multi-step flow at any point.
      </p>
      <p>
        This example is similar to the regular horizontal stepper, except steps
        are no longer automatically set to <code>{"disabled={true}"}</code>{" "}
        based on the <code>activeStep</code> property.
      </p>
      <p>
        The use of the <code>StepButton</code> here demonstrates clickable step
        labels, as well as setting the <code>completed</code> flag. However
        because steps can be accessed in a non-linear fashion, it's up to your
        own implementation to determine when all steps are completed (or even if
        they need to be completed). To suppress default numbers featured in the
        <code>StepIcon</code> use the <code>hideStepNum</code> prop in the <code>StepButton</code>.
      </p>
      <Demo demo={demos.NonLinear}>
        <NonLinear />
      </Demo>
      <SectionTitle>Vertical Stepper</SectionTitle>
      <p>
        To create a vertical stepper use <code>orientation="vertical"</code>.
      </p>
      <Demo demo={demos.Vertical}>
        <Vertical />
      </Demo>
      <SectionTitle>Vertical Non-Linear Stepper</SectionTitle>
      <p>
        To create a non-linear vertical stepper use <code>orientation="vertical"</code> and
        use the non-linear props. 
      </p>
      <Demo demo={demos.VerticalNonLinear}>
        <VerticalNonLinear />
      </Demo>
      <SectionTitle>Warning</SectionTitle>
      <p>
        A step can be marked as warned by using the <code>variant="warning"</code> prop.
      </p>
      <Demo demo={demos.Warning}>
        <Warning />
      </Demo>

      <SectionTitle>Canceled</SectionTitle>
      <p>
        A step can be marked as canceled by using the <code>variant="canceled"</code> prop.
      </p>
      <Demo demo={demos.Canceled}>
        <Canceled />
      </Demo>
    </Page>
  );
}
