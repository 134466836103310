import React, { useState } from "react";
import { Route, Switch as RouterSwitch, useRouteMatch } from "react-router-dom";
import { SectionTitle } from "../../resources/DemoPage";

import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Footer from "@liveramp/motif/core/Footer";
import FormControl from "@liveramp/motif/core/FormControl";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import FormGroup from "@liveramp/motif/core/FormGroup";
import FormLabel from "@liveramp/motif/core/FormLabel";
import GlobalAdminDemo from "./GlobalAdminDemo";
import GlobalNavDemo from "./GlobalNavDemo";
import IconButton from "@liveramp/motif/core/IconButton";
import Switch from "@liveramp/motif/core/Switch";

import { Favorite, Settings, User } from "@liveramp/motif/icons";
import CodeChunk from "../../resources/CodeChunk";
import css from "./index.module.css";

function DemoPage({ title }) {
  return <h3>{title}</h3>;
}

export default function Sandbox() {
  const [navigationDemo, setNavigationDemo] = useState(false);
  const [globalNavDemo, setGlobalNavDemo] = useState(false);
  const [globalAdminDemo, setGlobalAdminDemo] = useState(false);
  const [globalNavDisplayLevel, setGlobalNavDisplayLevel] = useState(false);

  const [customStyling, setCustomStyling] = useState(false);
  const [footerOpen, setFooterOpen] = useState(true);
  const [isAltNav, setIsAltNav] = useState(false);
  const [altNavHasBusinessObjects, setAltNavHasBusinessObjects] =
    useState(true);

  const match = useRouteMatch();

  return (
    <div className={css.container}>
      <GlobalNavDemo
        match={match}
        applyCustomStyling={customStyling}
        setGlobalNavDisplayLevel={setGlobalNavDisplayLevel}
        isAltNav={isAltNav}
        altNavHasBusinessObjects={altNavHasBusinessObjects}
      />
      <GlobalAdminDemo />
      <main>
        <RouterSwitch>
          <Route exact path={match.path}>
            <h2>Global Navigation & Global Admin</h2>
            <ul>
              <li>
                The GlobalNavigation component is demonstrated at left, and the
                Global Admin buttons are on the upper right.
              </li>
              <li>
                The GlobalNavigation component must be contained within a{" "}
                <code>div</code> with <code>display: flex</code>.
              </li>
              <li>
                Please report bugs by following the{" "}
                <a href="https://liveramp.atlassian.net/wiki/spaces/CI/pages/1280442773/AX+Bug+Process">
                  AX bug process
                </a>
                .
              </li>
            </ul>

            <SectionTitle>Global Nav Implementation</SectionTitle>
            <p>The Global Nav component takes 3 primary props.</p>
            <ul>
              <li>
                The <code>navigation</code> prop defines most of the nav
                content. It is described in more detail below.
              </li>
              <li>
                The <code>accountSwitcher</code> prop defines options and the
                callback for selecting the active account.
              </li>
              <li>
                The <code>customisation</code> prop allows certain styling and
                branding to be set.
              </li>
            </ul>
            <p>
              There is a fourth optional prop <code>openOnLoad</code> which will
              cause the menu to open to the L3 by default when the page loads.
            </p>
            <p>
              There is a fifth optional prop <code>defaultActiveOnL3</code>, a
              boolean which will cause the L3 to be set to active on click.
            </p>
            <p>
              There is a sixth optional prop{" "}
              <code>setGlobalNavDisplayLevel</code>
              which takes a function that will be called with the display level
              via <code>useEffect</code>:{" "}
              <code>setGlobalNavDisplayLevel(displayLevel)</code>
            </p>
            <p>
              See an implementation of the <code>GlobalNavigation</code>{" "}
              component below.
            </p>
            <p>
              There are seventh and eighth optional props
              <code>onOpen</code> and <code>onClose</code>. These functions will
              be triggered on the GN open and close events.
            </p>
            <p>
              Any additional props provided will be spread to the{" "}
              <code>nav</code> element.
            </p>
            <div className={css.demoButton}>
              <Button
                variant="secondary"
                color="primary"
                onClick={() => {
                  setGlobalNavDemo(!globalNavDemo);
                }}
              >
                Example Code
              </Button>
            </div>
            {globalNavDemo && (
              <CodeChunk
                language={"jsx"}
                style={{
                  whiteSpace: "pre-wrap"
                }}
              >
                {require(`!!raw-loader!./GlobalNavDemo.jsx`)}
              </CodeChunk>
            )}

            <SectionTitle>Flexible Navigation Levels</SectionTitle>
            <ul>
              <li>
                Level 1 (L1) is the left-most vertical bar. It contains the logo
                at the top, then the Account Switcher below that, and finally
                one or more icons with tooltips representing top level
                navigation items. In this demo, "Data Marketplace" is an example
                of an L1 item with no descendents. Optionally, a second set of
                icons can be positioned at the bottom of the bar.
              </li>
              <li>
                Level 2 (L2) is a temporary surface, emerging when an L1 item is
                clicked. The L2 surface is optional. Here, clicking "My
                Subscriptions" opens the L2. The L2 includes the company name at
                top, then the account name, and then the L2 navigation items.
              </li>
              <li>
                Level 3 (L3) is a temporary surface that can emerge from an L1
                or L2. Here, clicking on the L2 children of "My Subscriptions"
                or the "Safe Haven" L1 opens examples of L3 surfaces.
              </li>
            </ul>
            <p>
              The levels are defined by a navigation prop. The example code
              below demonstrates how a navigation object is constructed.
            </p>

            <div className={css.demoButton}>
              <Button
                variant="secondary"
                color="primary"
                onClick={() => {
                  setNavigationDemo(!navigationDemo);
                }}
              >
                Example Code
              </Button>
            </div>
            {navigationDemo && (
              <CodeChunk
                language={"jsx"}
                style={{
                  whiteSpace: "pre-wrap"
                }}
              >
                {require(`!!raw-loader!./navigation.js`)}
              </CodeChunk>
            )}

            <SectionTitle>Routing and Navigation</SectionTitle>
            <p>
              Each node in the navigation object can take two properties to
              control routing/navigation behavior:
            </p>
            <ol>
              <li>
                <code>route</code>
                <p>
                  Must be a string or a function that returns a string. The
                  string can be a relative or an absolute URL. If no
                  `onNavigate` hook is provided, clicking the node will cause a
                  full page load of the provided URL. If an `onNavigate` hook is
                  provided, clicking the node will push the provided route onto
                  the `history` stack and will execute the hook function with
                  the provided route as first argument.
                </p>
              </li>
              <li>
                <code>onNavigate</code>
                <p>
                  Must be a `void` function. Receives the URL to navigate to as
                  the first argument. When present, this function is executed in
                  response to click events on the node. Use this option when
                  behavior other than a full page load of the provided URL is
                  desired.
                </p>
              </li>
            </ol>

            <SectionTitle>Account and Country Selection</SectionTitle>
            <p>
              See the <a href={"/api/global-navigation"}>API</a> for details on
              the Account Switcher.
            </p>
            <p>
              Safe Haven has Country Selection in this demo. Country selection
              is defined by the navigation prop, please see the Safe Haven entry
              in the example above.
            </p>

            <SectionTitle>Global Admin Buttons</SectionTitle>
            <p>
              Global Admin buttons, in the upper right, are demoed here for
              notifications, technical docs (help), and user management.
            </p>
            <div className={css.demoButton}>
              <Button
                variant="secondary"
                color="primary"
                onClick={() => {
                  setGlobalAdminDemo(!globalAdminDemo);
                }}
              >
                Example Code
              </Button>
            </div>
            {globalAdminDemo && (
              <CodeChunk
                language={"jsx"}
                style={{
                  whiteSpace: "pre-wrap"
                }}
              >
                {require(`!!raw-loader!./GlobalAdminDemo.jsx`)}
              </CodeChunk>
            )}

            <SectionTitle>More</SectionTitle>
            <form>
              <div>
                <FormControl component="fieldset">
                  <p>
                    The logo, logo link, and some styling aspects can be
                    customised with the customisation prop. See the{" "}
                    <a href={"/api/global-navigation"}>API</a> for details.
                  </p>
                  <FormGroup>
                    <FormLabel>Toggle Customization</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={customStyling}
                          onChange={() => setCustomStyling(!customStyling)}
                        />
                      }
                      label="Enable"
                    />
                  </FormGroup>
                </FormControl>
              </div>
              <div>
                <FormControl component="fieldset">
                  <p>
                    Most pages with tables will have a side panel or footer
                    accompanying them. Toggle the switches to enable them.
                  </p>
                  <FormGroup>
                    <FormLabel>Toggle Footer</FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={footerOpen}
                          onChange={() => setFooterOpen(!footerOpen)}
                        />
                      }
                      label="Enable"
                    />
                    {/* <FormLabel>Toggle Side Panel</FormLabel>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={footerOpen}
                        onChange={() => setFooterOpen(!footerOpen)}
                      />
                    }
                    label="Enable"
                  /> */}
                  </FormGroup>
                </FormControl>
              </div>
              <div>
                <FormControl component="fieldset">
                  <p>Alternate nav to match upcoming universal nav</p>
                  <FormGroup>
                    <FormLabel>
                      Toggle <code>isAltNav</code>
                    </FormLabel>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isAltNav}
                          onChange={() => setIsAltNav(!isAltNav)}
                        />
                      }
                      label="Enable"
                    />
                    {isAltNav && (
                      <>
                        <p>
                          Display business object data (Audiences, Destination
                          Accounts)
                        </p>
                        <FormLabel>
                          Toggle <code>altNavHasBusinessObjects</code>
                        </FormLabel>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={altNavHasBusinessObjects}
                              onChange={() =>
                                setAltNavHasBusinessObjects(
                                  !altNavHasBusinessObjects
                                )
                              }
                            />
                          }
                          label="Enable"
                        />
                      </>
                    )}
                  </FormGroup>
                </FormControl>
              </div>
            </form>

            <Footer
              open={footerOpen}
              globalNavDisplayLevel={globalNavDisplayLevel}
              onClick={() => setFooterOpen(!footerOpen)}
            >
              <ButtonFormGroup>
                <IconButton>
                  <Settings />
                </IconButton>
                <IconButton>
                  <User />
                </IconButton>
                <IconButton>
                  <Favorite />
                </IconButton>
              </ButtonFormGroup>
            </Footer>
          </Route>
          <Route path={`${match.path}/*`}>
            {({ match }) => <DemoPage title={match.url} />}
          </Route>
        </RouterSwitch>
      </main>
    </div>
  );
}
