/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiFormHelperText",
  description: "",
  displayName: "FormHelperText",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description:
        "The content of the component.\r\n\r\nIf `' '` is provided, the component reserves one line height for displaying a future message."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'p'", computed: false }
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the helper text should be displayed in a disabled state."
    },
    error: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, helper text should be displayed in an error state."
    },
    filled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the helper text should use filled classes key."
    },
    focused: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the helper text should use focused classes key."
    },
    margin: {
      type: { name: "enum", value: [{ value: "'dense'", computed: false }] },
      required: false,
      description:
        "If `dense`, will adjust vertical spacing. This is normally obtained via context from\r\nFormControl."
    },
    required: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the helper text should use required classes key."
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'filled'", computed: false },
          { value: "'outlined'", computed: false },
          { value: "'standard'", computed: false }
        ]
      },
      required: false,
      description: "The variant to use."
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    error: {
      description: "Pseudo-class applied to the root element if `error={true}`."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    marginDense: {
      description: 'Styles applied to the root element if `margin="dense"`.'
    },
    contained: {
      description:
        'Styles applied to the root element if `variant="filled"` or `variant="outlined"`.'
    },
    focused: {
      description:
        "Pseudo-class applied to the root element if `focused={true}`."
    },
    filled: {
      description:
        "Pseudo-class applied to the root element if `filled={true}`."
    },
    required: {
      description:
        "Pseudo-class applied to the root element if `required={true}`."
    }
  }
};
export default generatedDocs;
