import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import Button from "@liveramp/motif/core/Button";
import { columns, rows } from "../../demos/data";

export default function DownloadDemo() {
  const downloadFunctionality = () => {
    const url =
      "https://upload.wikimedia.org/wikipedia/commons/1/18/Classic_bubble_tea.jpg";

    fetch(url).then(response =>
      response.blob().then(imgBlob => {
        const a = document.createElement("a");
        a.href = URL.createObjectURL(imgBlob);
        a.setAttribute("download", "classic_bubble_tea.jpg");
        a.click();
      })
    );
  };

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      toolbarActions={({ apiRef }) => (
        <>
          <Button onClick={downloadFunctionality}>Fetch Download Demo</Button>
          <Button onClick={() => apiRef.current.exportDataAsCsv()}>
            Download Data as CSV
          </Button>
        </>
      )}
      toolbarProps={{ end: true }}
    />
  );
}
