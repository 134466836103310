import React, { useState } from "react";
import { Subtitle, Supporting, NoticePanel } from "../../resources/Page";
import Demo, { generateDemos } from "../../resources/Demo";
import ColorPalette from "./ColorPalette";
import Alert from "@liveramp/motif/core/Alert";
import CodeChunk from "../../resources/CodeChunk";
import Chip from "@liveramp/motif/core/Chip";
import reporting1 from "@liveramp/motif/colors/reporting1";
import reporting2 from "@liveramp/motif/colors/reporting2";
import theme from "@liveramp/motif/theme";
import css from "./index.module.css";

import ColorManipulation from "./demos/ColorManipulation";

let demoNames = ["ColorManipulation"];

export default function Colors() {
  let demos = generateDemos("style/colors", demoNames);
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(theme.palette.info.main);

  let setStates = (open, color) => {
    setOpen(open);
    setColor(color);
  };

  let handleOpen = color => {
    if (open) {
      handleClose();
      setTimeout(() => setStates(true, color), 250);
    } else {
      setStates(true, color);
    }
  };

  let handleClose = (event, reason) => {
    if (reason === "clickaway") return;
    setOpen(false);
  };

  let ChipStatus = ({ label, bgColor }) => {
    return (
      <Chip
        label={label}
        style={{
          backgroundColor: bgColor,
          color: theme.palette.grey[800]
        }}
      />
    );
  };

  return (
    <div>
      <h1>Colors</h1>
      <Subtitle>
        Convey meaning through color. Out of the box you get access to all the
        colors in the LiveRamp design spec
      </Subtitle>
      <Supporting>
        The Motif color theme has been created to reflect the LiveRamp brand and
        style. The core colors have been emphasized and should be the primary
        colors used in your application.
      </Supporting>
      <h3 style={{ color: theme.palette.secondary.dark }}>Important Terms</h3>
      <p>
        <b>Palette</b>: a collection of colors, i.e. hues and their shades.
        Motif provides all colors from the LiveRamp design guidelines. This
        color palette has been designed to work harmoniously with each other.
        Palette color are available to consume as variables i.e. to access the
        primary green colors main shade without hardcoding the hex, use{" "}
        <code>theme.palette.primary.main</code> or directly from the green HUE
        object, <code>green[500]</code>.
      </p>
      <p>
        <b>Hue and Shade</b>: A single color within the palette is made up of a
        hue such as "green", and a shade, such as "500". <code>green[50]</code>{" "}
        is the lightest shade of green, while <code>green[900]</code> is the
        darkest.
      </p>
      <h3 style={{ color: theme.palette.secondary.dark }}>Color Palette</h3>
      <p>
        Given a <code>HUE</code> (green, grey, etc.) and a <code>SHADE</code>{" "}
        (500, 600, etc.) you can access the color in javascript as an array.
      </p>
      <CodeChunk>
        {`import HUE from "@liveramp/motif/colors/HUE";
        let color = HUE[SHADE];

        // translates to
        import darkBlue from "@liveramp/motif/colors/darkBlue";
        let blueColor = darkBlue[500];
        `}
      </CodeChunk>
      <p>
        The colors are also available as variables via CSS Modules in
        <code>@liveramp/motif/colors/colors.module.css</code>:
      </p>
      <CodeChunk css>
        {`@value HUESHADE from "~@liveramp/motif/colors/colors.module.css";
        body {
          color: HUESHADE;
        }

        /* translates to */
        @value darkBlue500 from "~@liveramp/motif/colors/colors.module.css";
        body {
          color: darkblue500;
        }
        `}
      </CodeChunk>
      <p>
        and with SASS also using{" "}
        <code>@liveramp/motif/colors/colors.module.css</code>:
      </p>
      <CodeChunk sass>
        {`@import "~@liveramp/motif/colors/colors.module";
          body
            color: HUESHADE

          /* translates to */
          body
            color: darkBlue500
        `}
      </CodeChunk>
      <p>
        and with vanilla CSS in <code>@liveramp/motif/colors/colors.css</code>:
      </p>
      <h5>Javascript</h5>
      <CodeChunk>
        {`/* You only need to import this once in your project, it will create the variables at the :root DOM level */
        import "@liveramp/motif/colors/colors.css";`}
      </CodeChunk>
      <h5>CSS</h5>
      <CodeChunk css>
        {`body {
          color: var(--HUESHADE);
        }

        /* translates to */
        body {
          color: var(--darkBlue500);
        }`}
      </CodeChunk>
      <h3 style={{ color: theme.palette.secondary.dark }}>
        Main Color Palette
      </h3>
      <div className={css.colors}>
        <div className={css.color}>
          <div>
            <ChipStatus
              label="Primary Green"
              bgColor={theme.palette.primary.light}
            />
            <p>
              Primary palette <code> theme.palette.primary.VARIANT</code>
              or <code>green[SHADE]</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.primary} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus
              label="Secondary Grey"
              bgColor={theme.palette.secondary.light}
            />
            <p>
              Secondary palette <code> theme.palette.secondary.VARIANT</code>
              or <code>grey[SHADE]</code>
            </p>
          </div>
          <ColorPalette
            color={theme.palette.secondary}
            handleOpen={handleOpen}
          />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus
              label="Basics"
              bgColor={theme.palette.secondary.light}
            />
            <p>
              For black and white use the common palette
              <code> theme.palette.common.VARIANT</code>
            </p>
          </div>
          <ColorPalette
            color={theme.palette.common}
            variant="common"
            handleOpen={handleOpen}
          />
        </div>
      </div>
      <h3 style={{ color: theme.palette.secondary.dark }}>
        Status/Alert Colors
      </h3>
      <div className={css.colors}>
        <div className={css.color}>
          <div>
            <ChipStatus label="Success" bgColor={theme.palette.success.light} />
            <p>
              The success color should be used to alert the user that a process
              has been completed successfully and is in a positive state.{" "}
              <code>theme.palette.success.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.success} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus label="Info" bgColor={theme.palette.info.light} />
            <p>
              The info color should be used for general info and details that
              can help guide a user through a workflow/process. It can also be
              used to show an active state of a process being completed
              passively by one of our systems.{" "}
              <code>theme.palette.info.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.info} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus
              label="Disabled"
              bgColor={theme.palette.disabled.main}
            />
            <p>
              The disabled grey should be used to show that an action, workflow
              or process has been disabled.{" "}
              <code>theme.palette.disabled.VARIANT</code>
            </p>
          </div>
          <ColorPalette
            color={theme.palette.disabled}
            handleOpen={handleOpen}
          />
        </div>
      </div>
      <div className={css.colors}>
        <div className={css.color}>
          <div>
            <ChipStatus label="Danger" bgColor={theme.palette.danger.light} />

            <p>
              The danger color should be used to alert the user of a blocking
              issue or an error that will interrupt the workflow, or a dangerous
              action such as deletion. <code>theme.palette.danger.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.danger} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus label="Pending" bgColor={theme.palette.pending.light} />

            <p>
              The pending color should be used to alert the user that a process
              is awaiting action from them before it can continue in the
              workflow. <code>theme.palette.pending.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.pending} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus label="Warning" bgColor={theme.palette.warning.light} />
            <p>
              The warning color should be used to alert the user of a
              non-blocking issue or error that has occurred, with the potential
              to continue moving through the workflow or process.
              <code>theme.palette.warning.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.warning} handleOpen={handleOpen} />
        </div>
        <div className={css.color}>
          <div>
            <ChipStatus label="Privacy" bgColor={theme.palette.privacy.light} />
            <p>
              The privacy color should be used to alert the user of a privacy
              related message, use in accordance with design guidance.
              <code>theme.palette.privacy.VARIANT</code>
            </p>
          </div>
          <ColorPalette color={theme.palette.privacy} handleOpen={handleOpen} />
        </div>
      </div>
      <h3 style={{ color: theme.palette.secondary.dark }}>Reporting Colors</h3>
      <p>
        These reporting color palettes have been created to be used solely in
        graphical representations of data where multiple different colors are
        required.
        <b>
          These colors are still pending review and may be modified at any time
          without prior notice.
        </b>
      </p>
      <p>
        Data sets should use these colors in order starting from 50, and ending
        at 900. Repeat the color series if necessary. Do not mix these colors
        with any other sets and do not use them out of order.
      </p>
      <p style={{ color: reporting1[400] }}>Reporting1</p>
      <ColorPalette color={reporting1} variant="full" handleOpen={handleOpen} />
      <p style={{ color: reporting2[400] }}>Reporting2</p>
      <ColorPalette color={reporting2} variant="full" handleOpen={handleOpen} />
      <h3 style={{ color: theme.palette.secondary.dark }}>
        Color Manipulation
      </h3>
      <NoticePanel variant="warning">
        <p>
          There are many more shades and functional colors available that are
          not listed on this page. Hues and shades can be found{" "}
          <a href="https://github.com/LiveRamp/motif/blob/master/lib/src/styles/colors/colors.css">
            here
          </a>{" "}
          and functional colors (e.g. active/focus/hover opacities) in the{" "}
          <a href="https://github.com/LiveRamp/motif/blob/master/lib/src/styles/theme.js">
            theme
          </a>{" "}
          object. Please ensure that none of these meet your requirements before
          adding custom colors.
        </p>
      </NoticePanel>
      <p>
        Motif provides three functions to adjust colors depending on your needs.
      </p>
      <ul>
        <li>
          <code>alpha(color, 0..1)</code>
          <br />
          Applies the specified percentage opacity to the color
        </li>
        <li>
          <code>lighten(color, 0..1)</code>
          <br />
          Lightens the color by the specified percentage
        </li>
        <li>
          <code>darken(color, 0..1)</code>
          <br />
          Darkens the color by the specified percentage
        </li>
      </ul>
      <Demo demo={demos.ColorManipulation}>
        <ColorManipulation />
      </Demo>
      <Alert open={open} onClose={handleClose}>
        <code
          style={{
            borderColor: color,
            backgroundColor: color,
            color: theme.palette.getContrastText(color),
            fontSize: "0.75rem",
            padding: "4px 6px",
            marginRight: "4px"
          }}
        >
          {color}
        </code>
        copied to clipboard.
      </Alert>
    </div>
  );
}
