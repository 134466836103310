import React, { useLayoutEffect, useState } from "react";
import chroma from "chroma-js";
import makeStyles from "../../styles/makeStyles";

export default function GlobalAdmin(props) {
  const [scrollY, setScrollY] = useState(window.scrollY);
  useStyles();

  const handleScroll = () => setScrollY(window.scrollY);

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="GlobalAdmin GlobalAdmin-root"
      {...props}
      // NB: this pushes the global admin bar down 1px to prevent the blur from
      // picking up the color from the bookmarks bar
      // https://liveramp.atlassian.net/browse/AX-1248?focusedCommentId=582457
      style={{ top: `${scrollY + 1}px` }}
    />
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".GlobalAdmin": {
      position: "absolute",
      right: "24px",
      display: "flex",
      gap: "24px",
      padding: "16px",
      backgroundColor: chroma(theme.palette.common.white).alpha(0.8).css(),
      zIndex: theme.zIndex.appBar,
      borderRadius: "0 0 24px 24px",

      "@supports (backdrop-filter: blur(8px))": {
        backgroundColor: "transparent",
        backdropFilter: "blur(8px)"
      }
    }
  }
}));

import MotifGlobalAdmin from "./GlobalAdmin";
import GAButton from "./GAButton";
import GANotificationsButton from "./GANotificationsButton";

export { MotifGlobalAdmin as GlobalAdmin, GAButton, GANotificationsButton };
