import React from "react";
import Breadcrumbs from "@liveramp/motif/core/Breadcrumbs";

function handleClick(event) {
  event.preventDefault();
  alert("You clicked a breadcrumb!");
}

export default function Simple() {
  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <a href="/">MUI</a>
        <a href="/getting-started/installation/">Core</a>
        <span>Breadcrumbs</span>
      </Breadcrumbs>
    </div>
  );
}
