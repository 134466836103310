const overrideDocs = {
  props: {
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'success'" },
          { value: "'pending'" },
          { value: "'running'" },
          { value: "'canceled'" },
          { value: "'error'" },
          { value: "'revoked'" }
        ]
      },
      required: true,
      description: "The variant to use."
    }
  }
};
  
export default overrideDocs;
