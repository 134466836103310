let overrideDocs = {
  props: {
    fixedValues: {
      type: { name: "array" },
      required: false,
      description:
        "Array of default values that are stickied to the start of a multi select and cannot be removed or cleared. The chips displayed are in a disabled state but are still submitted in a form.",
      defaultValue: { value: "[]", computed: "false" }
    }
  }
};

export default overrideDocs;
