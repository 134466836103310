import React from "react";
import Uploader from "@liveramp/motif/core/Uploader";

const POST_URL = "https://httpbin.org/post";

export default function FileValidation() {
  return (
    <Uploader
      id="multi-uploader"
      multiple
      url={POST_URL}
      validator={file => {
        const nameSplit = file.name.split(".");
        const extension = nameSplit[nameSplit.length - 1];

        if (extension === "txt")
          return { message: "We don't accept .txt files" };
        if (file.name.length > 22) return { message: "Name is too long" };
        return null;
      }}
    />
  );
}
