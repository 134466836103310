import React from "react";
import clsx from "clsx";
import MuiStepIcon from "@material-ui/core/StepIcon";
import { makeStyles, mergeClasses } from "../../utils";
import { StepIconStyle } from "../../utils/styleKeys";
import Check from "../../icons/Check";
import WarningCircle from "../../icons/WarningCircle";
import WarningCircleOutline from "../../icons/WarningCircleOutline";
import RemoveCircle from "../../icons/RemoveCircle";
import RemoveCircleOutline from "../../icons/RemoveCircleOutline";

const STEP_STATUS = {
  ERROR: "error",
  WARNING: "warning",
  CANCELED: "canceled",
  COMPLETED: "completed"
};

export default function StepIcon({ classes, icon, ...props }) {
  const defaultClasses = useStyles();
  const hasError = props.variant === STEP_STATUS.ERROR || props.error;
  const hasCompleted =
    props.variant === STEP_STATUS.COMPLETED || props.completed;
  const className = (others = {}) =>
    clsx(defaultClasses.root, {
      [defaultClasses.active]: props.active,
      [defaultClasses.error]: hasError,
      [defaultClasses.completed]: hasCompleted,
      ...others
    });

  const determineIcon = () => {
    const { completed, error, hideStepNum, variant } = props;

    if (typeof icon === "object") {
      return React.cloneElement(icon, { className: className() });
    }

    if (variant) {
      switch (variant) {
        case STEP_STATUS.ERROR:
          return <ErrorIcon className={className()} />;
        case STEP_STATUS.WARNING: {
          const warningClasses = warningStyles();
          return (
            <WarningIcon
              active={props.active}
              className={clsx({ [warningClasses.warning]: true })}
              fill="#FA8D28"
              stroke="#FA8D28"
            />
          );
        }
        case STEP_STATUS.CANCELED: {
          const cancelledClasses = cancelledStyles();
          return (
            <CanceledIcon
              active={props.active}
              className={clsx({ [cancelledClasses.canceled]: true })}
            />
          );
        }
        case STEP_STATUS.COMPLETED: {
          return <Check className={className()} />;
        }
      }
    }

    if (error) {
      return <ErrorIcon className={className()} />;
    }

    if (completed) {
      return <Check className={className()} />;
    }

    return <div className={className()}>{hideStepNum ? null : icon}</div>;
  };

  return (
    <MuiStepIcon
      classes={mergeClasses(defaultClasses, classes)}
      icon={determineIcon()}
      {...props}
    />
  );
}

const ErrorIcon = props => <div {...props}>!</div>;

const WarningIcon = ({ active, ...rest }) =>
  active ? <WarningCircle {...rest} /> : <WarningCircleOutline {...rest} />;

const CanceledIcon = ({ active, ...rest }) =>
  active ? <RemoveCircle {...rest} /> : <RemoveCircleOutline {...rest} />;

const useStyles = makeStyles(
  theme => ({
    ...StepIconStyle,
    root: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      maxHeight: "28px",
      maxWidth: "28px",
      border: `2px solid ${theme.palette.grey[500]}`,
      borderRadius: "50%",
      background: theme.palette.common.white,
      color: theme.palette.grey[500],
      "&:not(.MuiSvgIcon-root)": {
        height: "28px",
        width: "28px"
      }
    },
    active: {
      "&.MuiStepIcon-root": {
        color: theme.palette.common.white,
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        "&.Mui-error": {
          color: theme.palette.common.white,
          borderColor: theme.palette.error.main,
          background: theme.palette.error.main
        }
      }
    },
    error: {
      "&.MuiStepIcon-root": {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main
      }
    },
    completed: {
      "&.MuiStepIcon-root": {
        borderColor: theme.palette.primary.main
      }
    }
  }),
  { name: "MuiStepIcon" }
);

const warningStyles = makeStyles(
  theme => ({
    warning: {
      fontSize: "2em",
      fill: "#FA8D28",
      stroke: "#FA8D28"
    }
  }),
  { name: "MuiStepIcon" }
);

const cancelledStyles = makeStyles(
  theme => ({
    canceled: {
      fontSize: "2em",
      fill: theme.palette.disabled.main
    }
  }),
  { name: "MuiStepIcon" }
);
