import React, { useState } from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepLabel from "@liveramp/motif/core/StepLabel";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Button from "@liveramp/motif/core/Button";
import css from "../../../resources/Page.module.css";

export default function SimpleDemo() {
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={css.fullWidth}>
      <Stepper activeStep={activeStep}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <ButtonFormGroup>
        {activeStep === steps.length ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              Back
            </Button>
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </>
        )}
      </ButtonFormGroup>
    </div>
  );
}

function getSteps() {
  return ["Step One", "Step Two", "Step Three"];
}
