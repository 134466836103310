import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Collapse from "../../core-components/Collapse";
import CaretDown from "../../icons/CaretDown";
import makeStyles from "../../styles/makeStyles";
import { ButtonBase } from "@material-ui/core";

export default function SidePanelSubsection({
  className,
  children,
  open: openProp = true,
  title,
  ...props
}) {
  const [open, setOpen] = useState(openProp);
  useStyles();

  return (
    <div
      className={clsx(
        "SidePanelSubsection",
        "SidePanelSubsection-root",
        className
      )}
      {...props}
    >
      <ButtonBase
        className="SidePanelSubsection__title SidePanelSubsection-title"
        onClick={() => setOpen(!open)}
        aria-label="Toggle Subsection"
      >
        {title}
        <CaretDown
          className={clsx("SidePanelSubsection__caret", {
            ["SidePanelSubsection__caret--isOpen"]: open
          })}
        />
      </ButtonBase>
      <Collapse
        className="SidePanelSubsection__content SidePanelSubsection-collapse"
        in={open}
      >
        {children}
      </Collapse>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".SidePanelSubsection": {
      // .SidePanelSubsection__title
      "&__title": {
        ...theme.typography.subtitle1,
        backgroundColor: theme.palette.grey[100],
        borderRadius: "4px",
        padding: "8px 16px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        marginBottom: "16px"
      },
      // .SidePanelSubsection__caret
      "&__caret": {
        transition: theme.transitions.common.short,

        // .SidePanelSubsection__caret--isOpen
        "&&--isOpen": {
          transform: "rotate(180deg)"
        }
      },
      // .SidePanelSubsection__content
      "&__content": {
        backgroundColor: theme.palette.common.white,
        padding: "0 16px"
      }
    }
  }
}));

SidePanelSubsection.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};
