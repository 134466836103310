import React from "react";
import Header from "@liveramp/motif/core/Header";

export default function SimpleDemo() {
  return (
    <div style={{ width: "90%" }}>
      <Header>Hello World!</Header>
    </div>
  );
}
