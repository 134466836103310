import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Button from "@liveramp/motif/core/Button";
import { columns, rows } from "../../demos/data";

export default function SlotsDGFooterCustomSelectAll() {
  const footer = () => (
    <ButtonFormGroup>
      <Button>Action 1</Button>
      <Button variant="outlined">Action 2</Button>
    </ButtonFormGroup>
  );

  return (
    <RichDataTable
      rows={rows}
      columns={columns}
      footerActions={footer}
      footerProps={{
        selectAllCount: 234185,
        selectAllHandler: () => alert("selectAllHandler called"),
        deselectAllHandler: () => alert("deselectAllHandler called")
      }}
    />
  );
}
