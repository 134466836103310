import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MuiIconButton from "@material-ui/core/IconButton";
import makeStyles from "../../styles/makeStyles";
import { IconButtonStyle } from "../../utils/styleKeys";
import lightPalette from "../../styles/colors/light";
import darkPalette from "../../styles/colors/dark";

export default function IconButton(props) {
  let { className, onClick, light, green, small, ...etc } = props;
  let classes = useStyles(props);
  let [active, setActive] = useState(false);
  let timeoutIds = useRef([]);

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms);
    timeoutIds.current.push(timeoutId);
  };

  let handleClick = e => {
    e.persist();
    setActive(true);
    if (typeof onClick === "function") onClick(e);
  };

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout);
  };

  useEffect(() => {
    if (active) {
      registerTimeout(() => setActive(false), 250);
    }
    return cleanup;
  });

  return (
    <MuiIconButton
      classes={classes}
      className={clsx(
        {
          [active]: "Mui-active"
        },
        className
      )}
      onClick={handleClick}
      // TODO: next major release, remove the small prop. This will take lesser
      // precedence to the size prop.
      size={small ? "small" : "medium"}
      {...etc}
    />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...IconButtonStyle,
    root: {
      padding: "6px",
      "& svg": {
        fill: props => {
          if (props.green) return theme.palette.primary.main;
          if (props.light) return lightPalette.action.active;
          return darkPalette.action.active;
        }
      },
      "&.Mui-disabled svg": {
        fill: props =>
          props.light
            ? lightPalette.action.disabled
            : darkPalette.action.disabled
      }
    },
    sizeSmall: {
      padding: "4px",
      "& svg": {
        fontSize: "1.25rem"
      }
    }
  }),
  { name: "MuiIconButton" }
);

IconButton.propTypes = Object.assign({}, MuiIconButton.propTypes, {
  green: PropTypes.bool,
  light: PropTypes.bool,
  small: PropTypes.bool
});

IconButton.defaultProps = Object.assign({}, MuiIconButton.defaultProps, {
  className: "",
  green: false,
  light: false,
  small: false
});
