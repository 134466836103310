import React from "react";
import clsx from "clsx";
import makeStyles from "../../styles/makeStyles";

export default function LogoCardMedia({ className, ...props }) {
  useStyles();
  return (
    <div
      data-testid="LogoCardMedia"
      className={clsx("LogoCardMedia", "LogoCardMedia - root", className)}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".LogoCardMedia": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "68px",
      padding: "12px 14px 0", // -2px LR for border

      "& > *": {
        maxHeight: "56px",
        maxWidth: "96px",
        ".LogoCard-large &": {
          maxWidth: "128px"
        }
      },

      "& > svg": {
        minHeight: "56px",
        maxHeight: "56px",
        minWidth: "96px",
        maxWidth: "96px",

        ".LogoCard-large &": {
          minWidth: "128px",
          maxWidth: "128px"
        }
      }
    }
  }
}));
