import React from "react";
import PropTypes from "prop-types";
import IconButton from "../IconButton";
import CaretLeft from "../../icons/CaretLeft";
import css from "./index.module.css";
import clsx from "clsx";

function Header({
  children,
  helpText,
  onClickBackArrow,
  rightContent,
  rightContentText,
  subtitle
}) {
  let caretEnabled = onClickBackArrow ? css.header : "";

  return (
    <div className={clsx(caretEnabled, css.headerRoot)}>
      {onClickBackArrow && (
        <IconButton className={css.caret} onClick={onClickBackArrow}>
          <CaretLeft />
        </IconButton>
      )}
      <div className={css.headerGroup}>
        <h1 className={css.title}>{children}</h1>
        <span className={css.subtitle}>{subtitle}</span>
        <span
          className={rightContentText ? css.rightContentText : css.rightContent}
        >
          {rightContent}
        </span>
      </div>
      <div className={css.helpText}>{helpText}</div>
    </div>
  );
}

Header.propTypes = {
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  rightContent: PropTypes.any,
  rightContentText: PropTypes.bool,
  helpText: PropTypes.string,
  onClickBackArrow: PropTypes.func
};

Header.defaultProps = {
  subtitle: "",
  rightContent: "",
  rightContentText: false,
  helpText: "",
  onClickBackArrow: null
};

export default Header;
