import React from "react";
import ReactMarkdown from "react-markdown";
import Table from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";
import { GenerateName, generateDesc } from "./TableUtils";
import css from "../resources/Page.module.css";

// Global pseudo classes in MUI.
let pseudoClasses = [
  "checked",
  "disabled",
  "error",
  "focused",
  "focusVisible",
  "required",
  "expanded",
  "selected"
];
// MUI provides global classnames for its components to make it easier to access and style.
// They are typically structured as:
// - components: `[componentName]-[classesKey]`,
// - states: `Mui-[pseudoClass]`
//
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui-styles/src/createGenerateClassName/createGenerateClassName.js
// We've taken code from this MUI function, but we don't disable
// global classnames, so most of the function isn't required.
export function generateGlobalClass(name, key) {
  if (pseudoClasses.indexOf(key) !== -1) return `Mui-${key}`;
  return `${name}-${key}`;
}

export default function ClassesTable({ name: muiName, styles }) {
  let keys = Object.keys(styles);

  let buildBody = () => {
    return keys.map(key => {
      let row = styles[key];
      if (row.description.match(/@ignore/)) return null;
      let name = <GenerateName required={row.required}>{key}</GenerateName>;
      let globalClass = generateGlobalClass(muiName, key);
      let description = generateDesc(row.description);

      return (
        <TableRow key={key}>
          <TableCell size="small">
            <code>{name}</code>
          </TableCell>
          <TableCell>
            <code>{globalClass}</code>
          </TableCell>
          <TableCell>
            <ReactMarkdown className={css.markdown} source={description} />
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell size="small">Name</TableCell>
          <TableCell>Global ClassName</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>{buildBody()}</TableBody>
    </Table>
  );
}
