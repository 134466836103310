import React from "react";
import { Page, Title, Subtitle, Supporting } from "../../resources/DemoPage";
import Simple from "./demos/Simple";
import Demo, { generateDemos } from "../../resources/Demo";

let demoNames = ["Simple"];

export default function Chip() {
  let demos = generateDemos("core-component-demo/chip", demoNames);

  return (
    <Page components="Chip">
      <Title>Chip</Title>
      <Subtitle>
        Chips are compact elements that represent an input, attribute, or
        action.
      </Subtitle>
      <Supporting>
        Chips allow users to enter information, make selections, filter content,
        or trigger actions.
      </Supporting>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  );
}
