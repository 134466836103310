import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M9.213 14.225l-.92-.889c-1.884.45-3.945-.028-5.402-1.437-2.23-2.156-2.204-5.678.06-7.866 2.263-2.188 5.906-2.214 8.136-.058a5.38 5.38 0 011.442 5.387l.841.813L21.762 2 23 3.223 2.7 23 1.46 21.777l7.752-7.552zm6.698-1.594l4.604 4.451.015 3.058a.351.351 0 01-.346.347l-3.738.12-.064-2.014-1.982.08-.037-1.863-1.989.062.079-.872 3.458-3.369zM4.148 7.23c.574.555 1.511.548 2.094-.015.582-.562.589-1.469.015-2.023-.574-.555-1.511-.548-2.093.015a1.398 1.398 0 00-.016 2.023z"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "PermissionRevoke"
);
