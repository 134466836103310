import React from "react";

let WorkflowUpload = props => (
  <svg width={82} height={82} viewBox="0 0 82 82" {...props}>
    <g fillRule="nonzero">
      <path d="M41.488 82c11.592 0 20.988-9.397 20.988-20.988 0-11.592-9.396-20.988-20.988-20.988-11.591 0-20.988 9.396-20.988 20.988C20.5 72.603 29.897 82 41.488 82zm0-2.153c-10.402 0-18.835-8.433-18.835-18.835 0-10.403 8.433-18.836 18.835-18.836 10.403 0 18.836 8.433 18.836 18.836 0 10.402-8.433 18.835-18.836 18.835z" />
      <path d="M41.976 73.101V48.922c0-.601-.437-1.089-.976-1.089-.54 0-.976.488-.976 1.09V73.1c0 .602.437 1.09.976 1.09.54 0 .976-.488.976-1.09z" />
      <path d="M49.922 59.225c.415.43 1.09.43 1.505 0a1.13 1.13 0 000-1.558l-9.187-9.511a1.038 1.038 0 00-1.504 0l-9.186 9.511a1.13 1.13 0 000 1.558c.415.43 1.089.43 1.504 0l8.434-8.732 8.434 8.732z" />
      <path d="M73.368 24.756c0-8.222-6.678-15.615-14.565-15.615a18.01 18.01 0 00-7.056 1.299C47.985 3.583 40.59 0 31.29 0 18.478 0 8.092 10.352 8.092 23.122c.032 2.593.492 5.15 1.358 7.577A19.09 19.09 0 000 47.045c0 7.509 4.23 13.226 11.442 16.32a1.08 1.08 0 001.417-.563 1.074 1.074 0 00-.564-1.413C5.838 58.619 2.158 53.646 2.158 47.05a16.94 16.94 0 019.131-14.91c.492-.255.708-.841.502-1.354a21.234 21.234 0 01-1.541-7.678c0-11.569 9.42-20.958 21.04-20.958 8.94 0 15.792 3.505 18.987 10.144.257.534.897.76 1.434.507a15.882 15.882 0 017.07-1.51c6.664 0 12.43 6.383 12.43 13.444A13.685 13.685 0 0169.71 30.7a1.074 1.074 0 00.482 1.452c5.915 2.919 9.65 8.497 9.65 14.9 0 6.377-3.789 11.457-10.157 14.346a1.074 1.074 0 00-.535 1.424c.246.54.886.78 1.428.534C77.672 60.138 82 54.334 82 47.05c0-6.902-3.829-12.95-9.916-16.336a15.846 15.846 0 001.284-5.96z" />
    </g>
  </svg>
);

export default WorkflowUpload;
