let red = {
  50: "#feebef",
  100: "#fcccd4",
  200: "#eb989e",
  300: "#e17079",
  400: "#ec4e59",
  500: "#f13a42",
  600: "#e23140",
  700: "#d02739",
  800: "#c32032",
  900: "#b41227"
};

export default red;
