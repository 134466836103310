import { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error: error
    };
  }

  componentDidCatch(error, errorInfo) {
    this.props?.errorCallback(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return this.props?.errorDisplay;
    }
    return this.props.children;
  }
}
