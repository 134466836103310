import React from "react";
import Button from "@liveramp/motif/core/Button";
import Menu from "@liveramp/motif/core/Menu";
import MenuItem from "@liveramp/motif/core/MenuItem";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";
import ListItemText from "@liveramp/motif/core/ListItemText";
import Table from "../../table/demos/Simple";

import PlusIcon from "@liveramp/motif/icons/AddCircle";
import SettingsIcon from "@liveramp/motif/icons/Settings";
import css from "./RightAligned.module.css";

const options = [
  { label: "Add New Coffee", icon: <PlusIcon /> },
  { label: "Edit Brewing", icon: <SettingsIcon /> }
];

export default function CustomizedMenus() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={css.root}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={css.button}
      >
        Actions
      </Button>
      <Menu
        id="right-aligned-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        {options.map(option => (
          <MenuItem
            key={option.label}
            selected={option.label === "Sent"}
            onClick={handleClose}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText primary={option.label} />
          </MenuItem>
        ))}
      </Menu>
      <Table />
    </div>
  );
}
