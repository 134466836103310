import React, { useEffect, useRef, useState } from "react";
import MuiTab from "@material-ui/core/Tab";
import withStyles from "../../styles/withStyles";
import { TabStyle } from "../../utils/styleKeys";

function Tab({ className, onClick, ...props }) {
  let [active, setActive] = useState(false);
  let timeoutIds = useRef([]);

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms);
    timeoutIds.current.push(timeoutId);
  };

  let handleClick = e => {
    e.persist();
    setActive(true);
    if (typeof onClick === "function") onClick(e);
  };

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout);
  };

  useEffect(() => {
    if (active === true) {
      registerTimeout(() => setActive(false), 250);
    }
    return cleanup;
  });

  return (
    <MuiTab
      className={`${active ? "Mui-active" : ""} ${className}`}
      onClick={handleClick}
      {...props}
    />
  );
}

let styles = theme => ({
  ...TabStyle,
  root: {
    color: theme.palette.secondary.dark,
    minWidth: "120px",
    fontWeight: 600,
    transition: theme.transitions.common.short,
    "&:hover, &.Mui-focus, &.Mui-focusVisible": {
      backgroundColor: theme.palette.info.hover
    },
    "&:active, &.Mui-active": {
      color: theme.palette.info.dark,
      backgroundColor: theme.palette.info.active
    }
  },
  selected: { color: theme.palette.info.main }
});

export default withStyles(styles, { name: "MuiTab" })(Tab);
