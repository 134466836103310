import React from "react";
import Table, { TableWrapper } from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";

export default function ClassTable() {
  let data = [
    { classesKey: "checked", className: "Mui-checked" },
    { classesKey: "disabled", className: "Mui-disabled" },
    { classesKey: "error", className: "Mui-error" },
    { classesKey: "focused", className: "Mui-focused" },
    { classesKey: "focusVisible", className: "Mui-focusVisible" },
    { classesKey: "required", className: "Mui-required" },
    { classesKey: "expanded", className: "Mui-expanded" }
  ];

  return (
    <div style={{ width: "390px" }}>
      <TableWrapper height={"330px"}>
        <Table stickyHeader size="small">
          <TableHeader>
            <TableRow>
              <TableCell>classes key</TableCell>
              <TableCell>Global class name</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((row, i) => (
              <TableRow key={i}>
                <TableCell>{row.classesKey}</TableCell>
                <TableCell>{row.className}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  );
}
