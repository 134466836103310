import React, { useState } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import FullScreenDialog from "@liveramp/motif/core/FullScreenDialog";
import FullScreenDialogTitle from "@liveramp/motif/core/FullScreenDialogTitle";
import FullScreenDialogContent from "@liveramp/motif/core/FullScreenDialogContent";
import Grid from "@liveramp/motif/core/Grid";
import TextField from "@liveramp/motif/core/TextField";
import Table from "./grid-children/Table";
import LogoCardGroup from "./grid-children/LogoCardGroup";
import Stepper from "./grid-children/Stepper";
import Typography from "@liveramp/motif/core/Typography";
import makeStyles from "@liveramp/motif/styles/makeStyles";

let lorem = (
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    consequat.
  </p>
);

export default function MultiColumnDemo() {
  let classes = useStyles();
  let [openOneCol, setOneColOpen] = useState(false);
  let [openTwoCol, setTwoColOpen] = useState(false);
  let [openThreeCol, setThreeColOpen] = useState(false);

  return (
    <>
      <ButtonFormGroup>
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setOneColOpen(true)}
        >
          Open Single Column Dialog
        </Button>
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setTwoColOpen(true)}
        >
          Open Two Column Dialog
        </Button>
        <Button
          variant="secondary"
          color="primary"
          onClick={() => setThreeColOpen(true)}
        >
          Open Three Column Dialog
        </Button>
      </ButtonFormGroup>
      <FullScreenDialog open={openOneCol} onClose={() => setOneColOpen(false)}>
        <FullScreenDialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setOneColOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => setOneColOpen(false)}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </FullScreenDialogTitle>
        <FullScreenDialogContent>
          <Grid container spacing={3}>
            <Grid item xs />
            <Grid item xs={6}>
              {lorem}
              {lorem}
              {lorem}
              {lorem}
            </Grid>
            <Grid item xs />
          </Grid>
        </FullScreenDialogContent>
      </FullScreenDialog>
      <FullScreenDialog
        open={openTwoCol}
        onClose={() => setTwoColOpen(false)}
        fullScreen
      >
        <FullScreenDialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setTwoColOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => setTwoColOpen(false)}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </FullScreenDialogTitle>
        <FullScreenDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Table />
            </Grid>
            <Grid item xs={8}>
              <LogoCardGroup />
            </Grid>
          </Grid>
        </FullScreenDialogContent>
      </FullScreenDialog>
      <FullScreenDialog
        open={openThreeCol}
        onClose={() => setThreeColOpen(false)}
      >
        <FullScreenDialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setThreeColOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => setThreeColOpen(false)}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </FullScreenDialogTitle>
        <FullScreenDialogContent>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Stepper />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h4" className={classes.formHeader}>
                Content Header
              </Typography>
              <div className={classes.form}>
                <TextField
                  id="name"
                  label="Name"
                  helperText="Enter first and last"
                  defaultText="John Doe"
                  fullWidth
                />
                <TextField
                  id="email"
                  label="Email"
                  helperText="Enter email"
                  defaultText="jdoe@gmail.com"
                  fullWidth
                />
                <TextField
                  id="phone"
                  label="Phone"
                  defaultText="(808)-121-4500"
                  fullWidth
                />
                <TextField
                  id="account-name"
                  label="Account Name"
                  helperText="Valid account name"
                  defaultText="Acme LLC"
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="h4">Content Header</Typography>
              {lorem}
              {lorem}
              {lorem}
            </Grid>
          </Grid>
        </FullScreenDialogContent>
      </FullScreenDialog>
    </>
  );
}

let useStyles = makeStyles(theme => ({
  form: {
    display: "grid",
    gridTemplateColumns: "minmax(250px, 380px)",
    padding: "0 6rem",
    width: "100%",
    gap: "1rem"
  },
  formHeader: { padding: "0 6rem" }
}));
