import React from "react";
import ToggleButtonGroup from "@liveramp/motif/core/ToggleButtonGroup";
import ToggleButton from "@liveramp/motif/core/ToggleButton";

export default function SimpleDemo() {
  let [alignment, setAlignment] = React.useState("left");

  return (
    <ToggleButtonGroup
      value={alignment}
      onChange={(e, value) => setAlignment(value)}
      exclusive
    >
      <ToggleButton value="left" aria-label="left aligned">
        Option One
      </ToggleButton>
      <ToggleButton value="center" aria-label="centered">
        Option Two
      </ToggleButton>
      <ToggleButton value="right" aria-label="right aligned">
        Option Three
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
