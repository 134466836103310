import React from "react";
import { Title, Subtitle, NoticePanel } from "../resources/Page";

export default function ComposedDemos() {
  return (
    <div>
      <Title>Composed Components</Title>
      <Subtitle>
        Complex components built to create a unified and consistent user
        experience within LiveRamp's applications
      </Subtitle>
      <p>
        These components are more intricate and elaborate molecules and
        organisms built off of the core components in Motif.
      </p>
      <ul>
        <li>
          Created from Motif core components with customization from AX designed
          for specific use cases.
        </li>
        <li>
          Can be opinionated and have some understanding of business logic.
        </li>
        <li>
          Should ideally be used out-of-the-box; if they require some
          customization, please speak to{" "}
          <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">#dev-ax</a>
        </li>
        <li>
          If you would like to contribute a custom component;{" "}
          <a href="https://github.com/LiveRamp/motif/blob/master/CONTRIBUTING.md">
            please check out our guide!
          </a>
        </li>
      </ul>
      <p>
        Atomic design is the basis of Motif and all of its components. To learn
        more about what Atomic Design is, check out{" "}
        <a href="https://bradfrost.com/blog/post/atomic-web-design/">
          Brad Frost's write up here
        </a>
        .
      </p>
    </div>
  );
}

export function CustomizationWarning() {
  return (
    <NoticePanel variant="info">
      <p>
        This is a composed component that does not expect many customizations.
        Please chat to us at{" "}
        <a href="https://liveramp.slack.com/archives/C012Q4QCZG8">#dev-ax</a> if
        you need help.
      </p>
    </NoticePanel>
  );
}
