import React from "react";
import {
  Page,
  Title,
  Subtitle,
  SectionTitle
} from "../../../resources/DemoPage";
import { RdtNotices } from "../index";
import Demo, { generateDemos } from "../../../resources/Demo";
import SearchingDemo from "./demos/Searching";
import SearchingToolbarDemo from "./demos/SearchingToolbar";

let demoNames = ["Searching", "SearchingToolbar"];

export default function RichDataTable() {
  let demos = generateDemos(
    "composed-component-demo/rich-data-table/filtering",
    demoNames
  );

  return (
    <Page>
      <Title>Rich Data Table - Filtering</Title>
      <Subtitle>Rich Data Table for React apps</Subtitle>
      <RdtNotices />

      <SectionTitle>Searching</SectionTitle>
      <p>
        Search on a per column basis has already been implemented in the default
        Toolbar and is enabled when a column is defined with the{" "}
        <code>searchable</code> prop. Note that this relies on the{" "}
        <code>headerName</code> to be set so that it can be displayed in the
        search placeholder, otherwise it will default to the <code>field</code>.
      </p>
      <Demo demo={demos.Searching}>
        <SearchingDemo />
      </Demo>

      <SectionTitle>Custom Filtering</SectionTitle>
      <p>
        Additional controls to the provided Search functionality can use{" "}
        <code>toolbarActions</code>. Unfortunately there is currently an MUI
        limitation where only a single operator can be used between filters;
        i.e. <code>GridLinkOperator.And</code> or{" "}
        <code>GridLinkOperator.Or</code>.
      </p>
      <p>
        Toolbars are considered part of the table, so changes in state will
        re-render the table AND THE TOOLBAR. Any state that the toolbar may need
        must be passed down into the toolbar to properly maintain it across
        renders.
      </p>
      <Demo demo={demos.SearchingToolbar}>
        <SearchingToolbarDemo />
      </Demo>

      {/* <SectionTitle>Asynchronous Searching</SectionTitle>
      <ul>
        <li>
          Similar to client side searching, pass a callback function using the{" "}
          <code>handleSearchChange</code> prop that makes the server request.
          The rows prop should be modified by the response from the server.
        </li>
        <li>
          You can use the <code>loading</code> prop to set the loading state
          while rows are being updated
        </li>
      </ul>
      <Demo demo={demos.AsyncSearch}>
        <AsyncSearch />
      </Demo> */}
    </Page>
  );
}
