import React from "react";
import { GridOverlay } from "../../core-components/DataGrid";
import makeStyles from "../../styles/makeStyles";
import TableEmptyState from "../../icons/TableEmptyState";

export default function NoSearchResultsOverlay({ errorText }) {
  useStyles();

  return (
    <GridOverlay
      data-testid="RichDataTableNoResultsOverlay"
      className="RichDataTableNoResultsOverlay"
    >
      <TableEmptyState className="RichDataTableNoResultsOverlay__icon" />
      <div className="RichDataTableNoResultsOverlay__label">{errorText}</div>
    </GridOverlay>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".RichDataTableNoResultsOverlay": {
      flexDirection: "column",
      gap: "16px",

      // .RichDataTableNoResultsOverlay__icon
      "&__icon": {
        fontSize: "46px"
      },

      // .RichDataTableNoResultsOverlay__label
      "&__label": {
        ...theme.typography.h4Branding
      }
    }
  }
}));
