import React from "react";
import Button from "@liveramp/motif/core/Button";
import Tooltip from "@liveramp/motif/core/Tooltip";
import css from "./Placement.module.css";

export default function PlacementDemo() {
  let label = "Tooltip label";
  return (
    <div className={css.grid}>
      <Tooltip title={label} placement="top" className={css.t}>
        <Button variant="text" className={css.button} fullWidth>
          TOP
        </Button>
      </Tooltip>
      <Tooltip title={label} placement="left" className={css.l}>
        <Button variant="text" className={css.button} fullWidth>
          LEFT
        </Button>
      </Tooltip>
      <Tooltip title={label} placement="right" className={css.r}>
        <Button variant="text" className={css.button} fullWidth>
          RIGHT
        </Button>
      </Tooltip>
      <Tooltip title={label} placement="bottom" className={css.b}>
        <Button variant="text" className={css.button} fullWidth>
          BOTTOM
        </Button>
      </Tooltip>
    </div>
  );
}
