import React from "react";
import Select from "@liveramp/motif/core/Select";
import { films } from "./data";

export default function GroupedDemo() {
  let groupedFilms = [...films]
    .sort((a, b) => b.title - a.title) // sort alphabetically
    .sort((a, b) => b.year - a.year); // then by release year

  return (
    <Select
      id="grouped"
      label="Label"
      variant="underlined"
      options={groupedFilms}
      getOptionLabel={option => option.title}
      groupBy={option => option.year}
    />
  );
}
