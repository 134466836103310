import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        d="M7.875 13.929c2.223 0 6.615 1.117 6.864 3.342l.011.2v1.517c0 .514-.377.941-.86 1.004l-.122.008H1.982a.996.996 0 01-.974-.885L1 18.988V17.47c0-2.357 4.587-3.541 6.875-3.541zm8.25-2.834c2.223 0 6.615 1.117 6.864 3.343l.011.199v1.518c0 .513-.377.941-.86 1.004l-.122.008-6.06-.001a3.18 3.18 0 00-.51-1.423h6.17v-1.106c0-.328-.557-.858-1.71-1.334-1.172-.483-2.707-.785-3.783-.785s-2.611.302-3.783.785c-.079.033-.155.066-.229.099a12.057 12.057 0 00-1.884-.51c1.474-1.197 4.267-1.797 5.896-1.797zm-8.25-5.262c1.63 0 2.937 1.356 2.937 3.036s-1.307 3.036-2.937 3.036-2.946-1.356-2.946-3.036 1.316-3.036 2.946-3.036zM16.125 3c1.63 0 2.937 1.356 2.937 3.036S17.755 9.07 16.125 9.07s-2.946-1.356-2.946-3.035c0-1.68 1.316-3.036 2.946-3.036zm0 1.423c-.868 0-1.565.719-1.565 1.613s.697 1.612 1.565 1.612c.864 0 1.555-.715 1.555-1.612 0-.898-.69-1.613-1.555-1.613z"
        fillRule="nonzero"
      />
    </g>
  </React.Fragment>,
  "Lookalike"
);
