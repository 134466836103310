import React from "react";
import PropTypes from "prop-types";
import withStyles from "../../styles/withStyles";

function Loader({ classes, size = "default", ...props }) {
  return (
    <div>
      {size === "small" ? (
        <svg width="40px" height="15px" viewBox="0 0 50 85" {...props}>
          <circle fill="#73BF69" stroke="none" cx="0" cy="50" r="16">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle fill="#73BF69" stroke="none" cx="60" cy="50" r="16">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle fill="#73BF69" stroke="none" cx="120" cy="50" r="16">
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      ) : (
        <svg width="90px" height="80px" viewBox="0 0 130 136" {...props}>
          <polygon
            id="Shape"
            className={classes.triangle1}
            fill="#7F9FB4"
            points="31.06 103.2 0 135.73 40.17 135.73"
          />
          <polygon
            id="Shape"
            className={classes.triangle2}
            fill="#3A7D3D"
            points="37.14 102.13 46.56 135.73 129.13 135.73 129.13 124.25"
          />
          <polygon
            id="Shape"
            className={classes.triangle3}
            fill="#73BF69"
            points="40.8 96.75 129 117.93 129 66.05"
          />
          <polygon
            id="Shape"
            className={classes.triangle4}
            fill="#A2D296"
            points="44.23 89.05 129 59.53 129 7.10542736e-15"
          />
        </svg>
      )}
    </div>
  );
}

let styles = theme => ({
  triangle1: {
    animation: "$reveal 1.2s infinite"
  },
  triangle2: {
    animation: "$reveal 1.2s infinite",
    animationDelay: "0.2s"
  },
  triangle3: {
    animation: "$reveal 1.2s infinite",
    animationDelay: "0.4s"
  },
  triangle4: {
    animation: "$reveal 1.2s infinite",
    animationDelay: "0.6s"
  },
  "@keyframes reveal": {
    "0%": {
      opacity: "0.25"
    },
    "50%": {
      opacity: "1"
    },
    "100%": {
      opacity: "0.25"
    }
  }
});

Loader.propTypes = {
  size: PropTypes.oneOf(["default", "small"])
};

Loader.defaultProps = {
  size: "default"
};

export default withStyles(styles)(Loader);
