import React from "react";
import MuiCardHeader from "@material-ui/core/CardHeader";
import withStyles from "../../styles/withStyles";
import { CardHeaderStyle } from "../../utils/styleKeys";

function CardHeader(props) {
  return <MuiCardHeader disableTypography {...props} />;
}

let styles = theme => ({
  ...CardHeaderStyle,
  root: {
    fontSize: "20px",
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.grey[800],
    paddingBottom: "12px"
  },
  avatar: {
    marginRight: "12px"
  }
});

export default withStyles(styles, { name: "MuiCardHeader" })(CardHeader);
