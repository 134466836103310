import React, { useState } from "react";
import MuiDialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { makeStyles, mergeClasses } from "../../utils";
import { DialogStyle } from "../../utils/styleKeys";
import { Motif2DeprecationWarning } from "../../utils/internal";

let SlideTransition = React.forwardRef((props, ref) => (
  <Slide direction="up" {...props} ref={ref} />
));

export default function Dialog({
  classes,
  maxWidth,
  size,
  TransitionComponent,
  ...props
}) {
  if (props.fullScreen) {
    Motif2DeprecationWarning("<Dialog fullscreen />", "FullScreenDialog");
  }

  let [scrolled, setScrolled] = useState(false);
  let defaultClasses = useStyles(props);
  let transition = (() => {
    if (TransitionComponent) return TransitionComponent;
    if (props.fullScreen) return SlideTransition;
    return Fade;
  })();

  let setMaxWidth = () => {
    if (props.fullScreen) return false;
    if (size === "small") return "xs";
    return "sm";
  };

  let handleScroll = e => {
    let prev = scrolled;
    let current = e.target.scrollTop !== 0;

    if (prev !== current) {
      let titleElement = e.target.parentElement.querySelector(
        ".MuiDialogTitle-root"
      );

      if (current) {
        titleElement.classList.add("Mui-scrollShadow");
      } else {
        titleElement.classList.remove("Mui-scrollShadow");
      }

      setScrolled(current);
    }
  };

  return (
    <MuiDialog
      classes={mergeClasses(defaultClasses, classes)}
      TransitionComponent={transition}
      maxWidth={maxWidth ? maxWidth : setMaxWidth()}
      onScroll={props.fullScreen && handleScroll}
      disableEnforceFocus // hotfix for dialog closing filter (AX-1971)
      {...props}
    />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...DialogStyle,
    paper: {
      padding: "24px"
    },
    paperWidthXs: {
      width: "444px" // https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/Dialog/Dialog.js#L77
    },
    paperWidthSm: {
      width: theme.breakpoints.values.sm
    },
    // TODO: Motif 2.0, delete all fullScreen styling - replace in composed component
    paperFullScreen: {
      padding: 0,
      marginTop: "100px"
    },
    container: {
      // remove the margin height from the full screen dialog
      height: props => (props.fullScreen ? "calc(100% - 100px)" : "100%")
    },
    scrollPaper: {
      alignItems: props => (props.fullScreen ? "flex-start" : "center")
    }
  }),
  { name: "MuiDialog" }
);

import LocalDialog from "./Dialog";
import Title from "./Title";
import Content from "./Content";
import Actions from "./Actions";

export { LocalDialog as Dialog, Title, Content, Actions };
