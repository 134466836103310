import React from "react";
import Filter from "@liveramp/motif/core/Filter";
import css from "../../../resources/Page.module.css";

const virtualizedList = [
  "Lorem ipsum dolor sit amet",
  "Consectetur adipiscing elit sed do eiusmod",
  "Tempor incididunt ut labore et dolore",
  "Dolore magna aliqua ut enim ad minim veniam",
  "Quis nostrud exercitation ullamco",
  "Laboris nisi ut aliquip ex ea commodo consequat",
  "Duis aute irure dolor in reprehenderit in",
  "Velit esse cillum dolore eu fugiat nulla",
  "Excepteur sint occaecat cupidatat",
  "Non proident, sunt in culpa qui officia",
  "Mollit anim id est laborum",
  "At vero eos et accusamus et iusto odio",
  "Ducimus qui blanditiis praesentium",
  "Deleniti atque corrupti quos dolores et",
  "Molestias excepturi sint occaecati",
  "Non provident, similique sunt",
  "Officia deserunt mollitia animi",
  "Laborum et dolorum fuga",
  "Facilis est et expedita"
];

const options = virtualizedList.map(item => ({
  label: item,
  optCount: Math.floor(Math.random() * 100) + 1
}));

export default function VirtualizedListDemo() {
  return (
    <div className={css.grid}>
      <Filter
        id="filter-uncontrolled-demo"
        label="Virtualized List"
        options={options}
        defaultValue={[options[0], options[1]]}
        getOptionLabel={option => option.label}
        listPlacement="top-start"
        virtualized
        virtualizedWidth={480}
      />
    </div>
  );
}
