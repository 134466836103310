import React, { useState, useEffect, useRef } from "react";
import MuiButton from "@material-ui/core/Button";
import chroma from "chroma-js";
import withStyles from "../../styles/withStyles";
import PropTypes from "prop-types";

export let finalVariant = variant => {
  if (variant === "secondary" || variant === "outlined") return "outlined";
  if (variant === "text") return "text";
  return "contained";
};

const Button = React.forwardRef(function Button(
  {
    classes,
    className,
    color,
    disableFocusRipple, // ignore - we have our own button animations
    disableRipple, // ignore - we have our own button animations
    onClick,
    variant,
    ...props
  },
  ref
) {
  let [active, setActive] = useState(false);
  let timeoutIds = useRef([]);

  let colorClasses = (color, classes) => {
    let prefix = color;
    if (!["primary", "secondary", "danger"].includes(color)) prefix = "primary";
    return {
      text: classes[`${prefix}Text`],
      contained: classes[`${prefix}Contained`],
      outlined: classes[`${prefix}Outlined`]
    };
  };
  let buttonClasses = { root: classes.root, ...colorClasses(color, classes) };

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms);
    timeoutIds.current.push(timeoutId);
  };

  let handleClick = e => {
    e.persist();
    setActive(true);
    if (typeof onClick === "function") onClick(e);
  };

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout);
  };

  useEffect(() => {
    if (active === true) {
      registerTimeout(() => setActive(false), 250);
    }
    return cleanup;
  });

  return (
    <MuiButton
      ref={ref}
      variant={finalVariant(variant)}
      className={`${active ? "Mui-active" : ""} ${className}`}
      classes={buttonClasses}
      onClick={handleClick}
      centerRipple
      {...props}
    />
  );
});

let containedStyle = color => ({
  boxShadow: "none",
  minWidth: "100px",
  color: "white",
  border: `1px solid transparent`,
  backgroundColor: color.main,
  padding: "5.5px 16px 4.5px",
  "&:hover, &.Mui-focusVisible": {
    backgroundColor: chroma.mix(color.main, color.dark).css(),
    boxShadow: "none"
  },
  "&:active, &.Mui-active": {
    backgroundColor: color.dark,
    boxShadow: "none"
  }
});

let outlinedStyle = color => ({
  color: color.main,
  minWidth: "100px",
  padding: "5.5px 16px 4.5px",
  borderColor: color.main,
  "&:hover, &.Mui-focusVisible": {
    backgroundColor: color.hover
  },
  "&:active, &.Mui-active": {
    color: color.dark,
    backgroundColor: color.active
  }
});

let textStyle = color => ({
  color: color.main,
  padding: "6px 16px",
  minWidth: "80px",
  "&:hover, &.Mui-focusVisible": {
    backgroundColor: color.hover
  },
  "&:active, &.Mui-active": {
    color: color.dark,
    backgroundColor: color.active
  }
});

let styles = theme => ({
  root: {
    borderRadius: "4px",
    fontWeight: "600",
    transition: theme.transitions.common.short
  },

  // contained button styles
  primaryContained: containedStyle(theme.palette.primary),
  secondaryContained: containedStyle(theme.palette.secondary),
  dangerContained: containedStyle(theme.palette.error),

  // outlined button styles
  primaryOutlined: outlinedStyle(theme.palette.primary),
  secondaryOutlined: outlinedStyle(theme.palette.secondary),
  dangerOutlined: outlinedStyle(theme.palette.error),

  // text only button styles
  primaryText: textStyle(theme.palette.primary),
  secondaryText: textStyle(theme.palette.secondary),
  dangerText: textStyle(theme.palette.error)
});

Button.propTypes = Object.assign({}, MuiButton.propTypes, {
  color: PropTypes.oneOf(["default", "primary", "secondary", "danger"]),
  variant: PropTypes.oneOf([
    "default",
    "primary",
    "secondary",
    "contained",
    "outlined",
    "text"
  ])
});

Button.defaultProps = Object.assign({}, MuiButton.defaultProps, {
  className: "",
  color: "default",
  variant: "default"
});

export default withStyles(styles, { name: "MuiButton" })(Button);
