import React from "react";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import Button from "@liveramp/motif/core/Button";
import ButtonGroup from "@liveramp/motif/core/ButtonGroup";
import reporting1 from "@liveramp/motif/colors/reporting1";
import reporting2 from "@liveramp/motif/colors/reporting2";

export default function AdaptingHookDemo() {
  return (
    <ButtonGroup>
      <StyledButton color="blue">Blue</StyledButton>
      <StyledButton color="purple">purple</StyledButton>
    </ButtonGroup>
  );
}

function StyledButton(props) {
  const classes = useStyles(props);
  return <Button className={classes.root} {...props} />;
}

const useStyles = makeStyles({
  root: {
    background: props =>
      props.color === "blue"
        ? `linear-gradient(45deg, ${reporting1[200]} 30%, ${reporting1[700]} 90%)`
        : `linear-gradient(45deg, ${reporting2[200]} 30%, ${reporting2[700]} 90%)`
  }
});
