import React from "react";

let NavView = props => (
  <svg width={24} height={24} viewBox="0 0 24 26" {...props}>
    <g fillRule="nonzero">
      <g>
        <path d="M11.598 7.434v9.812a.715.715 0 1 0 1.43 0V7.434a.715.715 0 0 0-1.43 0zM8.325 9.615v7.631a.715.715 0 1 0 1.43 0v-7.63a.715.715 0 1 0-1.43 0zM5.052 12.723v4.523a.715.715 0 1 0 1.43 0v-4.523a.715.715 0 0 0-1.43 0zM14.87 5.263v11.983a.715.715 0 1 0 1.43 0V5.263a.715.715 0 1 0-1.43 0z" />
      </g>
      <path d="M12 24C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12c0 3.253-1.302 6.302-3.572 8.542A11.962 11.962 0 0 1 12 24zm0-1.4c2.825 0 5.474-1.11 7.445-3.055A10.564 10.564 0 0 0 22.6 12c0-5.854-4.746-10.6-10.6-10.6C6.146 1.4 1.4 6.146 1.4 12c0 5.854 4.746 10.6 10.6 10.6zM21.097 23.066l2.635 2.634a.682.682 0 1 0 .964-.966L22.061 22.1a.682.682 0 1 0-.964.966z" />
    </g>
  </svg>
);

export default NavView;
