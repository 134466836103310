export let data = [
  {
    id: "a",
    link: "https://connect.liveramp.com/file_tracking",
    type: "warning",
    createdAt: new Date("12/15/2020"),
    read: false,
    message: "Data from your file FB_OPTOUT_FB_20201205.csv has been delivered."
  },
  {
    id: "b",
    link: "https://connect.liveramp.com/distribution",
    type: "success",
    createdAt: new Date("12/15/2020"),
    read: false,
    message:
      "Your file FB_OPTOUT_FB_20201205.csv has finished processing in audience Facebook FR - Acxiom O... and is ready for distribution."
  },
  {
    id: "c",
    link: "https://connect.liveramp.com/distribution",
    type: "error",
    createdAt: new Date("12/15/2020"),
    read: true,
    message:
      "Your file ILUv17.1_DigitalDeleti... has finished processing in audience Facebook UK - Acxiom O... and is ready for distribution."
  },
  {
    id: "d",
    link: "https://connect.liveramp.com/file_tracking",
    type: "warning",
    createdAt: new Date("12/15/2020"),
    read: false,
    message: "Sometimes msg is short."
  },
  {
    id: "e",
    link: "https://connect.liveramp.com/distribution",
    type: "success",
    createdAt: new Date("12/15/2020"),
    read: false,
    message:
      "Your file FB_OPTOUT_FB_20201205.csv has finished processing in audience Facebook FR - Acxiom O... and is ready for distribution."
  },
  {
    id: "f",
    link: "https://connect.liveramp.com/distribution",
    type: "error",
    createdAt: new Date("12/15/2020"),
    read: true,
    message:
      "Your file ILUv17.1_DigitalDeleti... has finished processing in audience Facebook UK - Acxiom O... and is ready for distribution."
  }
];
