import React, { useEffect, useState } from "react";
import RichDataTable, {
  useGridApiRef
} from "@liveramp/motif/core/RichDataTable";
import Header from "@liveramp/motif/core/Header";
import toolbarActions from "./RdtToolbarActions";
import RdtSidePanel from "./RdtSidePanel";

import columns from "./RdtColumnDefinitions";

// Spotify development mode; there are no accounts authorised to authenticate
// and mess with my Spotify account so ¯\_(ツ)_/¯
const client_id = "73dc7d43355f415ab75932be1df455d5";
const client_st = "719a663011d6427a835dda817eb2de4e";
const spotifyTokenApi = "https://accounts.spotify.com/api/token";
const spotifyPlaylistEndpoint =
  "https://api.spotify.com/v1/playlists/69fEt9DN5r4JQATi52sRtq";

const soundOfEverythingUrl = "http://everynoise.com/thesoundofeverything.html";

export default function Rdt() {
  // table data states
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  // accompanying component states
  const [visibleRows, setVisibleRows] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [sidePanelOpen, setSidePanelOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);

  // toolbar states
  const [filterValue, setFilterValue] = useState([]);
  const [multiFilterValue, setMultiFilterValue] = useState([]);

  const apiRef = useGridApiRef();

  /** EVENT FUNCTIONS **/
  const handlePageChange = (page, pageCount, pageSize, rowCount) => {
    // handle table header state
    if (rowCount % pageSize === 0 || page !== pageCount - 1) {
      setVisibleRows(pageSize);
    } else {
      setVisibleRows(rowCount % pageSize);
    }
  };

  const handleRowClick = (params, event) => {
    if (sidePanelOpen && currentRowData?.params.row.id === params.row.id) {
      setSidePanelOpen(false);
    } else {
      setSidePanelOpen(true);
      setCurrentRowData({ params, event });
    }
  };

  /** EFFECTS **/
  // get spotify auth token
  useEffect(() => {
    (async () => {
      fetch(spotifyTokenApi, {
        method: "POST",
        headers: {
          Authorization: `Basic ${Buffer.from(
            `${client_id}:${client_st}`
          ).toString("base64")}`,
          "Content-Type": "application/x-www-form-urlencoded"
        },
        body: new URLSearchParams({
          grant_type: "client_credentials"
        })
      })
        .then(response => response.json())
        .then(token => {
          setToken(token);
        });
    })();
  }, []);

  // get spotify playlist data
  useEffect(() => {
    if (token)
      fetch(spotifyPlaylistEndpoint, {
        method: "GET",
        headers: {
          Authorization: `${token.token_type} ${token.access_token}`
        }
      })
        .then(response => response.json())
        .then(data => {
          return new Promise(resolve =>
            setTimeout(() => {
              // Changing the structure to make it easier to navigate and because
              // we're primarily interested in the tracks, not the playlist
              // metadata itself
              data.tracks.items = data.tracks.items.map(
                item => (item = item.track)
              );
              setData(data);
              setLoading(false);
            }, 3000)
          );
        });
  }, [token]);

  // table header state
  useEffect(() => {
    setVisibleRows(apiRef.current.state.pagination.pageSize);
    setTotalRows(apiRef.current.state.rows.totalRowCount);

    apiRef.current.subscribeEvent("pageChange", (page, event, { api }) => {
      setTimeout(() => {
        const { pageCount, pageSize, rowCount } = api.state.pagination;
        handlePageChange(page, pageCount, pageSize, rowCount);
      }, 100);
    });
    apiRef.current.subscribeEvent(
      "pageSizeChange",
      (pageSize, event, { api }) => {
        setTimeout(() => {
          const { page, pageCount, rowCount } = api.state.pagination;
          handlePageChange(page, pageCount, pageSize, rowCount);
        }, 100);
      }
    );
    apiRef.current.subscribeEvent(
      "filterModelChange",
      (params, event, { api }) => {
        setTimeout(() => {
          const { page, pageCount, pageSize, rowCount } = api.state.pagination;
          handlePageChange(page, pageCount, pageSize, rowCount);
        }, 100);
      }
    );
  }, [data, apiRef]);

  const sidePanelWidth = "500px";

  return (
    <>
      <Header
        subtitle={
          visibleRows && totalRows
            ? `Showing ${visibleRows} out of ${totalRows} rows`
            : ""
        }
      >
        The Sound of Everything
      </Header>
      <p>
        The Sounds of Spotify. One song from every genre we currently track.{" "}
        <a href={soundOfEverythingUrl}>Annotated here.</a>
      </p>
      <RichDataTable
        apiRef={apiRef}
        columns={columns}
        sidePanelOpen={sidePanelOpen}
        sidePanelWidth={sidePanelWidth}
        rows={data ? data.tracks.items : []}
        loading={loading}
        showActiveRow={sidePanelOpen}
        // toolbar props
        toolbarActions={(gridSlotComponentProps, searchDetails) =>
          toolbarActions(
            gridSlotComponentProps,
            searchDetails,
            filterValue,
            setFilterValue,
            multiFilterValue,
            setMultiFilterValue
          )
        }
        // pagination props
        pagination
        rowsPerPageOptions={[10, 25, 50]}
        pageSize={pageSize}
        onPageSizeChange={pageSize => setPageSize(pageSize)}
        // side panel props
        onRowClick={handleRowClick}
      />
      <RdtSidePanel
        open={sidePanelOpen}
        setOpen={setSidePanelOpen}
        width={sidePanelWidth}
        content={currentRowData?.params}
        token={token}
      />
    </>
  );
}
