import React from "react";
import MuiMenu from "@material-ui/core/Menu";

const MenuContent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

function Menu({ children, ...props }) {
  return (
    <MuiMenu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      getContentAnchorEl={null}
      elevation={1}
      {...props}
    >
      <MenuContent>{children}</MenuContent>
    </MuiMenu>
  );
}

export default Menu;

import MotifMenu from "./Menu";
import List from "./List";
import Item from "./Item";

export { MotifMenu as Menu, List, Item };
