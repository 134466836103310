import React from "react";
import Button from "@liveramp/motif/core/Button";
import withStyles from "@liveramp/motif/styles/withStyles";
import reporting2 from "@liveramp/motif/colors/reporting2";

function HigherOrderComponentDemo(props) {
  const { classes } = props;
  return <Button className={classes.root}>Higher-order component</Button>;
}

const styles = theme => ({
  root: {
    background: `linear-gradient(45deg, ${reporting2[200]} 30%, ${reporting2[500]} 90%)`
  }
});

export default withStyles(styles)(HigherOrderComponentDemo);
