import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle,
  NoticePanel
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import WithIcons from "./demos/WithIcons";
import Controlled from "./demos/Controlled";
import Sizes from "./demos/Sizes";

let demoNames = ["Simple", "WithIcons", "Controlled", "Sizes"];

export default function VerticalNav() {
  let demos = generateDemos("core-component-demo/tree-view", demoNames);

  return (
    <Page components={["TreeView", "TreeItem"]}>
      <Title>Tree View</Title>
      <Subtitle>A tree view widget presents a hierarchical list.</Subtitle>
      <TreeViewNotices />
      <Supporting>
        Tree views can be used to represent a file system navigator displaying
        folders and files, an item representing a folder can be expanded to
        reveal the contents of the folder, which may be files, folders, or both.
      </Supporting>
      <SectionTitle>Basic Tree View</SectionTitle>
      <Demo demo={demos.Simple} leftAligned>
        <div style={{ height: "300px" }}>
          <Simple />
        </div>
      </Demo>
      <SectionTitle>With Icons</SectionTitle>
      <Demo demo={demos.WithIcons} leftAligned>
        <div style={{ height: "310px" }}>
          <WithIcons />
        </div>
      </Demo>
      <SectionTitle>Controlled Tree View</SectionTitle>
      <p>The tree view also offers a controlled API.</p>
      <Demo demo={demos.Controlled} leftAligned>
        <div style={{ height: "430px" }}>
          <Controlled />
        </div>
      </Demo>
      <SectionTitle>Tree View Sizes</SectionTitle>
      <p>The tree view can also be made more compact with the <code>size</code> prop, which can be <code>small</code>, <code>medium</code> or the default, <code>large</code>.</p>
      <Demo demo={demos.Sizes} leftAligned>
        <div style={{ height: "430px" }}>
          <Sizes />
        </div>
      </Demo>
    </Page>
  );
}

export function TreeViewNotices() {
  return (
    <NoticePanel variant="warning">
      <div>
        The TreeView is built on a material-ui lab component (outside of the
        core package, for components not yet ready to be introduced to the core
        library), therefore there may be breaking changes in the coming months
        and upon new releases.
      </div>
    </NoticePanel>
  );
}
