import React from "react";
import Uploader from "@liveramp/motif/core/Uploader";

const STATUS_500_URL = "https://httpbin.org/status/500";

const buttonAction = () => {
  alert("This is a custom button action!");
};

export default function ErrorStatusDemo() {
  return (
    <Uploader
      id="error-status-uploader"
      customErrorMessage="Custom Error Message"
      url={STATUS_500_URL}
      buttonAction={buttonAction}
    />
  );
}
