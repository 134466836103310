import React, { useState } from "react";
import Button from "@liveramp/motif/core/Button";
import Drawer from "@liveramp/motif/core/Drawer";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function Sandbox() {
  let [open, setOpen] = useState(false);
  let classes = useStyles();

  return (
    <div className={classes.root}>
      <main>
        <Button onClick={() => setOpen(!open)}>Toggle Side Panel</Button>
      </main>
      <Drawer
        anchor="right"
        classes={{
          root: open ? classes.drawerOpen : classes.drawer,
          paper: open ? classes.drawerOpen : classes.drawer
        }}
        open={open}
        onClick={() => setOpen(!open)}
      >
        Hello World
      </Drawer>
    </div>
  );
}

let useStyles = makeStyles(theme => ({
  root: {
    display: "flex"
  },
  drawer: {
    transition: theme.transitions.common.enteringScreen,
    width: 0
  },
  drawerOpen: {
    transition: theme.transitions.common.leavingScreen,
    width: "150px"
  }
}));
