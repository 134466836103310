import React, { useState } from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepLabel from "@liveramp/motif/core/StepLabel";
import css from "../../../resources/Page.module.css";

export default function CanceledDemo() {
  const [activeStep] = useState(1);
  const steps = getSteps();

  return (
    <Stepper
      activeStep={activeStep}
      className={css.fullWidth}
      orientation="vertical"
    >
      {steps.map(({ label, labelProps = {} }) => (
        <Step key={label}>
          <StepLabel {...labelProps}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}

function getSteps() {
  return [
    {
      label: "Transferring",
      labelProps: {
        variant: 'canceled',
        optional: `
          Your file is being scanned, queued, and transferred to LiveRamp.
          Completed on 12-31-22 at 13:45
        `
      }
    },
    {
      label: "Processing Canceled",
      labelProps: {
        variant: 'canceled',
        optional: `
          Processing for this panel was canceled by the user.
          Canceled on 12-31-22 at 13:45
        `
      }
    },
    {
      label: "Ready",
      labelProps: {
        optional: `Your file has completed processing and can be used to create and distribute segments`
      }
    }
  ];
}
