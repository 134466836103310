import React from "react";
import clsx from "clsx";
import MuiTypography from "@material-ui/core/Typography";
import makeStyles from "../../styles/makeStyles";

// There is heavy customisation in this component as MUI does not allow
// mutations to the original Typography theme.
// This limitation is removed in MUIv5:
// - https://github.com/mui-org/material-ui/issues/22257
// - https://github.com/mui-org/material-ui/pull/22006

const customTypography = [
  "h2Branding",
  "h3Branding",
  "h4Branding",
  "subtitle3",
  "body3",
  "caption"
];

const bodyTypography = ["body1", "body2", "body3"];

export default function Typography({ className, variant, ...props }) {
  const customVariantClasses = useCustomVariants();
  const isCustom = customTypography.includes(variant);

  // Custom variants need to know which components to semantically map to
  let getComponent = variant => {
    if (variant === "h1") return "h1";
    if (variant === "h2" || variant === "h2Branding") return "h2";
    if (variant === "h3" || variant === "h3Branding") return "h3";
    if (variant === "h4" || variant === "h4Branding") return "h4";
    if (variant === "h5") return "h5";
    if (variant === "h6") return "h6";
    if (
      variant === "caption" ||
      variant === "subtitle1" ||
      variant === "subtitle2" ||
      variant === "subtitle3"
    )
      return "span";
    if (variant === "body1" || variant === "body2" || variant === "body3")
      return "p";
    return "div";
  };

  return (
    <MuiTypography
      className={clsx(className, {
        [customVariantClasses[variant]]: isCustom
      })}
      // Fixes warning about our custom typography styles
      variant={isCustom ? "inherit" : variant}
      component={getComponent(variant)}
      paragraph={bodyTypography.includes(variant)}
      {...props}
    />
  );
}

// All the type styles that currently fall outside of MUIs typography variants.
const useCustomVariants = makeStyles(
  theme => ({
    h2Branding: {
      ...theme.typography.h2Branding
    },
    h3Branding: {
      ...theme.typography.h3Branding
    },
    h4Branding: {
      ...theme.typography.h4Branding
    },
    subtitle3: {
      ...theme.typography.subtitle3
    },
    body3: {
      ...theme.typography.body3
    },
    caption: {
      ...theme.typography.caption
    }
  }),
  { name: "MuiTypography" }
);
