import React, { useState } from "react";
import Switch from "@liveramp/motif/core/Switch";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import {
  CheckCircle,
  RemoveCircle,
  Favorite,
  Lightbulb,
  Star
} from "@liveramp/motif/icons";
import css from "./Custom.module.css";
import yellow from "@liveramp/motif/colors/yellow";
import blue from "@liveramp/motif/colors/blue";
import withStyles from "@liveramp/motif/styles/withStyles";
import makeStyles from "@liveramp/motif/styles/makeStyles";

function CustomDemo({ classes: withStylesClasses }) {
  let [icon, setIcon] = useState(true);
  let [heart, setHeart] = useState(true);
  let [star, setStar] = useState(true);
  let [lightbulb, setLightbulb] = useState(true);

  let makeStylesClasses = useStyles();

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            className={css.clear}
            checked={icon}
            icon={<RemoveCircle />}
            checkedIcon={<CheckCircle />}
            onChange={event => setIcon(event.target.checked)}
            value="icon"
          />
        }
        label="Icon"
      />
      <FormControlLabel
        control={
          <Switch
            className={css.heart}
            checked={heart}
            onChange={event => setHeart(event.target.checked)}
            icon={<Favorite />}
            checkedIcon={<Favorite />}
            value="heart"
          />
        }
        label="CSS Modules"
      />
      <FormControlLabel
        control={
          <Switch
            classes={withStylesClasses}
            checked={star}
            onChange={event => setStar(event.target.checked)}
            icon={<Star />}
            checkedIcon={<Star />}
            value="star"
          />
        }
        label="JSS withStyles"
      />
      <FormControlLabel
        control={
          <Switch
            classes={makeStylesClasses}
            checked={lightbulb}
            onChange={event => setLightbulb(event.target.checked)}
            icon={<Lightbulb />}
            checkedIcon={<Lightbulb />}
            value="lightbulb"
          />
        }
        label="JSS makeStyles"
      />
    </>
  );
}

let withStylesStyles = theme => ({
  switchBase: {
    color: yellow[100],
    "&:hover": {
      backgroundColor: theme.palette.warning.hover
    }
  },
  track: {
    backgroundColor: yellow[50]
  },
  checked: {
    color: theme.palette.warning.main,
    "& + .MuiSwitch-track": {
      backgroundColor: yellow[300]
    }
  }
});

let useStyles = makeStyles(theme => ({
  switchBase: {
    color: blue[100],
    "&:hover": {
      backgroundColor: theme.palette.info.hover
    }
  },
  track: {
    backgroundColor: blue[50]
  },
  checked: {
    color: theme.palette.info.main,
    "& + .MuiSwitch-track": {
      backgroundColor: blue[300]
    }
  }
}));

export default withStyles(withStylesStyles)(CustomDemo);
