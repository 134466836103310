import React from "react";
import { Page, Title, Subtitle } from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";

let demoNames = ["Simple"];

export default function Breadcrumb() {
  let demos = generateDemos("core-component-demo/breadcrumbs", demoNames);

  return (
    <Page components={["Breadcrumbs"]}>
      <Title>Breadcrumbs</Title>
      <Subtitle>
        Breadcrumbs consist of a list of links that help a user visualize a
        page's location within the hierarchical structure of a website, and
        allow navigation up to any of its "ancestors".
      </Subtitle>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>
    </Page>
  );
}
