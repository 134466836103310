import React from "react";
import Uploader from "@liveramp/motif/core/Uploader";
import Button from "@liveramp/motif/core/Button";

const STATUS_500_URL = "https://httpbin.org/status/500";

export default function SimpleDemo() {
  return (
    <Uploader
      id="multi-uploader"
      multiple
      url={STATUS_500_URL}
      customErrorMessage="Error message to display, optional... even longer messages will be fully displayed"
      successComponent={() => (
        <Button variant="text" color="secondary" size="small">
          Go to audiences
        </Button>
      )}
    />
  );
}
