import React, { useState, useEffect, useRef } from "react";
import MuiButton from "@material-ui/core/Button";
import withStyles from "../../styles/withStyles";
import PropTypes from "prop-types";

import GoogleButtonNormal from "./PartnerAssets/Google/SignInWithGoogle/2x/btn_google_signin_dark_normal_web@2x.png";
import GoogleButtonPressed from "./PartnerAssets/Google/SignInWithGoogle/2x/btn_google_signin_dark_pressed_web@2x.png";
import GoogleButtonFocus from "./PartnerAssets/Google/SignInWithGoogle/2x/btn_google_signin_dark_focus_web@2x.png";
import GoogleButtonDisabled from "./PartnerAssets/Google/SignInWithGoogle/2x/btn_google_signin_dark_disabled_web@2x.png";

export const PARTNER_BUTTONS = {
  Google: { sign_in: "google_signInWithGoogle" }
};

function PartnerButton({ classes, onClick, variant, ...props }) {
  let [active, setActive] = useState(false);
  let timeoutIds = useRef([]);

  let registerTimeout = (f, ms) => {
    let timeoutId = setTimeout(f, ms);
    timeoutIds.current.push(timeoutId);
  };

  let handleClick = e => {
    e.persist();
    setActive(true);
    if (typeof onClick === "function") onClick(e);
  };

  let cleanup = () => {
    timeoutIds.current.forEach(clearTimeout);
  };

  useEffect(() => {
    if (active === true) {
      registerTimeout(() => setActive(false), 250);
    }
    return cleanup;
  });

  return <div className={classes[variant]} onClick={handleClick} />;
}

let googleSignInStyle = () => ({
  width: "191px",
  height: "46px",
  cursor: "pointer",
  backgroundSize: "contain",
  backgroundImage: `url(${GoogleButtonNormal})`,
  "&:active, &.Mui-active": {
    backgroundImage: `url(${GoogleButtonPressed})`
  },
  "&:focus": {
    backgroundImage: `url(${GoogleButtonFocus})`
  },
  "&:disabled": {
    backgroundImage: `url(${GoogleButtonDisabled})`
  }
});

let styles = () => ({
  [PARTNER_BUTTONS.Google.sign_in]: googleSignInStyle()
});

PartnerButton.propTypes = Object.assign({}, MuiButton.propTypes, {
  variant: PropTypes.oneOf([PARTNER_BUTTONS.Google.sign_in])
});

export default withStyles(styles)(PartnerButton);
