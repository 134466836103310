import React, { createRef } from "react";
import clsx from "clsx";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Paper from "@liveramp/motif/core/Paper";
import VirtualizedFixedSizeList from "@liveramp/motif/core/VirtualizedFixedSizeList";
import ListItem from "@liveramp/motif/core/ListItem";
import ListItemText from "@liveramp/motif/core/ListItemText";
import { makeStyles } from "@liveramp/motif/utils";
import css from "../../../resources/Page.module.css";

export default function VirtualizedListScrollingDemo() {
  const listRef = createRef();
  const classes = useStyles();
  const itemCount = 1500;
  const vHeight = 36;
  const vWidth = 150;

  return (
    <div className={css.grid}>
      <Paper>
        <VirtualizedFixedSizeList
          ref={listRef}
          itemCount={itemCount}
          height={vHeight * 5}
          width={vWidth}
          itemSize={vHeight}
          useIsScrolling
        >
          {({ index, isScrolling, style }) => (
            <ListItem
              button
              className={clsx({ [classes.even]: index % 2 === 0 })}
              key={index}
              style={style}
            >
              <ListItemText>
                {isScrolling ? "Scrolling" : `Item ${index + 1}`}
              </ListItemText>
            </ListItem>
          )}
        </VirtualizedFixedSizeList>
      </Paper>
      <ButtonFormGroup vertical>
        <Button onClick={() => listRef.current.scrollToItem(500)}>
          Scroll to 500 - Align Auto
        </Button>
        <Button onClick={() => listRef.current.scrollToItem(1000, "smart")}>
          Scroll to 1000 - Align Smart
        </Button>
        <Button onClick={() => listRef.current.scrollToItem(1200, "center")}>
          Scroll to 1200 - Align Center
        </Button>
      </ButtonFormGroup>
    </div>
  );
}

let useStyles = makeStyles(theme => ({
  even: { backgroundColor: theme.palette.grey[50] }
}));
