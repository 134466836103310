import React from "react";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function NestingSelectorsDemo() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      This is blue since it is inside the root.
      <p>
        This is green since it is inside the paragraph{" "}
        <span>and this is orange since it is inside the span</span>
      </p>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.info.dark,
    "& p": {
      margin: 0,
      color: theme.palette.primary.dark,
      "& span": {
        color: theme.palette.warning.dark
      }
    }
  }
}));
