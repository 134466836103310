import React from "react";

const Wallpaper = props => (
  <svg width={1017} width={1082} viewBox="0 0 1017 1082" {...props}>
    <style>
      {`.wallpaper-svg-path {fill:none;stroke:#88c981;stroke-width:5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}`}
    </style>
    <g opacity={0.7}>
      <path
        className="wallpaper-svg-path"
        d="M81 1052.2c-26.4-12.3-51.8-26.4-75.9-42.2M670.5 1.2c162.1 108.4 268.9 293.1 268.9 502.7 0 271.9-179.6 501.8-426.6 577.7"
      />
      <path
        className="wallpaper-svg-path"
        d="M827.4 1081.6l-137-137c-25.7-25.7-27.6-65.9-4.2-89.3l6-6.3c4.3-4.5 8.6-9.1 12.7-13.7 78.8-87.9 126.7-204.1 126.7-331.5m-28.4 383.5L998 1082M499.5 260c-50.8-20.2-106.3-31.3-164.3-31.3m394.1 237.7c-14.7-27.8-32.3-53.9-52.3-77.9M564.5 715.7c-67 40.3-145.5 63.5-229.3 63.5M729 542c-20.6 38.9-46.8 74.4-77.5 105.3m-414-407.9c10.1-2.3 20.3-4.2 30.7-5.7M3 377.4c43.1-48.3 96.6-87.1 157-112.9M5 632.7c81.5 89.9 199.2 146.4 330.1 146.4m.1-251.1v68.2M32.1 263.1v-96.7H236v96.7H32.1"
      />
      <path
        className="wallpaper-svg-path"
        d="M184.5 261.1v-28.5H80.2v28.5m242.1 2h-40.4c-7.1 0-12.9-5.8-12.9-12.9v-70.9c0-7.1 5.8-12.9 12.9-12.9h40.4c7.1 0 12.9 5.8 12.9 12.9v70.9c0 7.1-5.8 12.9-12.9 12.9zm-21.2-96.7v35.8M109.3 85.3V53.7M139 85.3v-59m29.6 59v-75m29.7 75v-75m29.7 75V1.2m29.6 84.1V1.2m200 918.8h-77.4l-45.4 35.6V810.5h122.8zm-95.5-78.2h68.2m-68.2 23.1h68.2m-68.2 23h68.2"
      />
      <circle className="wallpaper-svg-path" cx={746.5} cy={503.9} r={40.3} />
      <path
        className="wallpaper-svg-path"
        d="M5 541c4.8 2 10.1 3.2 15.7 3.2 22.2 0 40.3-18 40.3-40.3s-18-40.3-40.3-40.3c-5.6 0-10.8 1.1-15.7 3.2m741.5 21.6v31m-725.8-31v31M762 503.9h-31"
      />
      <circle className="wallpaper-svg-path" cx={609} cy={683.7} r={52.4} />
      <path
        className="wallpaper-svg-path"
        d="M609 680.8v266.6c0 7.1-5.8 13-13 13h-23.8c-7.1 0-13-5.9-13-13V799.8c0-7.1-5.8-13-13-13H521c-7.2 0-13 5.9-13 13v229.6c0 7.2-5.9 13-13 13h-24.4c-7.1 0-13-5.8-13-13V967c0-7.2-5.8-13-13-13h-25.1c-7.1 0-13 5.8-13 13v64.5c0 7.2-5.9 13-13 13h-42.7"
      />
      <path
        className="wallpaper-svg-path"
        d="M586 691.2l23-23 23 23m-91.1-187.3h164.5M384.3 229.4l169-169M335.6 229.1L520.1 44.7M334.8 181L483.1 32.7m-146.8 97.8L446.9 20M336.8 83.7l69.3-69.4m-71.3 22.4l26.6-26.6M662.2 194l32.7-32.7M626.2 181l43.1-43m-100.5 51.5l74.2-74.2M464.5 244.8L613.7 95.6m-186 139.6L586.3 76.7m123.5 167.6l28.4-28.5m-49.4.6l28.6-28.7m14.4 277.1l83.3-83.3m-102.7 53.7l90-90M692.6 406l96-96m-78.3 29.4l63.7-63.7m-54.7 8.3l37.1-37.1m31 257.6L830 462m-58.8 9.7l53.2-53.1"
      />
      <circle className="wallpaper-svg-path" cx={609} cy={292.6} r={110.7} />
      <path className="wallpaper-svg-path" d="M700.4 230.2L609 294.7V181.9" />
      <circle className="wallpaper-svg-path" cx={335.2} cy={503.9} r={147.4} />
      <path
        className="wallpaper-svg-path"
        d="M335.2 358.7v292.6m0-294.8c45.9 0 83.1 66 83.1 147.4s-37.2 147.4-83.1 147.4"
      />
      <path
        className="wallpaper-svg-path"
        d="M335.2 651.3c-45.9 0-83.1-66-83.1-147.4s37.2-147.4 83.1-147.4M211.7 503.9h246.9m-222-72.6h197.2M236.6 576.6h197.2M22.7 503.9h76m782.6 85.6c4.3-27.9 6.6-56.5 6.6-85.6C887.9 285.6 761.3 96.8 577.4 7m335.5 1075L725.7 894.9l4.3-4.2c41.7-42.5 76.6-91.8 102.8-146m-528.7 257.8V880.2c0-48.6-39.4-88.1-88.1-88.1-49.8 0-90.1 40.4-90.1 90.1v120.2"
      />
      <path
        className="wallpaper-svg-path"
        d="M347.7 1081.2v-42.8c0-15.9-11.2-29.2-26-32.7l-71.8-13v-34.3m-69.8-.1v34.3l-71.8 13c-14.9 3.4-26 16.8-26 32.7v42.8"
      />
      <path
        className="wallpaper-svg-path"
        d="M271.9 882v23.5c0 34.9-25.5 63.3-56.9 63.3s-56.9-28.3-56.9-63.3V879l32.5-35.1s21.9 26.9 72.6 36.3l8.7 1.8zm-68.7-197.1c-32.9-23.9-59-56.6-74.9-94.6l-1.3-3.2-4.9 1.9L5 635.8m198.2 49.1L4.6 956.7m207.1 62.7v61.8M5 793h116.9M5 760.1h143.4M5 727.2h120.9M5 859.4h67m-67-33h95M5 694.2h63.8M5 661.8h27.5M5 925.2h21.7M5 892.3h45.4"
      />
      <path
        className="wallpaper-svg-path"
        d="M335.2 290.4v38.8m-55.3-31.5l10.1 37.5M228.4 319l19.4 33.6m-63.6.3l27.5 27.5m-61.4 16.8l33.6 19.4m-55 32.1l37.5 10m-67.9 45.2h62"
      />
      <path
        className="wallpaper-svg-path"
        d="M157.7 562.7c1.2 3.6 2.5 7.2 3.9 10.8 27.6 68.7 94.9 117.3 173.5 117.3 86.1 0 158.6-58.2 180.3-137.5l.3-1"
      />
      <path
        className="wallpaper-svg-path"
        d="M335.2 779.2v-81.6m72.2 75.7L385.3 691m96.1 63.5L432 671.7m121.4 50.4l-81.2-81.2m101.4.6L503 600.8m187.7-1.6l-175-46.9m56.3-48.4h-49.7"
      />
      <path
        className="wallpaper-svg-path"
        d="M522.3 503.9c0-89.1-62.2-163.6-145.5-182.5M30.1.2V133h302.6V6"
      />
    </g>
  </svg>
);

export default Wallpaper;
