import React from "react";
import { copyToClipboard } from "../../resources/utils";
import theme from "@liveramp/motif/theme";
import css from "./ColorSquare.module.css";
import PropTypes from "prop-types";

export default function ColorSquare({
  hue,
  shade,
  label,
  hideHue,
  handleOpen,
  className,
  ...props
}) {
  let textColor = { color: theme.palette.getContrastText(hue[shade]) };
  return (
    <div
      className={`${css.square} ${className}`}
      style={{ backgroundColor: hue[shade] }}
      onClick={() => {
        copyToClipboard(hue[shade]);
        handleOpen(hue[shade]);
      }}
      {...props}
    >
      <code className={css.code} style={textColor}>
        {label ? label : shade}
      </code>
      {!hideHue && (
        <code className={css.code} style={textColor}>
          {hue[shade]}
        </code>
      )}
    </div>
  );
}

ColorSquare.propTypes = {
  hue: PropTypes.object,
  shade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  hideHue: PropTypes.bool,
  handleOpen: PropTypes.func
};

ColorSquare.defaultProps = {
  hideHue: false,
  handleOpen: () => {}
};
