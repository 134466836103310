import FormHelperText from "@material-ui/core/FormHelperText";
import { FormHelperTextStyle } from "../../utils/styleKeys";
export default FormHelperText;

export let styles = theme => ({
  ...FormHelperTextStyle,
  root: {
    marginTop: "6px",
    lineHeight: "1rem"
  },
  contained: {
    margin: "6px 4px 0"
  }
});
