import InputLabel from "@material-ui/core/InputLabel";
import { InputLabelStyle } from "../../utils/styleKeys";
export default InputLabel;

export let styles = theme => ({
  ...InputLabelStyle,
  root: {
    fontSize: "14px"
  },
  formControl: {
    transform: "translate(0, 26px)"
  },
  outlined: {
    transform: "translate(8px, 30px)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(8px, 0)"
    }
  },
  shrink: {
    transform: "translate(0, 0)"
  }
});
