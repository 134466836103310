import React from "react";
import { Subtitle } from "../../resources/Page";
import Layers from "@liveramp/motif/icons/Layers";
import css from "../../resources/Page.module.css";

export default function ZIndex() {
  return (
    <div>
      <h1>Z-Index</h1>
      <Subtitle>
        The z-index attribute lets you adjust the order of the layering of
        objects when rendering content <Layers className={css.titleIcon} />
      </Subtitle>

      <p>
        Various Motif components employ a default z-index scale that has been
        designed to properly layer dialogs, alerts, tooltips, and others.
      </p>
      <p>
        These values start at an arbitrary number, high and specific enough to
        ideally avoid conflicts.
      </p>
      <ul>
        <li>
          <code>1100: appBar</code>
        </li>
        <li>
          <code>1200: drawer</code>
        </li>
        <li>
          <code>1300: dialog</code>
        </li>
        <li>
          <code>1400: alert</code>
        </li>
        <li>
          <code>1500: tooltip</code>
        </li>
      </ul>

      <p>
        You will find these in the theme under the <code>zIndex</code> key of
        the <a href="/style/theme">theme</a>.
      </p>

      <p>
        If you need to build something custom with multiple layers, keep your
        components within the appropriate range:
      </p>
      <ul>
        <li>Page Content: 0 - 1099</li>
        <li>App bars, Navigation: 1100 - 1199</li>
        <li>Drawers, Dropdowns: 1200 - 1299</li>
        <li>Dialogs: 1300 - 1399 </li>
        <li>Alerts, Notifications: 1400 - 1499 </li>
        <li>Tooltips: 1500+</li>
      </ul>
    </div>
  );
}
