import React, { useState } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import FullScreenDialog from "@liveramp/motif/core/FullScreenDialog";
import FullScreenDialogTitle from "@liveramp/motif/core/FullScreenDialogTitle";
import FullScreenDialogContent from "@liveramp/motif/core/FullScreenDialogContent";
import Paper from "@liveramp/motif/core/Paper";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function CSSGridDemo() {
  let classes = useStyles();
  let [open, setOpen] = useState(false);

  return (
    <>
      <Button variant="secondary" color="primary" onClick={() => setOpen(true)}>
        Open
      </Button>
      <FullScreenDialog open={open} onClose={() => setOpen(false)} fullScreen>
        <FullScreenDialogTitle
          renderContentRight={
            <ButtonFormGroup>
              <Button variant="text" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)}>Confirm</Button>
            </ButtonFormGroup>
          }
        >
          Dialog Title
        </FullScreenDialogTitle>
        <FullScreenDialogContent>
          <div className={classes.container}>
            <div style={{ gridColumnEnd: "span 3" }}>
              <Paper className={classes.paper}>xs=3</Paper>
            </div>
            <div style={{ gridColumnEnd: "span 6" }}>
              <Paper className={classes.paper}>xs=6</Paper>
            </div>
            <div style={{ gridColumnEnd: "span 3" }}>
              <Paper className={classes.paper}>xs=3</Paper>
            </div>
          </div>
        </FullScreenDialogContent>
      </FullScreenDialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(12, 1fr)",
    gridGap: "24px"
  },
  paper: {
    padding: "8px",
    textAlign: "center",
    whiteSpace: "nowrap",
    marginBottom: "8px",
    height: "600px",
    backgroundColor: theme.palette.secondary.light
  }
}));
