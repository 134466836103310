import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import Simple from "./demos/Simple";
import Nested from "./demos/Nested";
import Inset from "./demos/Inset";
import VirtualizedFixedSizeList from "./demos/VirtualizedFixedSizeList";
import VirtualizedVariableSizeList from "./demos/VirtualizedVariableSizeList";
import VirtualizedListScrolling from "./demos/VirtualizedListScrolling";

let demoNames = [
  "Simple",
  "Nested",
  "Inset",
  "VirtualizedFixedSizeList",
  "VirtualizedVariableSizeList",
  "VirtualizedListScrolling"
];

export default function Button() {
  let demos = generateDemos("core-component-demo/list", demoNames);

  return (
    <Page
      components={[
        "List",
        "ListItem",
        "ListItemIcon",
        "ListItemText",
        "ListSubheader",
        "VirtualizedFixedSizeList",
        "VirtualizedVariableSizeList"
      ]}
    >
      <Title>Lists</Title>
      <Subtitle>
        Lists are continuous, vertical indexes of text or images
      </Subtitle>
      <Supporting>
        Lists are a continuous group of text or images. They are composed of
        items containing primary and supplemental actions, which are represented
        by icons and text.
      </Supporting>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Nested Lists</SectionTitle>
      <Demo demo={demos.Nested}>
        <Nested />
      </Demo>

      <SectionTitle>Inset Lists</SectionTitle>
      <p>This should be used if there is no left avatar or left icon.</p>
      <Demo demo={demos.Inset}>
        <Inset />
      </Demo>

      <SectionTitle>Virtualized Lists</SectionTitle>
      <p>
        Virtualized Lists in Motif use{" "}
        <a href="https://github.com/bvaughn/react-window">react-window</a>. To
        work, they require <code>itemCount</code>, total <code>height</code>,{" "}
        total <code>width</code> and <code>itemSize</code>.
      </p>
      <p>
        If this library doesn't cover your use case, you should consider using{" "}
        <a href="https://github.com/bvaughn/react-virtualized">
          react-virtualized
        </a>{" "}
        , then alternatives like{" "}
        <a href="https://github.com/petyosi/react-virtuoso">react-virtuoso</a>.
      </p>

      <SectionTitle subtitle>Virtualized Fixed Size List</SectionTitle>
      <p>
        Use if the heights and widths of all the items are known before
        rendering, or can be set to a static value.{" "}
        <a href="https://react-window.vercel.app/#/api/FixedSizeList">
          Fixed Size List API
        </a>
      </p>
      <Demo demo={demos.VirtualizedFixedSizeList}>
        <VirtualizedFixedSizeList />
      </Demo>

      <SectionTitle subtitle>Virtualized Variable Size List</SectionTitle>
      <p>
        Use if the heights and widths of all the items are unknown or are
        variable.{" "}
        <a href="https://react-window.vercel.app/#/api/VariableSizeList">
          Variable Size List API
        </a>
      </p>
      <Demo demo={demos.VirtualizedVariableSizeList}>
        <VirtualizedVariableSizeList />
      </Demo>

      <SectionTitle subtitle>
        Loading Scrolling and Scrolling to Items
      </SectionTitle>
      <Demo demo={demos.VirtualizedListScrolling}>
        <VirtualizedListScrolling />
      </Demo>
    </Page>
  );
}
