import React from "react";
import css from "./Panel.module.css";

export default function Panel({ children, selected, bgIcon, ...props }) {
  return (
    <div className={`${css.tab} ${!selected ? css.hidden : ""}`} {...props}>
      {bgIcon && React.cloneElement(bgIcon, { className: css.icon })}
      {children}
    </div>
  );
}
