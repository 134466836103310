import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Collapse from "@liveramp/motif/core/Collapse";
import Divider from "@liveramp/motif/core/Divider";
import Drawer from "@liveramp/motif/core/Drawer";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MenuList from "@liveramp/motif/core/MenuList";
import List from "@liveramp/motif/core/List";
import ListItem from "@liveramp/motif/core/ListItem";
import ListItemText from "@liveramp/motif/core/ListItemText";
import {
  composedDemoRoutes,
  coreDemoRoutes,
  layoutsRoutes,
  rdtDemoRoutes,
  startingRoutes,
  styleRoutes
} from "./Routes";
import { apiRoutes } from "./RoutesApis";
import css from "./Menu.module.css";
import motifPackageJson from "@liveramp/motif/package.json";

const VERSIONS = [
  {
    label: `v${motifPackageJson.version}`,
    url: "/"
  },
  {
    label: "v1(rebrand docs)",
    url: "https://v1-rebrand-motif.liveramp.com"
  },
  {
    label: "v2",
    url: "https://v2-motif.liveramp.com"
  },
  {
    label: "v3",
    url: "https://motif.liveramp.com"
  }
];

export default function Menu() {
  let location = useLocation()?.pathname;
  let [gettingStarted, setGettingStarted] = useState(
    location.startsWith("/getting-started")
  );
  let [style, setStyle] = useState(location.startsWith("/style"));
  let [layouts, setLayouts] = useState(location.startsWith("/layouts"));
  let [core, setCore] = useState(location.startsWith("/core"));
  let [composed, setComposed] = useState(location.startsWith("/composed"));
  let [rdt, setRdt] = useState(location.startsWith("/rich-data-table"));
  let [apis, setApis] = useState(location.startsWith("/api"));

  return (
    <Drawer
      classes={{
        root: css.menu,
        paper: css.menu
      }}
      PaperProps={{ component: "nav" }}
      variant="permanent"
    >
      <div className={css.menuHeader}>
        <Link to="/">
          <h1>Motif</h1>
        </Link>
        <ButtonMenu
          ButtonProps={{ label: VERSIONS[0].label, variant: "contained" }}
        >
          <MenuList>
            {VERSIONS.map((v, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  window.location.href = VERSIONS[i].url;
                }}
              >
                {v.label}
              </MenuItem>
            ))}
          </MenuList>
        </ButtonMenu>
      </div>
      <Divider />

      <List className={css.scrollable} disablePadding>
        {/* Getting Started */}
        <ListItem
          button
          key="gettingStarted"
          onClick={() => setGettingStarted(!gettingStarted)}
        >
          <ListItemText>Getting Started</ListItemText>
        </ListItem>
        <Collapse in={gettingStarted} timeout="auto" unmountOnExit>
          <List disablePadding>
            {startingRoutes.map(i => {
              let linkTo = `/getting-started/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* Style */}
        <ListItem button key="style" onClick={() => setStyle(!style)}>
          <ListItemText>Style</ListItemText>
        </ListItem>
        <Collapse in={style} timeout="auto" unmountOnExit>
          <List disablePadding>
            {styleRoutes.map(i => {
              let linkTo = `/style/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* Layouts */}
        <ListItem button key="layouts" onClick={() => setLayouts(!layouts)}>
          <ListItemText>Layouts</ListItemText>
        </ListItem>
        <Collapse in={layouts} timeout="auto" unmountOnExit>
          <List disablePadding>
            {layoutsRoutes.map(i => {
              let linkTo = `/layouts/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* Icons */}
        <ListItem
          button
          component={Link}
          key="icons"
          to="/icons"
          selected={location === "/icons"}
        >
          <ListItemText>Icons</ListItemText>
        </ListItem>

        {/* Core */}
        <ListItem
          button
          component={Link}
          key="coreComponents"
          to="/core"
          selected={location === "/core"}
          onClick={() => setCore(!core)}
        >
          <ListItemText>Core Components</ListItemText>
        </ListItem>
        <Collapse in={core} timeout="auto" unmountOnExit>
          <List disablePadding>
            {coreDemoRoutes.map(i => {
              let linkTo = `/core/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* Composed */}
        <ListItem
          button
          component={Link}
          key="composedComponents"
          to="/composed"
          selected={location === "/composed"}
          onClick={() => setComposed(!composed)}
        >
          <ListItemText>Composed Components</ListItemText>
        </ListItem>
        <Collapse in={composed} timeout="auto" unmountOnExit>
          <List disablePadding>
            {composedDemoRoutes.map(i => {
              let linkTo = `/composed/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* RDT */}
        <ListItem
          button
          component={Link}
          key="richDataTable"
          to="/rich-data-table"
          selected={location === "/rich-data-table"}
          onClick={() => setRdt(!rdt)}
        >
          <ListItemText>Rich Data Table</ListItemText>
        </ListItem>
        <Collapse in={rdt} timeout="auto" unmountOnExit>
          <List disablePadding>
            {rdtDemoRoutes.map(i => {
              let linkTo = `/rich-data-table/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>

        {/* API */}
        <ListItem button key="componentApis" onClick={() => setApis(!apis)}>
          <ListItemText>Component APIs</ListItemText>
        </ListItem>
        <Collapse in={apis} timeout="auto" unmountOnExit>
          <List disablePadding>
            {apiRoutes.map(i => {
              let linkTo = `/api/${i.link}`;
              return (
                <Link to={linkTo} key={i.name}>
                  <ListItem
                    className={css.inset}
                    selected={location === linkTo}
                    button
                  >
                    <ListItemText disableTypography className={css.inset}>
                      {i.name}
                    </ListItemText>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </Collapse>
      </List>
    </Drawer>
  );
}
