import React from "react";
import TreeView from "@liveramp/motif/core/TreeView";
import TreeItem from "@liveramp/motif/core/TreeItem";
import { CaretDown, CaretRight, Folder } from "@liveramp/motif/icons";

const WithIconsDemo = () => {
  const createWithChildren = label => (
    <>
      <Folder />
      <span>{label}</span>
    </>
  );

  return (
    <TreeView
      defaultCollapseIcon={<CaretDown />}
      defaultExpandIcon={<CaretRight />}
      defaultExpanded={["1", "5", "6", "7"]}
    >
      <TreeItem nodeId="1" label={createWithChildren("Applications")}>
        <TreeItem nodeId="2" label="Calendar" />
        <TreeItem nodeId="3" label="Chrome" />
        <TreeItem nodeId="4" label="Webstorm" />
      </TreeItem>
      <TreeItem nodeId="5" label={createWithChildren("Documents")}>
        <TreeItem nodeId="6" label={createWithChildren("Motif")}>
          <TreeItem nodeId="7" label={createWithChildren("src")}>
            <TreeItem nodeId="8" label="index.js" />
            <TreeItem nodeId="9" label="tree-view.js" />
          </TreeItem>
        </TreeItem>
        <TreeItem nodeId="10" label="OSS" />
      </TreeItem>
    </TreeView>
  );
};

export default WithIconsDemo;
