let overrideDocs = {
  props: {
    navigation: {
      type: { name: "object" },
      required: true,
      description:
        "Defines the hierarchical structure and content (item titles, links, and icons) of the nav. Determines whether country selection is included."
    },
    accountSwitcher: {
      type: { name: "object" },
      required: true,
      description:
        "Object with keys: value (the current account/option), options (array of options), getOptionLabel (function given an option, returns the label), getOptionLink (function given an option, returns the link), onChange (function executed on change, inputs are an event and an option)"
    },
    customisation: {
      type: { name: "object" },
      required: false,
      description:
        "Object with optional keys logo (icon displayed at top left in nav), label (branding text at top of L2 or L3), link (link from the branding text), theme (color), backgroundColor"
    },
    onOpen: {
      type: { name: "func" },
      required: false,
      description:
        "Triggered on the GN open event"
    },
    onClose: {
      type: { name: "func" },
      required: false,
      description:
        "Triggered on the GN close event"
    }
  }
};

export default overrideDocs;
