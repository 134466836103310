import React from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";

export default function PrimaryButtonDemo() {
  return (
    <ButtonFormGroup>
      <Button color="primary">Primary</Button>
      <Button disabled>Disabled</Button>
    </ButtonFormGroup>
  );
}
