import { Component } from "react";
import { withRouter } from "react-router-dom";

class Scroll extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}
export let ScrollToTop = withRouter(Scroll);

// This function needs to be more explicit about its path so
// webpack has some idea of what files to import at build time.
// Webpack no longer complains!
export function tryRequireOverride(path) {
  try {
    return require(`../component-api/components-override/${path}.js`).default;
  } catch (err) {
    return null;
  }
}

export function copyToClipboard(x) {
  var textField = document.createElement("textarea");
  textField.innerText = x;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}
