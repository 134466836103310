/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "",
  description:
    "A permanently displayed menu following https://www.w3.org/TR/wai-aria-practices/#menubutton.\r\nIt's exposed to help customization of the [`Menu`](/api/menu/) component. If you\r\nuse it separately you need to move focus into the component manually. Once\r\nthe focus is placed inside the component it is fully keyboard accessible.",
  displayName: "MenuList",
  methods: [],
  props: {
    autoFocus: {
      type: { name: "bool" },
      required: false,
      description:
        'If `true`, will focus the `[role="menu"]` container and move into tab order.',
      defaultValue: { value: "false", computed: false }
    },
    autoFocusItem: {
      type: { name: "bool" },
      required: false,
      description:
        'If `true`, will focus the first menuitem if `variant="menu"` or selected item\r\nif `variant="selectedMenu"`.',
      defaultValue: { value: "false", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "MenuList contents, normally `MenuItem`s."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    disabledItemsFocusable: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, will allow focus on disabled items.",
      defaultValue: { value: "false", computed: false }
    },
    disableListWrap: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the menu items will not wrap focus.",
      defaultValue: { value: "false", computed: false }
    },
    onKeyDown: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'menu'", computed: false },
          { value: "'selectedMenu'", computed: false }
        ]
      },
      required: false,
      description:
        "The variant to use. Use `menu` to prevent selected items from impacting the initial focus\r\nand the vertical alignment relative to the anchor element.",
      defaultValue: { value: "'selectedMenu'", computed: false }
    }
  },
  styles: {}
};
export default generatedDocs;
