/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiTable",
  description: "",
  displayName: "Table",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: true,
      description:
        "The content of the table, normally `TableHead` and `TableBody`."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'table'", computed: false }
    },
    padding: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.oneOf(['normal', 'checkbox', 'none', 'default']), props => {\n  if (props.padding === 'default') {\n    return new Error('Material-UI: padding=\"default\" was renamed to padding=\"normal\" for consistency.');\n  }\n\n  return null;\n})"
      },
      required: false,
      description:
        "Allows TableCells to inherit padding of the Table.\r\n`default` is deprecated, use `normal` instead.",
      defaultValue: { value: "'normal'", computed: false }
    },
    size: {
      type: {
        name: "enum",
        value: [
          { value: "'small'", computed: false },
          { value: "'medium'", computed: false }
        ]
      },
      required: false,
      description: "Allows TableCells to inherit size of the Table.",
      defaultValue: { value: "'medium'", computed: false }
    },
    stickyHeader: {
      type: { name: "bool" },
      required: false,
      description:
        "Set the header sticky.\r\n\r\n⚠️ It doesn't work with IE 11.",
      defaultValue: { value: "false", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    stickyHeader: {
      description:
        "Styles applied to the root element if `stickyHeader={true}`."
    }
  }
};
export default generatedDocs;
