/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiAvatar",
  description: "",
  displayName: "Avatar",
  methods: [],
  props: {
    alt: {
      type: { name: "string" },
      required: false,
      description:
        "Used in combination with `src` or `srcSet` to\r\nprovide an alt attribute for the rendered `img` element."
    },
    children: {
      type: { name: "node" },
      required: false,
      description:
        "Used to render icon or text elements inside the Avatar if `src` is not set.\r\nThis can be an element, or just a string."
    },
    classes: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.object, props => {\n  const {\n    classes\n  } = props;\n\n  if (classes == null) {\n    return null;\n  }\n\n  if (classes.circle != null && // 2 classnames? one from withStyles the other must be custom\n  classes.circle.split(' ').length > 1) {\n    throw new Error(`Material-UI: The \\`circle\\` class is deprecated. Use \\`circular\\` instead.`);\n  }\n\n  return null;\n})"
      },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'div'", computed: false }
    },
    imgProps: {
      type: { name: "object" },
      required: false,
      description:
        "Attributes applied to the `img` element if the component is used to display an image.\r\nIt can be used to listen for the loading error event."
    },
    sizes: {
      type: { name: "string" },
      required: false,
      description: "The `sizes` attribute for the `img` element."
    },
    src: {
      type: { name: "string" },
      required: false,
      description: "The `src` attribute for the `img` element."
    },
    srcSet: {
      type: { name: "string" },
      required: false,
      description:
        "The `srcSet` attribute for the `img` element.\r\nUse this attribute for responsive image display."
    },
    variant: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.oneOf(['circle', 'circular', 'rounded', 'square']), props => {\n  const {\n    variant\n  } = props;\n\n  if (variant === 'circle') {\n    throw new Error('Material-UI: `variant=\"circle\"` is deprecated. Use `variant=\"circular\"` instead.');\n  }\n\n  return null;\n})"
      },
      required: false,
      description: "The shape of the avatar.",
      defaultValue: { value: "'circular'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    colorDefault: {
      description:
        "Styles applied to the root element if not `src` or `srcSet`."
    },
    circle: {
      description: 'Styles applied to the root element if `variant="circle"`.'
    },
    circular: {
      description: 'Styles applied to the root element if `variant="circular"`.'
    },
    rounded: {
      description: 'Styles applied to the root element if `variant="rounded"`.'
    },
    square: {
      description: 'Styles applied to the root element if `variant="square"`.'
    },
    img: {
      description:
        "Styles applied to the img element if either `src` or `srcSet` is defined."
    },
    fallback: { description: "Styles applied to the fallback icon" }
  }
};
export default generatedDocs;
