import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Usage,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import CSSGrid from "./demos/CSSGrid";
import MultiColumn from "./demos/MultiColumn";

let demoNames = ["Simple", "CSSGrid", "MultiColumn"];

export default function FullScreenDialog() {
  let demos = generateDemos(
    "composed-component-demo/full-screen-dialog",
    demoNames
  );

  return (
    <Page
      components={[
        "FullScreenDialog",
        "FullScreenDialogTitle",
        "FullScreenDialogContent",
        "FullScreenDialogActions"
      ]}
    >
      <Title>Full Screen Dialogs</Title>
      <Subtitle>
        Full Screen Dialogs are generally for management of a task or require
        action(s) that are needed from users to perform a task. For other types
        of Dialogs (small and medium), see{" "}
        <a href="/core/dialog">core Dialog component</a>.
      </Subtitle>
      <Usage>
        <ul>
          <li>
            Full Screen Dialog Content areas are unopinionated and support
            composing between one and three columns
          </li>
          <li>
            Full Screen Dialogs should have clear CTAs in the Header for clearly
            managing the content within the Dialog
          </li>
          <li>
            The content can be scrollable if the interface that exposes the task
            required needs additional space
          </li>
        </ul>
      </Usage>

      <p>
        The full screen dialog requires that the entire body of content exists
        within a single <code>Dialog/Content</code> component. This ensures that
        the header can be stickied to the top of the dialog as a user scrolls.
      </p>
      <p>
        For ease in creating columns, use the <code>Grid</code> component.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Columns with CSS Grid</SectionTitle>
      <p>Alternatively, you can use CSS Grid to add columns to the Dialog.</p>
      <Demo demo={demos.CSSGrid}>
        <CSSGrid />
      </Demo>

      <SectionTitle>Composable complex Grids</SectionTitle>
      <p>
        Composable content area allows mixing and matching modules. See the
        following demo for typical multi-column Full Screen Dialog application.
      </p>
      <Demo demo={demos.MultiColumn}>
        <MultiColumn />
      </Demo>
    </Page>
  );
}
