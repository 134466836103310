import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <g fillRule="nonzero" fill="none">
    <path
      d="M7.808 7.808a.41.41 0 01.581 0l2.014 2.014 2.014-2.01a.41.41 0 01.58 0 .413.413 0 010 .577l-2.014 2.014 2.014 2.014a.41.41 0 11-.58.58l-2.014-2.014-2.014 2.014a.41.41 0 11-.58-.58l2.013-2.014-2.014-2.014a.41.41 0 010-.58z"
      fill="#EB989E"
    />
    <path
      d="M17.956 17.38l.034.03 3.444 3.47c.152.152.152.4 0 .553a.385.385 0 01-.514.03l-.034-.03-3.444-3.468a.394.394 0 010-.554.385.385 0 01.514-.031zM10.341 1.5c4.882 0 8.84 3.97 8.84 8.869 0 4.899-3.958 8.869-8.84 8.869S1.5 15.268 1.5 10.369C1.5 5.47 5.459 1.5 10.34 1.5zm0 .745c-4.476 0-8.103 3.638-8.103 8.124 0 4.486 3.627 8.124 8.103 8.124 4.475 0 8.102-3.638 8.102-8.124 0-4.486-3.627-8.124-8.102-8.124zm0 1.49a6.471 6.471 0 014.811 2.144.375.375 0 01-.024.526.367.367 0 01-.521-.025 5.736 5.736 0 00-4.266-1.899c-3.19 0-5.777 2.611-5.777 5.832 0 3.22 2.586 5.832 5.777 5.832 3.19 0 5.777-2.611 5.777-5.832a5.858 5.858 0 00-.484-2.34.374.374 0 01.19-.491.368.368 0 01.486.192 6.61 6.61 0 01.546 2.64c0 3.632-2.917 6.576-6.515 6.576-3.599 0-6.515-2.944-6.515-6.577 0-3.632 2.916-6.577 6.514-6.577z"
      fill="#424242"
    />
  </g>,
  "TableEmptyState"
);
