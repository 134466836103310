// Motif > Material UI name mapping
let componentMapping = [
  { motif: "Accordion", material: "Accordion" },
  { motif: "AccordionActions", material: "AccordionActions" },
  { motif: "AccordionDetails", material: "AccordionDetails" },
  { motif: "AccordionSummary", material: "AccordionSummary" },
  { motif: "Alert", material: "Snackbar" },
  { motif: "AlertInline", material: "Alert" },
  { motif: "AppBar", material: "AppBar" },
  { motif: "Autocomplete", material: "Autocomplete" },
  { motif: "Avatar", material: "Avatar" },
  { motif: "Badge", material: "Badge" },
  { motif: "Breadcrumbs", material: "Breadcrumbs" },
  { motif: "Button", material: "Button" },
  { motif: "ButtonBase", material: "ButtonBase" },
  { motif: "ButtonFormGroup" },
  { motif: "ButtonGroup" },
  { motif: "ButtonMenu" },
  { motif: "Card", material: "Card" },
  { motif: "CardActions", material: "CardActions" },
  { motif: "CardContent", material: "CardContent" },
  { motif: "CardHeader", material: "CardHeader" },
  { motif: "CardMedia", material: "CardMedia" },
  { motif: "Checkbox", material: "Checkbox" },
  { motif: "Chip", material: "Chip" },
  { motif: "ClickAwayListener", material: "ClickAwayListener" },
  { motif: "CloseButton" },
  { motif: "Collapse", material: "Collapse" },
  { motif: "Dialog", material: "Dialog" },
  { motif: "DialogActions", material: "DialogActions" },
  { motif: "DialogContent", material: "DialogContent" },
  { motif: "DialogTitle", material: "DialogTitle" },
  { motif: "Divider", material: "Divider" },
  { motif: "Drawer", material: "Drawer" },
  { motif: "ErrorBoundary" },
  { motif: "Fab", material: "Fab" },
  { motif: "Filter", material: "Autocomplete", composed: true },
  { motif: "Footer", material: "Drawer", composed: true },
  { motif: "FormControl", material: "FormControl" },
  { motif: "FormControlLabel", material: "FormControlLabel" },
  { motif: "FormGroup", material: "FormGroup" },
  { motif: "FormHelperText", material: "FormHelperText" },
  { motif: "FormLabel", material: "FormLabel" },
  { motif: "FullScreenDialog", material: "Dialog", composed: true },
  {
    motif: "FullScreenDialogActions",
    material: "DialogActions",
    composed: true
  },
  {
    motif: "FullScreenDialogContent",
    material: "DialogContent",
    composed: true
  },
  { motif: "FullScreenDialogTitle", material: "DialogTitle", composed: true },
  { motif: "GlobalActionButton", material: "Fab", composed: true },
  { motif: "GlobalActionButtonAction", material: "MenuItem", composed: true },
  { motif: "GlobalAdmin", composed: true },
  { motif: "GlobalAdminButton", composed: true },
  { motif: "GlobalAdminNotificationsButton", composed: true },
  { motif: "GlobalNavigation" },
  { motif: "Grid", material: "Grid" },
  { motif: "Grow", material: "Grow" },
  { motif: "Header" },
  { motif: "IconButton", material: "IconButton" },
  { motif: "InputAdornment", material: "InputAdornment" },
  { motif: "InputBase", material: "InputBase" },
  { motif: "InputLabel", material: "InputLabel" },
  { motif: "LinearProgress", material: "LinearProgress" },
  { motif: "Link", material: "Link" },
  { motif: "List", material: "List" },
  { motif: "ListItem", material: "ListItem" },
  { motif: "ListItemIcon", material: "ListItemIcon" },
  { motif: "ListItemText", material: "ListItemText" },
  { motif: "ListSubheader", material: "ListSubheader" },
  { motif: "Loader" },
  { motif: "LogoCard", material: "Card", composed: true },
  { motif: "LogoCardActions", material: "CardActions", composed: true },
  { motif: "LogoCardContent", material: "CardContent", composed: true },
  { motif: "LogoCardGroup", material: "FormGroup", composed: true },
  { motif: "LogoCardHeader", material: "CardHeader", composed: true },
  { motif: "LogoCardMedia", composed: true },
  { motif: "Menu", material: "Menu" },
  { motif: "MenuItem", material: "MenuItem" },
  { motif: "MenuList", material: "MenuList" },
  { motif: "Motif" },
  { motif: "NotificationContent" },
  { motif: "OutlinedInput", material: "OutlinedInput" },
  { motif: "Paper", material: "Paper" },
  { motif: "Popper", material: "Popper" },
  { motif: "Radio", material: "Radio" },
  { motif: "RadioGroup", material: "RadioGroup" },
  { motif: "RichDataTable" },
  { motif: "Select", material: "Autocomplete" },
  { motif: "SidePanel", material: "Drawer", composed: true },
  { motif: "SidePanelContent", composed: true },
  { motif: "SidePanelFieldValueRow", composed: true },
  { motif: "SidePanelFieldValue", composed: true },
  { motif: "SidePanelHeader", composed: true },
  { motif: "SidePanelImageSection", composed: true },
  { motif: "SidePanelList", material: "List", composed: true },
  { motif: "SidePanelListItem", material: "ListItem", composed: true },
  { motif: "SidePanelSubsection", composed: true },
  { motif: "Skeleton", material: "Skeleton" },
  { motif: "Status" },
  { motif: "Step", material: "Step" },
  { motif: "StepButton", material: "StepButton" },
  { motif: "StepConnector", material: "StepConnector" },
  { motif: "StepContent", material: "StepContent" },
  { motif: "StepIcon", material: "StepIcon" },
  { motif: "StepLabel", material: "StepLabel" },
  { motif: "Stepper", material: "Stepper" },
  { motif: "Switch", material: "Switch" },
  { motif: "Tab", material: "Tab" },
  { motif: "Table", material: "Table" },
  { motif: "TableBody", material: "TableBody" },
  { motif: "TableCell", material: "TableCell" },
  { motif: "TableFooter", material: "TableFooter" },
  { motif: "TableHeader", material: "TableHead" },
  { motif: "TableRow", material: "TableRow" },
  { motif: "TableSortLabel", material: "TableSortLabel" },
  { motif: "Tabs", material: "Tabs" },
  { motif: "TextField", material: "TextField" },
  { motif: "ToggleButton", material: "ToggleButton" },
  { motif: "ToggleButtonGroup", material: "ToggleButtonGroup" },
  { motif: "Toolbar", material: "Toolbar" },
  { motif: "Tooltip", material: "Tooltip" },
  { motif: "TreeItem", material: "TreeItem" },
  { motif: "TreeView", material: "TreeView" },
  { motif: "Typography", material: "Typography" },
  { motif: "UnderlinedInput", material: "Input" },
  { motif: "Uploader", composed: true },
  { motif: "VerticalNav", composed: true },
  { motif: "VerticalNavDivider", composed: true },
  { motif: "VerticalNavItem", composed: true },
  { motif: "VirtualizedFixedSizeList" },
  { motif: "VirtualizedVariableSizeList" }
  // { motif: "DataGrid", material: "DataGrid" }
];

export default componentMapping;
