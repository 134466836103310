/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "",
  description:
    "Listen for click events that occur somewhere in the document, outside of the element itself.\r\nFor instance, if you need to hide a menu when people click anywhere else on your page.",
  displayName: "ClickAwayListener",
  methods: [],
  props: {
    children: {
      type: { name: "custom", raw: "elementAcceptingRef.isRequired" },
      required: false,
      description: "The wrapped element."
    },
    disableReactTree: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the React tree is ignored and only the DOM tree is considered.\r\nThis prop changes how portaled elements are handled.",
      defaultValue: { value: "false", computed: false }
    },
    mouseEvent: {
      type: {
        name: "enum",
        value: [
          { value: "'onClick'", computed: false },
          { value: "'onMouseDown'", computed: false },
          { value: "'onMouseUp'", computed: false },
          { value: "false", computed: false }
        ]
      },
      required: false,
      description:
        "The mouse event to listen to. You can disable the listener by providing `false`.",
      defaultValue: { value: "'onClick'", computed: false }
    },
    onClickAway: {
      type: { name: "func" },
      required: true,
      description: 'Callback fired when a "click away" event is detected.'
    },
    touchEvent: {
      type: {
        name: "enum",
        value: [
          { value: "'onTouchEnd'", computed: false },
          { value: "'onTouchStart'", computed: false },
          { value: "false", computed: false }
        ]
      },
      required: false,
      description:
        "The touch event to listen to. You can disable the listener by providing `false`.",
      defaultValue: { value: "'onTouchEnd'", computed: false }
    }
  },
  styles: {}
};
export default generatedDocs;
