import React from "react";
import Status from "@liveramp/motif/core/Status";

export default function SimpleDemo() {
  return (
    <div style={{display: "flex", flexDirection: "column", flexWrap: "wrap", height: "150px", columnGap: "10px"}}>
      <Status variant="active" />
      <Status variant="error" />
      <Status variant="pending" />
      <Status variant="scheduled" />
      <Status variant="revoked" />
      <Status variant="expired" />

      <Status variant="success" />
      <Status variant="running" />
      <Status variant="cancelled" />
    </div>
  );
}
