import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M10.546.44c5.56 0 10.069 4.508 10.069 10.069 0 5.56-4.508 10.068-10.069 10.068C4.986 20.577.478 16.07.478 10.51.478 4.949 4.985.441 10.546.441zm1.475 6.671l-5.593 5.594a.252.252 0 00-.077.184v1.559c0 .143.113.256.256.256h1.559a.24.24 0 00.18-.077l5.598-5.593-1.923-1.923zm1.487-1.486l-.938.938 1.922 1.922.938-.938c.2-.2.2-.523 0-.723l-1.2-1.2a.51.51 0 00-.722 0z"
      fill="#BDBDBD"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "StatusDraft"
);
