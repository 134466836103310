import React, { Fragment } from "react";
import Header from "@liveramp/motif/core/Header";
import IconButton from "@liveramp/motif/core/IconButton";
import Favorite from "@liveramp/motif/icons/Favorite";
import Table from "../../table/demos/Simple";
import Filters from "./TableFilters";
import css from "./Full.module.css";

export default function FullDemo() {
  return (
    <div className={css.page}>
      <Header
        subtitle={
          <Fragment>
            Showing <span className={css.bold}>1</span> of{" "}
            <span className={css.bold}>1337</span>
          </Fragment>
        }
        rightContent={
          <IconButton>
            <Favorite />
          </IconButton>
        }
        helpText="Information about the purpose of the page and/or give context to what's important"
        onClickBackArrow={() => alert("You just clicked the back arrow!")}
      >
        Table
      </Header>
      <Filters />
      <Table />
    </div>
  );
}
