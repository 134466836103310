import React from "react";
import MuiTooltip from "@material-ui/core/Tooltip";
import { makeStyles, mergeClasses } from "../../utils";
import { TooltipStyle } from "../../utils/styleKeys";

const TooltipContent = React.forwardRef((props, ref) => (
  <div {...props} ref={ref}>
    {props.children}
  </div>
));

export default function Tooltip({
  classes,
  title = "",
  placement = "top",
  size = "small",
  children,
  ...props
}) {
  const defaultClasses = useStyles({ size });

  return (
    <MuiTooltip
      title={title && title}
      classes={mergeClasses(defaultClasses, classes)}
      placement={placement}
      arrow
      {...props}
    >
      <TooltipContent>{children}</TooltipContent>
    </MuiTooltip>
  );
}

const useStyles = makeStyles(
  theme => ({
    ...TooltipStyle,
    tooltip: {
      ...theme.typography.body2,
      textAlign: "left",
      backgroundColor: theme.palette.common.black,
      position: "relative",
      wordBreak: "break-word",
      borderRadius: "4px",
      maxWidth: "350px",
      padding: ({ size }) => (size === "large" ? "16px" : "8px 16px")
    },
    arrow: { color: theme.palette.common.black }
  }),
  { name: "MuiTooltip" }
);
