import React from "react";
import ToggleButtonGroup from "@liveramp/motif/core/ToggleButtonGroup";
import ToggleButton from "@liveramp/motif/core/ToggleButton";

export default function MultipleDemo() {
  const [formats, setFormats] = React.useState(() => ["bold", "italic"]);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  return (
    <ToggleButtonGroup
      value={formats}
      onChange={handleFormat}
      aria-label="text formatting"
    >
      <ToggleButton value="bold" aria-label="bold">
        Bold
      </ToggleButton>
      <ToggleButton value="italic" aria-label="italic">
        Italics
      </ToggleButton>
      <ToggleButton value="underlined" aria-label="underlined">
        Underline
      </ToggleButton>
      <ToggleButton value="color" aria-label="color">
        Color
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
