import React from "react";
import Button from "@liveramp/motif/core/Button";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import Switch from "@liveramp/motif/core/Switch";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import reporting1 from "@liveramp/motif/colors/reporting1";

export default function DynamicClassNameDemo() {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(true);

  const toggleChecked = () => {
    setChecked(prev => !prev);
  };

  return (
    <div className={classes.root}>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            onChange={toggleChecked}
            color="primary"
            value="dynamic-class-name"
          />
        }
        label={checked ? "Teal" : "Navy"}
      />
      <Button className={checked ? classes.teal : classes.navy}>
        Class name
      </Button>
    </div>
  );
}

const useStyles = makeStyles({
  root: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr"
  },
  teal: {
    background: `linear-gradient(45deg, ${reporting1[100]} 20%, ${reporting1[200]} 60%, ${reporting1[400]} 90%)`
  },
  navy: {
    background: `linear-gradient(45deg,${reporting1[500]} 20%, ${reporting1[700]} 60%, ${reporting1[800]} 90%)`
  }
});
