import React from "react";

export const Manage = props => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3518 4.0553C13.6555 4.0553 13.9018 4.30154 13.9018 4.6053V7.13503C13.9018 7.43878 13.6555 7.68503 13.3518 7.68503C13.048 7.68503 12.8018 7.43878 12.8018 7.13503V4.6053C12.8018 4.30154 13.048 4.0553 13.3518 4.0553Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.0002 4.15271C2.30395 4.15271 2.5502 4.39895 2.5502 4.70271V12.4054C2.5502 12.7034 2.7969 13.1041 3.59514 13.4984C4.35981 13.8762 5.47119 14.1592 6.78465 14.2906C7.0869 14.3208 7.30742 14.5903 7.2772 14.8926C7.24697 15.1948 6.97745 15.4153 6.6752 15.3851C5.28596 15.2462 4.03248 14.9414 3.10795 14.4847C2.217 14.0445 1.4502 13.3642 1.4502 12.4054V4.70271C1.4502 4.39895 1.69644 4.15271 2.0002 4.15271Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.39361 2.26417C4.49818 1.75096 6.00044 1.44995 7.67587 1.44995C9.3575 1.44995 10.8626 1.77743 11.9678 2.31744C13.0332 2.83799 13.9015 3.65387 13.9015 4.70265C13.9015 5.7625 13.0182 6.54227 11.9543 7.02669C10.85 7.52946 9.34889 7.82022 7.67587 7.82022V7.27022L7.67621 7.82022C7.6761 7.82022 7.67598 7.82022 7.67587 7.82022C6.31946 7.82102 4.9737 7.58069 3.70141 7.11044C3.41649 7.00513 3.27089 6.68879 3.3762 6.40387C3.48151 6.11895 3.79785 5.97335 4.08277 6.07866C5.23287 6.50375 6.44938 6.72098 7.67553 6.72022L7.67587 6.72022C9.23664 6.72022 10.5733 6.4468 11.4985 6.02558C12.4639 5.58601 12.8015 5.082 12.8015 4.70265C12.8015 4.31224 12.4489 3.77678 11.4849 3.30577C10.5608 2.85423 9.22803 2.54995 7.67587 2.54995C6.11752 2.54995 4.78194 2.83205 3.85711 3.26175C2.89215 3.71009 2.5502 4.22285 2.5502 4.60536C2.5502 4.90911 2.30395 5.15536 2.0002 5.15536C1.69644 5.15536 1.4502 4.90911 1.4502 4.60536C1.4502 3.54868 2.32919 2.75873 3.39361 2.26417Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37739 10.184C3.48466 9.89985 3.802 9.75644 4.08618 9.86371C5.0305 10.2202 6.28218 10.431 7.67573 10.431C8.02854 10.431 8.37159 10.4167 8.70086 10.3896C9.00359 10.3647 9.26921 10.5899 9.29414 10.8926C9.31908 11.1953 9.09387 11.4609 8.79114 11.4859C8.43122 11.5155 8.05805 11.531 7.67573 11.531C6.18279 11.531 4.79394 11.3066 3.69771 10.8928C3.41352 10.7856 3.27011 10.4682 3.37739 10.184Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.2898 8.64366C17.4598 8.46876 17.7246 8.42665 17.9404 8.54023L20.2796 9.77132C20.5174 9.89645 20.63 10.1751 20.5458 10.4303L20.0079 12.0616L20.8124 13.3068L22.1934 13.8241C22.4082 13.9045 22.5505 14.1098 22.5505 14.3391V16.6621C22.5505 16.877 22.4254 17.0722 22.2301 17.1619L20.9366 17.7561L20.2823 18.8401L20.6047 20.5708C20.6457 20.7906 20.5495 21.0134 20.3613 21.1343L18.2938 22.4626C18.083 22.598 17.8074 22.5739 17.6233 22.404L16.2827 21.1661H14.9208L13.7382 22.3832C13.5683 22.5581 13.3034 22.6002 13.0876 22.4866L10.7484 21.2555C10.5106 21.1304 10.3981 20.8518 10.4822 20.5966L11.0201 18.9653L10.2156 17.7201L8.83462 17.2028C8.61985 17.1224 8.47754 16.9171 8.47754 16.6878V14.3648C8.47754 14.1499 8.60268 13.9547 8.79795 13.865L10.2452 13.2001C10.5213 13.0733 10.8478 13.1943 10.9746 13.4703C11.1014 13.7463 10.9805 14.0729 10.7044 14.1997L9.57754 14.7174V16.3064L10.7718 16.7538C10.8824 16.7952 10.9768 16.8712 11.0409 16.9704L12.0868 18.5893C12.1771 18.729 12.1993 18.902 12.1472 19.06L11.6727 20.4989L13.2359 21.3217L14.2939 20.2329C14.3975 20.1263 14.5397 20.0661 14.6883 20.0661H16.4978C16.6361 20.0661 16.7693 20.1182 16.8709 20.212L18.057 21.3073L19.4556 20.4087L19.1625 18.8358C19.1378 18.7032 19.1627 18.5662 19.2324 18.4508L20.0824 17.0427C20.1393 16.9483 20.2234 16.8732 20.3236 16.8272L21.4505 16.3095V14.7204L20.2562 14.2731C20.1456 14.2316 20.0513 14.1557 19.9872 14.0565L18.9412 12.4376C18.851 12.2979 18.8288 12.1248 18.8809 11.9669L19.3554 10.5279L17.7921 9.70521L16.7342 10.794C16.6306 10.9006 16.4883 10.9607 16.3397 10.9607H14.5302C14.3919 10.9607 14.2587 10.9086 14.1571 10.8148L12.971 9.7196L11.5725 10.6181L11.8655 12.1911C11.9212 12.4897 11.7242 12.7769 11.4256 12.8325C11.127 12.8881 10.8398 12.6912 10.7841 12.3926L10.4233 10.4561C10.3824 10.2362 10.4786 10.0135 10.6667 9.8926L12.7343 8.56422C12.945 8.42882 13.2207 8.45292 13.4047 8.62286L14.7453 9.86073H16.1072L17.2898 8.64366Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5142 13.0905C14.176 13.0905 13.0912 14.1753 13.0912 15.5135C13.0912 16.8516 14.176 17.9364 15.5142 17.9364C16.8524 17.9364 17.9372 16.8516 17.9372 15.5135C17.9372 14.1753 16.8524 13.0905 15.5142 13.0905ZM11.9912 15.5135C11.9912 13.5678 13.5685 11.9905 15.5142 11.9905C17.4599 11.9905 19.0372 13.5678 19.0372 15.5135C19.0372 17.4591 17.4599 19.0364 15.5142 19.0364C13.5685 19.0364 11.9912 17.4591 11.9912 15.5135Z"
        fill="white"
      />
    </svg>
  );
};

export default Manage;
