import React, { useState } from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepButton from "@liveramp/motif/core/StepButton";
import StepLabel from "@liveramp/motif/core/StepLabel";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Button from "@liveramp/motif/core/Button";
import css from "../../../resources/Page.module.css";

export default function NonLinearDemo() {
  const steps = getSteps();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();

  const handleNext = () => {
    // It's the last step, but not all steps have been completed, so
    // find the first step that has been completed
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = step => () => setActiveStep(step);

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <div className={css.fullWidth}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map(({ label, labelProps }, index) => (
          <Step key={label}>
            <StepButton
              onClick={handleStep(index)}
              completed={completed[index]}
              hideStepNum
            >
              <StepLabel {...labelProps}>{label}</StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <ButtonFormGroup>
        {allStepsCompleted() ? (
          <Button onClick={handleReset}>Reset</Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleComplete}
            disabled={completed[activeStep]}
          >
            {completedSteps() === totalSteps() - 1 ? "Finish" : "Complete Step"}
          </Button>
        )}
      </ButtonFormGroup>
    </div>
  );
}

function getSteps() {
  return [
    {
      label: "Code",
      labelProps: {
        optional: "Hack code"
      }
    },
    {
      label: "Debug",
      labelProps: {
        optional: "Fix bugs"
      }
    },
    {
      label: "Refactor",
      labelProps: {
        optional: "Make it nice"
      }
    }
  ];
}
