import React, { useState } from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import { columns, generateRows } from "../../demos/data";

export default function PaginationDemo() {
  const rows = generateRows(100);
  const [pageSize, setPageSize] = useState(5);

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      pageSize={pageSize}
      onPageSizeChange={pageSize => setPageSize(pageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      pagination
    />
  );
}
