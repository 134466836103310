import React, { useState, useRef } from "react";
import clsx from "clsx";
import {
  DataGridPro as MuiDataGridPro,
  LicenseInfo
} from "@mui/x-data-grid-pro";
import makeStyles from "../../styles/makeStyles";
import { useOutsideAlerter } from "../../utils";

const LICENSE_KEY =
  "3c96ae773cc057837a0a53f90ca02209T1JERVI6MjAwMjAsRVhQSVJZPTE2NDE2OTc4NzIwMDAsS0VZVkVSU0lPTj0x";

const registerLicenseKey = () => {
  if (LicenseInfo.getKey() !== LICENSE_KEY) {
    LicenseInfo.setLicenseKey(LICENSE_KEY);
  }
};

registerLicenseKey();

const ROW_ACTIVE_TIME = 500;

export default function DataGrid({
  checkboxSelection = false,
  hideFooterSelectedRowCount = false,
  className,
  onRowClick,
  showPointerOnRows = false,
  showActiveRow = false,
  getRowClassName: getRowClassNameProp,
  ...props
}) {
  const [activeRowId, setActiveRowId] = useState(false);

  const classes = useStyles();
  const rowClickedRef = useRef();
  useOutsideAlerter(rowClickedRef);

  let handleRowClick = (params, event, details) => {
    const row = event.currentTarget;
    row.classList.add("Mui-active");

    // if menu button is clicked leave selected row style
    const classes = event.target.classList.value.split(" ");
    if (
      !classes.includes("MuiDataGrid-cell") &&
      !classes.includes("ButtonMenu-root")
    ) {
      row.classList.add("Mui-selected");
    }
    rowClickedRef.current = row;

    setActiveRowId(params.id);

    onRowClick && onRowClick(params, event, details);

    setTimeout(() => row.classList.remove("Mui-active"), ROW_ACTIVE_TIME);
  };

  const getRowClassName = params =>
    clsx(
      { ["MuiDataGrid-rowPointer"]: showPointerOnRows },
      params.row["id"] == activeRowId && showActiveRow
        ? "MuiDataGrid-row-active"
        : "",
      getRowClassNameProp
    );
  registerLicenseKey();
  return (
    <MuiDataGridPro
      className={clsx(classes.root, className)}
      onRowClick={handleRowClick}
      getRowClassName={getRowClassName}
      checkboxSelection={checkboxSelection}
      hideFooterSelectedRowCount={
        hideFooterSelectedRowCount || !checkboxSelection
      }
      autoHeight
      {...props}
    />
  );
}

let useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    border: 0,
    ...theme.typography.body3,

    /** TABLE STYLING **/
    "& .MuiDataGrid-main": {
      overflow: "hidden"
    },

    /** CELL STYLING **/
    // cell focus outline
    "& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus": {
      outline: 0
    },

    /** ROW STYLING **/
    "& .MuiDataGrid-dataContainer": {
      // row lines
      "& .MuiDataGrid-cell": {
        borderBottom: 0,
        borderTop: `thin solid ${theme.palette.secondary.light}`
      },
      "& .MuiDataGrid-row:last-child .MuiDataGrid-cell": {
        borderBottom: `thin solid ${theme.palette.secondary.light}`
      },
      "& .MuiDataGrid-row:first-child .MuiDataGrid-cell": {
        borderTop: `thin solid ${theme.palette.common.white}`
      },

      // row hover
      "& .MuiDataGrid-row:hover, & .MuiDataGrid-row.Mui-selected:hover": {
        backgroundColor: theme.palette.info.hover,
        "& .MuiDataGrid-cell, & + .MuiDataGrid-row .MuiDataGrid-cell": {
          borderTop: `thin solid ${theme.palette.info.main}`
        },
        "&:last-child .MuiDataGrid-cell": {
          borderBottom: `thin solid ${theme.palette.info.main}`
        }
      },

      // row active
      "& .MuiDataGrid-row.Mui-active, .MuiDataGrid-row.Mui-selected.Mui-active":
        {
          animation: `$animateRowActive ${ROW_ACTIVE_TIME}ms`
        },
      "& .MuiDataGrid-rowPointer": {
        cursor: "pointer"
      }
    },

    // row selected
    "& .MuiDataGrid-row.Mui-selected": {
      backgroundColor: "transparent",
      color: theme.palette.text.primary,
      "& .MuiDataGrid-cellRowMenu .MuiButtonBase-root": {
        visibility: "visible"
      }
    },

    // row active when show side panel
    "& .MuiDataGrid-row.MuiDataGrid-row-active": {
      backgroundColor: theme.palette.info.hover,
      borderTop: `thin solid ${theme.palette.info.main}`,
      borderBottom: `thin solid ${theme.palette.info.main}`,
      color: theme.palette.text.primary
    },

    /** HEADER BAR STYLING **/
    "& .MuiDataGrid-columnsContainer": {
      borderBottom: `2px solid ${theme.palette.secondary.light}`
    },

    /** HEADER COLUMN STYLING **/
    // column separator non-data columns
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnSeparator,\
     & [data-field='__rowMenu__'] .MuiDataGrid-columnSeparator": {
      display: "none"
    },

    // column separator resizable
    "& .MuiDataGrid-columnSeparator--resizable:hover": {
      color: theme.palette.info.main
    },

    // column header draggable
    "& .MuiDataGrid-columnHeaderDraggableContainer": {
      cursor: "pointer"
    },

    // column focus outline
    "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
      {
        outline: "none"
      },

    // column icons
    "& .MuiDataGrid-iconButtonContainer": {
      fontSize: theme.typography.h4.fontSize,
      backgroundColor: theme.palette.common.white,
      display: "flex",
      "& .MuiIconButton-root": {
        color: theme.palette.secondary.main,
        padding: 0,
        "&:hover": {
          backgroundColor: "transparent",
          color: theme.palette.info.main
        },
        "& .MuiTouchRipple-root": {
          display: "none"
        }
      },
      "& .MuiBadge-badge": {
        display: "none"
      }
    },

    // column checkbox and row menu progressive disclosure
    "& .MuiDataGrid-cellCheckbox .MuiButtonBase-root, \
    & .MuiDataGrid-cellRowMenu .MuiButtonBase-root": {
      visibility: "hidden"
    },
    "& .MuiDataGrid-row:hover .MuiDataGrid-cellCheckbox .MuiButtonBase-root, & .MuiCheckbox-colorPrimary.Mui-checked, \
     & .MuiDataGrid-row:hover .MuiDataGrid-cellRowMenu .MuiButtonBase-root": {
      visibility: "visible"
    }
  },
  "@keyframes animateRowActive": {
    "0%": { backgroundColor: theme.palette.info.hover },
    "50%": { backgroundColor: theme.palette.info.active },
    "100%": { backgroundColor: theme.palette.info.hover }
  }
}));

export * from "@mui/x-data-grid-pro";

export { registerLicenseKey };
