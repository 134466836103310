import React from "react";
import Button from "@liveramp/motif/core/Button";
import IconButton from "@liveramp/motif/core/IconButton";
import LogoCard from "@liveramp/motif/core/LogoCard";
import LogoCardActions from "@liveramp/motif/core/LogoCardActions";
import LogoCardHeader from "@liveramp/motif/core/LogoCardHeader";
import LogoCardMedia from "@liveramp/motif/core/LogoCardMedia";
import Tooltip from "@liveramp/motif/core/Tooltip";

import makeStyles from "@liveramp/motif/styles/makeStyles";
import StatusQueued from "@liveramp/motif/icons/StatusQueued";
import Microsoft from "@liveramp/motif/icons/Microsoft";
import MoreHoriz from "@liveramp/motif/icons/MoreHorizontal";
import Settings from "@liveramp/motif/icons/Settings";
import Delete from "@liveramp/motif/icons/Send";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MenuList from "@liveramp/motif/core/MenuList";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";

export default function ActionsWithMenuDemo() {
  let classes = useStyles();

  return (
    <div>
      <LogoCard size="large">
        <LogoCardHeader
          title={
            <>
              with <code>ButtonMenu</code>
            </>
          }
          status={
            <Tooltip title="Queued">
              <StatusQueued />
            </Tooltip>
          }
        />
        <LogoCardMedia>
          <Microsoft alt="Microsoft Logo" title="Microsoft Logo" />
        </LogoCardMedia>
        <LogoCardActions renderOnHover={false}>
          <div className={classes.actions}>
            <Button variant="outlined" color="secondary">
              CTA
            </Button>
            <ButtonFormGroup>
              <ButtonMenu
                component={
                  <IconButton>
                    <MoreHoriz />
                  </IconButton>
                }
              >
                <MenuList>
                  <MenuItem>
                    <ListItemIcon>
                      <Delete fontSize="small" />
                    </ListItemIcon>
                    <span>Delete</span>
                  </MenuItem>
                  <MenuItem>
                    <ListItemIcon>
                      <Settings fontSize="small" />
                    </ListItemIcon>
                    <span>Settings</span>
                  </MenuItem>
                </MenuList>
              </ButtonMenu>
            </ButtonFormGroup>
          </div>
        </LogoCardActions>
      </LogoCard>
    </div>
  );
}

let useStyles = makeStyles(theme => ({
  actions: {
    display: "flex",
    justifyContent: "space-between"
  }
}));
