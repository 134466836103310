/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiFormGroup",
  description:
    "`LogoCardGroup` wraps controls such as `Checkbox` and `Switch`.\r\nIt provides compact row layout.\r\nFor the `Radio`, you should be using the `RadioGroup` component instead of this one.",
  displayName: "FormGroup",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    row: {
      type: { name: "bool" },
      required: false,
      description: "Display group of elements in a compact row.",
      defaultValue: { value: "false", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    row: { description: "Styles applied to the root element if `row={true}`." }
  }
};
export default generatedDocs;
