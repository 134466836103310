import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import FormControl from "@liveramp/motif/core/FormControl";
import FormLabel from "@liveramp/motif/core/FormLabel";
import MenuItem from "@liveramp/motif/core/MenuItem";
import { columns, rows } from "../../demos/data";

export default function SlotsDGSettingsMenuDemo() {
  const bonusInput = apiRef => {
    const deselectAllRows = () => {
      apiRef.current.selectRows(
        apiRef.current.getSelectedRows().map(row => row.id),
        false
      );
    };

    const selectEvenRows = () => {
      deselectAllRows();
      apiRef.current.selectRows(
        apiRef.current.state.rows.allRows.filter((row, i) => i % 2 === 0)
      );
    };

    const selectOddRows = () => {
      deselectAllRows();
      apiRef.current.selectRows(
        apiRef.current.state.rows.allRows.filter((row, i) => i % 2 !== 0)
      );
    };

    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Manage Columns</FormLabel>
        <MenuItem key="even" onClick={selectEvenRows}>
          Select only even rows
        </MenuItem>
        <MenuItem key="odd" onClick={selectOddRows}>
          Select only odd rows
        </MenuItem>
      </FormControl>
    );
  };

  return (
    <RichDataTable
      columns={columns}
      rows={rows}
      settingsMenu={{
        hideableColumns: [columns[3]],
        additionalControls: apiRef => bonusInput(apiRef)
      }}
    />
  );
}
