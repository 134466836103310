import createTheme from "@material-ui/core/styles/createTheme";
import chroma from "chroma-js";
import blue from "./colors/blue";
import green from "./colors/green";
import grey from "./colors/grey";
import orange from "./colors/orange";
import red from "./colors/red";
import yellow from "./colors/yellow";
import purple from "./colors/purple";

let baseSize = 16;
let flexo = "'Flexo', sans-serif";
let opensans = "'Open Sans', sans-serif";

// https://material-ui.com/customization/default-theme/
// https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/styles/createPalette.js
let theme = createTheme({
  palette: {
    common: {
      black: grey[900],
      white: "#fff",
      primary: green[500],
      success: green[500],
      secondary: grey[500],
      error: red[500],
      danger: red[500],
      info: blue[500],
      pending: orange[500],
      warning: yellow[500]
    },
    primary: {
      hue: green,
      light: green[200],
      main: green[500],
      dark: green[800],
      hover: chroma(green[500]).alpha(0.15).css(),
      active: chroma(green[500]).alpha(0.28).css()
    },
    // same as primary
    success: {
      hue: green,
      light: green[200],
      main: green[500],
      dark: green[800],
      hover: chroma(green[500]).alpha(0.15).css(),
      active: chroma(green[500]).alpha(0.28).css()
    },
    secondary: {
      hue: grey,
      light: grey[200],
      main: grey[500],
      dark: grey[800],
      hover: chroma(grey[500]).alpha(0.15).css(),
      active: chroma(grey[500]).alpha(0.28).css()
    },
    // same as danger
    error: {
      hue: red,
      light: red[200],
      main: red[500],
      dark: red[700],
      hover: chroma(red[500]).alpha(0.15).css(),
      active: chroma(red[500]).alpha(0.28).css()
    },
    // same as error
    danger: {
      hue: red,
      light: red[200],
      main: red[500],
      dark: red[700],
      hover: chroma(red[500]).alpha(0.15).css(),
      active: chroma(red[500]).alpha(0.28).css()
    },
    info: {
      hue: blue,
      light: blue[200],
      main: blue[500],
      dark: blue[700],
      hover: chroma(blue[500]).alpha(0.1).css(),
      active: chroma(blue[500]).alpha(0.28).css()
    },
    pending: {
      hue: orange,
      light: orange[200],
      main: orange[500],
      dark: orange[800],
      hover: chroma(orange[500]).alpha(0.15).css(),
      active: chroma(orange[500]).alpha(0.28).css()
    },
    warning: {
      hue: yellow,
      light: yellow[200],
      main: yellow[400],
      dark: yellow[600],
      hover: chroma(yellow[500]).alpha(0.15).css(),
      active: chroma(yellow[500]).alpha(0.28).css()
    },
    privacy: {
      hue: purple,
      light: purple[200],
      main: purple[500],
      dark: purple[800],
      hover: chroma(purple[500]).alpha(0.15).css(),
      active: chroma(purple[500]).alpha(0.28).css()
    },
    action: {
      activeOpacity: 0.54,
      disabledOpacity: 0.26,
      disabledBackgroundOpacity: 0.12,
      hoverOpacity: 0.08,
      selectedOpacity: 0.14,
      loadingOpacity: 0.32
    },
    disabled: {
      hue: grey,
      light: grey[100],
      main: grey[400],
      dark: grey[700]
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: grey[900],
      secondary: grey[700],
      link: "#1155cc",
      hoverLink: "#1a0dab",
      visitedLink: "#660099"
    }
  },
  transitions: {
    common: {
      enteringScreen: "225ms cubic-bezier(0.4, 0, 0.2, 1)",
      short: "250ms cubic-bezier(0.4, 0, 0.2, 1)",
      leavingScreen: "195ms cubic-bezier(0.0, 0, 0.2, 1)"
    }
  },
  // Changes in the typography object should also be reflected in
  // docs/src/index.module.css
  typography: {
    htmlFontSize: baseSize,
    fontFamily: opensans,
    fontFamilyHeader: flexo,
    fontSize: baseSize,
    fontWeightLight: 300,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontFamily: flexo,
      fontSize: 36,
      fontWeight: 400,
      lineHeight: 1.22
    },
    h2: {
      fontFamily: opensans,
      fontSize: 24,
      fontWeight: 500,
      lineHeight: 1.25
    },
    h2Branding: {
      fontFamily: flexo,
      fontSize: 24,
      fontWeight: 400,
      lineHeight: 1.25
    },
    h3: {
      fontFamily: opensans,
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.3
    },
    h3Branding: {
      fontFamily: flexo,
      fontSize: 20,
      fontWeight: 400,
      lineHeight: 1.3
    },
    h4: {
      fontFamily: opensans,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: 1.33
    },
    h4Branding: {
      fontFamily: flexo,
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.33
    },
    h5: {
      fontFamily: opensans,
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.25
    },
    h6: {
      fontFamily: opensans,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.14
    },
    subtitle1: {
      fontFamily: opensans,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5
    },
    subtitle2: {
      fontFamily: opensans,
      fontSize: 14,
      fontWeight: 600,
      lineHeight: 1.43
    },
    subtitle3: {
      fontFamily: opensans,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.33
    },
    body1: { fontFamily: opensans, fontSize: 16, lineHeight: 1.5 },
    body2: { fontFamily: opensans, fontSize: 14, lineHeight: 1.43 },
    body3: { fontFamily: opensans, fontSize: 12, lineHeight: 1.33 },
    button: { fontFamily: opensans, fontSize: 14 },
    caption: {
      fontFamily: opensans,
      fontSize: 10,
      lineHeight: 1.4
    },
    overline: { fontFamily: opensans, fontSize: 12 },
    pxToRem: size => `${size / baseSize}rem`
  },
  overrides: {
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: "20px"
      },
      fontSizeLarge: {
        fontSize: "32px"
      }
    },
    MuiTouchRipple: {
      child: {
        transform: "scale(0.95)",
        opacity: 0.35
      },
      rippleVisible: {
        opacity: 0.2
      }
    }
  },
  props: {
    MuiTypography: {
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h3",
        h4: "h4",
        h5: "h5",
        h6: "h6",
        subtitle1: "span",
        subtitle2: "span",
        body1: "p",
        body2: "p",
        caption: "span"
      }
    }
  },
  zIndex: {
    dialog: 1300,
    alert: 1400
  }
});

export default theme;
