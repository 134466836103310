import React, { Component } from "react";
import OutlinedInput from "@liveramp/motif/core/OutlinedInput";
import InputLabel from "@liveramp/motif/core/InputLabel";
import Button from "@liveramp/motif/core/Button";
import FormHelperText from "@liveramp/motif/core/FormHelperText";
import withStyles from "@liveramp/motif/styles/withStyles";
import green from "@liveramp/motif/colors/green";
import css from "./AdvancedCustom.module.css";

class CustomDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      pin5: "",
      pin6: ""
    };
  }

  handleChange = name => event => {
    event.persist();
    this.setState({ [name]: event.target.value });

    if (event.target.value) {
      let current = parseInt(name[3]);
      if (current !== 6) {
        current++;
        let e = document.getElementById(`pin${current}`);
        e.focus();
        e.select();
      } else {
        document.getElementById("verify").focus();
      }
      return;
    }
  };

  render(
    { classes } = this.props,
    { pin1, pin2, pin3, pin4, pin5, pin6 } = this.state
  ) {
    let inputProps = {
      className: css.input,
      maxLength: 1
    };

    return (
      <div className={css.grid}>
        <h4>Sign In</h4>
        <InputLabel htmlFor="pin1">
          Please enter the code we sent you to sign in
        </InputLabel>
        <div className={css.inputGroup}>
          <OutlinedInput
            id="pin1"
            value={pin1}
            onChange={this.handleChange("pin1")}
            error={Boolean(pin1 && pin1 !== "1")}
            classes={classes}
            inputProps={inputProps}
          />
          <OutlinedInput
            id="pin2"
            value={pin2}
            onChange={this.handleChange("pin2")}
            error={Boolean(pin2 && pin2 !== "2")}
            classes={classes}
            inputProps={inputProps}
          />
          <OutlinedInput
            id="pin3"
            value={pin3}
            onChange={this.handleChange("pin3")}
            error={Boolean(pin3 && pin3 !== "3")}
            classes={classes}
            inputProps={inputProps}
          />
          <OutlinedInput
            id="pin4"
            value={pin4}
            onChange={this.handleChange("pin4")}
            error={Boolean(pin4 && pin4 !== "4")}
            classes={classes}
            inputProps={inputProps}
          />
          <OutlinedInput
            id="pin5"
            value={pin5}
            onChange={this.handleChange("pin5")}
            error={Boolean(pin5 && pin5 !== "5")}
            classes={classes}
            inputProps={inputProps}
          />
          <OutlinedInput
            id="pin6"
            value={pin6}
            onChange={this.handleChange("pin6")}
            error={Boolean(pin6 && pin6 !== "6")}
            classes={classes}
            inputProps={inputProps}
          />
        </div>
        <Button id="verify" fullWidth>
          Verify my Code
        </Button>
        <FormHelperText className={css.formHelperText}>
          Didn't receive your code?{" "}
          <span className={css.fakeLink}>Send it again.</span>
        </FormHelperText>
      </div>
    );
  }
}

let styles = theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    "&.Mui-focused": {
      backgroundColor: green[50]
    },
    "& fieldset": {
      borderWidth: "2px"
    },
    "&.Mui-focused fieldset": {
      boxShadow: `1px 1px 4px ${theme.palette.primary.active}`
    },
    "&:not(.Mui-error):hover fieldset.MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.primary.main
    }
  }
});

export default withStyles(styles)(CustomDemo);
