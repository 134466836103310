import React, { Fragment } from "react";
import Select from "@liveramp/motif/core/Select";
import TextField from "@liveramp/motif/core/TextField";
import Divider from "@liveramp/motif/core/Divider";
import withStyles from "@liveramp/motif/styles/withStyles";
import css from "../../../resources/Page.module.css";
import democss from "./Custom.module.css";
import darkBlue from "@liveramp/motif/colors/darkBlue";
import blue from "@liveramp/motif/colors/blue";
import { films } from "./data";

function CustomDemo({ classes }) {
  let groupedFilms = films
    .map(film => ({
      firstLetter: /[0-9]/.test(film.title[0])
        ? "00"
        : film.title[0].toLocaleUpperCase(),
      ...film
    }))
    .sort((a, b) => b.title - a.title);

  let getOptionLabel = option => option.title;

  return (
    <div className={css.grid}>
      <Select
        id="css-modules-custom-components"
        options={groupedFilms}
        getOptionLabel={getOptionLabel}
        defaultValue={[groupedFilms[49]]}
        groupBy={option => option.firstLetter}
        multiple
        renderInput={inputProps => (
          <TextField
            className={democss.textfield}
            label="CSS Modules"
            {...inputProps}
          />
        )}
        renderGroup={options => (
          <div className={democss.group} key={options.key}>
            <div className={democss.groupLetter}>{options.group}</div>
            <div>
              <div>{options.children}</div>
              <Divider className={democss.divider} />
            </div>
          </div>
        )}
        renderOption={(option, getOptionProps) => (
          <Fragment>{getOptionLabel(option)}</Fragment>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            let tagProps = getTagProps({ index });
            tagProps.className = `${tagProps.className} ${democss.tag}`;
            tagProps.onClick = tagProps.onDelete;
            delete tagProps.onDelete;

            return <div {...tagProps}>{getOptionLabel(option)}</div>;
          })
        }
      />

      <Select
        id="jss-withStyles-custom-components"
        classes={{ option: classes.option, tag: classes.tag }}
        options={groupedFilms}
        getOptionLabel={getOptionLabel}
        defaultValue={[groupedFilms[49]]}
        groupBy={option => option.firstLetter}
        multiple
        renderInput={inputProps => (
          <TextField
            className={classes.textfield}
            label="JSS withStyles"
            {...inputProps}
          />
        )}
        renderGroup={options => (
          <div className={classes.group} key={options.key}>
            <div className={classes.groupLetter}>{options.group}</div>
            <div>
              <div>{options.children}</div>
              <Divider className={classes.divider} />
            </div>
          </div>
        )}
        renderOption={(option, getOptionProps) => (
          <Fragment>{getOptionLabel(option)}</Fragment>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            let tagProps = getTagProps({ index });
            tagProps.onClick = tagProps.onDelete;
            delete tagProps.onDelete;

            return <div {...tagProps}>{getOptionLabel(option)}</div>;
          })
        }
      />
    </div>
  );
}

let styles = theme => ({
  textfield: {
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.info.main
    },
    "&:not(.Mui-error):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.info.main
    }
  },
  group: {
    display: "grid",
    gridTemplateColumns: "40px 1fr"
  },
  groupLetter: {
    color: blue[900],
    fontSize: "1.25rem",
    display: "flex",
    justifyContent: "center"
  },
  divider: {
    margin: "8px 16px"
  },
  tag: {
    backgroundColor: blue[100],
    color: darkBlue[500],
    fontSize: "0.75rem",
    padding: "2px 8px",
    borderRadius: "2px",
    cursor: "pointer"
  },
  option: {
    fontSize: "0.75rem",
    "&:hover, &[data-focus='true']": {
      backgroundColor: theme.palette.info.hover
    },
    "&[aria-selected='true']": {
      backgroundColor: theme.palette.info.active
    }
  }
});

export default withStyles(styles)(CustomDemo);
