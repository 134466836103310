import React, { useState } from "react";

import Radio from "@liveramp/motif/core/Radio";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";

export default function RadioTypeDemo() {
  let [radio, setRadio] = useState("a");
  let handleChange = event => setRadio(event.target.value);

  return (
    <>
      <FormControlLabel
        control={
          <Radio checked={radio === "a"} onChange={handleChange} value="a" />
        }
        label="Default"
      />
      <FormControlLabel control={<Radio />} label="Uncontrolled" />
      <FormControlLabel
        control={
          <Radio checked={true} onChange={handleChange} value="b" disabled />
        }
        label="Disabled Checked"
      />
      <FormControlLabel
        control={
          <Radio checked={false} onChange={handleChange} value="c" disabled />
        }
        label="Disabled Unchecked"
      />
    </>
  );
}
