import React from "react";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MenuList from "@liveramp/motif/core/MenuList";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";
import Paper from "@liveramp/motif/core/Paper";
import makeStyles from "@liveramp/motif/styles/makeStyles";
import DraftsIcon from "@liveramp/motif/icons/Drafts";
import SendIcon from "@liveramp/motif/icons/Send";
import PriorityHighIcon from "@liveramp/motif/icons/PriorityHigh";

export default function StaticWidthMenu() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <MenuList>
        <MenuItem>
          <ListItemIcon>
            <SendIcon fontSize="small" />
          </ListItemIcon>
          <span> A short message</span>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <PriorityHighIcon fontSize="small" />
          </ListItemIcon>
          <span> A very long text that overflows</span>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <DraftsIcon fontSize="small" />
          </ListItemIcon>
          <span
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
          >
            A very long text that overflows
          </span>
        </MenuItem>
      </MenuList>
    </Paper>
  );
}

const useStyles = makeStyles({
  root: {
    width: 230
  }
});
