import React from "react";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MenuList from "@liveramp/motif/core/MenuList";
import IconButton from "@liveramp/motif/core/IconButton";
import { SuperUniverse } from "@liveramp/motif/icons";

export default function SimpleDemo() {
  return (
    <ButtonFormGroup>
      <ButtonMenu>
        <MenuList>
          <MenuItem>Button Menu Item 1</MenuItem>
          <MenuItem>Button Menu Item 2</MenuItem>
          <MenuItem>Button Menu Item 3</MenuItem>
        </MenuList>
      </ButtonMenu>
      <ButtonMenu
        ButtonProps={{ label: "Button Menu", variant: "primary" }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "center"
          }
        }}
      >
        <MenuList>
          <MenuItem>Button Menu Item 1</MenuItem>
          <MenuItem>Button Menu Item 2</MenuItem>
          <MenuItem>Button Menu Item 3</MenuItem>
        </MenuList>
      </ButtonMenu>
      <ButtonMenu
        component={
          <IconButton>
            <SuperUniverse />
          </IconButton>
        }
      >
        <MenuList>
          <MenuItem>Button Menu Item 1</MenuItem>
          <MenuItem>Button Menu Item 2</MenuItem>
          <MenuItem>Button Menu Item 3</MenuItem>
        </MenuList>
      </ButtonMenu>
    </ButtonFormGroup>
  );
}
