import React from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import MenuItem from "@liveramp/motif/core/MenuItem";
import MenuList from "@liveramp/motif/core/MenuList";

export default function ButtonFormGroupDemo() {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gap: "16px",
        alignItems: "center"
      }}
    >
      <h6>Primary + Secondary</h6>
      <ButtonFormGroup>
        <Button>Primary</Button>
        <Button variant="secondary">Secondary</Button>
      </ButtonFormGroup>

      <h6>Two Secondarys</h6>
      <ButtonFormGroup>
        <Button variant="secondary">Secondary</Button>
        <Button variant="secondary">Secondary</Button>
      </ButtonFormGroup>

      <h6>Primary, Secondary + More</h6>
      <ButtonFormGroup>
        <Button>Primary</Button>
        <Button variant="secondary">Secondary</Button>
        <ButtonMenu>
          <MenuList>
            <MenuItem>Button Menu Item 1</MenuItem>
            <MenuItem>Button Menu Item 2</MenuItem>
            <MenuItem>Button Menu Item 3</MenuItem>
          </MenuList>
        </ButtonMenu>
      </ButtonFormGroup>
    </div>
  );
}
