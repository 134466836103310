import React from "react";
import Select from "@liveramp/motif/core/Select";
import { films } from "./data";

export default function DisabledOptionsDemo() {
  return (
    <Select
      id="disabled-options"
      label="Label"
      variant="underlined"
      options={films}
      getOptionLabel={option => option.title}
      getOptionDisabled={option => /[0-9]/.test(option.title[0])}
    />
  );
}
