import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import withStyles from "../../styles/withStyles";
import { ToggleButtonGroupStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...ToggleButtonGroupStyle,
  grouped: {
    "&:not(:first-child)": {
      borderWidth: "1px",
      marginLeft: "-1px"
    }
  }
});

export default withStyles(styles, { name: "MuiToggleButtonGroup" })(
  ToggleButtonGroup
);
