/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiListItem",
  description:
    "Uses an additional container component if `SidePanelListItemSecondaryAction` is the last child.",
  displayName: "ListItem",
  methods: [],
  props: {
    alignItems: {
      type: {
        name: "enum",
        value: [
          { value: "'flex-start'", computed: false },
          { value: "'center'", computed: false }
        ]
      },
      required: false,
      description: "Defines the `align-items` style property.",
      defaultValue: { value: "'center'", computed: false }
    },
    autoFocus: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the list item will be focused during the first mount.\r\nFocus will also be triggered if the value changes from false to true.",
      defaultValue: { value: "false", computed: false }
    },
    button: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the list item will be a button (using `ButtonBase`). Props intended\r\nfor `ButtonBase` can then be applied to `SidePanelListItem`.",
      defaultValue: { value: "false", computed: false }
    },
    children: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.node, props => {\n  const children = React.Children.toArray(props.children); // React.Children.toArray(props.children).findLastIndex(isListItemSecondaryAction)\n\n  let secondaryActionIndex = -1;\n\n  for (let i = children.length - 1; i >= 0; i -= 1) {\n    const child = children[i];\n\n    if (isMuiElement(child, ['ListItemSecondaryAction'])) {\n      secondaryActionIndex = i;\n      break;\n    }\n  } //  is ListItemSecondaryAction the last child of ListItem\n\n\n  if (secondaryActionIndex !== -1 && secondaryActionIndex !== children.length - 1) {\n    return new Error('Material-UI: You used an element after ListItemSecondaryAction. ' + 'For ListItem to detect that it has a secondary action ' + 'you must pass it as the last child to ListItem.');\n  }\n\n  return null;\n})"
      },
      required: false,
      description:
        "The content of the component. If a `SidePanelListItemSecondaryAction` is used it must\r\nbe the last child."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.\r\nBy default, it's a `li` when `button` is `false` and a `div` when `button` is `true`."
    },
    ContainerComponent: {
      type: { name: "elementType" },
      required: false,
      description:
        "The container component used when a `SidePanelListItemSecondaryAction` is the last child.",
      defaultValue: { value: "'li'", computed: false }
    },
    ContainerProps: {
      type: { name: "object" },
      required: false,
      description: "Props applied to the container component if used.",
      defaultValue: { value: "{}", computed: false }
    },
    dense: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, compact vertical padding designed for keyboard and mouse input will be used.",
      defaultValue: { value: "false", computed: false }
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the list item will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableGutters: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the left and right padding is removed.",
      defaultValue: { value: "false", computed: false }
    },
    divider: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, a 1px light border is added to the bottom of the list item.",
      defaultValue: { value: "false", computed: false }
    },
    focusVisibleClassName: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    selected: {
      type: { name: "bool" },
      required: false,
      description: "Use to apply selected styling.",
      defaultValue: { value: "false", computed: false }
    }
  },
  styles: {
    root: {
      description:
        "Styles applied to the (normally root) `component` element. May be wrapped by a `container`."
    },
    container: {
      description:
        "Styles applied to the `container` element if `children` includes `SidePanelListItemSecondaryAction`."
    },
    focusVisible: {
      description:
        "Pseudo-class applied to the `component`'s `focusVisibleClassName` prop if `button={true}`."
    },
    dense: {
      description: "Styles applied to the `component` element if dense."
    },
    alignItemsFlexStart: {
      description:
        'Styles applied to the `component` element if `alignItems="flex-start"`.'
    },
    disabled: {
      description:
        "Pseudo-class applied to the inner `component` element if `disabled={true}`."
    },
    divider: {
      description:
        "Styles applied to the inner `component` element if `divider={true}`."
    },
    gutters: {
      description:
        "Styles applied to the inner `component` element if `disableGutters={false}`."
    },
    button: {
      description:
        "Styles applied to the inner `component` element if `button={true}`."
    },
    secondaryAction: {
      description:
        "Styles applied to the `component` element if `children` includes `SidePanelListItemSecondaryAction`."
    },
    selected: {
      description:
        "Pseudo-class applied to the root element if `selected={true}`."
    }
  }
};
export default generatedDocs;
