import React from "react";

let NavManage = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <g>
      <path d="M23.36 15.932c.434-1.232.64-2.52.64-3.932 0-6.627-5.373-12-12-12-3.6 0-6.942 1.596-9.208 4.305a.7.7 0 1 0 1.074.898A10.574 10.574 0 0 1 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 1.26-.181 2.39-.56 3.468a.7.7 0 1 0 1.32.464zM20.689 23.256a.7.7 0 1 0 1.397.088l.362-5.792-5.792.362a.7.7 0 1 0 .088 1.397l4.208-.263-.263 4.208z" />
      <path d="M.64 8.068C.206 9.3 0 10.588 0 12c0 6.628 5.373 12 12 12 3.6 0 6.942-1.596 9.208-4.304a.7.7 0 0 0-1.074-.899A10.574 10.574 0 0 1 12 22.6C6.146 22.6 1.4 17.854 1.4 12c0-1.259.181-2.39.56-3.468a.7.7 0 1 0-1.32-.464zM3.096 4.904L3.36.696A.7.7 0 0 0 1.962.608L1.6 6.4l5.792-.362a.7.7 0 0 0-.088-1.397l-4.208.263z" />
    </g>
  </svg>
);

export default NavManage;
