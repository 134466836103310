/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiTabs",
  description: "",
  displayName: "Tabs",
  methods: [],
  props: {
    action: {
      type: { name: "custom", raw: "refType" },
      required: false,
      description:
        "Callback fired when the component mounts.\r\nThis is useful when you want to trigger an action programmatically.\r\nIt supports two actions: `updateIndicator()` and `updateScrollButtons()`\r\n\r\n@param {object} actions This object contains all possible actions\r\nthat can be triggered programmatically."
    },
    "aria-label": {
      type: { name: "string" },
      required: false,
      description: "The label for the Tabs as a string."
    },
    "aria-labelledby": {
      type: { name: "string" },
      required: false,
      description:
        "An id or list of ids separated by a space that label the Tabs."
    },
    centered: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the tabs will be centered.\r\nThis property is intended for large views.",
      defaultValue: { value: "false", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'div'", computed: false }
    },
    indicatorColor: {
      type: {
        name: "enum",
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description: "Determines the color of the indicator.",
      defaultValue: { value: "'secondary'", computed: false }
    },
    onChange: {
      type: { name: "func" },
      required: false,
      description:
        "Callback fired when the value changes.\r\n\r\n@param {object} event The event source of the callback\r\n@param {any} value We default to the index of the child (number)"
    },
    orientation: {
      type: {
        name: "enum",
        value: [
          { value: "'horizontal'", computed: false },
          { value: "'vertical'", computed: false }
        ]
      },
      required: false,
      description: "The tabs orientation (layout flow direction).",
      defaultValue: { value: "'horizontal'", computed: false }
    },
    ScrollButtonComponent: {
      type: { name: "elementType" },
      required: false,
      description: "The component used to render the scroll buttons.",
      defaultValue: { value: "TabScrollButton", computed: true }
    },
    scrollButtons: {
      type: {
        name: "enum",
        value: [
          { value: "'auto'", computed: false },
          { value: "'desktop'", computed: false },
          { value: "'off'", computed: false },
          { value: "'on'", computed: false }
        ]
      },
      required: false,
      description:
        "Determine behavior of scroll buttons when tabs are set to scroll:\r\n\r\n- `auto` will only present them when not all the items are visible.\r\n- `desktop` will only present them on medium and larger viewports.\r\n- `on` will always present them.\r\n- `off` will never present them.",
      defaultValue: { value: "'auto'", computed: false }
    },
    selectionFollowsFocus: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true` the selected tab changes on focus. Otherwise it only\r\nchanges on activation."
    },
    TabIndicatorProps: {
      type: { name: "object" },
      required: false,
      description: "Props applied to the tab indicator element.",
      defaultValue: { value: "{}", computed: false }
    },
    TabScrollButtonProps: {
      type: { name: "object" },
      required: false,
      description:
        "Props applied to the [`TabScrollButton`](/api/tab-scroll-button/) element."
    },
    textColor: {
      type: {
        name: "enum",
        value: [
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description: "Determines the color of the `Tab`.",
      defaultValue: { value: "'inherit'", computed: false }
    },
    value: {
      type: { name: "any" },
      required: false,
      description:
        "The value of the currently selected `Tab`.\r\nIf you don't want any selected `Tab`, you can set this property to `false`."
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'fullWidth'", computed: false },
          { value: "'scrollable'", computed: false },
          { value: "'standard'", computed: false }
        ]
      },
      required: false,
      description:
        "Determines additional display behavior of the tabs:\r\n\r\n - `scrollable` will invoke scrolling properties and allow for horizontally\r\n scrolling (or swiping) of the tab bar.\r\n -`fullWidth` will make the tabs grow to use all the available space,\r\n which should be used for small views, like on mobile.\r\n - `standard` will render the default state.",
      defaultValue: { value: "'standard'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.'
    },
    flexContainer: {
      description: "Styles applied to the flex container element."
    },
    flexContainerVertical: {
      description:
        'Styles applied to the flex container element if `orientation="vertical"`.'
    },
    centered: {
      description:
        'Styles applied to the flex container element if `centered={true}` & `!variant="scrollable"`.'
    },
    scroller: { description: "Styles applied to the tablist element." },
    fixed: {
      description:
        'Styles applied to the tablist element if `!variant="scrollable"`\b\b\b.'
    },
    scrollable: {
      description:
        'Styles applied to the tablist element if `variant="scrollable"`.'
    },
    scrollButtons: {
      description: "Styles applied to the `ScrollButtonComponent` component."
    },
    scrollButtonsDesktop: {
      description:
        'Styles applied to the `ScrollButtonComponent` component if `scrollButtons="auto"` or scrollButtons="desktop"`.'
    },
    indicator: {
      description: "Styles applied to the `TabIndicator` component."
    }
  }
};
export default generatedDocs;
