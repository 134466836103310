import React from "react";
import {
  Page,
  Title,
  Supporting,
  SectionTitle,
  Usage,
  NoticePanel
} from "../../resources/DemoPage";
import Table from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";
import Demo, { generateDemos } from "../../resources/Demo";
import SingleLine from "./demos/SingleLine.jsx";
import Wrapped from "./demos/Wrapped";
import PositionTooltip from "./demos/PositionTooltip.jsx";
import ForceTooltip from "./demos/ForceTooltip.jsx";
import Styling from "./demos/Styling";

let demoNames = ["SingleLine", "Wrapped", "Styling", "PositionTooltip", "ForceTooltip"];

export default function Truncate() {
  let demos = generateDemos("composed-component-demo/truncate", demoNames);
  return (
    <Page components={["Typography"]}>
      <Title>Truncate</Title>
      <Supporting>
        This component applies a centered, ellipsis-styled truncation to designated text.
        It supports both single-line and multi-line truncation and 
        is flexible enough to handle a wide array of font sizes and containers.
      </Supporting>
      <Usage>
        <ul>
          <li>Uses the <code>children</code> prop to display and/or truncate text based on certain conditions</li>
          <li>Has a listener on window resizing to apply/remove auto-truncation as necessary</li>
        </ul>
      </Usage>
      <NoticePanel variant="warning">
        <p>If there is a <code>position: "absolute"</code> property on the container, a fixed width property also needs to be included.</p>
      </NoticePanel>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>Prop</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Default Value</TableCell>
            <TableCell>Values</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell>
              <code>children</code>*
            </TableCell>
            <TableCell>String</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>
              Text to be truncated and displayed.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>wrapped</code>
            </TableCell>
            <TableCell>Boolean</TableCell>
            <TableCell>
              <code>false</code>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Indicates whether wrapped truncation will be used.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>tooltipPosition</code>
            </TableCell>
            <TableCell>String</TableCell>
            <TableCell>
              <code>"bottom"</code>
            </TableCell>
            <TableCell>"top", "bottom", "left", "right"</TableCell>
            <TableCell>
              Tooltip will show the full text on hover.
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <code>forceTooltip</code>
            </TableCell>
            <TableCell>Boolean</TableCell>
            <TableCell>
              <code>false</code>
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
              Show tooltip on hover even when text is not truncated.
            </TableCell>
          </TableRow>
        </TableBody>
        <caption>* is required</caption>
      </Table>
      <SectionTitle>Two Modes of Truncation</SectionTitle>
      <ol>
        <li>Single-line truncation, which will intelligently truncate your content while keeping it constrained to a single line</li>
        <li>Wrapped truncation, which automatically detects and truncates content that would otherwise exceed two lines</li>
      </ol>
      <SectionTitle subtitle>Single-Line Truncation</SectionTitle>
      <Demo demo={demos.SingleLine}>
        <SingleLine />
      </Demo>
      <SectionTitle subtitle>Wrapped Truncation</SectionTitle>
      <Demo demo={demos.Wrapped}>
        <Wrapped />
      </Demo>
      <SectionTitle>Styling</SectionTitle>
        <p><code>Typography</code> props and <code>className</code> can be passed in for styling purposes.
        In the event of overlapping styles, <code>Typography</code> takes precedence.</p>
        <Demo demo={demos.Styling}>
          <Styling />
        </Demo>
      <SectionTitle>Tooltip</SectionTitle>
      <SectionTitle subtitle>Placement</SectionTitle>
        <p>The <code>tooltipPosition</code> prop only accepts <code>top</code>, <code>bottom</code>, <code>left</code>, <code>right</code> as values.</p>
      <Demo demo={demos.PositionTooltip}>
        <PositionTooltip />
      </Demo>
      <SectionTitle subtitle>Forcing Tooltip</SectionTitle>
      <p>By default, the tooltip feature is only enabled when the text is truncated. 
        But a tooltip can be enabled in all circumstances by including the <code>forceTooltip</code> prop,
        as seen in the example below.</p>
      <Demo demo={demos.ForceTooltip}>
        <ForceTooltip />
      </Demo>
      <Supporting />
    </Page>
  );
}
