import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <g fillRule="evenodd">
      <path d="M8.702 14.197c2.107 2.603 3.052 4.778 3.052 4.778.239.488.657.485.93.014 0 0 1.475-2.689 3.161-4.792 1.686-2.104 2.959-4.555 2.959-6.197 0-3.59-2.963-6.5-6.618-6.5C8.53 1.5 5.568 4.41 5.568 8c0 1.62 1.028 3.593 3.134 6.197zm3.484-2.425c2.339 0 4.235-1.79 4.235-4s-1.896-4-4.235-4c-2.34 0-4.235 1.79-4.235 4s1.896 4 4.235 4z" />
      <path d="M10.068 7.772c0 .715.404 1.375 1.06 1.732a2.226 2.226 0 002.117 0c.655-.357 1.059-1.017 1.059-1.732 0-1.104-.949-2-2.118-2-1.17 0-2.118.896-2.118 2M6.078 22.608l.28-.03 4.137-2.796-.315-.125-6.58 1.688-.038.215 2.516 1.048zM10.353 18.992l.207-.147-4.07-2.743-.286-.024-2.437 1.004-.025.17zM13.76 18.826l.227.172 6.635-1.703.033-.157-2.465-1.062-.265-.016zM17.88 22.579l-4.07-2.797.232-.11 6.584 1.703.017.157-2.465 1.031z" />
    </g>
  </React.Fragment>,
  "Destination"
);
