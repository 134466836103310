import ToggleButton from "@material-ui/lab/ToggleButton";
import withStyles from "../../styles/withStyles";
import { ToggleButtonStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...ToggleButtonStyle,
  root: {
    borderColor: theme.palette.grey[400],
    borderRadius: "4px",
    padding: "5px 16px 5px",
    fontWeight: "600",
    transition: theme.transitions.common.short,
    "&:hover": {
      backgroundColor: theme.palette.secondary.hover
    },
    "&:focus": {
      backgroundColor: theme.palette.secondary.hover
    },
    "&.Mui-selected": {
      borderColor: theme.palette.primary.main,
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.primary.hover,
      "&:focus": {
        backgroundColor: theme.palette.primary.active
      },
      "&:hover": {
        backgroundColor: theme.palette.primary.active
      },
      "& + &": {
        borderLeft: "1px solid transparent",
        marginLeft: "-1px"
      }
    }
  }
});

export default withStyles(styles, { name: "MuiToggleButton" })(ToggleButton);

import MotifToggleButton from "./ToggleButton";
import ToggleButtonGroup from "./ToggleButtonGroup";

export { MotifToggleButton as ToggleButton, ToggleButtonGroup };
