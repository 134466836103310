import React from "react";
import { Link } from "react-router-dom";
import SegmentRobot from "@liveramp/motif/icons/SegmentRobot";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import css from "./Error.module.css";

export default function Error(props) {
  return (
    <div className={css.grid}>
      <div className={css.backgroundCircle}>
        <SegmentRobot />
      </div>
      <div className={css.info}>
        <h1 className={css.errorCode}>404</h1>
        <h3 className={css.subtitle}>Oops! Something went wrong!</h3>
        <p className={css.message}>We can't find the page you're looking for</p>
        <ButtonFormGroup>
          <Button onClick={props.history.goBack}>Go Back</Button>
          <Button variant="secondary" component={Link} to="/">
            Home Page
          </Button>
        </ButtonFormGroup>
      </div>
    </div>
  );
}
