import React from "react";
import {
  GlobalActionButton,
  Action
} from "@liveramp/motif/core/GlobalActionButton";
import MoreVertical from "@liveramp/motif/icons/MoreVertical";

export default function SimpleDemo() {
  let handleItemClick = () => {
    alert("You clicked an action ✨");
  };

  return (
    <GlobalActionButton icon={<MoreVertical />}>
      <Action onClick={handleItemClick}>Partner Manager</Action>
      <Action onClick={handleItemClick}>Lookalike Modeler</Action>
      <Action onClick={handleItemClick}>Overlap Analysis</Action>
    </GlobalActionButton>
  );
}
