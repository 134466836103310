/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

import AccordionApi from "../component-api/components/Accordion";
import AccordionActionsApi from "../component-api/components/AccordionActions";
import AccordionDetailsApi from "../component-api/components/AccordionDetails";
import AccordionSummaryApi from "../component-api/components/AccordionSummary";
import AlertApi from "../component-api/components/Alert";
import AlertInlineApi from "../component-api/components/AlertInline";
import AppBarApi from "../component-api/components/AppBar";
import AutocompleteApi from "../component-api/components/Autocomplete";
import AvatarApi from "../component-api/components/Avatar";
import BadgeApi from "../component-api/components/Badge";
import BreadcrumbsApi from "../component-api/components/Breadcrumbs";
import ButtonApi from "../component-api/components/Button";
import ButtonBaseApi from "../component-api/components/ButtonBase";
import ButtonFormGroupApi from "../component-api/components/ButtonFormGroup";
import ButtonGroupApi from "../component-api/components/ButtonGroup";
import ButtonMenuApi from "../component-api/components/ButtonMenu";
import CardApi from "../component-api/components/Card";
import CardActionsApi from "../component-api/components/CardActions";
import CardContentApi from "../component-api/components/CardContent";
import CardHeaderApi from "../component-api/components/CardHeader";
import CardMediaApi from "../component-api/components/CardMedia";
import CheckboxApi from "../component-api/components/Checkbox";
import ChipApi from "../component-api/components/Chip";
import ClickAwayListenerApi from "../component-api/components/ClickAwayListener";
import CloseButtonApi from "../component-api/components/CloseButton";
import CollapseApi from "../component-api/components/Collapse";
import DialogApi from "../component-api/components/Dialog";
import DialogActionsApi from "../component-api/components/DialogActions";
import DialogContentApi from "../component-api/components/DialogContent";
import DialogTitleApi from "../component-api/components/DialogTitle";
import DividerApi from "../component-api/components/Divider";
import DrawerApi from "../component-api/components/Drawer";
import ErrorBoundaryApi from "../component-api/components/ErrorBoundary";
import FabApi from "../component-api/components/Fab";
import FilterApi from "../component-api/components/Filter";
import FooterApi from "../component-api/components/Footer";
import FormControlApi from "../component-api/components/FormControl";
import FormControlLabelApi from "../component-api/components/FormControlLabel";
import FormGroupApi from "../component-api/components/FormGroup";
import FormHelperTextApi from "../component-api/components/FormHelperText";
import FormLabelApi from "../component-api/components/FormLabel";
import FullScreenDialogApi from "../component-api/components/FullScreenDialog";
import FullScreenDialogActionsApi from "../component-api/components/FullScreenDialogActions";
import FullScreenDialogContentApi from "../component-api/components/FullScreenDialogContent";
import FullScreenDialogTitleApi from "../component-api/components/FullScreenDialogTitle";
import GlobalActionButtonApi from "../component-api/components/GlobalActionButton";
import GlobalActionButtonActionApi from "../component-api/components/GlobalActionButtonAction";
import GlobalAdminApi from "../component-api/components/GlobalAdmin";
import GlobalAdminButtonApi from "../component-api/components/GlobalAdminButton";
import GlobalAdminNotificationsButtonApi from "../component-api/components/GlobalAdminNotificationsButton";
import GlobalNavigationApi from "../component-api/components/GlobalNavigation";
import GridApi from "../component-api/components/Grid";
import GrowApi from "../component-api/components/Grow";
import HeaderApi from "../component-api/components/Header";
import IconButtonApi from "../component-api/components/IconButton";
import InputAdornmentApi from "../component-api/components/InputAdornment";
import InputBaseApi from "../component-api/components/InputBase";
import InputLabelApi from "../component-api/components/InputLabel";
import LinearProgressApi from "../component-api/components/LinearProgress";
import LinkApi from "../component-api/components/Link";
import ListApi from "../component-api/components/List";
import ListItemApi from "../component-api/components/ListItem";
import ListItemIconApi from "../component-api/components/ListItemIcon";
import ListItemTextApi from "../component-api/components/ListItemText";
import ListSubheaderApi from "../component-api/components/ListSubheader";
import LoaderApi from "../component-api/components/Loader";
import LogoCardApi from "../component-api/components/LogoCard";
import LogoCardActionsApi from "../component-api/components/LogoCardActions";
import LogoCardContentApi from "../component-api/components/LogoCardContent";
import LogoCardGroupApi from "../component-api/components/LogoCardGroup";
import LogoCardHeaderApi from "../component-api/components/LogoCardHeader";
import LogoCardMediaApi from "../component-api/components/LogoCardMedia";
import MenuApi from "../component-api/components/Menu";
import MenuItemApi from "../component-api/components/MenuItem";
import MenuListApi from "../component-api/components/MenuList";
import MotifApi from "../component-api/components/Motif";
import NotificationContentApi from "../component-api/components/NotificationContent";
import OutlinedInputApi from "../component-api/components/OutlinedInput";
import PaperApi from "../component-api/components/Paper";
import PopperApi from "../component-api/components/Popper";
import RadioApi from "../component-api/components/Radio";
import RadioGroupApi from "../component-api/components/RadioGroup";
import RichDataTableApi from "../component-api/components/RichDataTable";
import SelectApi from "../component-api/components/Select";
import SidePanelApi from "../component-api/components/SidePanel";
import SidePanelContentApi from "../component-api/components/SidePanelContent";
import SidePanelFieldValueRowApi from "../component-api/components/SidePanelFieldValueRow";
import SidePanelFieldValueApi from "../component-api/components/SidePanelFieldValue";
import SidePanelHeaderApi from "../component-api/components/SidePanelHeader";
import SidePanelImageSectionApi from "../component-api/components/SidePanelImageSection";
import SidePanelListApi from "../component-api/components/SidePanelList";
import SidePanelListItemApi from "../component-api/components/SidePanelListItem";
import SidePanelSubsectionApi from "../component-api/components/SidePanelSubsection";
import SkeletonApi from "../component-api/components/Skeleton";
import StatusApi from "../component-api/components/Status";
import StepApi from "../component-api/components/Step";
import StepButtonApi from "../component-api/components/StepButton";
import StepConnectorApi from "../component-api/components/StepConnector";
import StepContentApi from "../component-api/components/StepContent";
import StepIconApi from "../component-api/components/StepIcon";
import StepLabelApi from "../component-api/components/StepLabel";
import StepperApi from "../component-api/components/Stepper";
import SwitchApi from "../component-api/components/Switch";
import TabApi from "../component-api/components/Tab";
import TableApi from "../component-api/components/Table";
import TableBodyApi from "../component-api/components/TableBody";
import TableCellApi from "../component-api/components/TableCell";
import TableFooterApi from "../component-api/components/TableFooter";
import TableHeaderApi from "../component-api/components/TableHeader";
import TableRowApi from "../component-api/components/TableRow";
import TabsApi from "../component-api/components/Tabs";
import TextFieldApi from "../component-api/components/TextField";
import ToggleButtonApi from "../component-api/components/ToggleButton";
import ToggleButtonGroupApi from "../component-api/components/ToggleButtonGroup";
import ToolbarApi from "../component-api/components/Toolbar";
import TooltipApi from "../component-api/components/Tooltip";
import TreeItemApi from "../component-api/components/TreeItem";
import TreeViewApi from "../component-api/components/TreeView";
import TypographyApi from "../component-api/components/Typography";
import UnderlinedInputApi from "../component-api/components/UnderlinedInput";
import UploaderApi from "../component-api/components/Uploader";
import VerticalNavApi from "../component-api/components/VerticalNav";
import VerticalNavDividerApi from "../component-api/components/VerticalNavDivider";
import VerticalNavItemApi from "../component-api/components/VerticalNavItem";
import VirtualizedFixedSizeListApi from "../component-api/components/VirtualizedFixedSizeList";
import VirtualizedVariableSizeListApi from "../component-api/components/VirtualizedVariableSizeList";

export let apiRoutes = [
  { name: "Accordion", link: "accordion", component: AccordionApi },
  {
    name: "Accordion Actions",
    link: "accordion-actions",
    component: AccordionActionsApi
  },
  {
    name: "Accordion Details",
    link: "accordion-details",
    component: AccordionDetailsApi
  },
  {
    name: "Accordion Summary",
    link: "accordion-summary",
    component: AccordionSummaryApi
  },
  { name: "Alert", link: "alert", component: AlertApi },
  { name: "Alert Inline", link: "alert-inline", component: AlertInlineApi },
  { name: "App Bar", link: "app-bar", component: AppBarApi },
  { name: "Autocomplete", link: "autocomplete", component: AutocompleteApi },
  { name: "Avatar", link: "avatar", component: AvatarApi },
  { name: "Badge", link: "badge", component: BadgeApi },
  { name: "Breadcrumbs", link: "breadcrumbs", component: BreadcrumbsApi },
  { name: "Button", link: "button", component: ButtonApi },
  { name: "Button Base", link: "button-base", component: ButtonBaseApi },
  {
    name: "Button Form Group",
    link: "button-form-group",
    component: ButtonFormGroupApi
  },
  { name: "Button Group", link: "button-group", component: ButtonGroupApi },
  { name: "Button Menu", link: "button-menu", component: ButtonMenuApi },
  { name: "Card", link: "card", component: CardApi },
  { name: "Card Actions", link: "card-actions", component: CardActionsApi },
  { name: "Card Content", link: "card-content", component: CardContentApi },
  { name: "Card Header", link: "card-header", component: CardHeaderApi },
  { name: "Card Media", link: "card-media", component: CardMediaApi },
  { name: "Checkbox", link: "checkbox", component: CheckboxApi },
  { name: "Chip", link: "chip", component: ChipApi },
  {
    name: "Click Away Listener",
    link: "click-away-listener",
    component: ClickAwayListenerApi
  },
  { name: "Close Button", link: "close-button", component: CloseButtonApi },
  { name: "Collapse", link: "collapse", component: CollapseApi },
  { name: "Dialog", link: "dialog", component: DialogApi },
  {
    name: "Dialog Actions",
    link: "dialog-actions",
    component: DialogActionsApi
  },
  {
    name: "Dialog Content",
    link: "dialog-content",
    component: DialogContentApi
  },
  { name: "Dialog Title", link: "dialog-title", component: DialogTitleApi },
  { name: "Divider", link: "divider", component: DividerApi },
  { name: "Drawer", link: "drawer", component: DrawerApi },
  {
    name: "Error Boundary",
    link: "error-boundary",
    component: ErrorBoundaryApi
  },
  { name: "Fab", link: "fab", component: FabApi },
  { name: "Filter", link: "filter", component: FilterApi },
  { name: "Footer", link: "footer", component: FooterApi },
  { name: "Form Control", link: "form-control", component: FormControlApi },
  {
    name: "Form Control Label",
    link: "form-control-label",
    component: FormControlLabelApi
  },
  { name: "Form Group", link: "form-group", component: FormGroupApi },
  {
    name: "Form Helper Text",
    link: "form-helper-text",
    component: FormHelperTextApi
  },
  { name: "Form Label", link: "form-label", component: FormLabelApi },
  {
    name: "Full Screen Dialog",
    link: "full-screen-dialog",
    component: FullScreenDialogApi
  },
  {
    name: "Full Screen Dialog Actions",
    link: "full-screen-dialog-actions",
    component: FullScreenDialogActionsApi
  },
  {
    name: "Full Screen Dialog Content",
    link: "full-screen-dialog-content",
    component: FullScreenDialogContentApi
  },
  {
    name: "Full Screen Dialog Title",
    link: "full-screen-dialog-title",
    component: FullScreenDialogTitleApi
  },
  {
    name: "Global Action Button",
    link: "global-action-button",
    component: GlobalActionButtonApi
  },
  {
    name: "Global Action Button Action",
    link: "global-action-button-action",
    component: GlobalActionButtonActionApi
  },
  { name: "Global Admin", link: "global-admin", component: GlobalAdminApi },
  {
    name: "Global Admin Button",
    link: "global-admin-button",
    component: GlobalAdminButtonApi
  },
  {
    name: "Global Admin Notifications Button",
    link: "global-admin-notifications-button",
    component: GlobalAdminNotificationsButtonApi
  },
  {
    name: "Global Navigation",
    link: "global-navigation",
    component: GlobalNavigationApi
  },
  { name: "Grid", link: "grid", component: GridApi },
  { name: "Grow", link: "grow", component: GrowApi },
  { name: "Header", link: "header", component: HeaderApi },
  { name: "Icon Button", link: "icon-button", component: IconButtonApi },
  {
    name: "Input Adornment",
    link: "input-adornment",
    component: InputAdornmentApi
  },
  { name: "Input Base", link: "input-base", component: InputBaseApi },
  { name: "Input Label", link: "input-label", component: InputLabelApi },
  {
    name: "Linear Progress",
    link: "linear-progress",
    component: LinearProgressApi
  },
  { name: "Link", link: "link", component: LinkApi },
  { name: "List", link: "list", component: ListApi },
  { name: "List Item", link: "list-item", component: ListItemApi },
  {
    name: "List Item Icon",
    link: "list-item-icon",
    component: ListItemIconApi
  },
  {
    name: "List Item Text",
    link: "list-item-text",
    component: ListItemTextApi
  },
  {
    name: "List Subheader",
    link: "list-subheader",
    component: ListSubheaderApi
  },
  { name: "Loader", link: "loader", component: LoaderApi },
  { name: "Logo Card", link: "logo-card", component: LogoCardApi },
  {
    name: "Logo Card Actions",
    link: "logo-card-actions",
    component: LogoCardActionsApi
  },
  {
    name: "Logo Card Content",
    link: "logo-card-content",
    component: LogoCardContentApi
  },
  {
    name: "Logo Card Group",
    link: "logo-card-group",
    component: LogoCardGroupApi
  },
  {
    name: "Logo Card Header",
    link: "logo-card-header",
    component: LogoCardHeaderApi
  },
  {
    name: "Logo Card Media",
    link: "logo-card-media",
    component: LogoCardMediaApi
  },
  { name: "Menu", link: "menu", component: MenuApi },
  { name: "Menu Item", link: "menu-item", component: MenuItemApi },
  { name: "Menu List", link: "menu-list", component: MenuListApi },
  { name: "Motif", link: "motif", component: MotifApi },
  {
    name: "Notification Content",
    link: "notification-content",
    component: NotificationContentApi
  },
  {
    name: "Outlined Input",
    link: "outlined-input",
    component: OutlinedInputApi
  },
  { name: "Paper", link: "paper", component: PaperApi },
  { name: "Popper", link: "popper", component: PopperApi },
  { name: "Radio", link: "radio", component: RadioApi },
  { name: "Radio Group", link: "radio-group", component: RadioGroupApi },
  {
    name: "Rich Data Table",
    link: "rich-data-table",
    component: RichDataTableApi
  },
  { name: "Select", link: "select", component: SelectApi },
  { name: "Side Panel", link: "side-panel", component: SidePanelApi },
  {
    name: "Side Panel Content",
    link: "side-panel-content",
    component: SidePanelContentApi
  },
  {
    name: "Side Panel Field Value Row",
    link: "side-panel-field-value-row",
    component: SidePanelFieldValueRowApi
  },
  {
    name: "Side Panel Field Value",
    link: "side-panel-field-value",
    component: SidePanelFieldValueApi
  },
  {
    name: "Side Panel Header",
    link: "side-panel-header",
    component: SidePanelHeaderApi
  },
  {
    name: "Side Panel Image Section",
    link: "side-panel-image-section",
    component: SidePanelImageSectionApi
  },
  {
    name: "Side Panel List",
    link: "side-panel-list",
    component: SidePanelListApi
  },
  {
    name: "Side Panel List Item",
    link: "side-panel-list-item",
    component: SidePanelListItemApi
  },
  {
    name: "Side Panel Subsection",
    link: "side-panel-subsection",
    component: SidePanelSubsectionApi
  },
  { name: "Skeleton", link: "skeleton", component: SkeletonApi },
  { name: "Status", link: "status", component: StatusApi },
  { name: "Step", link: "step", component: StepApi },
  { name: "Step Button", link: "step-button", component: StepButtonApi },
  {
    name: "Step Connector",
    link: "step-connector",
    component: StepConnectorApi
  },
  { name: "Step Content", link: "step-content", component: StepContentApi },
  { name: "Step Icon", link: "step-icon", component: StepIconApi },
  { name: "Step Label", link: "step-label", component: StepLabelApi },
  { name: "Stepper", link: "stepper", component: StepperApi },
  { name: "Switch", link: "switch", component: SwitchApi },
  { name: "Tab", link: "tab", component: TabApi },
  { name: "Table", link: "table", component: TableApi },
  { name: "Table Body", link: "table-body", component: TableBodyApi },
  { name: "Table Cell", link: "table-cell", component: TableCellApi },
  { name: "Table Footer", link: "table-footer", component: TableFooterApi },
  { name: "Table Header", link: "table-header", component: TableHeaderApi },
  { name: "Table Row", link: "table-row", component: TableRowApi },
  { name: "Tabs", link: "tabs", component: TabsApi },
  { name: "Text Field", link: "text-field", component: TextFieldApi },
  { name: "Toggle Button", link: "toggle-button", component: ToggleButtonApi },
  {
    name: "Toggle Button Group",
    link: "toggle-button-group",
    component: ToggleButtonGroupApi
  },
  { name: "Toolbar", link: "toolbar", component: ToolbarApi },
  { name: "Tooltip", link: "tooltip", component: TooltipApi },
  { name: "Tree Item", link: "tree-item", component: TreeItemApi },
  { name: "Tree View", link: "tree-view", component: TreeViewApi },
  { name: "Typography", link: "typography", component: TypographyApi },
  {
    name: "Underlined Input",
    link: "underlined-input",
    component: UnderlinedInputApi
  },
  { name: "Uploader", link: "uploader", component: UploaderApi },
  { name: "Vertical Nav", link: "vertical-nav", component: VerticalNavApi },
  {
    name: "Vertical Nav Divider",
    link: "vertical-nav-divider",
    component: VerticalNavDividerApi
  },
  {
    name: "Vertical Nav Item",
    link: "vertical-nav-item",
    component: VerticalNavItemApi
  },
  {
    name: "Virtualized Fixed Size List",
    link: "virtualized-fixed-size-list",
    component: VirtualizedFixedSizeListApi
  },
  {
    name: "Virtualized Variable Size List",
    link: "virtualized-variable-size-list",
    component: VirtualizedVariableSizeListApi
  }
];
