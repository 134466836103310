/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiTab",
  description: "",
  displayName: "Tab",
  methods: [],
  props: {
    children: {
      type: { name: "custom", raw: "unsupportedProp" },
      required: false,
      description:
        "This prop isn't supported.\r\nUse the `component` prop if you need to change the children structure."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the tab will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableFocusRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the  keyboard focus ripple will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the ripple effect will be disabled."
    },
    fullWidth: {
      type: { name: "bool" },
      required: false,
      description: "@ignore"
    },
    icon: {
      type: { name: "node" },
      required: false,
      description: "The icon element."
    },
    indicator: {
      type: { name: "node" },
      required: false,
      description:
        "@ignore\r\nFor server-side rendering consideration, we let the selected tab\r\nrender the indicator."
    },
    label: {
      type: { name: "node" },
      required: false,
      description: "The label element."
    },
    onChange: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    onClick: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    onFocus: {
      type: { name: "func" },
      required: false,
      description: "@ignore"
    },
    selected: {
      type: { name: "bool" },
      required: false,
      description: "@ignore"
    },
    selectionFollowsFocus: {
      type: { name: "bool" },
      required: false,
      description: "@ignore"
    },
    textColor: {
      type: {
        name: "enum",
        value: [
          { value: "'secondary'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'inherit'", computed: false }
        ]
      },
      required: false,
      description: "@ignore",
      defaultValue: { value: "'inherit'", computed: false }
    },
    value: {
      type: { name: "any" },
      required: false,
      description:
        "You can provide your own value. Otherwise, we fallback to the child position index."
    },
    wrapped: {
      type: { name: "bool" },
      required: false,
      description:
        "Tab labels appear in a single row.\r\nThey can use a second line if needed.",
      defaultValue: { value: "false", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    labelIcon: {
      description:
        "Styles applied to the root element if both `icon` and `label` are provided."
    },
    textColorInherit: {
      description:
        'Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="inherit"`.'
    },
    textColorPrimary: {
      description:
        'Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="primary"`.'
    },
    textColorSecondary: {
      description:
        'Styles applied to the root element if the parent [`Tabs`](/api/tabs/) has `textColor="secondary"`.'
    },
    selected: {
      description:
        "Pseudo-class applied to the root element if `selected={true}` (controlled by the Tabs component)."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}` (controlled by the Tabs component)."
    },
    fullWidth: {
      description:
        "Styles applied to the root element if `fullWidth={true}` (controlled by the Tabs component)."
    },
    wrapped: {
      description: "Styles applied to the root element if `wrapped={true}`."
    },
    wrapper: {
      description: "Styles applied to the `icon` and `label`'s wrapper element."
    }
  }
};
export default generatedDocs;
