import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import { Star, StarHalf, StarOutline } from "@liveramp/motif/icons";

const columns = [
  {
    field: "default",
    headerName: "Default",
    description: "Render row[field]",
    width: 150
  },
  {
    field: "valueGetter",
    headerName: "valueGetter()",
    description: "Get specific data instead of the row[field] to render in the cell",
    width: 150,
    valueGetter: params =>
      [
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten"
      ][params.row.id]
  },
  {
    field: "valueFormatter",
    headerName: "valueFormatter()",
    description: "Apply a formatter before rendering the value",
    width: 150,
    valueFormatter: cell => cell.value.toFixed(2)
  },
  {
    field: "renderCell",
    headerName: "renderCell()",
    description: "Apply a custom function",
    width: 150,
    renderCell: cell => {
      const total = cell.value / 2;
      const fullStars = Math.floor(total);
      const noStars = 5 - Math.ceil(total);
      const halfStars = 5 - fullStars - noStars;

      const renderStars = (count, star) => {
        const stars = [];
        for (let i = 0; i < count; i++) {
          const name = star.type.type.render.displayName;
          stars.push(React.cloneElement(star, { key: `${name}-${i}` }));
        }
        return stars;
      };

      return (
        <>
          {renderStars(fullStars, <Star />)}
          {renderStars(halfStars, <StarHalf />)}
          {renderStars(noStars, <StarOutline />)}
        </>
      );
    }
  }
];

const rows = [...Array(10)].map((row, i) => ({
  id: i,
  default: i + 1,
  valueFormatter: i + 1,
  renderCell: i + 1
}));

export default function RenderCellDemo() {
  return <RichDataTable columns={columns} rows={rows} />;
}
