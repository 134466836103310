import React, { useState } from "react";
import Button from "../Button";
import Menu from "../Menu";
import makeStyles from "../../styles/makeStyles";
import ArrowDropDown from "../../icons/ArrowDropDown";

export default function ButtonMenu({
  children,
  ButtonProps,
  MenuProps,
  component
}) {
  let [anchorEl, setAnchorEl] = useState(null);
  let buttonClasses = useButtonStyles();

  let handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  let handleClose = () => setAnchorEl(null);

  let buttonComponent = component ? (
    React.cloneElement(component, {
      onClick: handleClick
    })
  ) : (
    <Button
      // replace with className when button css overrides are fixed
      className={buttonClasses["ButtonMenu-button"]}
      onClick={handleClick}
      variant="secondary"
      {...ButtonProps}
    >
      {ButtonProps?.label ? ButtonProps.label : "More"}
      <ArrowDropDown />
    </Button>
  );

  return (
    <div className="ButtonMenu-root">
      {buttonComponent}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        {...MenuProps}
      >
        {children}
      </Menu>
    </div>
  );
}

let useButtonStyles = makeStyles(theme => ({
  "ButtonMenu-button": {
    "& .MuiSvgIcon-root": {
      marginRight: "-8px"
    }
  }
}));
