import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12 17.799 22.5 12 22.5zm1.523-7.206l2.83 2.788a.648.648 0 00.91-.924l-2.843-2.8a3.321 3.321 0 10-.897.937zm3.774-2.292V6.383c0-.78-.633-1.412-1.412-1.412H10.36c-.402 0-.785.171-1.053.47L6.262 8.848c-.232.259-.36.594-.36.941v7.626c0 .78.632 1.412 1.412 1.412h5.469a.648.648 0 000-1.297H7.314a.115.115 0 01-.115-.115V9.788c0-.028.01-.055.03-.076l3.045-3.406a.115.115 0 01.086-.038h5.525c.063 0 .115.051.115.115v6.619a.648.648 0 001.297 0zm-5.679 1.595a2.024 2.024 0 110-4.049 2.024 2.024 0 010 4.05z"
      fill="#FB9E47"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "StatusInReview"
);
