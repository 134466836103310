/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiStepButton",
  description: "",
  displayName: "StepButton",
  methods: [],
  props: {
    active: {
      type: { name: "bool" },
      required: false,
      description:
        "@ignore\r\nPassed in via `Step` - passed through to `StepLabel`."
    },
    alternativeLabel: {
      type: { name: "bool" },
      required: false,
      description:
        "@ignore\r\nSet internally by Stepper when it's supplied with the alternativeLabel property."
    },
    children: {
      type: { name: "node" },
      required: false,
      description:
        "Can be a `StepLabel` or a node to place inside `StepLabel` as children."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    completed: {
      type: { name: "bool" },
      required: false,
      description: "@ignore\r\nSets completed styling. Is passed to StepLabel."
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description:
        "@ignore\r\nDisables the button and sets disabled styling. Is passed to StepLabel."
    },
    expanded: {
      type: { name: "bool" },
      required: false,
      description: "@ignore\r\npotentially passed from parent `Step`"
    },
    icon: {
      type: { name: "node" },
      required: false,
      description: "The icon displayed by the step label."
    },
    last: { type: { name: "bool" }, required: false, description: "@ignore" },
    optional: {
      type: { name: "node" },
      required: false,
      description: "The optional node to display."
    },
    orientation: {
      type: {
        name: "enum",
        value: [
          { value: "'horizontal'", computed: false },
          { value: "'vertical'", computed: false }
        ]
      },
      required: false,
      description: "@ignore"
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    horizontal: {
      description:
        'Styles applied to the root element if `orientation="horizontal"`.'
    },
    vertical: {
      description:
        'Styles applied to the root element if `orientation="vertical"`.'
    },
    touchRipple: {
      description: "Styles applied to the `ButtonBase` touch-ripple."
    }
  }
};
export default generatedDocs;
