import React from "react";
import Stepper from "@liveramp/motif/core/Stepper";
import Step from "@liveramp/motif/core/Step";
import StepLabel from "@liveramp/motif/core/StepLabel";
//import StepButton from "@liveramp/motif/core/StepButton";
import css from "../../../resources/Page.module.css";

export default function VerticalDemo() {
  //const [activeStep, setActiveStep] = useState(1);
  const steps = getSteps();

  return (
    <div style={{ display: "flex" }}>
      <div>
        <Stepper
          activeStep={1}
          className={css.fullWidth}
          orientation="vertical"
        >
          {steps.map(({ label, labelProps }) => (
            <Step key={label}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      {/* not currently working, all commented coded is due this */}
      {/* <div>
        <Stepper nonLinear activeStep={activeStep} orientation="vertical">
          {steps.map(({ label, labelProps }, index) => (
            <Step key={label}>
              <StepButton onClick={() => setActiveStep(index)}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div> */}
    </div>
  );
}

function getSteps() {
  return [
    {
      label: "Step One",
      labelProps: {
        optional: "This is step 1"
      }
    },
    {
      label: "Step Two",
      labelProps: {
        optional: "Vertical step with optional text"
      }
    },
    {
      label: "Step 3"
    }
  ];
}

