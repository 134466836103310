/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiMenu",
  description: "",
  displayName: "Menu",
  methods: [],
  props: {
    anchorEl: {
      type: {
        name: "union",
        value: [{ name: "custom", raw: "HTMLElementType" }, { name: "func" }]
      },
      required: false,
      description:
        "A HTML element, or a function that returns it.\r\nIt's used to set the position of the menu."
    },
    autoFocus: {
      type: { name: "bool" },
      required: false,
      description:
        'If `true` (Default) will focus the `[role="menu"]` if no focusable child is found. Disabled\r\nchildren are not focusable. If you set this prop to `false` focus will be placed\r\non the parent modal container. This has severe accessibility implications\r\nand should only be considered if you manage focus otherwise.',
      defaultValue: { value: "true", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "Menu contents, normally `MenuItem`s."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    disableAutoFocusItem: {
      type: { name: "bool" },
      required: false,
      description:
        'When opening the menu will not focus the active item but the `[role="menu"]`\r\nunless `autoFocus` is also set to `false`. Not using the default means not\r\nfollowing WAI-ARIA authoring practices. Please be considerate about possible\r\naccessibility implications.',
      defaultValue: { value: "false", computed: false }
    },
    MenuListProps: {
      type: { name: "object" },
      required: false,
      description:
        "Props applied to the [`MenuList`](/api/menu-list/) element.",
      defaultValue: { value: "{}", computed: false }
    },
    onClose: {
      type: { name: "func" },
      required: false,
      description:
        'Callback fired when the component requests to be closed.\r\n\r\n@param {object} event The event source of the callback.\r\n@param {string} reason Can be: `"escapeKeyDown"`, `"backdropClick"`, `"tabKeyDown"`.'
    },
    onEnter: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired before the Menu enters.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    onEntered: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired when the Menu has entered.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    onEntering: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired when the Menu is entering.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    onExit: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired before the Menu exits.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    onExited: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired when the Menu has exited.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    onExiting: {
      type: {
        name: "custom",
        raw:
          "deprecatedPropType(PropTypes.func, 'Use the `TransitionProps` prop instead.')"
      },
      required: false,
      description:
        "Callback fired when the Menu is exiting.\r\n@deprecated Use the `TransitionProps` prop instead."
    },
    open: {
      type: { name: "bool" },
      required: true,
      description: "If `true`, the menu is visible."
    },
    PaperProps: {
      type: { name: "object" },
      required: false,
      description: "@ignore",
      defaultValue: { value: "{}", computed: false }
    },
    PopoverClasses: {
      type: { name: "object" },
      required: false,
      description:
        "`classes` prop applied to the [`Popover`](/api/popover/) element."
    },
    transitionDuration: {
      type: {
        name: "union",
        value: [
          { name: "enum", value: [{ value: "'auto'", computed: false }] },
          { name: "number" },
          {
            name: "shape",
            value: {
              appear: { name: "number", required: false },
              enter: { name: "number", required: false },
              exit: { name: "number", required: false }
            }
          }
        ]
      },
      required: false,
      description: "The length of the transition in `ms`, or 'auto'",
      defaultValue: { value: "'auto'", computed: false }
    },
    TransitionProps: {
      type: { name: "object" },
      required: false,
      description:
        "Props applied to the transition element.\r\nBy default, the element is based on this [`Transition`](http://reactcommunity.org/react-transition-group/transition) component.",
      defaultValue: { value: "{}", computed: false }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'menu'", computed: false },
          { value: "'selectedMenu'", computed: false }
        ]
      },
      required: false,
      description:
        "The variant to use. Use `menu` to prevent selected items from impacting the initial focus\r\nand the vertical alignment relative to the anchor element.",
      defaultValue: { value: "'selectedMenu'", computed: false }
    }
  },
  styles: {
    paper: { description: "Styles applied to the `Paper` component." },
    list: {
      description: "Styles applied to the `List` component via `MenuList`."
    }
  }
};
export default generatedDocs;
