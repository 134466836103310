import React from "react";
import Link from "@liveramp/motif/core/Link";
import Typography from "@liveramp/motif/core/Typography";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function SimpleDemo() {
  const classes = useStyles();

  return (
    <div className={classes.grid}>
      <Link href="#" className={classes.centered}>
        This is what a Link component looks like
      </Link>

      <div>
        <span className={classes.linkColors}>
          <Typography className={classes.link}>Normal Link Color</Typography>
          <Typography className={classes.hoverLink}>
            Hover Link Color
          </Typography>
          <Typography className={classes.visitedLink}>
            Visited Link Color
          </Typography>
        </span>
        <Typography variant="caption">
          These are not actual links, just examples of the colors used to
          represent the different link states.
        </Typography>
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  grid: {
    display: "grid",
    gap: "16px",
    gridAutoFlow: "row"
  },
  centered: {
    textAlign: "center"
  },
  linkColors: {
    display: "flex",
    gap: "16px",
    justifyContent: "center"
  },
  link: {
    color: theme.palette.text.link
  },
  hoverLink: {
    color: theme.palette.text.hoverLink
  },
  visitedLink: {
    color: theme.palette.text.visitedLink
  }
}));
