import React from "react";
import Select from "@liveramp/motif/core/Select";
import { films } from "./data";

export default function FreeValueDemo() {
  return (
    <Select
      id="free-solo"
      label="Label"
      variant="underlined"
      options={films}
      getOptionLabel={option => option.title}
      value={{ title: "My Neighbor Totoro" }}
      freeSolo
    />
  );
}
