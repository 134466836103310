/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiPaper",
  description: "",
  displayName: "Paper",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'div'", computed: false }
    },
    elevation: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.number, props => {\n  const {\n    classes,\n    elevation\n  } = props; // in case `withStyles` fails to inject we don't need this warning\n\n  if (classes === undefined) {\n    return null;\n  }\n\n  if (elevation != null && classes[`elevation${elevation}`] === undefined) {\n    return new Error(`Material-UI: This elevation \\`${elevation}\\` is not implemented.`);\n  }\n\n  return null;\n})"
      },
      required: false,
      description:
        "Shadow depth, corresponds to `dp` in the spec.\r\nIt accepts values between 0 and 24 inclusive.",
      defaultValue: { value: "1", computed: false }
    },
    square: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, rounded corners are disabled.",
      defaultValue: { value: "false", computed: false }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'elevation'", computed: false },
          { value: "'outlined'", computed: false }
        ]
      },
      required: false,
      description: "The variant to use.",
      defaultValue: { value: "'elevation'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    rounded: {
      description: "Styles applied to the root element if `square={false}`."
    },
    outlined: {
      description: 'Styles applied to the root element if `variant="outlined"`.'
    }
  }
};
export default generatedDocs;
