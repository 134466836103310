/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiAppBar",
  description: "",
  displayName: "AppBar",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'default'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
          { value: "'transparent'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component.",
      defaultValue: { value: "'primary'", computed: false }
    },
    position: {
      type: {
        name: "enum",
        value: [
          { value: "'absolute'", computed: false },
          { value: "'fixed'", computed: false },
          { value: "'relative'", computed: false },
          { value: "'static'", computed: false },
          { value: "'sticky'", computed: false }
        ]
      },
      required: false,
      description:
        "The positioning type. The behavior of the different options is described\r\n[in the MDN web docs](https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Positioning).\r\nNote: `sticky` is not universally supported and will fall back to `static` when unavailable.",
      defaultValue: { value: "'fixed'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    positionFixed: {
      description: 'Styles applied to the root element if `position="fixed"`.'
    },
    positionAbsolute: {
      description:
        'Styles applied to the root element if `position="absolute"`.'
    },
    positionSticky: {
      description: 'Styles applied to the root element if `position="sticky"`.'
    },
    positionStatic: {
      description: 'Styles applied to the root element if `position="static"`.'
    },
    positionRelative: {
      description:
        'Styles applied to the root element if `position="relative"`.'
    },
    colorDefault: {
      description: 'Styles applied to the root element if `color="default"`.'
    },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.'
    },
    colorSecondary: {
      description: 'Styles applied to the root element if `color="secondary"`.'
    },
    colorInherit: {
      description: 'Styles applied to the root element if `color="inherit"`.'
    },
    colorTransparent: {
      description:
        'Styles applied to the root element if `color="transparent"`.'
    }
  }
};
export default generatedDocs;
