import React from "react";
import AppBar from "@liveramp/motif/core/AppBar";
import Toolbar from "@liveramp/motif/core/Toolbar";
import IconButton from "@liveramp/motif/core/IconButton";

import { Github } from "@liveramp/motif/icons";
import css from "./Header.module.css";

export default function Header() {
  return (
    <AppBar className={css.appbar} position="sticky">
      <Toolbar className={css.toolbar}>
        <IconButton light href="https://github.com/LiveRamp/motif/blob/v1/CHANGELOG.md">
          <Github />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}
