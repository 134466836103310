import React from 'react';
import AlertInline from "@liveramp/motif/core/AlertInline";

export default function Simple() {
  return (
    <>
      <AlertInline variant="danger" onClickButtonLabel="READ MORE" onClickButton={() => alert('You can trigger a modal or page redirect here')}>Danger</AlertInline>
      <AlertInline variant="info">Info</AlertInline>
      <AlertInline variant="warning">Warning</AlertInline>
      <AlertInline variant="privacy">Privacy</AlertInline>
      <AlertInline variant="success">Success</AlertInline>
    </>
  );
}
