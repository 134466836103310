import React, { useState } from "react";
import ButtonMenu from "@liveramp/motif/core/ButtonMenu";
import Card from "@liveramp/motif/core/Card";
import NotificationContent from "@liveramp/motif/core/NotificationContent";
import { data } from "./data";
import makeStyles from "@liveramp/motif/styles/makeStyles";

export default function SurfaceDemo() {
  let classes = useStyles();
  let [currentData, setCurrentData] = useState(data);

  let notification = (
    <NotificationContent
      onMarkReadClick={notification => setCurrentData([])}
      onSeeAllClick={() => {
        setCurrentData(data);
        alert('Clicked "SEE ALL" on the Notifications!');
      }}
      notifications={currentData}
      height="320px"
    />
  );

  return (
    /* Click SEE ALL to restore the Notifications for the demos */
    <div className={classes.grid}>
      <Card>{notification}</Card>
      <Card>
        <ButtonMenu
          MenuProps={{
            MenuListProps: { disablePadding: true }
          }}
        >
          {notification}
        </ButtonMenu>
      </Card>
    </div>
  );
}

let useStyles = makeStyles(theme => ({
  grid: {
    display: "grid",
    gridTemplateColumns: "500px 320px",
    gap: "1rem",
    "& > .MuiCard-root": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  }
}));
