import React, { Component } from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Paper from "@liveramp/motif/core/Paper";
import Dialog from "@liveramp/motif/core/Dialog";
import DialogActions from "@liveramp/motif/core/DialogActions";
import DialogContent from "@liveramp/motif/core/DialogContent";
import DialogTitle from "@liveramp/motif/core/DialogTitle";

import SegmentRobot from "@liveramp/motif/icons/SegmentRobot";
import democss from "./VariedButtonFormGroup.module.css";
import css from "../../../resources/Page.module.css";

export default class VariedButtonFormGroupDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  render() {
    return (
      <div className={css.grid}>
        {/* Profile Card */}
        <Paper className={democss.card}>
          <div className={democss.robot}>
            <SegmentRobot viewBox={"50 -15 300 300"} />
          </div>
          <div className={democss.details}>
            <div className={democss.text}>
              <div>User #12345</div>
              <div>Segment Robot</div>
              <div>Robot Mascot</div>
              <div>LiveRamp</div>
            </div>
            <div className={democss.buttonGroup}>
              <ButtonFormGroup>
                <Button variant="text">Delete</Button>
              </ButtonFormGroup>
              <ButtonFormGroup>
                <Button variant="secondary">Edit</Button>
                <Button>Submit</Button>
              </ButtonFormGroup>
            </div>
          </div>
        </Paper>

        {/* Dialog Prompt */}
        <Paper className={democss.paper}>
          <div className={democss.dialogButton}>
            <Button onClick={this.handleOpen}>Open Sample Dialog</Button>
          </div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog"
          >
            <DialogTitle onClose={this.handleClose}>
              Deactivate Account?
            </DialogTitle>
            <DialogContent>
              Are you sure you want to deactivate your account? This cannot be
              undone.
            </DialogContent>
            <DialogActions>
              <ButtonFormGroup>
                <Button
                  onClick={this.handleClose}
                  color="secondary"
                  variant="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={this.handleClose} color="danger">
                  Deactivate
                </Button>
              </ButtonFormGroup>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    );
  }
}
