import React, { useState } from "react";
import MultiFilter from "@liveramp/motif/core/MultiFilter";

let options = [];
let multiOptions = [];
for (let i = 1; i <= 3; i++) {
  options.push(`Filter Option ${i}`);
}

multiOptions.push("MultiFilter Option 1");
multiOptions.push("MultiFilter Option 2");
multiOptions.push({
  id: "multifilter-option-3",
  label: "MultiFilter Option 3",
  getOptionLabel: opt => opt.label
});
multiOptions.push({
  id: "multifilter-option-4",
  label: "MultiFilter Option 4",
  getOptionLabel: opt => opt.label
});
multiOptions.push({
  id: "multifilter-option-5",
  label: "MultiFilter Option 5",
  getOptionLabel: opt => opt.label,
  options: options
});
multiOptions.push({
  id: "multifilter-option-6",
  label: "MultiFilter Option 6",
  getOptionLabel: opt => opt.label,
  options: options
});
multiOptions.push({
  id: "multifilter-option-7",
  label: "MultiFilter Option 7",
  getOptionLabel: opt => opt.label,
  options: options
});
multiOptions.push({
  id: "multifilter-option-8",
  label: "MultiFilter Option 8",
  getOptionLabel: opt => opt.label,
  options: options.map(opt => ({
    id: opt,
    label: opt,
    getOptionLabel: opt => opt.label
  }))
});
multiOptions.push({
  id: "multifilter-option-9",
  label: "MultiFilter Option 9",
  getOptionLabel: opt => opt.label,
  options: options.map(opt => ({
    id: opt,
    label: opt,
    getOptionLabel: opt => opt.label
  }))
});
multiOptions.push({
  id: "multifilter-option-10",
  label: "MultiFilter Option 10",
  getOptionLabel: opt => opt.label,
  options: options.map(opt => ({
    id: opt,
    label: opt,
    getOptionLabel: opt => opt.label
  }))
});

/**
 * MultiOptions demoed like so:
 * 0. String, no children, unchecked
 * 1. String, no children, checked
 * 2. Object, no children, unchecked
 * 3. Object, no children, checked
 * 4. Object, string children, unchecked
 * 5. Object, string children, some checked
 * 6. Object, string children, checked
 * 7. Object, object children, unchecked
 * 8. Object, object children, some checked
 * 9. Object, object children, checked
 **/

export default function MultiFilterDemo() {
  let [multiValue, setMultiValue] = useState([
    multiOptions[1],
    multiOptions[3],
    { ...multiOptions[5], options: [multiOptions[5].options[0]] },
    multiOptions[6],
    { ...multiOptions[8], options: [multiOptions[8].options[0]] },
    multiOptions[9]
  ]);

  const handleApply = (event, value) => alert("Run your server side search!");

  return (
    <MultiFilter
      id="multifilter-controlled-demo"
      label="Controlled"
      options={multiOptions}
      value={multiValue}
      onChange={(e, v) => setMultiValue(v)}
      onApply={handleApply}
    />
  );
}
