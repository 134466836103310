import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path fill="#F25022" d="M2.97 2.97h8.458v8.458H2.97V2.97" />
    <path fill="#FFB900" d="M2.97 20.825h9.397v-8.458h8.458v8.458z" />
    <path fill="#00A4EF" d="M2.97 20.825v-8.458h8.458v8.458" />
    <path fill="#7FBA00" d="M2.97 2.97h9.397v8.458h8.458V2.97" />
  </React.Fragment>,
  "Microsoft"
);
