import CardActions from "@material-ui/core/CardActions";
import withStyles from "../../styles/withStyles";
import { CardActionsStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...CardActionsStyle,
  root: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    padding: "16px"
  },
  spacing: {
    // cleaning up MUI flex hacks
    "& > * + *, & > :not(:first-child)": {
      margin: "0"
    }
  }
});

export default withStyles(styles, { name: "MuiCardActions" })(CardActions);
