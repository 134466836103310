import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM9.143 7.714c-.786 0-1.429.643-1.429 1.429v5.714c0 .786.643 1.429 1.429 1.429h5.714c.786 0 1.429-.643 1.429-1.429V9.143c0-.786-.643-1.429-1.429-1.429H9.143z"
      fill="#E02020"
      fillRule="evenodd"
    />
  </React.Fragment>,
  "StatusStopped"
);
