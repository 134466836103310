import React, { useState } from "react";
import RichDataTable, {
  GridToolbarContainer
} from "@liveramp/motif/core/RichDataTable";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";
import Checkbox from "@liveramp/motif/core/Checkbox";
import FormControl from "@liveramp/motif/core/FormControl";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import FormGroup from "@liveramp/motif/core/FormGroup";
import FormLabel from "@liveramp/motif/core/FormLabel";
import Radio from "@liveramp/motif/core/Radio";
import RadioGroup from "@liveramp/motif/core/RadioGroup";
import { columns, rows } from "../../demos/data";

// This demo is a unique case in that it forces the table state to change within
// its own toolbar to force the overlays. This functionality is typically not
// what occurs with a standard table so the state being handled by what is two
// separate React components, coupled with the nuances from the datagrid, has
// made retaining its state difficult. Do not use this code as a base sample.
function ToolbarActions(
  apiRef,
  loading,
  setLoading,
  noRowsErrorText,
  noSearchResultsErrorText,
  setNoRowsErrorText,
  setnoSearchResultsErrorText
) {
  const [overlay, setOverlay] = useState("no-rows");

  const fakeFilter = {
    items: [
      {
        columnField: "name",
        operatorValue: "contains",
        value: "non-existent value"
      }
    ]
  };

  const handleOverlayChange = e => {
    const value = e.target.value;
    if (value === "no-rows") {
      apiRef.current.updateRows(
        rows.map(row => ({ ...row, _action: "delete" }))
      );
      apiRef.current.setFilterModel({ items: [] });
    } else if (value === "no-search") {
      apiRef.current.updateRows(rows);
      apiRef.current.setFilterModel(fakeFilter);
    }
    setOverlay(value);
  };

  return (
    <GridToolbarContainer>
      <ButtonFormGroup start>
        <FormControl component="fieldset">
          <FormLabel component="legend">Set Results</FormLabel>
          <RadioGroup value={overlay} onChange={handleOverlayChange} row>
            <FormControlLabel
              value="no-rows"
              control={<Radio />}
              label="No Rows"
            />
            <FormControlLabel
              value="no-search"
              control={<Radio />}
              label="No Search Results"
            />
          </RadioGroup>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">Custom Options</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={loading}
                  onChange={() => setLoading(!loading)}
                  value="loading"
                />
              }
              label="Set Loading"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={noRowsErrorText !== "There is no data to display"}
                  onChange={() =>
                    setNoRowsErrorText(
                      noRowsErrorText !== "There is no data to display"
                        ? "There is no data to display"
                        : "I am customized no rows error text"
                    )
                  }
                  value="I am customized no rows error text"
                />
              }
              label="Set No Rows Error Text"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    noSearchResultsErrorText !==
                    "There is no results for this search"
                  }
                  onChange={() =>
                    setnoSearchResultsErrorText(
                      noSearchResultsErrorText !==
                        "There is no results for this search"
                        ? "There is no results for this search"
                        : "I am customized no search results text"
                    )
                  }
                  value="I am customized no search results text"
                />
              }
              label="Set No Search Results Error Text"
            />
          </FormGroup>
        </FormControl>
      </ButtonFormGroup>
    </GridToolbarContainer>
  );
}

export default function SlotsDGOverlaysDemo() {
  const [loading, setLoading] = useState(false);
  const [noRowsErrorText, setNoRowsErrorText] = useState(
    "There is no data to display"
  );
  const [noSearchResultsErrorText, setnoSearchResultsErrorText] = useState(
    "There is no results for this search"
  );

  return (
    <>
      <RichDataTable
        columns={columns}
        rows={[]}
        loading={loading}
        height={350}
        noRowsErrorText={noRowsErrorText}
        noSearchResultsErrorText={noSearchResultsErrorText}
        toolbarActions={({ apiRef }) =>
          ToolbarActions(
            apiRef,
            loading,
            setLoading,
            noRowsErrorText,
            noSearchResultsErrorText,
            setNoRowsErrorText,
            setnoSearchResultsErrorText
          )
        }
      />
    </>
  );
}
