import React from "react";

let LiverampLogo = props => (
  <svg viewBox="0 0 130 136" {...props}>
    <g fillRule="nonzero" fill="none">
      <path fill="#7F9FB4" d="M31.06 103.2L0 135.73h40.17z" />
      <path fill="#3A7D3D" d="M37.14 102.13l9.42 33.6h82.57v-11.48z" />
      <path fill="#73BF69" d="M40.8 96.75l88.2 21.18V66.05z" />
      <path fill="#A2D296" d="M44.23 89.05L129 59.53V0z" />
    </g>
  </svg>
);

export default LiverampLogo;
