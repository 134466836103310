// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Dashboard category
let icons = [
  "DashboardAudienceUpdate",
  "DashboardCalculating",
  "DashboardEmpty",
  "DashboardExploreMoreDestinations",
  "DashboardUniqueRecords",
  "NotificationsEmpty"
];

export default icons;
