import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <React.Fragment>
    <path
      d="M12 22.3C6.311 22.3 1.7 17.689 1.7 12 1.7 6.311 6.311 1.7 12 1.7c5.689 0 10.3 4.611 10.3 10.3 0 5.689-4.611 10.3-10.3 10.3zm-.492-17.159l-2.55 2.55c-.24.24-.07.659.279.659h1.775v5.334a2.33 2.33 0 00-1.076.789l-3.141-1.814.887-1.537a.384.384 0 00-.435-.564l-3.483.933a.384.384 0 00-.275.476l.934 3.483c.088.328.535.39.71.088l.887-1.537 3.449 1.992-.003-.118a2.325 2.325 0 004.647.113l3.439-1.985.887 1.537c.174.302.622.24.706-.094l.933-3.483a.384.384 0 00-.275-.477l-3.483-.933a.387.387 0 00-.43.571l.887 1.537-3.134 1.809a2.33 2.33 0 00-1.081-.79V8.35h1.774c.349 0 .52-.419.272-.659l-2.55-2.55a.384.384 0 00-.55 0z"
      fill="#73C06B"
      fillRule="nonzero"
    />
  </React.Fragment>,
  "StatusDistributing"
);
