/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiButton",
  description: "",
  displayName: "Button",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the button."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'default'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component.",
      defaultValue: { value: "'default'", computed: false }
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'button'", computed: false }
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the button will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableElevation: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, no elevation is used.",
      defaultValue: { value: "false", computed: false }
    },
    disableFocusRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the  keyboard focus ripple will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableRipple: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the ripple effect will be disabled.\r\n\r\n⚠️ Without a ripple there is no styling for :focus-visible by default. Be sure\r\nto highlight the element by applying separate styles with the `focusVisibleClassName`."
    },
    endIcon: {
      type: { name: "node" },
      required: false,
      description: "Element placed after the children."
    },
    focusVisibleClassName: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    fullWidth: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the button will take up the full width of its container.",
      defaultValue: { value: "false", computed: false }
    },
    href: {
      type: { name: "string" },
      required: false,
      description:
        "The URL to link to when the button is clicked.\r\nIf defined, an `a` element will be used as the root node."
    },
    size: {
      type: {
        name: "enum",
        value: [
          { value: "'large'", computed: false },
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false }
        ]
      },
      required: false,
      description:
        "The size of the button.\r\n`small` is equivalent to the dense button styling.",
      defaultValue: { value: "'medium'", computed: false }
    },
    startIcon: {
      type: { name: "node" },
      required: false,
      description: "Element placed before the children."
    },
    type: {
      type: {
        name: "union",
        value: [
          {
            name: "enum",
            value: [
              { value: "'button'", computed: false },
              { value: "'reset'", computed: false },
              { value: "'submit'", computed: false }
            ]
          },
          { name: "string" }
        ]
      },
      required: false,
      description: "@ignore",
      defaultValue: { value: "'button'", computed: false }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'contained'", computed: false },
          { value: "'outlined'", computed: false },
          { value: "'text'", computed: false }
        ]
      },
      required: false,
      description: "The variant to use.",
      defaultValue: { value: "'text'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    label: {
      description: "Styles applied to the span element that wraps the children."
    },
    text: {
      description: 'Styles applied to the root element if `variant="text"`.'
    },
    textPrimary: {
      description:
        'Styles applied to the root element if `variant="text"` and `color="primary"`.'
    },
    textSecondary: {
      description:
        'Styles applied to the root element if `variant="text"` and `color="secondary"`.'
    },
    outlined: {
      description: 'Styles applied to the root element if `variant="outlined"`.'
    },
    outlinedPrimary: {
      description:
        'Styles applied to the root element if `variant="outlined"` and `color="primary"`.'
    },
    outlinedSecondary: {
      description:
        'Styles applied to the root element if `variant="outlined"` and `color="secondary"`.'
    },
    contained: {
      description:
        'Styles applied to the root element if `variant="contained"`.'
    },
    containedPrimary: {
      description:
        'Styles applied to the root element if `variant="contained"` and `color="primary"`.'
    },
    containedSecondary: {
      description:
        'Styles applied to the root element if `variant="contained"` and `color="secondary"`.'
    },
    disableElevation: {
      description:
        "Styles applied to the root element if `disableElevation={true}`."
    },
    focusVisible: {
      description:
        "Pseudo-class applied to the ButtonBase root element if the button is keyboard focused."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    colorInherit: {
      description: 'Styles applied to the root element if `color="inherit"`.'
    },
    textSizeSmall: {
      description:
        'Styles applied to the root element if `size="small"` and `variant="text"`.'
    },
    textSizeLarge: {
      description:
        'Styles applied to the root element if `size="large"` and `variant="text"`.'
    },
    outlinedSizeSmall: {
      description:
        'Styles applied to the root element if `size="small"` and `variant="outlined"`.'
    },
    outlinedSizeLarge: {
      description:
        'Styles applied to the root element if `size="large"` and `variant="outlined"`.'
    },
    containedSizeSmall: {
      description:
        'Styles applied to the root element if `size="small"` and `variant="contained"`.'
    },
    containedSizeLarge: {
      description:
        'Styles applied to the root element if `size="large"` and `variant="contained"`.'
    },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"`.'
    },
    sizeLarge: {
      description: 'Styles applied to the root element if `size="large"`.'
    },
    fullWidth: {
      description: "Styles applied to the root element if `fullWidth={true}`."
    },
    startIcon: {
      description: "Styles applied to the startIcon element if supplied."
    },
    endIcon: {
      description: "Styles applied to the endIcon element if supplied."
    },
    iconSizeSmall: {
      description:
        'Styles applied to the icon element if supplied and `size="small"`.'
    },
    iconSizeMedium: {
      description:
        'Styles applied to the icon element if supplied and `size="medium"`.'
    },
    iconSizeLarge: {
      description:
        'Styles applied to the icon element if supplied and `size="large"`.'
    }
  }
};
export default generatedDocs;
