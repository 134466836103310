import React from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import CheckboxUnchecked from "../../icons/CheckboxUnchecked";
import IndeterminateCheckBox from "../../icons/IndeterminateCheckBox";
import withStyles from "../../styles/withStyles";
import { CheckboxStyle } from "../../utils/styleKeys";

function Checkbox(props) {
  return (
    <MuiCheckbox
      color="default"
      // When a checkbox is indeterminate but NOT checked, use an empty checkbox
      // instead of a greyed indeterminate icon
      indeterminateIcon={
        props.checked ? <IndeterminateCheckBox /> : <CheckboxUnchecked />
      }
      {...props}
    />
  );
}

let styles = theme => ({
  ...CheckboxStyle,
  root: {
    color: theme.palette.grey[500],
    transition: theme.transitions.common.short,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover
    }
  },
  checked: {
    color: theme.palette.primary.main
  }
});

export default withStyles(styles, { name: "MuiCheckbox" })(Checkbox);
