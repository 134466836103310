import React from "react";
import Paper from "@liveramp/motif/core/Paper";
import List from "@liveramp/motif/core/List";
import ListItem from "@liveramp/motif/core/ListItem";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";
import ListItemText from "@liveramp/motif/core/ListItemText";
import Star from "@liveramp/motif/icons/Star";

export default function SimpleDemo() {
  return (
    <Paper style={{ width: "300px" }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <Star />
          </ListItemIcon>
          <ListItemText>ListItemText</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText inset>ListItemText</ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
}
