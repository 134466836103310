import React from "react";
import clsx from "clsx";
import Fab from "../../../core-components/Fab";
import CircularProgress from "../../../core-components/CircularProgress";
import { makeStyles } from "../../../utils";
import Check from "../../../icons/Check";
import Clear from "../../../icons/Clear";
import Refresh from "../../../icons/Refresh";
import grey from "../../../styles/colors/grey";
import red from "../../../styles/colors/red";

const MultipleFileResult = ({ file }) => {
  const {
    isSuccess,
    isError,
    isRejected,
    retryUpload,
    cancelUpload,
    removeFile,
    progress
  } = file;

  const _isError = isError || isRejected;
  useClasses();

  const renderButton = () => {
    if (isSuccess) {
      return <Check color="primary" className="MultipleFileResult__success" />;
    }

    if (isRejected)
      return (
        <Fab
          className={clsx("MultipleFileResult__fab", {
            ["MultipleFileResult__fab--isError"]: _isError
          })}
          aria-label="retry"
          color="secondary"
          onClick={removeFile}
        >
          <Clear color="error" />
        </Fab>
      );

    if (isError)
      return (
        <Fab
          className={clsx("MultipleFileResult__fab", {
            ["MultipleFileResult__fab--isError"]: _isError
          })}
          aria-label="retry"
          color="secondary"
          onClick={retryUpload}
        >
          <Refresh color="error" />
        </Fab>
      );

    return (
      <Fab
        className={clsx("MultipleFileResult__fab", {
          ["MultipleFileResult__fab--isError"]: _isError
        })}
        aria-label="retry"
        color="secondary"
        onClick={cancelUpload}
      >
        <Clear color="secondary" />
      </Fab>
    );
  };

  return (
    <div data-testid="MultipleFileResult" className="MultipleFileResult">
      {renderButton()}
      <CircularProgress
        className={clsx("MultipleFileResult__progress", {
          ["MultipleFileResult__progress--isError"]: _isError
        })}
        size={43}
        variant={progress ? "determinate" : "indeterminate"}
        value={progress}
      />
    </div>
  );
};

const useClasses = makeStyles(theme => ({
  "@global": {
    ".MultipleFileResult": {
      position: "relative",

      // .MultipleFileResult__progress
      "&__progress": {
        position: "absolute",
        top: "-3px",
        right: "-3px",
        color: theme.palette.primary.main,

        // .MultipleFileResult__progress--isError
        "&--isError": {
          color: theme.palette.error.main
        }
      },

      // .MultipleFileResult__success
      "&__success": {
        margin: "6px"
      },

      // .MultipleFileResult__fab
      "&__fab": {
        width: "37px",
        height: "37px",
        zIndex: "2",
        boxShadow: "none",
        backgroundColor: theme.palette.secondary.light,

        "&:hover": {
          backgroundColor: grey[300]
        },

        // .MultipleFileResult__fab--isError
        "&--isError": {
          backgroundColor: red[50],

          "&:hover": {
            backgroundColor: theme.palette.error.hover
          }
        }
      }
    }
  }
}));

export default MultipleFileResult;
