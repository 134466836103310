import React from "react";
import TextField from "@liveramp/motif/core/TextField";
import css from "./States.module.css";

export default function OutlinedStatesDemo() {
  return (
    <div className={css.grid}>
      <TextField
        id="outlined-helper-input"
        label="label, helperText"
        helperText="This is your helper text"
      />
      <TextField
        id="outlined-default-input"
        label="defaultValue"
        defaultValue="Hello World"
        helperText="This input has default text"
      />
      <TextField
        id="outlined-placeholder-input"
        label="placeholder"
        placeholder="Hello World"
        helperText="This input has a placeholder"
      />
      <TextField
        id="outlined-required-input"
        label="required"
        helperText="This input is marked as required"
        required
      />
      <TextField
        id="outlined-error-input"
        label="error"
        helperText="This input has an error"
        error
      />
      <TextField
        id="outlined-disabled-input"
        label="disabled"
        helperText="This input is disabled"
        disabled
      />
      <TextField
        id="outlined-readonly-input"
        label="readOnly"
        defaultValue="Read-Only"
        helperText="This input is read-only"
        readOnly
      />
      <TextField
        id="outlined-password-input"
        defaultValue="password"
        label='type="password"'
        helperText="This input is a password"
        type="password"
      />
      <TextField
        id="outlined-number-input"
        label='type="number"'
        helperText="This input can only be a number"
        type="number"
      />
      <TextField
        id="outlined-search-input"
        defaultValue="Hover to see clear icon"
        label='type="search"'
        helperText="This input looks like a search"
        type="search"
      />
      <TextField
        id="outlined-multiline-input"
        label="multiline, maxRows"
        defaultValue="Type to expand"
        helperText="This input is multiline with max rows"
        multiline
        maxRows={4}
      />
      <TextField
        id="outlined-multiline-default-input"
        label="multiline, rows"
        defaultValue={`Fully expanded`}
        helperText="This input is multiline with starting rows"
        multiline
        rows={4}
      />
      <TextField
        id="outlined-fullwidth-input"
        label="fullWidth"
        helperText="This input takes up the full width of its container"
        fullWidth
      />
    </div>
  );
}
