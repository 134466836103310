/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiFormLabel",
  description: "",
  displayName: "FormLabel",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component."
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'label'", computed: false }
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the label should be displayed in a disabled state."
    },
    error: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the label should be displayed in an error state."
    },
    filled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the label should use filled classes key."
    },
    focused: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the input of this label is focused (used by `FormGroup` components)."
    },
    required: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the label will indicate that the input is required."
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    colorSecondary: {
      description:
        "Styles applied to the root element if the color is secondary."
    },
    focused: {
      description:
        "Pseudo-class applied to the root element if `focused={true}`."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    error: {
      description: "Pseudo-class applied to the root element if `error={true}`."
    },
    filled: {
      description:
        "Pseudo-class applied to the root element if `filled={true}`."
    },
    required: {
      description:
        "Pseudo-class applied to the root element if `required={true}`."
    },
    asterisk: { description: "Styles applied to the asterisk element." }
  }
};
export default generatedDocs;
