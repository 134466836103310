import React from "react";
import { Page, Title, Subtitle, SectionTitle } from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import Simple from "./demos/Simple";
import Multiple from "./demos/Multiple";

let demoNames = ["Simple", "Multiple"];

export default function ToggleButton() {
  let demos = generateDemos("core-component-demo/toggle-button", demoNames);

  return (
    <Page components={["ToggleButtonGroup", "ToggleButton"]}>
      <Title>Toggle Button</Title>
      <Subtitle>Toggle buttons can be used to group related options.</Subtitle>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Multiple selection</SectionTitle>
      <p>
        Logically-grouped options, like bold, italic, and underline, allow
        multiple options to be selected.
      </p>
      <Demo demo={demos.Multiple}>
        <Multiple />
      </Demo>
    </Page>
  );
}
