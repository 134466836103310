import React, { useContext } from "react";
import clsx from "clsx";
import Drawer from "../../core-components/Drawer";
import Divider from "../../core-components/Divider";
import List from "../../core-components/List";
import L1L2Header from "./L1L2Header";
import L1L2AccountSwitcher from "./L1L2AccountSwitcher";
import L1L2Item from "./L1L2Item";
import { GlobalNavigationContext } from "./GlobalNavigation";
import makeStyles from "../../styles/makeStyles";

export const NAV_DRAWER_OPEN_WIDTH = 258;

function L1L2(props) {
  const { customisation, navigation, displayLevel } = useContext(
    GlobalNavigationContext
  );

  useStyles({ customisation });

  const getDrawerClasses = () => {
    if (displayLevel === 1) return "GNL1L2-drawer";
    else if (displayLevel === 2)
      return clsx("GNL1L2-drawer", "GNL1L2-drawer--isOpen");
    else if (displayLevel === 3)
      return clsx("GNL1L2-drawer", "GNL1L2-drawer--isOpenL3Padding");
  };

  return (
    <Drawer
      className="GNL1L2"
      classes={{
        root: getDrawerClasses(),
        paper: getDrawerClasses()
      }}
      variant="permanent"
      {...props}
    >
      <L1L2Header />
      <Divider light />
      <L1L2AccountSwitcher />
      <Divider light />
      <div className={clsx("GNL1L2-menu", "GNL1L2-scroll")}>
        {navigation.map((list, i) => (
          <List key={`l1-list-${i}`} className="GNL1L2-list">
            {list.map(l1 => (
              <L1L2Item key={`l1-item-${l1.label}`} {...l1} />
            ))}
          </List>
        ))}
      </div>
    </Drawer>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".GNL1L2": {
      // .GNL1L2-drawer
      "&-drawer": {
        fontFamily: theme.typography.fontFamilyHeader,
        backgroundColor: ({ customisation }) =>
          customisation?.backgroundColor
            ? customisation.backgroundColor
            : theme.palette.grey[900],
        boxShadow: theme.shadows[3],
        borderRight: 0,
        transition: theme.transitions.common.enteringScreen,
        width: "50px",
        overflowX: "hidden",
        padding: 0,

        // .GNL1L2-drawer--isOpen
        "&--isOpen": {
          width: NAV_DRAWER_OPEN_WIDTH
        },

        // .GNL1L2-drawer--isOpenL3Padding
        "&--isOpenL3Padding": {
          // This margin is required as the L3 menu does not live within the L1L2 and
          // has no context to its positioning. When the L1L2 is closed, it is collapsed
          // within the DOM, thus requiring this margin to keep the content body in the
          // correct place.
          marginRight: "208px"
        }
      },

      // .GNL1L2-menu
      "&-menu": {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "28px"
      },

      // .GNL1L2-scroll
      "&-scroll": {
        // This class is duplicated in the AccountSwitcher component
        // Any changes will need to be reflected in both places
        "&::-webkit-scrollbar": {
          backgroundColor: ({ customisation }) =>
            customisation?.backgroundColor
              ? customisation.backgroundColor
              : theme.palette.grey[900],
          width: "14px"
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: ({ customisation }) =>
            customisation?.backgroundColor
              ? customisation.backgroundColor
              : theme.palette.grey[900]
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          borderRadius: "14px",
          border: ({ customisation }) =>
            `4px solid ${
              customisation?.backgroundColor
                ? customisation.backgroundColor
                : theme.palette.grey[900]
            }`
        }
      }
    }
  }
}));

export default L1L2;
