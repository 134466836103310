/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiAccordionSummary",
  description: "",
  displayName: "AccordionSummary",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the accordion summary."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    expandIcon: {
      type: { name: "node" },
      required: false,
      description: "The icon to display as the expand indicator."
    },
    focusVisibleClassName: {
      type: { name: "string" },
      required: false,
      description:
        "This prop can help identify which element has keyboard focus.\r\nThe class name will be applied when the element gains the focus through keyboard interaction.\r\nIt's a polyfill for the [CSS :focus-visible selector](https://drafts.csswg.org/selectors-4/#the-focus-visible-pseudo).\r\nThe rationale for using this feature [is explained here](https://github.com/WICG/focus-visible/blob/master/explainer.md).\r\nA [polyfill can be used](https://github.com/WICG/focus-visible) to apply a `focus-visible` class to other components\r\nif needed."
    },
    IconButtonProps: {
      type: { name: "object" },
      required: false,
      description:
        "Props applied to the `IconButton` element wrapping the expand icon.",
      defaultValue: { value: "{}", computed: false }
    },
    onClick: { type: { name: "func" }, required: false, description: "@ignore" }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    expanded: {
      description:
        "Pseudo-class applied to the root element, children wrapper element and `IconButton` component if `expanded={true}`."
    },
    focusVisible: {
      description:
        "Pseudo-class applied to the ButtonBase root element if the button is keyboard focused."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    content: { description: "Styles applied to the children wrapper element." },
    expandIcon: {
      description:
        "Styles applied to the `IconButton` component when `expandIcon` is supplied."
    }
  }
};
export default generatedDocs;
