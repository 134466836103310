import React from "react";
import createSvgIcon from "./utils/createSvgIcon";

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M.01 2C.01.9.9 0 2 0h8l6 6v12c0 1.1-.9 2-2 2H1.99C.89 20 0 19.1 0 18L.01 2zM9 1.5V7h5.5L9 1.5zM12 16v-2.083L5.856 7.773 3.773 9.856 9.917 16H12zM2.162 7.462a.553.553 0 000 .784L3.18 9.262 5.262 7.18 4.246 6.162a.553.553 0 00-.784 0l-1.3 1.3z"
  />,
  "Notepad"
);
