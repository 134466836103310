// this export is icon files w/o the js extension; include icon names in this export if an icon should be rendered in the display page under the Status category

let icons = [
  "StatusActive",
  "StatusCancelled",
  "StatusDistributing",
  "StatusDistributionActive",
  "StatusDistributionRejected",
  "StatusDraft",
  "StatusError",
  "StatusInProgress",
  "StatusInReview",
  "StatusMore",
  "StatusNew",
  "StatusPending",
  "StatusPermission",
  "StatusQueued",
  "StatusRestricted",
  "StatusRevoked",
  "StatusStopped",
  "StatusScheduled",
  "StatusSuccess",
  "StatusWarning"
];

export default icons;
