let overrideDocs = {
  props: {
    productionPrefix: {
      type: { name: "string" },
      required: false,
      description:
        "Customize the generated classnames in production by adding a prefix"
    }
  }
};

export default overrideDocs;
