import React, { useState } from "react";
import Table from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";
import TableFooter from "@liveramp/motif/core/TableFooter";
import TableSortLabel from "@liveramp/motif/core/TableSortLabel";
import Checkbox from "@liveramp/motif/core/Checkbox";

export default function Sorting() {
  let [checkedItems, setCheckedItems] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");

  function createData(name, calories, fat, carbs, protein) {
    return {
      name,
      calories,
      fat,
      carbs,
      protein
    };
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const rows = [
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Donut", 452, 25.0, 51, 4.9),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
    createData("Honeycomb", 408, 3.2, 87, 6.5),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Jelly Bean", 375, 0.0, 94, 0.0),
    createData("KitKat", 518, 26.0, 65, 7.0),
    createData("Lollipop", 392, 0.2, 98, 0.0),
    createData("Marshmallow", 318, 0, 81, 2.0),
    createData("Nougat", 360, 19.0, 9, 37.0),
    createData("Oreo", 437, 18.0, 63, 4.0)
  ];

  let headCells = [
    {
      id: "name",
      align: "left",
      label: "Dessert (100g serving)"
    },
    {
      id: "calories",
      align: "left",
      label: "Calories"
    },
    {
      id: "fat",
      align: "left",
      label: "Fat (g)"
    },
    {
      id: "carbs",
      align: "right",
      label: "Carbs (g)"
    },
    {
      id: "protein",
      align: "center",
      label: "Protein (g)"
    }
  ];

  let handleToggleAll = () => {
    if (checkedItems.length !== rows.length) {
      setCheckedItems(rows.map(item => item.name));
    } else {
      setCheckedItems([]);
    }
  };

  let toggleItem = name => {
    if (checkedItems.includes(name)) {
      setCheckedItems(checkedItems.filter(item => item !== name));
    } else {
      setCheckedItems([...checkedItems, name]);
    }
  };

  const createSortHeader = cellName => {
    const isAsc = orderBy === cellName && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(cellName);
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkedItems.length > 0}
              indeterminate={checkedItems.length < rows.length}
              onChange={handleToggleAll}
            />
          </TableCell>
          {headCells.map(item => (
            <TableCell
              key={item.id}
              align={item.align}
              sortDirection={orderBy === item.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === item.id}
                direction={orderBy === item.id ? order : "asc"}
                onClick={() => createSortHeader(item.id)}
              >
                {item.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice().map() */}
        {stableSort(rows, getComparator(order, orderBy)).map((row, i) => (
          <TableRow key={i}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={checkedItems.includes(row.name)}
                onChange={() => toggleItem(row.name)}
              />
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.calories}</TableCell>
            <TableCell>{row.fat}</TableCell>
            <TableCell align="right">{row.carbs}</TableCell>
            <TableCell align="center">{row.protein}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          <TableCell colSpan={5}>
            Showing rows <b>1</b> - <b>{rows.length}</b>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
