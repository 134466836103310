/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiDrawer",
  description:
    'The props of the [Modal](/api/modal/) component are available\r\nwhen `variant="temporary"` is set.',
  displayName: "Drawer",
  methods: [],
  props: {
    anchor: {
      type: {
        name: "enum",
        value: [
          { value: "'bottom'", computed: false },
          { value: "'left'", computed: false },
          { value: "'right'", computed: false },
          { value: "'top'", computed: false }
        ]
      },
      required: false,
      description: "Side from which the drawer will appear.",
      defaultValue: { value: "'left'", computed: false }
    },
    BackdropProps: {
      type: { name: "object" },
      required: false,
      description: "@ignore"
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "The contents of the drawer."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    elevation: {
      type: { name: "number" },
      required: false,
      description: "The elevation of the drawer.",
      defaultValue: { value: "16", computed: false }
    },
    ModalProps: {
      type: { name: "object" },
      required: false,
      description: "Props applied to the [`Modal`](/api/modal/) element.",
      defaultValue: { value: "{}", computed: false }
    },
    onClose: {
      type: { name: "func" },
      required: false,
      description:
        "Callback fired when the component requests to be closed.\r\n\r\n@param {object} event The event source of the callback."
    },
    open: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the drawer is open.",
      defaultValue: { value: "false", computed: false }
    },
    PaperProps: {
      type: { name: "object" },
      required: false,
      description: "Props applied to the [`Paper`](/api/paper/) element.",
      defaultValue: { value: "{}", computed: false }
    },
    SlideProps: {
      type: { name: "object" },
      required: false,
      description: "Props applied to the [`Slide`](/api/slide/) element."
    },
    transitionDuration: {
      type: {
        name: "union",
        value: [
          { name: "number" },
          {
            name: "shape",
            value: {
              appear: { name: "number", required: false },
              enter: { name: "number", required: false },
              exit: { name: "number", required: false }
            }
          }
        ]
      },
      required: false,
      description:
        "The duration for the transition, in milliseconds.\r\nYou may specify a single timeout for all transitions, or individually with an object.",
      defaultValue: {
        value:
          "{\n  enter: duration.enteringScreen,\n  exit: duration.leavingScreen\n}",
        computed: false
      }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'permanent'", computed: false },
          { value: "'persistent'", computed: false },
          { value: "'temporary'", computed: false }
        ]
      },
      required: false,
      description: "The variant to use.",
      defaultValue: { value: "'temporary'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    docked: {
      description:
        'Styles applied to the root element if `variant="permanent or persistent"`.'
    },
    paper: { description: "Styles applied to the `Paper` component." },
    paperAnchorLeft: {
      description: 'Styles applied to the `Paper` component if `anchor="left"`.'
    },
    paperAnchorRight: {
      description:
        'Styles applied to the `Paper` component if `anchor="right"`.'
    },
    paperAnchorTop: {
      description: 'Styles applied to the `Paper` component if `anchor="top"`.'
    },
    paperAnchorBottom: {
      description:
        'Styles applied to the `Paper` component if `anchor="bottom"`.'
    },
    paperAnchorDockedLeft: {
      description:
        'Styles applied to the `Paper` component if `anchor="left"` and `variant` is not "temporary".'
    },
    paperAnchorDockedTop: {
      description:
        'Styles applied to the `Paper` component if `anchor="top"` and `variant` is not "temporary".'
    },
    paperAnchorDockedRight: {
      description:
        'Styles applied to the `Paper` component if `anchor="right"` and `variant` is not "temporary".'
    },
    paperAnchorDockedBottom: {
      description:
        'Styles applied to the `Paper` component if `anchor="bottom"` and `variant` is not "temporary".'
    },
    modal: { description: "Styles applied to the `Modal` component." }
  }
};
export default generatedDocs;
