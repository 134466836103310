import React from "react";
import { Page, Title, Subtitle, SectionTitle } from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";

import Simple from "./demos/Simple";
import Permanent from "./demos/Permanent";
import Temporary from "./demos/Temporary";

let demoNames = ["Simple", "Temporary", "Permanent"];

export default function Dialog() {
  let demos = generateDemos("core-component-demo/drawer", demoNames);

  return (
    <Page components="Drawer">
      <Title>Drawer</Title>
      <Subtitle>
        Drawers provide access to actions and app functionality. They can either
        be permanently on-screen or temporarily shown with user action.
      </Subtitle>

      <SectionTitle>Drawer</SectionTitle>
      <p>
        Drawers can toggle open or closed. This example is closed by default,
        when toggled open the Drawer sits temporarily above all other content
        until closed.
      </p>
      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>Temporary Drawer</SectionTitle>
      <p>
        The Temporary Drawer can be cancelled by clicking outside of the Drawer,
        making a selection, or pressing the Esc key. For the following behavior,
        use prop <code>variant=temporary</code>.
      </p>
      <p>
        If the temporary prop is used, the page content beneath the open drawer
        can't be interacted with until the Drawer is closed.
      </p>
      <Demo demo={demos.Temporary}>
        <Temporary />
      </Demo>

      <SectionTitle>Permanent Drawer</SectionTitle>
      <p>
        Permanent drawers are always visible and pinned to the left edge, at the
        same elevation as the content or background. They cannot be closed. The
        following example shows how to construct a common Footer component with
        icons.
      </p>
      <Demo demo={demos.Permanent}>
        <Permanent />
      </Demo>
    </Page>
  );
}
