import React, { useState } from "react";
import Switch from "@liveramp/motif/core/Switch";
import FormControlLabel from "@liveramp/motif/core/FormControlLabel";
import {
  CheckCircle,
  RemoveCircle,
  Favorite,
  Lightbulb
} from "@liveramp/motif/icons";
import css from "./CSSModulesMuiSelector.module.css";

export default function CSSModulesMuiSelectorDemo() {
  let [icon, setIcon] = useState(true);
  let [heart, setHeart] = useState(true);
  let [star, setStar] = useState(true);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            className={css.clear}
            checked={icon}
            icon={<RemoveCircle />}
            checkedIcon={<CheckCircle />}
            onChange={event => setIcon(event.target.checked)}
            value="icon"
          />
        }
      />
      <FormControlLabel
        control={
          <Switch
            className={css.heart}
            checked={heart}
            onChange={event => setHeart(event.target.checked)}
            icon={<Favorite />}
            checkedIcon={<Favorite />}
            value="heart"
          />
        }
      />
      <FormControlLabel
        control={
          <Switch
            className={css.star}
            checked={star}
            onChange={event => setStar(event.target.checked)}
            icon={<Lightbulb style={{ fontSize: "1.65rem" }} />}
            checkedIcon={<Lightbulb style={{ fontSize: "1.65rem" }} />}
            value="star"
          />
        }
      />
    </>
  );
}
