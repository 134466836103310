/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiLinearProgress",
  description:
    "## ARIA\r\n\r\nIf the progress bar is describing the loading progress of a particular region of a page,\r\nyou should use `aria-describedby` to point to the progress bar, and set the `aria-busy`\r\nattribute to `true` on that region until it has finished loading.",
  displayName: "LinearProgress",
  methods: [],
  props: {
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component.",
      defaultValue: { value: "'primary'", computed: false }
    },
    value: {
      type: { name: "number" },
      required: false,
      description:
        "The value of the progress indicator for the determinate and buffer variants.\r\nValue between 0 and 100."
    },
    valueBuffer: {
      type: { name: "number" },
      required: false,
      description:
        "The value for the buffer variant.\r\nValue between 0 and 100."
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'buffer'", computed: false },
          { value: "'determinate'", computed: false },
          { value: "'indeterminate'", computed: false },
          { value: "'query'", computed: false }
        ]
      },
      required: false,
      description:
        "The variant to use.\r\nUse indeterminate or query when there is no progress value.",
      defaultValue: { value: "'indeterminate'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    colorPrimary: {
      description:
        'Styles applied to the root and bar2 element if `color="primary"`; bar2 if `variant="buffer"`.'
    },
    colorSecondary: {
      description:
        'Styles applied to the root and bar2 elements if `color="secondary"`; bar2 if `variant="buffer"`.'
    },
    determinate: {
      description:
        'Styles applied to the root element if `variant="determinate"`.'
    },
    indeterminate: {
      description:
        'Styles applied to the root element if `variant="indeterminate"`.'
    },
    buffer: {
      description: 'Styles applied to the root element if `variant="buffer"`.'
    },
    query: {
      description: 'Styles applied to the root element if `variant="query"`.'
    },
    dashed: {
      description:
        'Styles applied to the additional bar element if `variant="buffer"`.'
    },
    dashedColorPrimary: {
      description:
        'Styles applied to the additional bar element if `variant="buffer"` and `color="primary"`.'
    },
    dashedColorSecondary: {
      description:
        'Styles applied to the additional bar element if `variant="buffer"` and `color="secondary"`.'
    },
    bar: {
      description: "Styles applied to the layered bar1 and bar2 elements."
    },
    barColorPrimary: {
      description:
        'Styles applied to the bar elements if `color="primary"`; bar2 if `variant` not "buffer".'
    },
    barColorSecondary: {
      description:
        'Styles applied to the bar elements if `color="secondary"`; bar2 if `variant` not "buffer".'
    },
    bar1Indeterminate: {
      description:
        'Styles applied to the bar1 element if `variant="indeterminate or query"`.'
    },
    bar1Determinate: {
      description:
        'Styles applied to the bar1 element if `variant="determinate"`.'
    },
    bar1Buffer: {
      description: 'Styles applied to the bar1 element if `variant="buffer"`.'
    },
    bar2Indeterminate: {
      description:
        'Styles applied to the bar2 element if `variant="indeterminate or query"`.'
    },
    bar2Buffer: {
      description: 'Styles applied to the bar2 element if `variant="buffer"`.'
    }
  }
};
export default generatedDocs;
