import React from "react";

const WorkflowUploadData = props => (
  <svg width={70} height={80} viewBox="0 0 70 80" {...props}>
    <path d="M36.706 41.464c8.796 0 15.59 3.224 15.59 7.821 0 .048 0 .096-.002.144l.003.05v21.727c0 4.597-6.795 7.82-15.591 7.82h-.325c-8.638-.08-15.267-3.28-15.267-7.82V49.479c0-.033.002-.065.006-.097l-.006-.097c0-4.597 6.796-7.82 15.592-7.82zm0 1.675c-7.984 0-13.917 2.814-13.917 6.146l-.005.097.005.097v21.727c0 3.332 5.933 6.146 13.917 6.146s13.916-2.814 13.916-6.146v-8.484c-2.576 2.618-7.862 4.342-14.002 4.342a28.643 28.643 0 01-10.187-1.946.837.837 0 01.607-1.56 26.961 26.961 0 009.586 1.831c7.88 0 13.996-3.027 13.996-6.34v-5.998c-2.566 2.676-7.82 4.443-13.916 4.443-3.968 0-7.464-.538-10.147-1.612a.837.837 0 01.622-1.555c2.462.985 5.754 1.492 9.525 1.492 7.837 0 13.916-3.107 13.916-6.534 0-3.332-5.932-6.146-13.916-6.146z" />
    <path d="M36.623 24.668l8.704 9.334a1.067 1.067 0 11-1.56 1.455l-6.151-6.595v19.795a1.067 1.067 0 01-2.135 0v-19.64l-6.116 6.546a1.067 1.067 0 01-1.56-1.457l8.818-9.438zM39.378.656c9.158 0 16.111 6.696 16.424 16.239a11.847 11.847 0 018.604 11.41c0 6.544-5.283 11.849-11.8 11.849h-3.948a1.075 1.075 0 01-1.073-1.077c0-.595.48-1.077 1.073-1.077h3.949c5.332 0 9.654-4.341 9.654-9.695 0-5.355-4.322-9.695-9.654-9.695h-3.568a1.075 1.075 0 01-1.073-1.078c0-.595.48-1.077 1.073-1.077h3.568c.347 0 .69.015 1.03.045-.479-8.127-6.406-13.69-14.26-13.69-4.667 0-8.95 2.263-11.612 5.978a8.977 8.977 0 013.747 7.308c0 .595-.48 1.077-1.073 1.077a1.075 1.075 0 01-1.073-1.077c0-3.768-3.041-6.822-6.793-6.822-4.038 0-6.808 2.763-6.808 7.35 0 .046-.003.09-.009.135a1.077 1.077 0 01-.74.958c-4.229 1.374-7.103 5.307-7.103 9.87 0 5.75 4.643 10.413 10.37 10.413h5.72c.592 0 1.073.482 1.073 1.077s-.48 1.077-1.073 1.077h-5.72c-6.912 0-12.515-5.627-12.515-12.568 0-5.238 3.154-9.804 7.87-11.661.29-5.38 3.93-8.806 8.935-8.806 1.164 0 2.276.224 3.295.63C28.916 3.35 33.92.656 39.378.656z" />
  </svg>
);

export default WorkflowUploadData;
