import React from "react";
import Button from "@liveramp/motif/core/Button";
import ButtonFormGroup from "@liveramp/motif/core/ButtonFormGroup";

export default function TextButtonDemo() {
  return (
    <ButtonFormGroup>
      <Button variant="text" color="primary">
        Primary
      </Button>
      <Button variant="text" color="secondary">
        Secondary
      </Button>
      <Button variant="text" disabled>
        Disabled
      </Button>
    </ButtonFormGroup>
  );
}
