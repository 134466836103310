import React from "react";
import MuiSwitch from "@material-ui/core/Switch";
import withStyles from "../../styles/withStyles";
import { SwitchStyle } from "../../utils/styleKeys";

function Switch(props) {
  return <MuiSwitch color="default" {...props} />;
}

let styles = theme => ({
  ...SwitchStyle,
  switchBase: {
    padding: props => (props.icon || props.iconChecked ? "6px" : undefined),
    "&:hover": {
      backgroundColor: theme.palette.primary.hover
    }
  },
  track: {
    opacity: 1,
    backgroundColor: theme.palette.grey[400]
  },
  checked: {
    color: theme.palette.primary.main,
    "& + .MuiSwitch-track": {
      backgroundColor: theme.palette.primary.light
    }
  },
  disabled: {
    "&.MuiSwitch-switchBase + .MuiSwitch-track, &.Mui-checked + .MuiSwitch-track":
      {
        opacity: 1,
        color: theme.palette.grey[300],
        backgroundColor: theme.palette.grey[300]
      }
  }
});

export default withStyles(styles, { name: "MuiSwitch" })(Switch);
