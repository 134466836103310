import React from "react";
import RichDataTable from "@liveramp/motif/core/RichDataTable";
import { caffeineOrder, rows } from "../../demos/data";

const columns = [
  { field: "name", headerName: "Name", width: 150, searchable: true },
  {
    field: "caffeine",
    headerName: "Caffeine",
    width: 120,
    sortComparator: (value1, value2) =>
      caffeineOrder.indexOf(value1) - caffeineOrder.indexOf(value2)
  },
  {
    field: "price",
    headerName: "Price",
    type: "number",
    width: 120,
    valueFormatter: params => `$${params.value.toFixed(2)}`
  },
  { field: "desc", headerName: "Description", width: 500 }
];

export default function SearchingDemo() {
  return <RichDataTable columns={columns} rows={rows} />;
}
