import React from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import CloseButton from "../../core-components/CloseButton";
import { makeStyles, mergeClasses } from "../../utils";
import { DialogTitleStyle } from "../../utils/styleKeys";

export default function DialogTitle({
  children,
  classes,
  onClose,
  renderContentRight,
  ...props
}) {
  let defaultClasses = useStyles();

  return (
    <MuiDialogTitle
      disableTypography
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    >
      <h2>{children}</h2>
      <span className="MuiDialogTitle-contentRight">
        {renderContentRight}
        {onClose && <CloseButton onClick={onClose} />}
      </span>
    </MuiDialogTitle>
  );
}

let useStyles = makeStyles(
  theme => ({
    ...DialogTitleStyle,
    root: {
      display: "flex",
      alignItems: "flex-start",
      "& > h2": {
        color: theme.palette.text.primary,
        ...theme.typography.h2Branding
      },
      "& .MuiDialogTitle-contentRight": {
        display: "grid",
        gridAutoFlow: "column",
        gap: "16px",
        justifyContent: "flex-end",
        margin: " -6px -6px 0 0"
      },
      ":not(.MuiDialog-paperFullScreen) > &": {
        padding: "0",
        marginBottom: "24px",
        "& > h2": {
          flex: "1 1 auto"
        }
      },
      ".MuiDialog-paperFullScreen > &": {
        display: "flex",
        justifyContent: "space-between",
        padding: "24px 32px 24px 25%",
        transition: theme.transitions.common.enteringScreen,
        "&.Mui-scrollShadow": {
          boxShadow: theme.shadows[3],
          transition: theme.transitions.common.leavingScreen,
          zIndex: theme.zIndex.dialog
        }
      }
    }
  }),
  { name: "MuiDialogTitle" }
);
