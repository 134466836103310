import React from "react";
import { GridOverlay } from "../../core-components/DataGrid";
import makeStyles from "../../styles/makeStyles";
import TableNullState from "../../icons/TableNullState";

export default function NoRowsOverlay({ errorText }) {
  useStyles();

  return (
    <GridOverlay
      data-testid="RichDataTableNoRowsOverlay"
      className="RichDataTableNoRowsOverlay"
    >
      <TableNullState className="RichDataTableNoRowsOverlay__icon" />
      <div className="RichDataTableNoRowsOverlay__label">{errorText}</div>
    </GridOverlay>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".RichDataTableNoRowsOverlay": {
      flexDirection: "column",
      gap: "16px",

      // .RichDataTableNoRowsOverlay__icon
      "&__icon": {
        fontSize: "46px"
      },

      // .RichDataTableNoRowsOverlay__label
      "&__label": {
        ...theme.typography.h4Branding
      }
    }
  }
}));
