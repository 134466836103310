import React from "react";

const WorkflowBuyData = props => (
  <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <g fillRule="nonzero">
      <path d="M15.04 58.24a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6zm0-1.92a2.88 2.88 0 110-5.76 2.88 2.88 0 010 5.76zM14.97 42.88l-2.434-31.897A8.118 8.118 0 004.485 3.52a.96.96 0 10-.01 1.92 6.197 6.197 0 016.147 5.694l2.5 32.779a.96.96 0 00.958.887H30.4a.96.96 0 100-1.92H14.97zM56.062 14.88a577.056 577.056 0 01-.698 8.716.96.96 0 101.912.168 578.944 578.944 0 00.962-12.503.96.96 0 00-.958-1.021H17.92a.96.96 0 000 1.92h38.334c-.055.81-.12 1.724-.192 2.72z" />
      <path d="M40.96 11.2v16.32a.96.96 0 001.92 0V11.2a.96.96 0 10-1.92 0zM25.6 11.2v32.64a.96.96 0 001.92 0V11.2a.96.96 0 10-1.92 0z" />
      <path d="M41.92 26.56H12.88a.96.96 0 100 1.92h29.04a.96.96 0 100-1.92zM74.56 40v26.88c0 4.173-7.582 7.68-17.76 7.68s-17.76-3.507-17.76-7.68V40a.96.96 0 00-1.92 0v26.88c0 5.638 8.567 9.6 19.68 9.6s19.68-3.962 19.68-9.6V40a.96.96 0 00-1.92 0z" />
      <path d="M43.971 48.094c3.39 1.322 7.81 1.986 12.829 1.986 10.917 0 19.68-4.367 19.68-10.08 0-5.638-8.567-9.6-19.68-9.6S37.12 34.362 37.12 40a.96.96 0 001.92 0c0-4.173 7.582-7.68 17.76-7.68S74.56 35.827 74.56 40c0 4.289-7.767 8.16-17.76 8.16-4.799 0-8.99-.63-12.131-1.854a.96.96 0 10-.698 1.788zM43.97 60.574a35.842 35.842 0 0012.823 2.466c10.924 0 19.687-4.367 19.687-10.08a.96.96 0 00-1.92 0c0 4.289-7.767 8.16-17.76 8.16a33.913 33.913 0 01-12.13-2.334.96.96 0 00-.7 1.788z" />
    </g>
  </svg>
);

export default WorkflowBuyData;
