import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  Usage,
  NoticePanel,
  SectionTitle
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import CodeChunk from "../../resources/CodeChunk";

import Simple from "./demos/Simple";
import CheckboxType from "./demos/CheckboxType";
import FormGroup from "./demos/FormGroup";
import LabelPlacement from "./demos/LabelPlacement";
import Size from "./demos/Size";
import Custom from "./demos/Custom";

let demoNames = [
  "Simple",
  "CheckboxType",
  "FormGroup",
  "LabelPlacement",
  "Size",
  "Custom"
];

export default function Checkbox() {
  let demos = generateDemos("core-component-demo/checkbox", demoNames);
  return (
    <Page
      components={[
        "Checkbox",
        "FormGroup",
        "FormControl",
        "FormLabel",
        "FormControlLabel",
        "FormHelperText"
      ]}
    >
      <Title>Checkbox</Title>
      <Subtitle>
        Checkboxes allow the user to select one or more items from a set
      </Subtitle>
      <Supporting />
      <Usage>
        <ul>
          <li>
            Checkboxes can be used to select one or multiple items from a list.
          </li>
          <li>They can present a list containing sub-selections.</li>
          <li>
            If you have a single option, avoid using a checkbox and use an
            on/off switch instead.
          </li>
          <li>
            <a href="https://www.nngroup.com/articles/checkboxes-vs-radio-buttons/">
              Checkboxes vs. Radio Button
            </a>{" "}
            (<i>Nielson Norman Group</i>)
          </li>
          <li>
            <a href="https://material.io/components/selection-controls/">
              Material Design documentation
            </a>{" "}
            (<i>Google</i>)
          </li>
        </ul>
      </Usage>

      <Demo demo={demos.Simple}>
        <Simple />
      </Demo>

      <SectionTitle>FormControlLabel</SectionTitle>
      <p>
        <code>FormControlLabel</code> has a <code>control</code> prop that
        accepts a control element to attach a label description to e.g.{" "}
        <code>Radio</code>,<code>Checkbox</code>, <code>Switch</code>
      </p>
      <Demo demo={demos.CheckboxType}>
        <CheckboxType />
      </Demo>

      <SectionTitle>FormGroup and FormHelperText</SectionTitle>
      <p>
        <code>FormGroup</code> is a wrapper used to group multiple selection
        control elements.
      </p>
      <p>
        <code>FormHelperText</code> appends text to your <code>FormGroup</code>,
        such as descriptions, hints and errors.
      </p>
      <Demo demo={demos.FormGroup}>
        <FormGroup />
      </Demo>

      <SectionTitle>Label Placement</SectionTitle>
      <Demo demo={demos.LabelPlacement}>
        <LabelPlacement />
      </Demo>

      <SectionTitle>Size</SectionTitle>
      <NoticePanel variant="info">
        <p>
          Remember to also use <code>size="small"</code> for the FormGroupLabel.
        </p>
      </NoticePanel>
      <Demo demo={demos.Size}>
        <Size />
      </Demo>

      <SectionTitle>Custom Checkboxes</SectionTitle>
      <Demo demo={demos.Custom}>
        <Custom />
      </Demo>

      <SectionTitle>Accessibility</SectionTitle>
      <p>
        All form controls should have labels, and this includes radio buttons,
        checkboxes, and switches. In most cases, this is done by using the{" "}
        <code>{`<label>`}</code> element (FormControlLabel).
      </p>
      <p>
        When a label can't be used, it's necessary to add an attribute directly
        to the input component. In this case, you can apply the additional
        attribute (e.g. <code>aria-label</code>, <code>aria-labelledby</code>,{" "}
        <code>title</code>) via the inputProps property.
      </p>
      <CodeChunk>
        {`<Checkbox value="checkedA" inputProps={{ 'aria-label': 'Checkbox A' }} />`}
      </CodeChunk>
    </Page>
  );
}
