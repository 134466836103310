import React from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { makeStyles, mergeClasses } from "../../utils";
import { DialogContentStyle } from "../../utils/styleKeys";

export default function DialogContent({ classes, ...props }) {
  let defaultClasses = useStyles();

  return (
    <MuiDialogContent
      classes={mergeClasses(defaultClasses, classes)}
      {...props}
    />
  );
}

let useStyles = makeStyles(
  theme => ({
    ...DialogContentStyle,
    root: {
      padding: 0,
      "&:first-child": {
        paddingTop: "16px"
      },
      "& :first-child": {
        marginTop: 0
      },
      ":not(.MuiDialog-paperFullScreen) > &": {
        marginBottom: "32px"
      },
      ".MuiDialog-paperFullScreen > &": {
        padding: "0 25%"
      }
    }
  }),
  { name: "MuiDialogContent" }
);
