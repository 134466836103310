import React from "react";
import PropTypes from "prop-types";
import MuiSnackbar from "@material-ui/core/Snackbar";
import Content from "./Content";
import { dependentProps, withStyles } from "../../utils";
import { AlertStyle } from "../../utils/styleKeys";

function Alert({
  action, // ignore - is handled internally by <Content /> already
  anchorOrigin, // ignore - we only use full screen alert banners
  autoHideDuration,
  children,
  ContentProps,
  icon,
  onClickButton,
  onClickButtonLabel,
  onClose,
  TransitionComponent, // ignore - we don't want users adjusting transitions
  transitionDuration, // ignore - we don't want users adjusting transitions
  TransitionProps, // ignore - we don't want users adjusting transitions
  variant,
  ...props
}) {
  return (
    <MuiSnackbar
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      {...props}
    >
      <Content
        variant={variant}
        onClickButton={onClickButton}
        onClickButtonLabel={onClickButtonLabel}
        onClose={onClose}
        icon={icon}
        {...ContentProps}
      >
        {children}
      </Content>
    </MuiSnackbar>
  );
}

let styles = theme => ({
  ...AlertStyle,
  root: {
    top: "0",
    left: "0",
    right: "0",
    transform: "none"
  }
});

Alert.propTypes = Object.assign({}, MuiSnackbar.propTypes, {
  icon: PropTypes.bool,
  onClickButton: dependentProps(["onClickButton", "onClickButtonLabel"]),
  onClickButtonLabel: dependentProps(["onClickButton", "onClickButtonLabel"]),
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.oneOf([
    "default",
    "success",
    "warning",
    "danger",
    "privacy"
  ])
});

Alert.defaultProps = Object.assign({}, MuiSnackbar.defaultProps, {
  autoHideDuration: 6000,
  icon: true,
  variant: "default"
});

export default withStyles(styles, { name: "MuiSnackbar" })(Alert);
