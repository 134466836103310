import React, { useContext } from "react";
import clsx from "clsx";
import TextField from "../../core-components/TextField";
import { GlobalNavigationContext } from "./GlobalNavigation";
import makeStyles from "../../styles/makeStyles";
import SearchIcon from "../../icons/Search";

export default function SearchField({
  className,
  lightColor,
  onFocus = () => {},
  renderInputProps,
  ...props
}) {
  const { customisation } = useContext(GlobalNavigationContext);
  const { InputLabelProps, InputProps, inputProps, ...etc } = renderInputProps;
  useStyles({ customisation, lightColor });

  return (
    <TextField
      className={clsx("GNSearchField", "GNSearchField-textfield", className)}
      inputRef={InputProps?.ref}
      InputLabelProps={InputLabelProps}
      InputProps={{
        ...InputProps,
        className: "GNSearchField-Input",
        endAdornment: <SearchIcon />
      }}
      inputProps={{
        ...inputProps,
        className: clsx("GNSearchField-input", inputProps.className)
      }}
      onFocus={onFocus}
      placeholder="Search"
      variant="underlined"
      autoFocus
      fullWidth
      {...etc}
      {...props}
    />
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".GNSearchField": {
      // .GNSearchField-textfield
      "&-textfield": {
        padding: "0 10px",

        "& .MuiInputBase-input::-webkit-input-placeholder": {
          fontSize: "14px",
          color: ({ lightColor }) =>
            lightColor
              ? theme.palette.common.white
              : theme.palette.text.secondary,
          opacity: 1
        },

        "& .MuiInput-underline:before": {
          borderColor: ({ lightColor }) =>
            lightColor
              ? theme.palette.common.white
              : theme.palette.text.secondary
        },

        "& .MuiSvgIcon-root": {
          fill: ({ lightColor }) =>
            lightColor
              ? theme.palette.common.white
              : theme.palette.text.secondary
        }
      },

      // .GNSearchField-input
      "&-input": {
        color: ({ lightColor }) =>
          lightColor ? theme.palette.common.white : theme.palette.text.primary
      },

      // .GNSearchField-Input
      "&-Input": {
        "&:after": {
          borderColor: ({ customisation }) =>
            customisation?.theme
              ? customisation.theme
              : theme.palette.primary.main
        },

        "&:hover:not(.Mui-disabled):before": {
          borderBottom: ({ customisation }) =>
            `1px solid ${
              customisation?.theme
                ? customisation.theme
                : theme.palette.primary.main
            }`
        }
      }
    }
  }
}));
