/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiSkeleton",
  description: "",
  displayName: "Skeleton",
  methods: [],
  props: {
    animation: {
      type: {
        name: "enum",
        value: [
          { value: "'pulse'", computed: false },
          { value: "'wave'", computed: false },
          { value: "false", computed: false }
        ]
      },
      required: false,
      description:
        "The animation.\r\nIf `false` the animation effect is disabled.",
      defaultValue: { value: "'pulse'", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "Optional children to infer width and height from."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'span'", computed: false }
    },
    height: {
      type: { name: "union", value: [{ name: "number" }, { name: "string" }] },
      required: false,
      description:
        "Height of the skeleton.\r\nUseful when you don't want to adapt the skeleton to a text element but for instance a card."
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'text'", computed: false },
          { value: "'rect'", computed: false },
          { value: "'circle'", computed: false }
        ]
      },
      required: false,
      description: "The type of content that will be rendered.",
      defaultValue: { value: "'text'", computed: false }
    },
    width: {
      type: { name: "union", value: [{ name: "number" }, { name: "string" }] },
      required: false,
      description:
        "Width of the skeleton.\r\nUseful when the skeleton is inside an inline element with no width of its own."
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    text: {
      description: 'Styles applied to the root element if `variant="text"`.'
    },
    rect: {
      description: 'Styles applied to the root element if `variant="rect"`.'
    },
    circle: {
      description: 'Styles applied to the root element if `variant="circle"`.'
    },
    pulse: {
      description: 'Styles applied to the root element if `animation="pulse"`.'
    },
    wave: {
      description: 'Styles applied to the root element if `animation="wave"`.'
    },
    withChildren: {
      description: "Styles applied when the component is passed children."
    },
    fitContent: {
      description:
        "Styles applied when the component is passed children and no width."
    },
    heightAuto: {
      description:
        "Styles applied when the component is passed children and no height."
    }
  }
};
export default generatedDocs;
