import React, { useCallback, useRef, useState, useEffect } from "react";
import clsx from "clsx";
import { useControlled } from "@material-ui/core";
import Button from "../../core-components/Button";
import FilterAutocomplete from "./FilterAutocomplete";
import Popper from "../../core-components/Popper";
import Tooltip from "../../core-components/Tooltip";
import ClickAwayListener from "../../core-components/ClickAwayListener";
import makeStyles from "../../styles/makeStyles";
import FilterIcon from "../../icons/Filter";
import ArrowDropDownIcon from "../../icons/ArrowDropDown";

export default function Filter({
  id,
  label,
  tooltipProps,
  buttonProps,
  popperProps,
  listPlacement = "bottom-start",
  virtualized = false,
  virtualizedWidth,
  loading = false,
  onOpen,
  onClose: onCloseProp,
  ...props
}) {
  const {
    defaultValue: defaultValueProp = [],
    value: valueProp,
    inputValue: inputValueProp,
    getOptionLabel,
    options
  } = props;

  const buttonRef = useRef();
  const menuRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [inputValue, setInputValue] = useControlled({
    controlled: inputValueProp,
    default: "",
    name: "Filter",
    state: "inputValue"
  });
  const [value, setValue] = useControlled({
    controlled: valueProp,
    default: defaultValueProp,
    name: "Filter",
    state: "value"
  });

  useStyles(props);

  useEffect(() => {
    if (anchorEl && onOpen && typeof onOpen === "function")
      onOpen(menuRef.current);
  }, [anchorEl]);

  const setListeners = useCallback(node => {
    if (node) {
      node.addEventListener("keydown", handleKeyDown, { capture: true });
    }
    menuRef.current = node;
  }, []);

  const handleAnchorElKeyDown = e => {
    if (e.code === "Enter") {
      // wait for list to render before setting focus
      setTimeout(() => {
        // setting focus here allows ESC to be used
        // without user having to click the list first
        if (menuRef.current) menuRef.current.focus();
      }, 100);
    }
  };

  const handleKeyDown = e => {
    switch (e.key) {
      case "Escape":
        // currently the escape key is being hooked into an internal
        // useAutocomplete handler which closes the autocomplete but our open
        // state is being handled with anchorEl
        setAnchorEl(null);
        e.stopPropagation();
        break;
      default:
    }
  };

  const open = Boolean(anchorEl);

  const handleClose = (e, reason) => {
    if (reason === "toggleInput") return;
    setAnchorEl(null);
    open && onCloseProp && onCloseProp(e);
  };

  const generateTooltip = () => {
    let tooltipStr = value
      .slice(0, 9)
      .map(item => getOptionLabel(item))
      .join(", ");

    if (value.length > 10) {
      tooltipStr = `${tooltipStr} +${value.length - 10}`;
    }
    return tooltipStr;
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div
        data-testid="Filter"
        className={clsx("Filter", [open ? "Filter--isOpen" : ""])}
        id={id}
      >
        <Tooltip
          title={generateTooltip()}
          open={!open && tooltipOpen}
          onClose={() => setTooltipOpen(false)}
          onOpen={() => setTooltipOpen(true)}
          placement="bottom"
          interactive
          {...tooltipProps}
        >
          <Button
            ref={buttonRef}
            className="Filter__button Filter-buttonBase"
            onClick={event => {
              anchorEl ? setAnchorEl(null) : setAnchorEl(event.currentTarget);
            }}
            onKeyDown={handleAnchorElKeyDown}
            startIcon={<FilterIcon />}
            endIcon={
              <ArrowDropDownIcon
                className={clsx("Filter__button-caret", [
                  open ? "Filter__button-caret--isOpen" : ""
                ])}
              />
            }
            variant={value.length > 0 ? "default" : "secondary"}
            color={value.length > 0 ? "primary" : "secondary"}
            {...buttonProps}
          >
            <span className="Filter__button-label">{label}</span>
            <span className="Filter__button-counter">
              ({value.length}/{options.length})
            </span>
          </Button>
        </Tooltip>

        <Popper
          anchorEl={anchorEl}
          ref={setListeners}
          className="Filter__popper"
          open={open}
          placement={listPlacement}
          modifiers={{ flip: { enabled: false } }}
          style={{ minWidth: buttonRef?.current?.offsetWidth }}
          keepMounted
          tabIndex="0"
          {...popperProps}
        >
          <FilterAutocomplete
            onClose={handleClose}
            value={value}
            setValue={setValue}
            inputValue={inputValue}
            setInputValue={setInputValue}
            virtualized={virtualized}
            virtualizedWidth={virtualizedWidth}
            loading={loading}
            {...props}
          />
        </Popper>
      </div>
    </ClickAwayListener>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".Filter": {
      display: "block",
      overflowX: "hidden",

      "&.Filter--isOpen": {
        // - fixes issue where selecting an item in the list
        //   then clicking the button doesn't close the list
        // - closing the list relies on the ClickAwayListener
        pointerEvents: "none"
      }
    },
    ".Filter__button": {
      width: "100%",
      paddingLeft: "8px",
      paddingRight: "8px"
    },
    ".Filter__button-label": {
      flex: "1",
      textAlign: "left",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden"
    },
    ".Filter__button-counter": {
      marginLeft: "24px"
    },
    ".Filter__button-caret--isOpen": {
      transform: "rotate(180deg)"
    },
    ".Filter__popper": {
      zIndex: theme.zIndex.dialog
    }
  }
}));
