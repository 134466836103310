import React from "react";
import { Title, Subtitle, NoticePanel } from "../resources/Page";
import IconSection from "./IconSection";
import iconSectionIndex from "./iconSectionIndex";

export default function Icons() {
  return (
    <div>
      <Title>Icons</Title>
      <Subtitle>
        LiveRamp icons are delightful, beautifully crafted symbols for common
        actions and items. They are designed to be simple, modern, and friendly.
        See how to customize icon styles <a href={`/style/icons`}>here</a>.
      </Subtitle>
      <br />
      <NoticePanel variant="info">
        <p>
          If you find the icon you need here, it's available for use! The name
          of each icon shown on this page corresponds to the icon component's
          exported name. Import it into your project with the given name:
          <br />
          i.e <code>New Icon</code> can be imported as{" "}
          <code>import NewIcon from "@liveramp/motif/icons/NewIcon"</code>.
        </p>
        <h5>
          Don't see the icon you're looking for? Look through{" "}
          <a
            href="https://material.io/resources/icons/?style=round"
            target="_blank"
            rel="noopener noreferrer"
          >
            Material's official icon selection
          </a>
        </h5>
        <p>
          If you need a custom icon see the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://liveramp.atlassian.net/wiki/spaces/UEDR/pages/614174099/Icons+Design+Request+Process#Using-Icons-directly-from-Material-UIs-source%3A"
          >
            Icons KB page
          </a>{" "}
          for more information.
        </p>
      </NoticePanel>

      {Object.keys(iconSectionIndex).map(section => (
        <IconSection
          key={section}
          title={section}
          icons={iconSectionIndex[section]}
        />
      ))}
    </div>
  );
}
