import React from "react";
import Paper from "@liveramp/motif/core/Paper";
import List from "@liveramp/motif/core/List";
import ListItem from "@liveramp/motif/core/ListItem";
import ListItemIcon from "@liveramp/motif/core/ListItemIcon";
import ListItemText from "@liveramp/motif/core/ListItemText";
import ListSubheader from "@liveramp/motif/core/ListSubheader";
import Inbox from "@liveramp/motif/icons/Inbox";

export default function SimpleDemo() {
  return (
    <Paper style={{ width: "300px" }}>
      <List>
        <ListSubheader>ListSubheader</ListSubheader>
        <ListItem selected>
          <ListItemText>Selected ListItemText</ListItemText>
        </ListItem>
        <ListItem button onClick={() => alert("Clicked on a ListItem!")}>
          <ListItemText secondary="as a Button">ListItemText</ListItemText>
        </ListItem>
        <ListItem
          button
          component="a"
          href="https://motif.nexus.liveramp.com/core/list"
        >
          <ListItemText secondary="as a Link">ListItemText</ListItemText>
        </ListItem>
        <ListItem button>
          <ListItemIcon>
            <Inbox />
          </ListItemIcon>
          <ListItemText secondary="with ListItemIcon">
            ListItemText
          </ListItemText>
        </ListItem>
      </List>
    </Paper>
  );
}
