import React from "react";

const WorkflowManageDataSet = props => (
  <svg width={80} height={80} viewBox="0 0 80 80" {...props}>
    <path
      d="M48.139 9.45c-2.799 0-5.511.185-8.081.546a1.05 1.05 0 00.292 2.08 56.122 56.122 0 017.789-.526c14.453 0 25.261 5.127 25.261 11.404 0 .06.005.117.014.174a.831.831 0 00-.008.058l-.006.114v38.746c0 6.211-10.582 11.296-24.806 11.402l-.455.002-.455-.002c-14.072-.105-24.578-5.082-24.803-11.202l-.003-.2V45.76c4.125 5.025 13.9 8.456 25.41 8.456a50.637 50.637 0 0018.01-3.44 1.05 1.05 0 10-.762-1.957 48.528 48.528 0 01-17.255 3.297c-14.268 0-25.403-5.512-25.403-11.75a1.05 1.05 0 10-2.1 0v21.679c0 7.785 11.653 13.391 26.898 13.502l.463.002.463-.002c15.093-.11 26.665-5.605 26.895-13.27l.003-.232V23.3c0-.059-.005-.117-.014-.173.01-.056.014-.114.014-.173C75.5 15.09 63.61 9.45 48.139 9.45zm17.15 22.908c-4.45 1.78-10.374 2.692-17.15 2.692-4.486 0-8.745-.556-12.503-1.6a1.05 1.05 0 00-.562 2.023c3.945 1.096 8.393 1.677 13.065 1.677 7.023 0 13.204-.952 17.93-2.843a1.05 1.05 0 00-.78-1.95zM20.82 3.5c-8.632 0-15.63 6.998-15.63 15.63 0 8.632 6.998 15.63 15.63 15.63 8.632 0 15.63-6.998 15.63-15.63 0-8.632-6.998-15.63-15.63-15.63zm0 2.1c7.472 0 13.53 6.058 13.53 13.53 0 7.472-6.058 13.53-13.53 13.53-7.472 0-13.53-6.058-13.53-13.53 0-7.472 6.058-13.53 13.53-13.53zm5.575 10.476a.993.993 0 00-.214 1.028c.27.682.41 1.443.41 2.224 0 3.195-2.386 5.806-5.365 5.926l-.214.004V23.49c0-.415-.437-.632-.73-.41l-.06.054-2.594 2.758a.511.511 0 00-.061.623l.06.079 2.595 2.757c.269.295.724.126.784-.26l.006-.086v-1.769c4.11 0 7.438-3.538 7.438-7.907a8.165 8.165 0 00-.53-2.916c-.251-.662-1.05-.84-1.525-.336zM20.888 9.91l-.006.086v1.769c-4.106 0-7.432 3.538-7.432 7.907 0 1.028.186 2.016.53 2.916.25.662 1.05.84 1.523.336a.993.993 0 00.214-1.028 6.077 6.077 0 01-.41-2.224c0-3.195 2.385-5.806 5.361-5.926l.214-.004v1.769c0 .415.437.632.736.41l.062-.054 2.592-2.758a.511.511 0 00.061-.623l-.06-.079L21.67 9.65c-.269-.295-.724-.126-.783.26z"
      fillRule="nonzero"
    />
  </svg>
);

export default WorkflowManageDataSet;
