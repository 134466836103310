/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiFab",
  description: "",
  displayName: "Fab",
  methods: [],
  props: {
    children: {
      type: { name: "node" },
      required: true,
      description: "The content of the button."
    },
    classes: {
      type: { name: "object" },
      required: false,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'default'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component.",
      defaultValue: { value: "'default'", computed: false }
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.",
      defaultValue: { value: "'button'", computed: false }
    },
    disabled: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the button will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableFocusRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the  keyboard focus ripple will be disabled.",
      defaultValue: { value: "false", computed: false }
    },
    disableRipple: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the ripple effect will be disabled."
    },
    focusVisibleClassName: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    href: {
      type: { name: "string" },
      required: false,
      description:
        "The URL to link to when the button is clicked.\r\nIf defined, an `a` element will be used as the root node."
    },
    size: {
      type: {
        name: "enum",
        value: [
          { value: "'large'", computed: false },
          { value: "'medium'", computed: false },
          { value: "'small'", computed: false }
        ]
      },
      required: false,
      description:
        "The size of the button.\r\n`small` is equivalent to the dense button styling.",
      defaultValue: { value: "'large'", computed: false }
    },
    variant: {
      type: {
        name: "custom",
        raw:
          "chainPropTypes(PropTypes.oneOf(['extended', 'circular', 'round']), props => {\n  if (props.variant === 'round') {\n    throw new Error('Material-UI: variant=\"round\" was renamed variant=\"circular\" for consistency.');\n  }\n\n  return null;\n})"
      },
      required: false,
      description:
        "The variant to use.\r\n'round' is deprecated, use 'circular' instead.",
      defaultValue: { value: "'circular'", computed: false }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    label: {
      description: "Styles applied to the span element that wraps the children."
    },
    primary: {
      description: 'Styles applied to the root element if `color="primary"`.'
    },
    secondary: {
      description: 'Styles applied to the root element if `color="secondary"`.'
    },
    extended: {
      description: 'Styles applied to the root element if `variant="extended"`.'
    },
    focusVisible: {
      description:
        "Pseudo-class applied to the ButtonBase root element if the button is keyboard focused."
    },
    disabled: {
      description:
        "Pseudo-class applied to the root element if `disabled={true}`."
    },
    colorInherit: {
      description: 'Styles applied to the root element if `color="inherit"`.'
    },
    sizeSmall: {
      description: 'Styles applied to the root element if `size="small"``.'
    },
    sizeMedium: {
      description: 'Styles applied to the root element if `size="medium"``.'
    }
  }
};
export default generatedDocs;
