import React from "react";
import Divider from "@liveramp/motif/core/Divider";
import Typography from "@liveramp/motif/core/Typography";
import Chip from "@liveramp/motif/core/Chip";
import css from "./Demos.module.css";

let lorem =
  "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.";

export default function TypographyDemo() {
  return (
    <div className={css.root}>
      <Chip className={css.chip}>
        Check out the code snippets to see the Typography component in action!
      </Chip>
      <Typography variant="h1">h1 Heading</Typography>
      <Divider />
      <Typography variant="h2">h2 Heading</Typography>
      <Typography variant="h2Branding">h2 Heading Branding</Typography>
      <Divider />
      <Typography variant="h3">h3 Heading</Typography>
      <Typography variant="h3Branding">h3 Heading Branding</Typography>
      <Divider />
      <Typography variant="h4">h4 Heading</Typography>
      <Typography variant="h4Branding">h4 Heading Branding</Typography>
      <Divider />
      <Typography variant="h5">h5 Heading</Typography>
      <Divider />
      <Typography variant="h6">h6 Heading</Typography>
      <Divider />
      <Typography variant="subtitle1">Subtitle 1</Typography>
      <Typography variant="subtitle2">Subtitle 2</Typography>
      <Typography variant="subtitle3">Subtitle 3</Typography>
      <Divider />
      <Typography variant="body1">Body 1. {lorem}</Typography>
      <Typography variant="body2">Body 2. {lorem}</Typography>
      <Typography variant="body3">Body 3. {lorem}</Typography>
      <Divider />
      <Typography variant="caption">Caption</Typography>
    </div>
  );
}
