import List from "@material-ui/core/MenuList";
import withStyles from "../../styles/withStyles";
import { MenuListStyle } from "../../utils/styleKeys";

let styles = theme => ({
  ...MenuListStyle,
  root: {
    "&:focus": {
      outline: "none"
    }
  }
});

export default withStyles(styles, { name: "MuiMenuList" })(List);
