import React, { useState } from "react";
import Table from "@liveramp/motif/core/Table";
import TableHeader from "@liveramp/motif/core/TableHeader";
import TableBody from "@liveramp/motif/core/TableBody";
import TableRow from "@liveramp/motif/core/TableRow";
import TableCell from "@liveramp/motif/core/TableCell";
import TableFooter from "@liveramp/motif/core/TableFooter";
import Checkbox from "@liveramp/motif/core/Checkbox";
import { Check, Clear } from "@liveramp/motif/icons";
import theme from "@liveramp/motif/theme";

export default function SimpleDemo() {
  let [checkedItems, setCheckedItems] = useState([]);
  let data = [
    { name: "Cappuccino", temp: "Hot", caffeine: true },
    { name: "Latte", temp: "Hot", caffeine: true },
    { name: "Cortado", temp: "Hot", caffeine: true },
    { name: "Flat White", temp: "Hot", caffeine: true },
    { name: "Affogato", temp: "Cold", caffeine: false },
    { name: "Cold Brew", temp: "Cold", caffeine: true },
    { name: "Decaf Latte", temp: "Hot", caffeine: false }
  ];

  let handleToggleAll = () => {
    if (checkedItems.length !== data.length) {
      setCheckedItems(data.map(item => item.name));
    } else {
      setCheckedItems([]);
    }
  };

  let toggleItem = name => {
    if (checkedItems.includes(name)) {
      setCheckedItems(checkedItems.filter(item => item !== name));
    } else {
      setCheckedItems([...checkedItems, name]);
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              checked={checkedItems.length > 0}
              indeterminate={checkedItems.length < data.length}
              onChange={handleToggleAll}
            />
          </TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Caffeine</TableCell>
          <TableCell>Temperature</TableCell>
          <TableCell align="right">Price</TableCell>
          <TableCell align="center">Available</TableCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, i) => (
          <TableRow key={i}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={checkedItems.includes(row.name)}
                onChange={() => toggleItem(row.name)}
              />
            </TableCell>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.caffeine ? "High" : "Low"}</TableCell>
            <TableCell>{row.temp}</TableCell>
            <TableCell align="right">
              ${(Math.random() * 6 + 1).toFixed(2)}
            </TableCell>
            <TableCell align="center">
              {Math.random() < 0.5 ? (
                <Check style={{ color: theme.palette.primary.main }} />
              ) : (
                <Clear style={{ color: theme.palette.error.main }} />
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell />
          <TableCell colSpan={5}>
            Showing rows <b>1</b> - <b>{data.length}</b>
          </TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}
