/* eslint-disable no-template-curly-in-string */
/*
  This file is automagically generated; please do not make any changes.
  - To update, run `yarn generate-docs` from the ./lib directory.
  - To override, add a corresponding .js file in components-override.

  Please refer to the `new-components-guide.md` for full instructions!
*/

let generatedDocs = {
  muiName: "MuiTypography",
  description: "",
  displayName: "Typography",
  methods: [],
  props: {
    align: {
      type: {
        name: "enum",
        value: [
          { value: "'inherit'", computed: false },
          { value: "'left'", computed: false },
          { value: "'center'", computed: false },
          { value: "'right'", computed: false },
          { value: "'justify'", computed: false }
        ]
      },
      required: false,
      description: "Set the text-align on the component.",
      defaultValue: { value: "'inherit'", computed: false }
    },
    children: {
      type: { name: "node" },
      required: false,
      description: "The content of the component."
    },
    classes: {
      type: { name: "object" },
      required: true,
      description:
        "Override or extend the styles applied to the component.\r\nSee [CSS API](#css) below for more details."
    },
    className: {
      type: { name: "string" },
      required: false,
      description: "@ignore"
    },
    color: {
      type: {
        name: "enum",
        value: [
          { value: "'initial'", computed: false },
          { value: "'inherit'", computed: false },
          { value: "'primary'", computed: false },
          { value: "'secondary'", computed: false },
          { value: "'textPrimary'", computed: false },
          { value: "'textSecondary'", computed: false },
          { value: "'error'", computed: false }
        ]
      },
      required: false,
      description:
        "The color of the component. It supports those theme colors that make sense for this component.",
      defaultValue: { value: "'initial'", computed: false }
    },
    component: {
      type: { name: "elementType" },
      required: false,
      description:
        "The component used for the root node.\r\nEither a string to use a HTML element or a component.\r\nOverrides the behavior of the `variantMapping` prop."
    },
    display: {
      type: {
        name: "enum",
        value: [
          { value: "'initial'", computed: false },
          { value: "'block'", computed: false },
          { value: "'inline'", computed: false }
        ]
      },
      required: false,
      description: "Controls the display type",
      defaultValue: { value: "'initial'", computed: false }
    },
    gutterBottom: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the text will have a bottom margin.",
      defaultValue: { value: "false", computed: false }
    },
    noWrap: {
      type: { name: "bool" },
      required: false,
      description:
        "If `true`, the text will not wrap, but instead will truncate with a text overflow ellipsis.\r\n\r\nNote that text overflow can only happen with block or inline-block level elements\r\n(the element needs to have a width in order to overflow).",
      defaultValue: { value: "false", computed: false }
    },
    paragraph: {
      type: { name: "bool" },
      required: false,
      description: "If `true`, the text will have a bottom margin.",
      defaultValue: { value: "false", computed: false }
    },
    variant: {
      type: {
        name: "enum",
        value: [
          { value: "'h1'", computed: false },
          { value: "'h2'", computed: false },
          { value: "'h3'", computed: false },
          { value: "'h4'", computed: false },
          { value: "'h5'", computed: false },
          { value: "'h6'", computed: false },
          { value: "'subtitle1'", computed: false },
          { value: "'subtitle2'", computed: false },
          { value: "'body1'", computed: false },
          { value: "'body2'", computed: false },
          { value: "'caption'", computed: false },
          { value: "'button'", computed: false },
          { value: "'overline'", computed: false },
          { value: "'srOnly'", computed: false },
          { value: "'inherit'", computed: false }
        ]
      },
      required: false,
      description: "Applies the theme typography styles.",
      defaultValue: { value: "'body1'", computed: false }
    },
    variantMapping: {
      type: { name: "object" },
      required: false,
      description:
        "The component maps the variant prop to a range of different HTML element types.\r\nFor instance, subtitle1 to `<h6>`.\r\nIf you wish to change that mapping, you can provide your own.\r\nAlternatively, you can use the `component` prop.",
      defaultValue: {
        value:
          "{\n  h1: 'h1',\n  h2: 'h2',\n  h3: 'h3',\n  h4: 'h4',\n  h5: 'h5',\n  h6: 'h6',\n  subtitle1: 'h6',\n  subtitle2: 'h6',\n  body1: 'p',\n  body2: 'p'\n}",
        computed: false
      }
    }
  },
  styles: {
    root: { description: "Styles applied to the root element." },
    body2: {
      description: 'Styles applied to the root element if `variant="body2"`.'
    },
    body1: {
      description: 'Styles applied to the root element if `variant="body1"`.'
    },
    caption: {
      description: 'Styles applied to the root element if `variant="caption"`.'
    },
    button: {
      description: 'Styles applied to the root element if `variant="button"`.'
    },
    h1: {
      description: 'Styles applied to the root element if `variant="h1"`.'
    },
    h2: {
      description: 'Styles applied to the root element if `variant="h2"`.'
    },
    h3: {
      description: 'Styles applied to the root element if `variant="h3"`.'
    },
    h4: {
      description: 'Styles applied to the root element if `variant="h4"`.'
    },
    h5: {
      description: 'Styles applied to the root element if `variant="h5"`.'
    },
    h6: {
      description: 'Styles applied to the root element if `variant="h6"`.'
    },
    subtitle1: {
      description:
        'Styles applied to the root element if `variant="subtitle1"`.'
    },
    subtitle2: {
      description:
        'Styles applied to the root element if `variant="subtitle2"`.'
    },
    overline: {
      description: 'Styles applied to the root element if `variant="overline"`.'
    },
    srOnly: {
      description:
        'Styles applied to the root element if `variant="srOnly"`. Only accessible to screen readers.'
    },
    alignLeft: {
      description: 'Styles applied to the root element if `align="left"`.'
    },
    alignCenter: {
      description: 'Styles applied to the root element if `align="center"`.'
    },
    alignRight: {
      description: 'Styles applied to the root element if `align="right"`.'
    },
    alignJustify: {
      description: 'Styles applied to the root element if `align="justify"`.'
    },
    noWrap: {
      description: "Styles applied to the root element if `nowrap={true}`."
    },
    gutterBottom: {
      description:
        "Styles applied to the root element if `gutterBottom={true}`."
    },
    paragraph: {
      description: "Styles applied to the root element if `paragraph={true}`."
    },
    colorInherit: {
      description: 'Styles applied to the root element if `color="inherit"`.'
    },
    colorPrimary: {
      description: 'Styles applied to the root element if `color="primary"`.'
    },
    colorSecondary: {
      description: 'Styles applied to the root element if `color="secondary"`.'
    },
    colorTextPrimary: {
      description:
        'Styles applied to the root element if `color="textPrimary"`.'
    },
    colorTextSecondary: {
      description:
        'Styles applied to the root element if `color="textSecondary"`.'
    },
    colorError: {
      description: 'Styles applied to the root element if `color="error"`.'
    },
    displayInline: {
      description: 'Styles applied to the root element if `display="inline"`.'
    },
    displayBlock: {
      description: 'Styles applied to the root element if `display="block"`.'
    }
  }
};
export default generatedDocs;
