import React from "react";
import {
  Page,
  Title,
  Subtitle,
  Supporting,
  SectionTitle,
  NoticePanel
} from "../../resources/DemoPage";
import Demo, { generateDemos } from "../../resources/Demo";
import RegularFooter from "./demos/RegularFooter.jsx";

let demoNames = ["RegularFooter"];

export default function RichDataTable() {
  let demos = generateDemos("composed-component-demo/footer", demoNames);
  return (
    <Page components={["Drawer", "Footer"]}>
      <Title>Footers</Title>
      <Subtitle>
        Footers provide access to perform actions on a page or specific rows in
        data tables. Footers can either be permanently on-screen or temporarily
        shown with user action.
      </Subtitle>
      <Supporting />
      <SectionTitle>Regular Footer</SectionTitle>
      <ul>
        <li>The footer will by default span the entire window.</li>
        <li>
          The footer is not opinionated about placement of objects within it, it
          is up to you to place actions and content within the footer.
        </li>
        <NoticePanel variant="warning">
          <p>
            The footer is placed using `position: static` and as such has no
            knowledge at render of the Global Navigation sidebar. It's up to the
            consuming application to use the `setGlobalNavDisplayLevel` callback
            hook on the Global Navigation component to get the current Global
            Navigation display level and pass that down to the Footer via the
            `globalNavDisplayLevel` prop. Failure to do so will result in the
            Footer and Global Navigation components overlapping when the Global
            Navigation is open.
          </p>
        </NoticePanel>
      </ul>
      <Demo demo={demos.RegularFooter}>
        <RegularFooter />
      </Demo>
      <SectionTitle>Table Action Footer</SectionTitle>
      <ul>
        <li>
          Embbed footer in a table or another component to have it resize with
          the component.
        </li>
      </ul>
      See{" "}
      <a href="/rich-data-table/data-grid-slots#footer">
        Rich Data Table footer demo
      </a>
      <Supporting />
    </Page>
  );
}
