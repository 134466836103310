import React, { Fragment } from "react";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import TextField from "../TextField";
import Chip from "../Chip";
import withStyles from "../../styles/withStyles";
import { SelectStyle } from "../../utils/styleKeys";
import Checkbox from "../../core-components/Checkbox";

function Select({
  // TextField props
  autoFocus,
  color,
  error,
  FormHelperTextProps,
  fullWidth,
  helperText,
  inputRef,
  label,
  margin,
  multiline,
  name,
  onChange,
  placeholder,
  required = false,
  rows,
  maxRows,
  type,
  variant,

  // Select Props
  className,
  defaultValue,
  disableClearable = false,
  disabled,
  fixedValues,
  getOptionLabel,
  hideMoreTag,
  id,
  multiple,
  options,
  size, // ignore - we don't support multiple sized chips and text fields
  value,
  ...props
}) {
  let handleLabels = option =>
    typeof getOptionLabel !== "undefined" ? getOptionLabel(option) : option;

  let handleOnChange = (e, val, reason) => {
    let allValues = [
      ...fixedValues,
      ...val.filter(option => fixedValues.indexOf(option) === -1)
    ];
    onChange(e, allValues, reason);
  };

  return (
    <MuiAutocomplete
      autoComplete
      className={`${className} ${multiple ? "MuiAutocomplete-multiple" : ""}`}
      defaultValue={defaultValue}
      disableClearable={required || disableClearable}
      disableCloseOnSelect={multiple}
      disabled={disabled}
      fullWidth={fullWidth}
      getOptionLabel={getOptionLabel}
      getLimitTagsText={numTags => (
        <span>{`+${numTags} ${!hideMoreTag ? "more" : ""}`}</span>
      )}
      id={id}
      includeInputInList
      multiple={multiple}
      onChange={fixedValues ? handleOnChange : onChange}
      options={options}
      value={value}
      renderInput={inputProps => (
        <TextField
          autoFocus={autoFocus}
          color={color}
          error={error}
          FormHelperTextProps={FormHelperTextProps}
          helperText={helperText}
          inputRef={inputRef}
          label={label}
          margin={margin}
          multiline={multiline}
          placeholder={placeholder}
          required={required}
          rows={rows}
          maxRows={maxRows}
          type={type}
          variant={variant}
          {...inputProps}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          let tagProps = getTagProps({ index });
          tagProps.disabled =
            tagProps.disabled ||
            (fixedValues &&
              fixedValues.length > 0 &&
              fixedValues.indexOf(option) > -1);

          return <Chip {...tagProps}>{handleLabels(option)}</Chip>;
        })
      }
      renderOption={(option, { selected }) => {
        const label = handleLabels(option);
        return multiple ? (
          <Fragment>
            <div key={label} className={"MuiAutocomplete-label"}>
              <Checkbox checked={selected} value={label} />
              {label}
            </div>
          </Fragment>
        ) : (
          <Fragment>{label}</Fragment>
        );
      }}
      {...props}
    />
  );
}

let styles = theme => ({
  ...SelectStyle,
  root: {
    width: "250px",
    "&.MuiAutocomplete-multiple": {
      minWidth: "250px",
      width: "unset"
    }
  },
  // TODO: pending MUI fix for fullWidth prop being applied to all selects
  fullWidth: {
    width: "100%"
  },
  inputRoot: {
    // outlined
    "&.MuiOutlinedInput-root": {
      // single select
      padding: "9px 40px 8px 8px",
      // multi select
      ".MuiAutocomplete-multiple &": {
        minHeight: "36px",
        padding: "4px 60px 4px 4px",
        "& .MuiAutocomplete-input": {
          padding: "unset",
          margin: "4px 4px 5px"
        }
      }
    },

    // underlined
    "&.MuiInput-root": {
      // single select
      padding: "9px 24px 8px 0",
      "& .MuiAutocomplete-input:first-child": {
        padding: "unset"
      },
      // multi select
      ".MuiAutocomplete-multiple &": {
        minHeight: "36px",
        padding: "4px 56px 4px 0",
        "& .MuiAutocomplete-input": {
          padding: "unset",
          margin: "4px 4px 5px"
        }
      }
    }
  },
  tag: {
    height: "24px",
    padding: 0,
    margin: "2px",
    maxWidth: "calc(100% - 8px)",
    display: "flex",
    alignItems: "center",
    "& > span": {
      color: theme.palette.grey[600],
      fontSize: "14px",
      fontWeight: "600"
    },
    "& .MuiChip-label": {
      paddingLeft: "8px",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  paper: {
    margin: 0
  },
  option: {
    display: "flex",
    padding: "8px 16px",
    "& .MuiAutocomplete-label": {
      display: "flex",
      gap: "8px",
      alignItems: "center"
    },
    "& .MuiCheckbox-root": {
      padding: "6px"
    },
    "& svg.MuiSvgIcon-root.Mui-active": { fill: theme.palette.primary.main },
    "&:active, &[aria-selected='true']": {
      backgroundColor: theme.palette.primary.active
    },
    "&:hover, &[data-focus='true']": {
      backgroundColor: theme.palette.primary.hover
    }
  }
});

Select.propTypes = Object.assign({}, MuiAutocomplete.propTypes, {
  fixedValues: function (props, propName, componentName) {
    if (
      props.hasOwnProperty("fixedValues") &&
      !(props.hasOwnProperty("value") || props.hasOwnProperty("onChange"))
    ) {
      return new Error(
        `${componentName}: [value, onChange] are required to use the fixedValues functionality.`
      );
    }
  }
});

Select.defaultProps = Object.assign({}, MuiAutocomplete.defaultProps, {
  className: "",
  hideMoreTag: false
});

export default withStyles(styles, { name: "MuiAutocomplete" })(Select);
