import React, { useState, useRef } from "react";
import DataGrid from "../../core-components/DataGrid";

import Footer from "../RichDataTableSlots/Footer";
import Toolbar from "../RichDataTableSlots/Toolbar";
import LoadingOverlay from "../RichDataTableSlots/LoadingOverlay";
import NoRowsOverlay from "../RichDataTableSlots/NoRowsOverlay";
import NoResultsOverlay from "../RichDataTableSlots/NoResultsOverlay";

import makeStyles from "../../styles/makeStyles";
import "./index.module.css";
import { RDTDeprecationWarning } from "../../utils/internal";

const MENU_COLUMN_FIELD = "rdtRowMenu";

export default function RichDataTable({
  apiRef,
  columns,
  rows = [],
  sidePanelOpen,
  sidePanelWidth,

  height = null,
  pagination = false,
  components,

  // dg slots
  footerActions,
  footerProps = {},
  toolbarActions,
  toolbarProps = {},
  settingsMenu,
  noRowsErrorText = "There is no data to display",
  noSearchResultsErrorText = "There are no results for this search",
  ...props
}) {
  const [selectAllClicked, setSelectAllClicked] = useState(false);

  const gridWrapperRef = useRef(null);
  useStyles({ sidePanelOpen, sidePanelWidth });

  rows.forEach((row, i) => (row[MENU_COLUMN_FIELD] = row.id));

  const getHeightStyles = h => {
    if (typeof h === "string") return { height: h };
    if (typeof h === "number") return { height: `${h}px` };
    return null;
  };

  RDTDeprecationWarning();
  return (
    <div data-testid="RichDataTable" className="RichDataTable">
      <div
        className="RichDataTable__table"
        style={height && getHeightStyles(height)}
        ref={gridWrapperRef}
      >
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          components={{
            Toolbar: () => (
              <Toolbar
                actions={toolbarActions}
                settingsMenu={settingsMenu}
                {...toolbarProps}
              />
            ),
            Footer: () => (
              <Footer
                actions={footerActions}
                pagination={pagination}
                selectAllClicked={selectAllClicked}
                setSelectAllClicked={setSelectAllClicked}
                {...footerProps}
              />
            ),
            NoRowsOverlay: () => <NoRowsOverlay errorText={noRowsErrorText} />,
            NoResultsOverlay: () => (
              <NoResultsOverlay errorText={noSearchResultsErrorText} />
            ),
            LoadingOverlay: () => <LoadingOverlay />,
            ...components
          }}
          pagination={pagination}
          checkboxSelection
          disableSelectionOnClick
          disableColumnSelector
          {...props}
        />
      </div>
    </div>
  );
}

const useStyles = makeStyles(theme => ({
  "@global": {
    ".RichDataTable": {
      width: ({ sidePanelOpen, sidePanelWidth }) =>
        sidePanelOpen ? `calc(100% - ${sidePanelWidth})` : "100%",
      overflowY: "hidden",
      transition: ({ sidePanelOpen }) =>
        sidePanelOpen
          ? theme.transitions.common.enteringScreen
          : theme.transitions.common.leavingScreen,

      // .RichDataTable__table
      "&__table": {
        width: "auto",
        display: "flex",
        position: "relative"
      }
    }
  }
}));

export * from "../../core-components/DataGrid";
